import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A big decimal (or a float) */
  BigDecimal: any;
  /** A number formatted to pt-BR BRL currency format, eg. R$ 100,00. */
  Currency: any;
  Date: any;
  /** A number formatted to percentual format, eg. +50,00%. */
  Percentual: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptTradeOutsideRiskProfileInput = {
  /** the ticker of the instrument */
  instrumentSymbol: Scalars['String'];
  /** the risk of the instrument. If empty, it will be fetched using the symbol */
  instrumentRiskProfile?: Maybe<Scalars['String']>;
};

export type AllocationBySubtype = {
  __typename?: 'AllocationBySubtype';
  name?: Maybe<Scalars['String']>;
  allocations?: Maybe<Array<Maybe<Allocations>>>;
};

export type AllocationCategories = {
  __typename?: 'AllocationCategories';
  isRoot?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  percent?: Maybe<Scalars['Float']>;
};

export type Allocations = {
  __typename?: 'Allocations';
  isRoot?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  percent?: Maybe<Scalars['Float']>;
  apiId?: Maybe<Scalars['String']>;
  boxApiId?: Maybe<Scalars['String']>;
};

/** Balance data that will be displayed on summary component */
export type Balance = {
  __typename?: 'Balance';
  /** Customer's/Portfolio's total balance */
  totalBalance?: Maybe<FormattableBigDecimal>;
  /** Customer's/Portfolio's net balance */
  netBalance?: Maybe<FormattableBigDecimal>;
  /** Customer's/Portfolio's total earnings */
  totalEarnings?: Maybe<FormattableBigDecimal>;
  /** Customer's/Portfolio's current earnings */
  currentEarnings?: Maybe<FormattableBigDecimal>;
  /** Customer's/Portfolio's net earnings */
  netEarnings?: Maybe<FormattableBigDecimal>;
  /** Customer's/Portfolio's last date that the balance was update (withdraw, deposit, etc) */
  balanceLastUpdatedAt?: Maybe<FormattableDate>;
  /** It's a flag that indicates whether the customer has global portfolio or not */
  hasGlobalPortfolio?: Maybe<Scalars['Boolean']>;
  /** Financial operation tax that's realated to IOF in Brazil */
  financialOperationTax?: Maybe<FormattableBigDecimal>;
  /** Income tax that's realated to IR in Brazil */
  incomeTax?: Maybe<FormattableBigDecimal>;
  /** Total ammount invested + managed portfolio account balance */
  totalGrossWorth?: Maybe<FormattableBigDecimal>;
  /** Total ammount invested */
  grossWorth?: Maybe<FormattableBigDecimal>;
  /** Managed portfolio account balance */
  accountAvailableBalance?: Maybe<FormattableBigDecimal>;
  /** Partner tax already payed */
  managementTax?: Maybe<FormattableBigDecimal>;
  /** Pending partner tax */
  pendingManagementTax?: Maybe<FormattableBigDecimal>;
  /** Administration tax already payed */
  administrationTax?: Maybe<FormattableBigDecimal>;
  /** Pending administration tax */
  pendingAdministrationTax?: Maybe<FormattableBigDecimal>;
  /** Total ammount invested earnings */
  totalGrossEarnings?: Maybe<FormattableBigDecimal>;
  /** Total ammount invested net earnings */
  totalNetEarnings?: Maybe<FormattableBigDecimal>;
  /** Total net worth excluding taxes */
  netWorth?: Maybe<FormattableBigDecimal>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CalculateWarnings = {
  __typename?: 'CalculateWarnings';
  /** apiCodeError value */
  apiCodeError: Scalars['String'];
  /** message label */
  message: Scalars['String'];
};

export type CashTransactionsSummaryArgs = {
  range?: Maybe<DateRangeInput>;
};

export type CashChartArgs = {
  options?: Maybe<CashChartIput>;
};

export type CashExtractArgs = {
  options?: Maybe<CashExtractOptionsInput>;
};

export type CashLastTransactionsArgs = {
  options?: Maybe<CashLastTransactionsOptions>;
};

export type CashBankAccount = {
  __typename?: 'CashBankAccount';
  /** Account holder name. */
  name: Scalars['String'];
  /** Account holder document. */
  document: Scalars['String'];
  /** Bank name. */
  bankName: Scalars['String'];
  /** Bank code. */
  bankCode: Scalars['String'];
  /** Full agency number with digit */
  agency: Scalars['String'];
  /** Agency number. */
  agencyNumber: Scalars['String'];
  /** Agency digit. */
  agencyDigit: Scalars['String'];
  /** Full account number with digit */
  account: Scalars['String'];
  /** Account number. */
  accountNumber: Scalars['String'];
  /** Account digit. */
  accountDigit: Scalars['String'];
  /** Bank account UI labels. */
  labels: CashBankAccountLabels;
};

export type CashBankAccountLabels = {
  __typename?: 'CashBankAccountLabels';
  /** Name label, eg: Company name, N, etc. */
  name: Scalars['String'];
  /** Document label, eg: CPF, CNPJ, RG, etc. */
  document: Scalars['String'];
};

export type CashChart = {
  __typename?: 'CashChart';
  /** availabe filters to this chart */
  filters: Array<CashChartFilter>;
  /** default selected chart filter */
  defaultFilter: Scalars['Int'];
  /** available types for this chart */
  types: Array<ECashChartType>;
  /** default chart type */
  defaultChartType: Scalars['Int'];
  /** axis y data */
  axisY: Array<CashChartAxisY>;
  /** default selected axis y */
  defaultAxisY: Scalars['Int'];
  /** axis x data */
  axisX: Array<CashChartAxisX>;
  /** default selected axis x */
  defaultAxisX: Scalars['Int'];
};

export type CashChartTypesArgs = {
  options?: Maybe<CashChartTypesInput>;
};

export type CashChartAxisX = {
  __typename?: 'CashChartAxisX';
  /** axis key */
  key: ECashChartAxisX;
  /** axis key's description */
  description: Scalars['String'];
  /** axis values */
  values: Array<Maybe<FormattableBigDecimal>>;
};

export type CashChartAxisY = {
  __typename?: 'CashChartAxisY';
  /** axis key */
  key: ECashChartAxisY;
  /** axis key's description formatted */
  description: Scalars['String'];
  /** axis y date values formated as dates */
  values: Array<Scalars['String']>;
};

export type CashChartAxisYValuesArgs = {
  format?: Maybe<Scalars['String']>;
};

export type CashChartFilter = {
  __typename?: 'CashChartFilter';
  /** filter key; eg. ONE_MONTH */
  key: ECashChartFilter;
  /** filter description; eg. 30 dias */
  description: Scalars['String'];
};

export type CashChartIput = {
  /** cash chart filter */
  filter?: Maybe<ECashChartFilter>;
};

export type CashChartTypesInput = {
  /** limits the number of types returned by query result; defaults to 2 */
  limit?: Maybe<Scalars['Int']>;
};

/** cash transactions grouped by date */
export type CashExtractDate = {
  __typename?: 'CashExtractDate';
  /** current date formatted as sting */
  date: Scalars['String'];
  /** cash account balance for current date */
  balance: FormattableBigDecimal;
  /** list os cash transactions for current date */
  transactions: Array<CashTransaction>;
};

/** cash transactions grouped by date */
export type CashExtractDateDateArgs = {
  format?: Maybe<Scalars['String']>;
};

export type CashExtractOptionsInput = {
  /** date range to filter transactions, default to last month */
  range?: Maybe<DateRangeInput>;
  /** pagination info */
  page?: Maybe<PageInfoInput>;
};

export type CashExtractPaginated = {
  __typename?: 'CashExtractPaginated';
  /** current page info */
  page: PageInfo;
  /** list of dates in the extract */
  list: Array<CashExtractDate>;
};

export type CashLastTransactions = {
  __typename?: 'CashLastTransactions';
  /** last transactions from cash account */
  transactions: Array<CashTransaction>;
  /** true if there is more transactions than returned */
  hasMore: Scalars['Boolean'];
};

export type CashLastTransactionsOptions = {
  /** number of transactions to be returned */
  limit?: Maybe<Scalars['Int']>;
};

export type CashStatus = {
  __typename?: 'CashStatus';
  /** true if customer has an investment account created */
  enabled?: Maybe<Scalars['Boolean']>;
  /** customer's investment account id */
  investmentAccountId?: Maybe<Scalars['ID']>;
};

export type CashSummary = {
  __typename?: 'CashSummary';
  /** total balance of cash account */
  totalBalance: FormattableBigDecimal;
  /** net balance of cash account */
  netBalance: FormattableBigDecimal;
  /** total earnings of cash account */
  currentEarnings: FormattableBigDecimal;
  /** net earnings of cash account  */
  currentNetEarnings: FormattableBigDecimal;
  /** income tax */
  incomeTax: FormattableBigDecimal;
  /** transactions tax */
  transactionTax: FormattableBigDecimal;
  /** administration tax */
  administrationTax: FormattableBigDecimal;
  /** message with the index return rate of the cash account */
  marketIndexMessage: Scalars['String'];
};

export type CashTransaction = {
  __typename?: 'CashTransaction';
  /** transaction's unique identifier */
  id: Scalars['ID'];
  /** transaction's type */
  type: CashTransactionType;
  /** transaction's status */
  status: CashTransactionStatus;
  /** transaction's amount */
  amount: FormattableBigDecimal;
  /** transaction's date formatted as tring */
  date: Scalars['String'];
  /** transaction's description */
  description?: Maybe<Scalars['String']>;
};

export type CashTransactionDateArgs = {
  format?: Maybe<Scalars['String']>;
};

export type CashTransactionStatus = {
  __typename?: 'CashTransactionStatus';
  /** transaction status enum key */
  value?: Maybe<ECashTransactionStatus>;
  /** transaction status formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type CashTransactionType = {
  __typename?: 'CashTransactionType';
  /** transaction type enum key (from core) */
  value?: Maybe<ECashTransactionType>;
  /** transaction type formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type CashTransactionsSummary = {
  __typename?: 'CashTransactionsSummary';
  /** balance from last transactions  */
  totalBalance?: Maybe<FormattableBigDecimal>;
  /** earnings from last transactions */
  totalEarnings?: Maybe<FormattableBigDecimal>;
  /** amount of incoming transactions  */
  totalIncoming?: Maybe<FormattableBigDecimal>;
  /** amount of outgoing transactions */
  totalOutgoing?: Maybe<FormattableBigDecimal>;
};

export type Channels = {
  __typename?: 'Channels';
  /** customer's identifier */
  customerId: Scalars['ID'];
  /** feed */
  newsFeed: Array<Maybe<News>>;
  /** new history */
  newsHistory: NewsHistory;
};

export type ChannelsNewsFeedArgs = {
  customerId?: Maybe<Scalars['String']>;
};

export type ChannelsNewsHistoryArgs = {
  customerId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type ChannelsMutation = {
  __typename?: 'ChannelsMutation';
  /** customer's identifier */
  customerId: Scalars['ID'];
  /** update news */
  updateNews: News;
};

export type ChannelsMutationUpdateNewsArgs = {
  input: ChannelsUpdateNewsInput;
};

export type ChannelsUpdateNewsInput = {
  /** News identifier */
  id: Scalars['ID'];
  /** see will count one more view to the news on feed */
  see?: Maybe<Scalars['Boolean']>;
  /** read will count one more view to the news article */
  read?: Maybe<Scalars['Boolean']>;
  /** dismiss will count one more dismiss and remove from feed */
  dismiss?: Maybe<Scalars['Boolean']>;
};

export type ChargingFeeDetail = {
  __typename?: 'ChargingFeeDetail';
  /** The id of the transaction your are trying to query */
  transactionId: Scalars['ID'];
  /** transaction amount according to FormattableBigDecimal */
  amount?: Maybe<FormattableBigDecimal>;
  /** transaction date according to FormattableDate */
  date?: Maybe<FormattableDate>;
  status?: Maybe<TransactionStatus>;
  type: TransactionType;
  /** A short description about transaction */
  description?: Maybe<Scalars['String']>;
};

export type Chart = {
  __typename?: 'Chart';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ChartFormatted = {
  __typename?: 'ChartFormatted';
  label?: Maybe<FormattableAllocationsType>;
  allocValue?: Maybe<FormattableAllocationsValue>;
  subType?: Maybe<Array<Maybe<SubType>>>;
};

export type ChartResume = {
  __typename?: 'ChartResume';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  subtypes?: Maybe<Array<Maybe<AllocationBySubtype>>>;
};

export type ChartResumeFormatted = {
  __typename?: 'ChartResumeFormatted';
  label?: Maybe<FormattableAllocationsType>;
  allocValue?: Maybe<FormattableAllocationsValue>;
  subType?: Maybe<Array<Maybe<SubTypeResume>>>;
};

/** Someone's contact information */
export type ContactInformation = {
  __typename?: 'ContactInformation';
  /** The full name of the person e.g. Leonardo Vogel Dalcin */
  fullName?: Maybe<Scalars['String']>;
  /** The email of the contact, e.g. leonardo.dalcin@warrenbrasil.com */
  email?: Maybe<Scalars['String']>;
};

export enum CoreMarketIndicators {
  Savings = 'SAVINGS',
  Cdi = 'CDI',
  Ipca = 'IPCA',
  Portfolio = 'PORTFOLIO'
}

export enum CreationOptionType {
  ManagedPortfolio = 'MANAGED_PORTFOLIO',
  CustomPortfolio = 'CUSTOM_PORTFOLIO'
}

/** The status of a product dash, indicated if user needs to take an action to activate account  */
export type DashStatus = {
  __typename?: 'DashStatus';
  /** This card displays a message with and button to an action */
  card?: Maybe<DashStatusCard>;
};

/** The card data and action to be redered  */
export type DashStatusCard = {
  __typename?: 'DashStatusCard';
  /** A message to e displayed to user */
  message?: Maybe<Scalars['String']>;
  /** A title to be displayed to user  */
  title?: Maybe<Scalars['String']>;
  /** Image to be rendered */
  image?: Maybe<DashStatusCardImage>;
  /** Button label to be rendered to user */
  button?: Maybe<Scalars['String']>;
  /** Card and button action */
  action?: Maybe<Scalars['String']>;
};

export type DashStatusCardImage = {
  __typename?: 'DashStatusCardImage';
  /** Image to be used by mobile clients */
  mobile?: Maybe<DashStatusCardImagePlatform>;
  /** Image to be used by web clients */
  web?: Maybe<DashStatusCardImagePlatform>;
};

export type DashStatusCardImagePlatform = {
  __typename?: 'DashStatusCardImagePlatform';
  /** Full resolution image  */
  full?: Maybe<Scalars['String']>;
  /** Regular resolution image  */
  regular?: Maybe<Scalars['String']>;
  /** Small resolution image  */
  small?: Maybe<Scalars['String']>;
};

export type DateRangeInput = {
  /** init date of the range, should be in YYYY-MM-DD format */
  initDate: Scalars['String'];
  /** end date of the range, should be in YYYY-MM-DD format */
  endDate: Scalars['String'];
};

export type DepositDetail = {
  __typename?: 'DepositDetail';
  /** The id of the transaction your are trying to query */
  transactionId?: Maybe<Scalars['ID']>;
  /** transaction date according to FormattableDate */
  date?: Maybe<FormattableDate>;
  /** transaction amount according to FormattableBigDecimal */
  amount?: Maybe<FormattableBigDecimal>;
  /** transaction creation date according to FormattableDate */
  createdAt?: Maybe<FormattableDate>;
  /** transaction operation date according to FormattableDate */
  operatedAt?: Maybe<FormattableDate>;
  /** transaction convertion date according to FormattableDate */
  convertedAt?: Maybe<FormattableDate>;
  /** transaction liquidation date according to FormattableDate */
  liquidatedAt?: Maybe<FormattableDate>;
  isPending?: Maybe<Scalars['Boolean']>;
  isToAccount?: Maybe<Scalars['Boolean']>;
  isFromImpersonate?: Maybe<Scalars['Boolean']>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  useCurrencyAccountBalance?: Maybe<Scalars['Boolean']>;
  isTreasury?: Maybe<Scalars['Boolean']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryCNPJ?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatus>;
  type?: Maybe<TransactionType>;
  depositIdentifier?: Maybe<Scalars['Int']>;
  /** Invoice's bar code */
  barCode?: Maybe<Scalars['String']>;
  /** Invoice's bar code image */
  barCodeImage?: Maybe<Scalars['String']>;
  /** Invoice's bar code pdf url */
  docToPDF?: Maybe<Scalars['String']>;
  /** Check if deposit is by invoice */
  isDepositByInvoice?: Maybe<Scalars['Boolean']>;
  /** Portfolio`s name */
  portfolioName?: Maybe<Scalars['String']>;
  /** All the portfolio's related to the deposit */
  transactionInvest?: Maybe<Array<Maybe<ITransactionInvest>>>;
  /** Check if the invoice info is ready */
  isInvoiceProcessing?: Maybe<Scalars['Boolean']>;
};

export enum ECashChartAxisX {
  Deposits = 'DEPOSITS',
  Earnings = 'EARNINGS',
  Balance = 'BALANCE'
}

export enum ECashChartAxisY {
  Dates = 'DATES'
}

export enum ECashChartFilter {
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH'
}

export enum ECashChartType {
  Bars = 'BARS'
}

export enum ECashTransactionStatus {
  Processing = 'processing',
  Accepted = 'accepted',
  Scheduled = 'scheduled',
  Canceled = 'canceled'
}

export enum ECashTransactionType {
  Invoice = 'invoice',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Transfer = 'transfer',
  Refund = 'refund',
  Payment = 'payment',
  ChargingFees = 'chargingFees',
  ExchangeTransfer = 'exchangeTransfer'
}

export enum EGlobalSummaryChartFilter {
  ThreeHundredSixtyFiveDays = 'threeHundredSixtyFiveDays',
  HundredEightyDays = 'hundredEightyDays',
  NinetyDays = 'ninetyDays',
  ThirtyDays = 'thirtyDays',
  Year = 'year',
  All = 'all'
}

export enum EGlobalSummaryChartType {
  Lines = 'LINES'
}

export enum EGlobalSummaryOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum EGlobalSummaryPortfolioStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED',
  Pending = 'PENDING'
}

export enum EGlobalSummaryProductClassType {
  FixedIncome = 'fixedIncome',
  VariableIncome = 'variableIncome',
  Multimarket = 'multimarket',
  Others = 'others'
}

export enum EGlobalTransactionModule {
  Overview = 'overview',
  Cash = 'cash',
  Wealth = 'wealth',
  Trade = 'trade'
}

export enum EGlobalTransactionOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum EGlobalTransactionOrderBy {
  CreatedDate = 'createdDate',
  TransactionDate = 'transactionDate',
  Value = 'value',
  LiquidateAndCreatedDate = 'liquidateAndCreatedDate'
}

export enum EGlobalTransactionType {
  Deposit = 'deposit',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
  Payment = 'payment',
  ChargingFees = 'chargingFees',
  AssetTransfer = 'assetTransfer',
  ExchangeTransfer = 'exchangeTransfer',
  InstrumentEvent = 'instrumentEvent',
  AutoDebit = 'autoDebit',
  CashbackPayment = 'cashbackPayment',
  CashbackReinvestment = 'cashbackReinvestment',
  ElliotTransfer = 'elliotTransfer',
  FinancialInstitutionTransfer = 'financialInstitutionTransfer',
  Refund = 'refund',
  Pix = 'pix',
  PixReturn = 'pixReturn',
  PixScheduled = 'pixScheduled',
  Spb = 'spb',
  Unknown = 'unknown'
}

export enum EGlobalTransactionTypeV2 {
  TradeWithdrawTransfer = 'tradeWithdrawTransfer',
  TradeInvestmentTransfer = 'tradeInvestmentTransfer',
  WithdrawTransfer = 'withdrawTransfer',
  InvestmentTransfer = 'investmentTransfer',
  Deposit = 'deposit',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
  Payment = 'payment',
  ChargingFees = 'chargingFees',
  AssetTransfer = 'assetTransfer',
  ExchangeTransfer = 'exchangeTransfer',
  InstrumentEvent = 'instrumentEvent',
  AutoDebit = 'autoDebit',
  CashbackPayment = 'cashbackPayment',
  CashbackReinvestment = 'cashbackReinvestment',
  ElliotTransfer = 'elliotTransfer',
  FinancialInstitutionTransfer = 'financialInstitutionTransfer',
  Refund = 'refund',
  Pix = 'pix',
  PixReturn = 'pixReturn',
  PixScheduled = 'pixScheduled',
  Spb = 'spb',
  Unknown = 'unknown'
}

export enum EGlobalTransactionsMovementTypeFilter {
  Input = 'INPUT',
  Output = 'OUTPUT',
  All = 'ALL'
}

export enum ENewsType {
  General = 'general',
  Customer = 'customer'
}

export enum EParticipationFlowType {
  Claimant = 'CLAIMANT',
  Donor = 'DONOR'
}

export enum EPixAccountType {
  Current = 'CURRENT',
  Savings = 'SAVINGS',
  Salary = 'SALARY'
}

export enum EPixCustomerStatus {
  PixOnboarding = 'PIX_ONBOARDING',
  PixReadyToUse = 'PIX_READY_TO_USE',
  PixFacematchProcessing = 'PIX_FACEMATCH_PROCESSING',
  PixFacematchInvalid = 'PIX_FACEMATCH_INVALID'
}

export enum EPixDocumentLabel {
  Cpf = 'CPF',
  Cnpj = 'CNPJ'
}

export enum EPixEntityKey {
  Claim = 'CLAIM',
  Key = 'KEY'
}

export enum EPixKeyMechanism {
  Key = 'KEY',
  StaticQrCode = 'STATIC_QR_CODE',
  DynamicQrCode = 'DYNAMIC_QR_CODE'
}

export enum EPixKeyRequiredAction {
  Claim = 'CLAIM',
  Portability = 'PORTABILITY',
  SmsVerification = 'SMS_VERIFICATION',
  EmailVerification = 'EMAIL_VERIFICATION'
}

export enum EPixKeyStatus {
  PendingRegister = 'PENDING_REGISTER',
  Registered = 'REGISTERED',
  PendingExclusion = 'PENDING_EXCLUSION',
  Excluded = 'EXCLUDED',
  ClaimPending = 'CLAIM_PENDING',
  ClaimOpened = 'CLAIM_OPENED',
  ClaimAwaitingResolution = 'CLAIM_AWAITING_RESOLUTION',
  ClaimConfirmed = 'CLAIM_CONFIRMED',
  ClaimCanceled = 'CLAIM_CANCELED',
  ClaimCompleted = 'CLAIM_COMPLETED',
  Error = 'ERROR'
}

export enum EPixKeyType {
  Cpf = 'CPF',
  Cnpj = 'CNPJ',
  Email = 'EMAIL',
  Mobile = 'MOBILE',
  AleatoryKey = 'ALEATORY_KEY'
}

export enum EPixPersonType {
  Physical = 'PHYSICAL',
  Legal = 'LEGAL'
}

export enum EPixSlipReceiptType {
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum ERiskGrades {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum ETickerPriceHistoryPeriod {
  LastFiveYears = 'LAST_FIVE_YEARS',
  LastYear = 'LAST_YEAR',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  IntraDay = 'INTRA_DAY'
}

export enum ETradeButtonAction {
  Search = 'SEARCH',
  Transaction = 'TRANSACTION',
  Deposit = 'DEPOSIT',
  DepositConversation = 'DEPOSIT_CONVERSATION',
  Withdrawal = 'WITHDRAWAL'
}

export enum ETradeChartAxisX {
  Dates = 'DATES'
}

export enum ETradeChartAxisY {
  Earnings = 'EARNINGS',
  Portfolio = 'PORTFOLIO',
  Projection = 'PROJECTION',
  Planning = 'PLANNING',
  Deposits = 'DEPOSITS',
  DepositsProjection = 'DEPOSITS_PROJECTION',
  TotalBalance = 'TOTAL_BALANCE',
  Savings = 'SAVINGS',
  Ipca = 'IPCA',
  Cdi = 'CDI'
}

/** CHART */
export enum ETradeChartFilter {
  Default = 'DEFAULT',
  ThirtyDays = 'THIRTY_DAYS',
  TwelveMonths = 'TWELVE_MONTHS'
}

export enum ETradeChartType {
  Lines = 'LINES'
}

export enum ETradeDataSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ETradeDirection {
  Buy = 'Buy',
  Sell = 'Sell'
}

/** STATUS */
export enum ETradeHomeBrokerStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Requested = 'Requested'
}

export enum ETradeMarketDataSymbolStatus {
  Opened = 'Opened',
  Suspended = 'Suspended',
  Frozen = 'Frozen',
  Inhibited = 'Inhibited',
  Auctioned = 'Auctioned',
  OpenReprogramed = 'OpenReprogramed',
  Closed = 'Closed',
  Unknown = 'Unknown',
  NightProcess = 'NightProcess',
  Preparation = 'Preparation',
  PreClosing = 'PreClosing',
  Promoter = 'Promoter',
  EoDconsulting = 'EODconsulting',
  PreOpening = 'PreOpening',
  Aftermarket = 'Aftermarket',
  Trading = 'Trading',
  Impedido = 'Impedido',
  Bovespa = 'Bovespa',
  Interrupted = 'Interrupted',
  None = 'None'
}

/** MARKET AVAILABLE */
export enum ETradeMarketStatus {
  MarketClosedDate = 'MARKET_CLOSED_DATE',
  MarketClosedHour = 'MARKET_CLOSED_HOUR',
  MarketClosedSpHoliday = 'MARKET_CLOSED_SP_HOLIDAY',
  MarketUnavailable = 'MARKET_UNAVAILABLE',
  MarketAvailable = 'MARKET_AVAILABLE'
}

export enum ETradeMovementStatus {
  Processing = 'PROCESSING',
  Accepted = 'ACCEPTED',
  Scheduled = 'SCHEDULED',
  Canceled = 'CANCELED',
  Placing = 'PLACING',
  Inactive = 'INACTIVE',
  Working = 'WORKING',
  Rejected = 'REJECTED',
  Filled = 'FILLED',
  PartiallyFilled = 'PARTIALLY_FILLED',
  Expired = 'EXPIRED'
}

export enum ETradeMovementType {
  Order = 'Order',
  Transaction = 'Transaction',
  Dividend = 'Dividend',
  Interest = 'Interest',
  Yield = 'Yield',
  Bonus = 'Bonus',
  Amortization = 'Amortization',
  AssetBonus = 'AssetBonus',
  Fee = 'Fee',
  AssetTransfer = 'AssetTransfer',
  Cashback = 'Cashback',
  Reimburse = 'Reimburse',
  InternalOccurrence = 'InternalOccurrence',
  BmfFinancialEntry = 'BmfFinancialEntry',
  Subscription = 'Subscription',
  Inplit = 'Inplit',
  Split = 'Split',
  Incorporation = 'Incorporation',
  Custody = 'Custody',
  RegisteredSecuritiesLoan = 'RegisteredSecuritiesLoan',
  LoanEventsFees = 'LoanEventsFees',
  LoanEventsRefund = 'LoanEventsRefund',
  ForwardOperations = 'ForwardOperations',
  FractionSecuritiesCredit = 'FractionSecuritiesCredit',
  FractionSecuritiesRefund = 'FractionSecuritiesRefund',
  SubscriptionLeftovers = 'SubscriptionLeftovers',
  MrpContribuition = 'MrpContribuition',
  RepurchaseAdjust = 'RepurchaseAdjust',
  StockOptions = 'StockOptions',
  IpoProvisioning = 'IpoProvisioning',
  AssetDeliveryRelated = 'AssetDeliveryRelated',
  OtherExpensesFee = 'OtherExpensesFee',
  WithdrawalVariableIncomePayment = 'WithdrawalVariableIncomePayment',
  IrfFee = 'IrfFee',
  JudicialLock = 'JudicialLock',
  FineDebtBalance = 'FineDebtBalance',
  OperationalError = 'OperationalError',
  ReturnFees = 'ReturnFees',
  AssetUpdate = 'AssetUpdate',
  Generic = 'Generic'
}

export enum ETradeNegotiationButtonState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Action = 'ACTION'
}

export enum ETradeNegotiationStatus {
  ActivateMfa = 'ACTIVATE_MFA',
  UpdateToRegulation = 'UPDATE_TO_REGULATION',
  MarketClosed = 'MARKET_CLOSED',
  NoneTradeAccount = 'NONE_TRADE_ACCOUNT',
  TradeUnavailable = 'TRADE_UNAVAILABLE',
  None = 'NONE'
}

export enum ETradeOrderStatus {
  Placing = 'Placing',
  Inactive = 'Inactive',
  Working = 'Working',
  Rejected = 'Rejected',
  Filled = 'Filled',
  PartiallyFilled = 'Partially_filled',
  Cancelled = 'Cancelled',
  Expired = 'Expired'
}

export enum ETradeOrderType {
  Limit = 'Limit',
  Market = 'Market',
  Stop = 'Stop',
  StopLimit = 'StopLimit',
  Subscription = 'Subscription',
  MarketWithLeftoverAsLimt = 'MarketWithLeftoverAsLimt'
}

export enum ETradeRiskTolerance {
  Passive = 'PASSIVE',
  Moderated = 'MODERATED',
  Aggressive = 'AGGRESSIVE'
}

export enum ETradeTransactionDirection {
  Credit = 'Credit',
  Debit = 'Debit'
}

export enum ETradeTransactionStatus {
  Processing = 'processing',
  Accepted = 'accepted',
  Scheduled = 'scheduled',
  Canceled = 'canceled'
}

export enum ETradeTransactionType {
  Doc = 'Doc',
  Ted = 'Ted',
  Invoice = 'Invoice',
  DirectDebit = 'DirectDebit',
  WithDrawal = 'WithDrawal',
  Investment = 'INVESTMENT',
  PortfoliosTransfer = 'PORTFOLIOS_TRANSFER',
  ChargingFees = 'ChargingFees',
  AutoDebit = 'AutoDebit',
  Refund = 'Refund',
  ElliotTransfer = 'ElliotTransfer',
  FinancialInstitutionTransfer = 'FinancialInstitutionTransfer',
  PaymentBankSlip = 'PaymentBankSlip',
  CashbackReinvestment = 'CashbackReinvestment',
  CashbackPayment = 'CashbackPayment',
  InstrumentEvent = 'InstrumentEvent',
  AssetTransfer = 'AssetTransfer',
  AssetUpdate = 'AssetUpdate'
}

/** WATCHLIST */
export enum ETradeWatchlistDirection {
  Include = 'INCLUDE',
  Remove = 'REMOVE'
}

export enum ETransactionStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED'
}

export enum ETransactionType {
  Deposit = 'DEPOSIT',
  Transfer = 'TRANSFER',
  Withdraw = 'WITHDRAW',
  Order = 'ORDER',
  ChargingFees = 'CHARGING_FEES',
  AssetTransfer = 'ASSET_TRANSFER',
  AssetUpdate = 'ASSET_UPDATE'
}

export enum EWealthChartAxisX {
  Dates = 'DATES'
}

export enum EWealthChartAxisY {
  Portfolio = 'PORTFOLIO',
  Projection = 'PROJECTION',
  Deposits = 'DEPOSITS',
  Cdi = 'CDI',
  Earnings = 'EARNINGS'
}

export type Exchange = {
  __typename?: 'Exchange';
  /** customer's identifier */
  customerId: Scalars['ID'];
  /** list of coins */
  coins: Array<ExchangeCoin>;
  /** list of natures for an exchange */
  natures: Array<ExchangeNature>;
  /** checking if the user is registering with ourinvest */
  register: ExchangeCustomerRegister;
};

export type ExchangeCalculate = {
  __typename?: 'ExchangeCalculate';
  /** nature value */
  codeNatureOperation?: Maybe<Scalars['Int']>;
  /** symbol of base currency */
  symbolBaseCurrency?: Maybe<Scalars['String']>;
  /** symbol of base quote (buy currency) */
  symbolCurrencyQuote?: Maybe<Scalars['String']>;
  /** trade currency amount */
  tradedCurrencyAmount?: Maybe<FormattableBigDecimal>;
  /** exchange rate value */
  exchangeRateValue?: Maybe<FormattableBigDecimal>;
  /** rate value */
  rateValue?: Maybe<FormattableBigDecimal>;
  /** gross value */
  grossValue?: Maybe<FormattableBigDecimal>;
  /** has balance for the operation */
  hasBalance?: Maybe<Scalars['Boolean']>;
  /** iof percentage */
  iofPercentage?: Maybe<Scalars['Float']>;
  /** iof value */
  iofValue?: Maybe<FormattableBigDecimal>;
  /** net value */
  netValue?: Maybe<FormattableBigDecimal>;
  /** vet Code */
  vet?: Maybe<FormattableBigDecimal>;
  /** quote date */
  quoteDate?: Maybe<FormattableDate>;
  /** experation date */
  experationDate?: Maybe<FormattableDate>;
  /** payment date */
  paymentDate?: Maybe<FormattableDate>;
  /** spread percentage */
  spreadPercentage?: Maybe<Scalars['Float']>;
  /** spread value */
  spreadValue?: Maybe<FormattableBigDecimal>;
  /** commercial rate */
  comercialRate?: Maybe<FormattableBigDecimal>;
  /** token value */
  token?: Maybe<Scalars['String']>;
  /** token value */
  warnings: Array<CalculateWarnings>;
};

export type ExchangeCalculateInput = {
  /** value of the operation */
  operationNature: Scalars['Int'];
  /** symbol of the base currency */
  baseCurrencyOut: Scalars['String'];
  /** symbol of the currency quote */
  currencyQuoteOut: Scalars['String'];
  /** amount currency */
  amountCurrencyBase: Scalars['Float'];
};

export type ExchangeCoin = {
  __typename?: 'ExchangeCoin';
  /** value is the symbol coin */
  value: Scalars['String'];
  /** label is the name of the coin */
  label: Scalars['String'];
  /** bacen code */
  bacenCode?: Maybe<Scalars['Int']>;
  /** bacen name */
  bacenName?: Maybe<Scalars['String']>;
  /** precision for exchange */
  precision?: Maybe<Scalars['Int']>;
};

export type ExchangeCustomerRegister = {
  __typename?: 'ExchangeCustomerRegister';
  /** customer is registering? */
  registered?: Maybe<Scalars['Boolean']>;
};

export type ExchangeCustomerRegistration = {
  __typename?: 'ExchangeCustomerRegistration';
  /** customer is registering */
  name?: Maybe<Scalars['String']>;
};

export type ExchangeMutation = {
  __typename?: 'ExchangeMutation';
  customerId: Scalars['ID'];
  /** updates the portfolio status */
  calculate?: Maybe<ExchangeCalculate>;
  /** customer register ourinvest  */
  createCustomer?: Maybe<ExchangeCustomerRegistration>;
};

export type ExchangeMutationCalculateArgs = {
  input: ExchangeCalculateInput;
};

export type ExchangeNature = {
  __typename?: 'ExchangeNature';
  /** nature value */
  value: Scalars['Int'];
  /** nature label */
  label: Scalars['String'];
};

export type FloatFormatableField = {
  __typename?: 'FloatFormatableField';
  value?: Maybe<Scalars['Float']>;
  formatted?: Maybe<Scalars['String']>;
};

export type FormattableAllocationsType = {
  __typename?: 'FormattableAllocationsType';
  value?: Maybe<InstrumentType>;
  formatted?: Maybe<Scalars['String']>;
};

export type FormattableAllocationsTypeResume = {
  __typename?: 'FormattableAllocationsTypeResume';
  value?: Maybe<InstrumentType>;
  formatted?: Maybe<Scalars['String']>;
};

export type FormattableAllocationsValue = {
  __typename?: 'FormattableAllocationsValue';
  value?: Maybe<Scalars['Float']>;
  formatted?: Maybe<Scalars['String']>;
};

export type FormattableAllocationsValueResume = {
  __typename?: 'FormattableAllocationsValueResume';
  value?: Maybe<Scalars['Float']>;
  formatted?: Maybe<Scalars['String']>;
};

/** Formatable big decimal object. */
export type FormattableBigDecimal = {
  __typename?: 'FormattableBigDecimal';
  /** Unformatted big decimal value. */
  value: Scalars['BigDecimal'];
  /** Formatted as currency as string. */
  formatted: Scalars['String'];
};

/** Formatable big decimal object. */
export type FormattableBigDecimalFormattedArgs = {
  options?: Maybe<FormattableBigDecimalOptions>;
};

/** Format output options */
export type FormattableBigDecimalOptions = {
  /** switch if currency sign should be included; default to true. */
  addCurrencySign?: Maybe<Scalars['Boolean']>;
  /** switch if positive sign should be displayed; default to false. */
  addPositiveSign?: Maybe<Scalars['Boolean']>;
  /** number of decimal places */
  decimalPlaces?: Maybe<Scalars['Int']>;
  /** rounding decimal places (example: 24,569 is equal to 24,57) */
  rounding?: Maybe<Scalars['Boolean']>;
  /** BCP 47 tag for identifying languages (example: pt-BR, en-US) */
  locale?: Maybe<Scalars['String']>;
  /** ISO 4217 currency code (example: BRL, USD) */
  currency?: Maybe<Scalars['String']>;
};

export type FormattableBilletLine = {
  __typename?: 'FormattableBilletLine';
  value?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

/** Formattable date object. */
export type FormattableDate = {
  __typename?: 'FormattableDate';
  /** Timestamp date value. */
  value: Scalars['BigDecimal'];
  /** Formatted date as string. */
  formatted: Scalars['String'];
};

/** Formattable date object. */
export type FormattableDateFormattedArgs = {
  options?: Maybe<FormattableDateOptions>;
};

/** Format output options */
export type FormattableDateOptions = {
  /** desired date format accordingly to moment.js string formats, see: https://momentjs.com/docs/#/parsing/string-formats/ */
  format?: Maybe<Scalars['String']>;
  /** Desired date time zone accordingly to moment-timezone.js string formats, see: https://momentjs.com/timezone/docs/#/data-formats/ */
  timezone?: Maybe<Scalars['String']>;
};

export type FormattableIndicatorNumber = {
  __typename?: 'FormattableIndicatorNumber';
  value: Scalars['BigDecimal'];
  formatted: Scalars['String'];
};

/** Formattable percentage object. */
export type FormattablePercentage = {
  __typename?: 'FormattablePercentage';
  /** Unformatted percentage value. */
  value: Scalars['BigDecimal'];
  /** Formatted as percentage as string. */
  formatted: Scalars['String'];
};

/** Formattable percentage object. */
export type FormattablePercentageValueArgs = {
  options?: Maybe<FormattableValuePercentageOptions>;
};

/** Formattable percentage object. */
export type FormattablePercentageFormattedArgs = {
  options?: Maybe<FormattablePercentageOptions>;
};

/** Format output options */
export type FormattablePercentageOptions = {
  /** switch if positive sign should be included; default to false. */
  addSign?: Maybe<Scalars['Boolean']>;
  /** number of decimal places */
  decimalPlaces?: Maybe<Scalars['Int']>;
};

/** "Data in values can be either a Formattable Big Decimal or a Formattable Percentage" */
export type FormattableTradeInstrumentsIndicator =
  | FormattableBigDecimal
  | FormattablePercentage;

/** "Data in values can be either a Formattable Big Decimal or a Formattable Percentage" */
export type FormattableTradeValues =
  | FormattableBigDecimal
  | FormattablePercentage;

export type FormattableValuePercentageOptions = {
  /** switch raw value to value multiplied by 100 (e.g. 0.4 to 40)  */
  multiplied?: Maybe<Scalars['Boolean']>;
};

export type FormattableWealthValues = {
  __typename?: 'FormattableWealthValues';
  value: Scalars['BigDecimal'];
  formatted: Scalars['String'];
  benchmarkComparison: {
    label: Scalars['String'];
    value: Scalars['BigDecimal'];
    formatted: Scalars['String'];
  } | null;
};

export type GlobalSummaryChartArgs = {
  period: Scalars['String'];
};

export type GlobalSummaryTransactionsSummaryArgs = {
  max: Scalars['Int'];
};

export type GlobalSummaryPerformanceHistoryArgs = {
  input?: Maybe<GlobalSummaryPerformanceHistoryInput>;
};

export type GlobalSummaryTransactionDetailArgs = {
  input: GlobalSummaryTransactionDetailInput;
};

export type GlobalSummaryTransactionsHistoryArgs = {
  input: GlobalSummaryTransactionsHistoryInput;
};

export type GlobalSummarySuggestedPortfolioDetailsArgs = {
  input: GlobalSummarySuggestedPortfolioInput;
};

export type GlobalSummaryAllocValue = {
  __typename?: 'GlobalSummaryAllocValue';
  /** alloc value percentual */
  value?: Maybe<Scalars['BigDecimal']>;
  /** alloc value formatted */
  formatted?: Maybe<Scalars['Percentual']>;
};

export type GlobalSummaryAnnualPerformance = {
  __typename?: 'GlobalSummaryAnnualPerformance';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  /** @deprecated Use performancePercentage instead */
  performance?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentage?: Maybe<FormattablePercentage>;
  /** @deprecated Use performancePercentage instead */
  performanceOverMarketIndex?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentageOverMarketIndex?: Maybe<FormattablePercentage>;
  marketIndex?: Maybe<Scalars['String']>;
};

export type GlobalSummaryBankAccount = {
  __typename?: 'GlobalSummaryBankAccount';
  /** Account holder name. */
  name: Scalars['String'];
  /** Account holder document. */
  document: Scalars['String'];
  /** Bank name. */
  bankName: Scalars['String'];
  /** Bank code. */
  bankCode: Scalars['String'];
  /** Full agency number with digit */
  agency: Scalars['String'];
  /** Agency number. */
  agencyNumber: Scalars['String'];
  /** Agency digit. */
  agencyDigit: Scalars['String'];
  /** Full account number with digit */
  account: Scalars['String'];
  /** Account number. */
  accountNumber: Scalars['String'];
  /** Account digit. */
  accountDigit: Scalars['String'];
  /** Bank account UI labels. */
  labels: GlobalSummaryBankAccountLabels;
};

export type GlobalSummaryBankAccountLabels = {
  __typename?: 'GlobalSummaryBankAccountLabels';
  /** Name label, eg: Company name, N, etc. */
  name: Scalars['String'];
  /** Document label, eg: CPF, CNPJ, RG, etc. */
  document: Scalars['String'];
};

export type GlobalSummaryButton = {
  __typename?: 'GlobalSummaryButton';
  title?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

export type GlobalSummaryCard = {
  __typename?: 'GlobalSummaryCard';
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  button?: Maybe<GlobalSummaryButton>;
  helperView?: Maybe<GlobalSummaryHelperView>;
};

export type GlobalSummaryChart = {
  __typename?: 'GlobalSummaryChart';
  filters?: Maybe<Array<Maybe<GlobalSummaryFilter>>>;
  defaultFilter?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Maybe<GlobalSummaryChartType>>>;
  defaultChartType?: Maybe<Scalars['Int']>;
  axisY?: Maybe<Array<Maybe<GlobalSummaryChartAxisY>>>;
  defaultAxisY?: Maybe<Scalars['Int']>;
  axisX?: Maybe<Array<Maybe<GlobalSummaryChartAxisX>>>;
  defaultAxisX?: Maybe<Scalars['Int']>;
};

export type GlobalSummaryChartAxisX = {
  __typename?: 'GlobalSummaryChartAxisX';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GlobalSummaryChartAxisY = {
  __typename?: 'GlobalSummaryChartAxisY';
  quotes?: Maybe<GlobalSummaryChartAxisYQuotes>;
  totalBalance?: Maybe<GlobalSummaryChartAxisYTotalBalance>;
};

export type GlobalSummaryChartAxisYQuotes = {
  __typename?: 'GlobalSummaryChartAxisYQuotes';
  description?: Maybe<Scalars['String']>;
  /** investment type of the quote */
  key?: Maybe<Scalars['String']>;
  /** quote values */
  values?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type GlobalSummaryChartAxisYTotalBalance = {
  __typename?: 'GlobalSummaryChartAxisYTotalBalance';
  description?: Maybe<Scalars['String']>;
  /** total balance investment type */
  key?: Maybe<Scalars['String']>;
  /** values of total balance */
  values?: Maybe<Array<Maybe<FormattableBigDecimal>>>;
};

export type GlobalSummaryChartType = {
  __typename?: 'GlobalSummaryChartType';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<EGlobalSummaryChartType>;
};

export type GlobalSummaryCheckingAccount = {
  __typename?: 'GlobalSummaryCheckingAccount';
  totalBalance?: Maybe<Scalars['Float']>;
  availableBalance?: Maybe<Scalars['Float']>;
  lockedBalance?: Maybe<Scalars['Float']>;
};

export type GlobalSummaryFilter = {
  __typename?: 'GlobalSummaryFilter';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<EGlobalSummaryChartFilter>;
};

export type GlobalSummaryGroupInvite = {
  __typename?: 'GlobalSummaryGroupInvite';
  sender?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  isCustomerCreated?: Maybe<Scalars['Boolean']>;
};

export type GlobalSummaryHelperView = {
  __typename?: 'GlobalSummaryHelperView';
  icon?: Maybe<Scalars['String']>;
  button?: Maybe<GlobalSummaryButton>;
};

export type GlobalSummaryImage = {
  __typename?: 'GlobalSummaryImage';
  raw?: Maybe<Scalars['String']>;
  regular?: Maybe<Scalars['String']>;
  full?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
};

export type GlobalSummaryInvestmentClassType = {
  __typename?: 'GlobalSummaryInvestmentClassType';
  /** investment class type */
  value: EGlobalSummaryProductClassType;
  /** investment class type formatted */
  formatted: Scalars['String'];
};

export type GlobalSummaryMarketIndexCodeCompared = {
  __typename?: 'GlobalSummaryMarketIndexCodeCompared';
  /** market index code */
  value?: Maybe<Scalars['String']>;
  /** market index code formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type GlobalSummaryMonthlyPerformance = {
  __typename?: 'GlobalSummaryMonthlyPerformance';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  /** @deprecated Use performancePercentage instead */
  performance?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentage?: Maybe<FormattablePercentage>;
  /** @deprecated Use performancePercentage instead */
  performanceOverMarketIndex?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentageOverMarketIndex?: Maybe<FormattablePercentage>;
  marketIndex?: Maybe<Scalars['String']>;
};

export type GlobalSummaryMutation = {
  __typename?: 'GlobalSummaryMutation';
  customerId: Scalars['ID'];
  /** updates the portfolio status */
  updatePortfolioStatus: Scalars['Boolean'];
};

export type GlobalSummaryMutationUpdatePortfolioStatusArgs = {
  input: GlobalSummaryPortfolioStatusInput;
};

export type GlobalSummaryPerformanceHistory = {
  __typename?: 'GlobalSummaryPerformanceHistory';
  title?: Maybe<Scalars['String']>;
  /** @deprecated Use performancePercentage instead */
  performance?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentage?: Maybe<FormattablePercentage>;
  /** @deprecated Use performancePercentage instead */
  performanceOverMarketIndex?: Maybe<GlobalSummaryPerformancePercentual>;
  performancePercentageOverMarketIndex?: Maybe<FormattablePercentage>;
  marketIndex?: Maybe<Scalars['String']>;
  detail?: Maybe<GlobalSummaryPerformanceHistoryDetail>;
};

export type GlobalSummaryPerformanceHistoryDetail = {
  __typename?: 'GlobalSummaryPerformanceHistoryDetail';
  annualPerformance?: Maybe<Array<Maybe<GlobalSummaryAnnualPerformance>>>;
  monthlyPerformance?: Maybe<Array<Maybe<GlobalSummaryMonthlyPerformance>>>;
};

export type GlobalSummaryPerformanceHistoryInput = {
  year?: Maybe<Scalars['String']>;
};

export type GlobalSummaryPerformanceInfo = {
  __typename?: 'GlobalSummaryPerformanceInfo';
  /**
   * how much the money performed over the time
   * @deprecated Use performancePercentage instead
   */
  performance?: Maybe<GlobalSummaryPerformancePercentual>;
  /** how much the money performed over the time */
  performancePercentage?: Maybe<FormattablePercentage>;
  /** the market index code default (usually CDI) */
  marketIndexCode?: Maybe<Scalars['String']>;
  /** @deprecated Use weightedPerformancePercentage instead */
  weightedPerformance?: Maybe<GlobalSummaryPerformancePercentual>;
  weightedPerformancePercentage?: Maybe<FormattablePercentage>;
  /** the market index code that was used to compare performance */
  marketIndexCodeCompared?: Maybe<GlobalSummaryMarketIndexCodeCompared>;
  /** how much the money performed over the index code */
  performanceOverMarketIndex?: Maybe<FormattablePercentage>;
};

export type GlobalSummaryPerformancePercentual = {
  __typename?: 'GlobalSummaryPerformancePercentual';
  /** percentual number */
  value?: Maybe<Scalars['BigDecimal']>;
  /** percentual formatted */
  formatted?: Maybe<Scalars['Percentual']>;
};

export type GlobalSummaryPortfolioStatusInput = {
  portfolioId: Scalars['ID'];
  /** the status the portfolio must be updated to e.g ACCEPTED  */
  status: EGlobalSummaryPortfolioStatus;
};

export type GlobalSummaryPortfolioSuggested = {
  __typename?: 'GlobalSummaryPortfolioSuggested';
  brandName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GlobalSummaryPortfolioSuggestionAllocation = {
  __typename?: 'GlobalSummaryPortfolioSuggestionAllocation';
  name?: Maybe<GlobalSummaryInvestmentClassType>;
  allocValue: FormattablePercentage;
};

export type GlobalSummaryPortfolioSuggestionFeature = {
  __typename?: 'GlobalSummaryPortfolioSuggestionFeature';
  /** the title of a characteristic of a suggested portfolio */
  title: Scalars['String'];
  /** the detail of a characteristic of a suggested portfolio */
  detail: Scalars['String'];
};

export type GlobalSummaryPortfolioSuggestionProduct = {
  __typename?: 'GlobalSummaryPortfolioSuggestionProduct';
  /** product ID */
  id: Scalars['ID'];
  /** product name */
  name: Scalars['String'];
  /** allocation percentage */
  allocValue: FormattablePercentage;
};

export type GlobalSummaryPortfolioSuggestionProductClass = {
  __typename?: 'GlobalSummaryPortfolioSuggestionProductClass';
  /** the type of a product class e.g. fixed income */
  type: EGlobalSummaryProductClassType;
  allocValue: FormattablePercentage;
  /** list of product of a certain type */
  products: Array<Maybe<GlobalSummaryPortfolioSuggestionProduct>>;
};

export type GlobalSummaryPost = {
  __typename?: 'GlobalSummaryPost';
  /** @deprecated use query.channels.newsFeed */
  id?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  type?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  releaseDate?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  dashboardTitle?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  dashboardSubtitle?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  articleTitle?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  articleSubtitle?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  articleBody?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  readCount?: Maybe<Scalars['Float']>;
  /** @deprecated use query.channels.newsFeed */
  lastReadAt?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  discardedCount?: Maybe<Scalars['Float']>;
  /** @deprecated use query.channels.newsFeed */
  lastDiscardedAt?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  seenCount?: Maybe<Scalars['Float']>;
  /** @deprecated use query.channels.newsFeed */
  lastSeenAt?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  publicReadCount?: Maybe<Scalars['Float']>;
  /** @deprecated use query.channels.newsFeed */
  lastPublicReadAt?: Maybe<Scalars['String']>;
  /** @deprecated use query.channels.newsFeed */
  expire?: Maybe<Scalars['Boolean']>;
};

export type GlobalSummaryProduct = {
  __typename?: 'GlobalSummaryProduct';
  /** product name */
  name: Scalars['String'];
  /** product id */
  id: Scalars['String'];
  /**
   * allocation's percentage
   * @deprecated Use allocPercentage instead
   */
  allocValue: GlobalSummaryAllocValue;
  /** allocation's percentage */
  allocPercentage: FormattablePercentage;
  totalBalance?: Maybe<FormattableBigDecimal>;
  performanceInfo?: Maybe<GlobalSummaryPerformanceInfo>;
  /** is fixed income value */
  isReferenceValue?: Maybe<Scalars['Boolean']>;
  /** reference date for fixed income */
  referenceValueDate?: Maybe<FormattableDate>;
};

export type GlobalSummaryProductOffer = {
  __typename?: 'GlobalSummaryProductOffer';
  productId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validityPeriod?: Maybe<GlobalSummaryValidityPeriod>;
  image?: Maybe<GlobalSummaryImage>;
};

export type GlobalSummaryProductOverview = {
  __typename?: 'GlobalSummaryProductOverview';
  /** e.g. fixed income */
  investmentClassType?: Maybe<GlobalSummaryInvestmentClassType>;
  /**
   * allocation's percentage
   * @deprecated Use allocPercentage instead
   */
  allocValue: GlobalSummaryAllocValue;
  /** allocation's percentage */
  allocPercentage: FormattablePercentage;
  totalBalance?: Maybe<FormattableBigDecimal>;
  /** @deprecated Use weightedPerformancePercentage instead */
  weightedPerformance?: Maybe<GlobalSummaryPerformancePercentual>;
  weightedPerformancePercentage?: Maybe<FormattablePercentage>;
  products?: Maybe<Array<Maybe<GlobalSummaryProduct>>>;
};

export type GlobalSummaryProductTaxes = {
  __typename?: 'GlobalSummaryProductTaxes';
  /** the value of management taxes related to the investment account */
  cashAdministrationTax: FormattableBigDecimal;
  /** the value of management taxes related to the wealth */
  wealthAdministrationTax: FormattableBigDecimal;
};

export type GlobalSummarySection = {
  __typename?: 'GlobalSummarySection';
  /** e.g. cash */
  type?: Maybe<GlobalSummarySectionType>;
  /** allocation's percentage */
  allocValue: FormattablePercentage;
  /** the total balance of a section */
  totalBalance: FormattableBigDecimal;
  /** the net balance of a section */
  netBalance: FormattableBigDecimal;
  /** the value that should be displayed in this section (automatically selected) */
  amount: FormattableBigDecimal;
};

export type GlobalSummarySectionType = {
  __typename?: 'GlobalSummarySectionType';
  /** section type */
  value?: Maybe<Scalars['String']>;
  /** section formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type GlobalSummarySuggestedPortfolioDetails = {
  __typename?: 'GlobalSummarySuggestedPortfolioDetails';
  /** the types of allocation and it's allocation percentages */
  allocation: Array<GlobalSummaryPortfolioSuggestionAllocation>;
  /** the portfolio characteristics */
  features: Array<GlobalSummaryPortfolioSuggestionFeature>;
  /** the boxes of product types and its products inside */
  productClasses: Array<GlobalSummaryPortfolioSuggestionProductClass>;
};

export type GlobalSummarySuggestedPortfolioInput = {
  portfolioId: Scalars['ID'];
};

export type GlobalSummaryTransaction = {
  __typename?: 'GlobalSummaryTransaction';
  /** transaction Id */
  id?: Maybe<Scalars['String']>;
  /** type of transaction e.g deposit */
  type: GlobalSummaryTransactionType;
  /** transaction's status e.g. accepted */
  status: GlobalSummaryTransactionStatus;
  amount: FormattableBigDecimal;
  date?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionDetail = {
  __typename?: 'GlobalSummaryTransactionDetail';
  /** transaction id  */
  id: Scalars['String'];
  /** customer's id */
  customer: Scalars['String'];
  /** type of transaction e.g deposit */
  type: GlobalSummaryTransactionType;
  /** transaction's status e.g. accepted */
  status: GlobalSummaryTransactionStatus;
  fromInvoice?: Maybe<Scalars['String']>;
  fromAccount?: Maybe<Scalars['String']>;
  toAccount?: Maybe<Scalars['String']>;
  fromPortfolio?: Maybe<Scalars['String']>;
  fromPortfolioName?: Maybe<Scalars['String']>;
  toPortfolio?: Maybe<Scalars['String']>;
  toPortfolioName?: Maybe<Scalars['String']>;
  amount: FormattableBigDecimal;
  /** if is the total amount of money that is being operated */
  isTotal: Scalars['Boolean'];
  /** transaction's operated at date, formatted as string */
  operatedAt?: Maybe<Scalars['String']>;
  /** transaction's liquidated at date, formatted as string */
  liquidatedAt?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionDetailOperatedAtArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionDetailLiquidatedAtArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionDetailInput = {
  transactionId: Scalars['String'];
};

export type GlobalSummaryTransactionStatus = {
  __typename?: 'GlobalSummaryTransactionStatus';
  value?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionType = {
  __typename?: 'GlobalSummaryTransactionType';
  value?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

/** global summary transactions grouped by date */
export type GlobalSummaryTransactionsDate = {
  __typename?: 'GlobalSummaryTransactionsDate';
  /** current date formatted as sting */
  date: Scalars['String'];
  /** account balance for current date */
  balance: FormattableBigDecimal;
  /** list of transactions for current date */
  transactions: Array<GlobalSummaryTransaction>;
};

/** global summary transactions grouped by date */
export type GlobalSummaryTransactionsDateDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type GlobalSummaryTransactionsHistory = {
  __typename?: 'GlobalSummaryTransactionsHistory';
  /** current page info */
  page: PageInfo;
  /** list of dates in the extract */
  list: Array<GlobalSummaryTransactionsDate>;
};

export type GlobalSummaryTransactionsHistoryInput = {
  /** the number of the page */
  page: Scalars['Int'];
  /** how many elements the page must have */
  size: Scalars['Int'];
  /** the order direction the elements must follow e.g desc */
  orderDirection?: Maybe<EGlobalSummaryOrderDirection>;
};

export type GlobalSummaryTransactionsSummary = {
  __typename?: 'GlobalSummaryTransactionsSummary';
  id?: Maybe<Scalars['String']>;
  type: GlobalSummaryTransactionType;
  status: GlobalSummaryTransactionStatus;
  amount: FormattableBigDecimal;
  date: Scalars['String'];
  name: Scalars['String'];
  direction: Scalars['String'];
};

export type GlobalSummaryUpdateToAccountRegulation = {
  __typename?: 'GlobalSummaryUpdateToAccountRegulation';
  /** account regulation status title */
  title: Scalars['String'];
  /** account regulation status description */
  description: Scalars['String'];
  /** account regulation status labelButton */
  labelButton: Scalars['String'];
};

export type GlobalSummaryValidityPeriod = {
  __typename?: 'GlobalSummaryValidityPeriod';
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type GlobalTransactionType = {
  __typename?: 'GlobalTransactionType';
  /** transaction type enum key (from API Node) */
  value: EGlobalTransactionType;
  /** transaction type formatted */
  formatted: Scalars['String'];
};

export type GlobalTransactionTypeV2 = {
  __typename?: 'GlobalTransactionTypeV2';
  /** transaction type enum key (from API Node) */
  value: EGlobalTransactionTypeV2;
  /** transaction type formatted */
  formatted: Scalars['String'];
};

export type GlobalTransactionsMovementTypeFilter = {
  __typename?: 'GlobalTransactionsMovementTypeFilter';
  /** key of the type */
  key: EGlobalTransactionsMovementTypeFilter;
  /** label of the type */
  description: Scalars['String'];
};

export type GlobalTransactionsPageInfo = {
  __typename?: 'GlobalTransactionsPageInfo';
  /** page's index; should start from 1 */
  index: Scalars['Int'];
  /** page's items size */
  size: Scalars['Int'];
  /** amount of pages */
  amount: Scalars['Int'];
  /** true if there is more pages after current index */
  hasMore: Scalars['Boolean'];
  /** date range to search (from clients) */
  range: TransactionsDateRangeInput;
  /** the selected portfolioId */
  portfolioId?: Maybe<Scalars['String']>;
};

export type IFormattableDateValues = {
  __typename?: 'IFormattableDateValues';
  value?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

export type IFormattableValues = {
  __typename?: 'IFormattableValues';
  value?: Maybe<Scalars['Float']>;
  formatted?: Maybe<Scalars['String']>;
};

export type ITransactionInvest = {
  __typename?: 'ITransactionInvest';
  /** Each amount for each portfolio on the deposit */
  amount?: Maybe<FormattableBigDecimal>;
  /** Each name of each portfolio on the deposit */
  name?: Maybe<Scalars['String']>;
  /** Each type of each portfolio on the deposit */
  type?: Maybe<Scalars['String']>;
};

export type InputAllocations = {
  isRoot?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  percent?: Maybe<Scalars['Float']>;
};

export type InputPlan = {
  maxInitialInvestment?: Maybe<Scalars['Float']>;
  monthlyInvestment?: Maybe<Scalars['Float']>;
  goalAmount?: Maybe<Scalars['Float']>;
  goalName?: Maybe<Scalars['String']>;
  maxLiquidity?: Maybe<Scalars['Float']>;
  goalDate?: Maybe<Scalars['Date']>;
};

export type InputProduct = {
  percentage?: Maybe<Scalars['Float']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  minimumInitialInvestment?: Maybe<Scalars['Float']>;
  minimumFinancialMovement?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  apiId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  investorType?: Maybe<Scalars['String']>;
};

export type InputUpdateLock = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
};

export type InputUpdateProductPercentage = {
  alloc?: Maybe<InputAllocations>;
  newRootFound?: Maybe<Scalars['Boolean']>;
};

export type InputWalletCustomer = {
  customerId?: Maybe<Scalars['String']>;
  isQualifiedInvestor?: Maybe<Scalars['Boolean']>;
  isProfessionalInvestor?: Maybe<Scalars['Boolean']>;
};

export enum InstrumentClass {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS'
}

export type InstrumentDetail = {
  __typename?: 'InstrumentDetail';
  boxName?: Maybe<Scalars['String']>;
  emitterName?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  administrationTax?: Maybe<Scalars['Float']>;
  subType?: Maybe<Scalars['Int']>;
  video?: Maybe<Array<Maybe<InstrumentDetailContent>>>;
  documents?: Maybe<Array<Maybe<InstrumentDetailContent>>>;
  apiId?: Maybe<Scalars['String']>;
  boxApiId?: Maybe<Scalars['String']>;
  lastTwelveMonthsInterestRate?: Maybe<Scalars['Float']>;
  isIndexBased?: Maybe<Scalars['Boolean']>;
  isIndexName?: Maybe<Scalars['String']>;
  minimumFinancialMovement?: Maybe<Scalars['Float']>;
  minimumInitialInvestment?: Maybe<Scalars['Float']>;
  riskRate?: Maybe<Scalars['Float']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  minPercentage?: Maybe<Scalars['Float']>;
  availabilityReason?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Date']>;
  liquidityInfo?: Maybe<Scalars['String']>;
  daysToLiquidation?: Maybe<Scalars['Int']>;
  daysToExpiration?: Maybe<Scalars['Int']>;
  daysToGracePeriod?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['Float']>;
  investorType?: Maybe<InvestorType>;
  fixedPercentualRate?: Maybe<Scalars['Float']>;
  yearlyEstimatedPercentualRate?: Maybe<Scalars['Float']>;
  riskGrade?: Maybe<RiskGrade>;
  type?: Maybe<InstrumentClass>;
  liquidity?: Maybe<IntFormatableField>;
  profitability?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
};

export type InstrumentDetailContent = {
  __typename?: 'InstrumentDetailContent';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type InstrumentDetailQueryResult = {
  __typename?: 'InstrumentDetailQueryResult';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<InstrumentDetail>>>;
};

export type InstrumentList = {
  __typename?: 'InstrumentList';
  pageIndex?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
  fixedIncomeTotalRecords?: Maybe<Scalars['Int']>;
  variableIncomeTotalRecords?: Maybe<Scalars['Int']>;
  otherTotalRecords?: Maybe<Scalars['Int']>;
  instruments?: Maybe<Array<Maybe<InstrumentSummary>>>;
};

export enum InstrumentOrderByField {
  Subcategory = 'SUBCATEGORY',
  Name = 'NAME'
}

export type InstrumentQueryResult = {
  __typename?: 'InstrumentQueryResult';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<InstrumentList>;
};

export type InstrumentRiskDependent = {
  __typename?: 'InstrumentRiskDependent';
  apiId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['Float']>;
  riskGrade?: Maybe<RiskGrade>;
};

export type InstrumentSearchFilter = {
  __typename?: 'InstrumentSearchFilter';
  pageIndex?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  isAscending?: Maybe<Scalars['Boolean']>;
  brandApiId?: Maybe<Scalars['String']>;
};

export type InstrumentSummary = {
  __typename?: 'InstrumentSummary';
  instrumentBoxApiId?: Maybe<Scalars['String']>;
  category?: Maybe<InstrumentClass>;
  subCategory?: Maybe<Scalars['String']>;
  instrumentDetailApiId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  minimumFinancialMovement?: Maybe<Scalars['Float']>;
  minimumInitialInvestment?: Maybe<Scalars['Float']>;
  riskScore?: Maybe<Scalars['Float']>;
  lastTwelveMonthsInterestRate?: Maybe<Scalars['Float']>;
  daysToLiquidationAtWithdraw?: Maybe<Scalars['Int']>;
  daysToExpiration?: Maybe<Scalars['Int']>;
  daysToQuotizationAtWithdraw?: Maybe<Scalars['Int']>;
  daysToWithdraw?: Maybe<Scalars['Int']>;
  riskGrade?: Maybe<RiskGrade>;
  liquidity?: Maybe<IntFormatableField>;
  profitability?: Maybe<Scalars['String']>;
  investorType?: Maybe<InvestorType>;
  indexBasedInterest?: Maybe<Scalars['Boolean']>;
  indexName?: Maybe<Scalars['String']>;
  benchmarkFormatted?: Maybe<Scalars['String']>;
  comparisonValues?: Maybe<Array<Maybe<MarketIndexComparison>>>;
};

export type InstrumentTermDependent = {
  __typename?: 'InstrumentTermDependent';
  apiId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<InstrumentDetailContent>>>;
};

export type InstrumentTermsResult = {
  __typename?: 'InstrumentTermsResult';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<InstrumentDetailContent>;
};

export enum InstrumentType {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS',
  Notallocated = 'NOTALLOCATED'
}

export type InstrumentValidationQueryResult = {
  __typename?: 'InstrumentValidationQueryResult';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<ValidatedInstrumentData>;
};

export type IntFormatableField = {
  __typename?: 'IntFormatableField';
  value?: Maybe<Scalars['Int']>;
  formatted?: Maybe<Scalars['String']>;
};

export enum InvestorType {
  Common = 'COMMON',
  Qualified = 'QUALIFIED',
  Professional = 'PROFESSIONAL',
  None = 'NONE'
}

export type MarketIndexComparison = {
  __typename?: 'MarketIndexComparison';
  marketIndexName?: Maybe<Scalars['String']>;
  performance?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** module trade */
  trade?: Maybe<TradeMutation>;
  /** module global summary */
  globalSummary?: Maybe<GlobalSummaryMutation>;
  /** module exchange */
  exchange?: Maybe<ExchangeMutation>;
  /** module pix */
  pix?: Maybe<PixMutation>;
  /** module channels */
  channels?: Maybe<ChannelsMutation>;
  setCustomer: Scalars['Boolean'];
  updateLock: Scalars['Boolean'];
  setPlan: Scalars['Boolean'];
  setAllocationRootValues: Scalars['Boolean'];
  updateProductPercentage?: Maybe<ProductPercentage>;
  addProductToWallet?: Maybe<ProductToWallet>;
  removeProductFromWallet?: Maybe<Product>;
  revertAllocationChanges: Scalars['Boolean'];
  saveState?: Maybe<Scalars['String']>;
  termSignatures?: Maybe<TermSignatureResponse>;
  riskSignatures?: Maybe<RiskSignatureResponse>;
};

export type MutationTradeArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type MutationGlobalSummaryArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type MutationExchangeArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type MutationPixArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type MutationChannelsArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateLockArgs = {
  updateLockParams?: Maybe<InputUpdateLock>;
};

export type MutationSetPlanArgs = {
  plan?: Maybe<InputPlan>;
};

export type MutationSetAllocationRootValuesArgs = {
  plan?: Maybe<InputPlan>;
};

export type MutationUpdateProductPercentageArgs = {
  updateProductPercentageParams?: Maybe<InputUpdateProductPercentage>;
};

export type MutationAddProductToWalletArgs = {
  instrument?: Maybe<InputProduct>;
};

export type MutationRemoveProductFromWalletArgs = {
  instrument?: Maybe<InputProduct>;
};

export type MutationSaveStateArgs = {
  stateLevel?: Maybe<Scalars['Int']>;
};

export type MutationTermSignaturesArgs = {
  instrumentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationRiskSignaturesArgs = {
  instrumentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type News = {
  __typename?: 'News';
  /** News's identifier */
  id: Scalars['String'];
  /** customer's identifier */
  customerId: Scalars['ID'];
  /** news code */
  code: Scalars['String'];
  /** news type */
  type: ENewsType;
  /** articleBody */
  articleBody: Scalars['String'];
  /** articleSubtitle */
  articleSubtitle: Scalars['String'];
  /** articleTitle */
  articleTitle: Scalars['String'];
  /** dashboardSubtitle */
  dashboardSubtitle: Scalars['String'];
  /** dashboardTitle */
  dashboardTitle: Scalars['String'];
  /** dashboardBackground */
  dashboardBackground?: Maybe<Scalars['String']>;
  /** url */
  url?: Maybe<Scalars['String']>;
};

export type NewsHistory = {
  __typename?: 'NewsHistory';
  /** list of news */
  data?: Maybe<Array<Maybe<News>>>;
  /** current page */
  page?: Maybe<Scalars['Int']>;
  /** total pages */
  pages?: Maybe<Scalars['Int']>;
  /** total of news */
  total?: Maybe<Scalars['Int']>;
};

export enum OptionType {
  ManagedPortfolio = 'MANAGED_PORTFOLIO',
  CustomPortfolio = 'CUSTOM_PORTFOLIO'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** page's index; should start from 1 */
  index: Scalars['Int'];
  /** page's items size */
  size: Scalars['Int'];
  /** amount of pages */
  amount: Scalars['Int'];
  /** true if there is more pages after current index */
  hasMore: Scalars['Boolean'];
};

export type PageInfoInput = {
  /** page's items size (max value is 1000) */
  size: Scalars['Int'];
  /** page's index starting from 1 */
  index: Scalars['Int'];
};

export type PaginatedWealthTransaction = {
  __typename?: 'PaginatedWealthTransaction';
  /** current page info */
  page: PageInfo;
  /** list of transactons for current page */
  transactions: Array<WealthTransaction>;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  /** The id of the transaction your are trying to query */
  transactionId?: Maybe<Scalars['ID']>;
  status?: Maybe<TransactionStatus>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  bankSlipType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  barCode?: Maybe<Scalars['String']>;
  billetLine?: Maybe<FormattableBilletLine>;
  dueDate?: Maybe<FormattableDate>;
  scheduledDate?: Maybe<FormattableDate>;
  initialValue?: Maybe<FormattableBigDecimal>;
  fineValue?: Maybe<FormattableBigDecimal>;
  taxValue?: Maybe<FormattableBigDecimal>;
  discountValue?: Maybe<FormattableBigDecimal>;
  totalAmount?: Maybe<FormattableBigDecimal>;
  paidValue?: Maybe<FormattableBigDecimal>;
  payerInfo?: Maybe<PaymentInfo>;
  recipientInfo?: Maybe<PaymentInfo>;
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
};

export type Pix = {
  __typename?: 'Pix';
  /** customer's identifier */
  customerId: Scalars['ID'];
  /**
   * customer pix informations
   * @deprecated Use customerStatus instead
   */
  customer?: Maybe<PixCustomer>;
  /** customer pix status */
  customerStatus?: Maybe<EPixCustomerStatus>;
  /** customer current balance */
  customerBalance: FormattableBigDecimal;
  /** show ready register keys from customer */
  keys: PixKeys;
  /** show available keys for registration */
  availableKeys: Array<PixAvailableKeys>;
  /** show details give one key */
  detailsKey: PixKey;
  /** generate payment QrCode */
  generateQrCode: PixStaticQrCode;
  /** recipient data */
  recipient?: Maybe<PixPaymentRecipient>;
  /** ispb list */
  ispbList: Array<Maybe<PixIspb>>;
  /** most used banks list */
  mostUsedBanks: Array<Maybe<PixMostUsedBanks>>;
  /** payment slip receipt */
  paymentSlipReceipt?: Maybe<PixPaymentSlipReceipt>;
  /** decode payment QrCode */
  decodeQrCode: PixDecodeQrCodePayment;
};

export type PixDetailsKeyArgs = {
  input: PixDetailsKeyInput;
};

export type PixGenerateQrCodeArgs = {
  input: PixGenerateQrCodeInput;
};

export type PixRecipientArgs = {
  input: PixKeyInput;
};

export type PixPaymentSlipReceiptArgs = {
  input: PixPaymentSlipReceiptInput;
};

export type PixDecodeQrCodeArgs = {
  input: PixDecodeQrCodeInput;
};

export type PixAcceptRequestInput = {
  /** Key ID */
  apiId: Scalars['ID'];
  /** Choice to approve Key */
  isApproved: Scalars['Boolean'];
};

/** List all keys availables to register */
export type PixAvailableKeys = {
  __typename?: 'PixAvailableKeys';
  /** type of key */
  type: PixKeyType;
  /** respective value given the key */
  value: Scalars['String'];
};

export type PixBank = {
  __typename?: 'PixBank';
  /** formatted BankName */
  bankName?: Maybe<Scalars['String']>;
};

export type PixBankDataInput = {
  /** recipient bank details */
  recipient: PixRecipientBankDataInput;
  /** amount currency */
  amount: Scalars['Float'];
  /** transaction description */
  description?: Maybe<Scalars['String']>;
  /** customer password */
  password?: Maybe<Scalars['String']>;
  /** scheduled date time */
  payAt?: Maybe<Scalars['String']>;
  /** request date time */
  appRequestDateTime?: Maybe<Scalars['String']>;
};

export type PixClaimStatus = {
  __typename?: 'PixClaimStatus';
  /** key enum value */
  value?: Maybe<EPixKeyRequiredAction>;
  /** key type formatted */
  formatted: Scalars['String'];
};

export type PixCreateKeyInput = {
  /** Key type */
  type: EPixKeyType;
  /** Key value */
  value: Scalars['String'];
  /** OTP value */
  otp?: Maybe<Scalars['String']>;
};

export type PixCreatedKey = {
  __typename?: 'PixCreatedKey';
  /** Pix key created */
  key?: Maybe<PixKey>;
  /** Possible required action when the key is not created */
  requiredAction?: Maybe<EPixKeyRequiredAction>;
};

export type PixCustomer = {
  __typename?: 'PixCustomer';
  /** customer pix status */
  status?: Maybe<EPixCustomerStatus>;
};

export type PixDecodeQrCodeInput = {
  /** qrcode payload */
  payload: Scalars['String'];
};

export type PixDecodeQrCodePayment = {
  __typename?: 'PixDecodeQrCodePayment';
  /** customer available balance */
  amount: Scalars['Float'];
  /** value of pix key */
  keyValue: Scalars['String'];
  /** recipient data  */
  recipient: PixPaymentRecipient;
  /** qr code type */
  decodedQRCodeType: Scalars['String'];
};

export type PixDeleteKeyInput = {
  /** Key ID */
  apiId: Scalars['ID'];
};

export type PixDeletedKey = {
  __typename?: 'PixDeletedKey';
  /** Key ID */
  apiId: Scalars['ID'];
  /** Customer ID */
  customerApiId: Scalars['ID'];
};

export type PixDetailsKeyInput = {
  /** Key ID */
  apiId: Scalars['ID'];
  /** Entity Key */
  entity: EPixEntityKey;
};

export type PixGenerateQrCodeInput = {
  /** key details */
  key: PixKeyInput;
  /** amount currency */
  amount?: Maybe<Scalars['Float']>;
  /** transaction description */
  description?: Maybe<Scalars['String']>;
};

export type PixIspb = {
  __typename?: 'PixIspb';
  /** ispb code */
  ispbCode: Scalars['Int'];
  /** bank name */
  companyName: Scalars['String'];
  /** bank short name */
  shortName: Scalars['String'];
};

export type PixKey = {
  __typename?: 'PixKey';
  /** key identifier */
  id: Scalars['ID'];
  /** type of key */
  type: PixKeyType;
  /** respective value given the key */
  value: Scalars['String'];
  /** current status to key */
  status: PixKeyStatus;
  /** key entity */
  entity: EPixEntityKey;
  /** date of key creation */
  createdAt: FormattableDate;
  /** date limit of portability or claim */
  deadlineDate?: Maybe<FormattableDate>;
  /** claim requested bank information */
  requestSentTo?: Maybe<PixBank>;
  /** claim request received information */
  requestFrom?: Maybe<PixBank>;
  /** type of claim */
  claimType?: Maybe<PixClaimStatus>;
  /** flow of participation */
  participationFlow?: Maybe<EParticipationFlowType>;
};

export type PixKeyInput = {
  /** key type */
  type: EPixKeyType;
  /** key value */
  value: Scalars['String'];
};

export type PixKeyStatus = {
  __typename?: 'PixKeyStatus';
  /** key enum value */
  value: EPixKeyStatus;
  /** key type formatted */
  formatted: Scalars['String'];
};

export type PixKeyType = {
  __typename?: 'PixKeyType';
  /** key enum value */
  value: EPixKeyType;
  /** key type formatted */
  formatted: Scalars['String'];
};

export type PixKeys = {
  __typename?: 'PixKeys';
  /** total keys allowed */
  max: Scalars['Int'];
  /** total keys already registered */
  count: Scalars['Int'];
  /** list of keys */
  list: Array<PixKey>;
};

export type PixMostUsedBanks = {
  __typename?: 'PixMostUsedBanks';
  /** ispb code */
  ispbCode: Scalars['Int'];
  /** bank code */
  bankCode: Scalars['String'];
  /** bank short name */
  shortName: Scalars['String'];
};

export type PixMutation = {
  __typename?: 'PixMutation';
  /** customer's identifier */
  customerId: Scalars['ID'];
  /** register key */
  createKey: PixCreatedKey;
  /** delete key */
  deleteKey: PixDeletedKey;
  /** accept portability request  */
  acceptPortabilityRequest: PixKey;
  /** send request for key portability */
  sendPortabilityRequest: PixKey;
  /** send request for key claim */
  sendClaimRequest: PixKey;
  /** send pix transaction using pix key */
  paymentByKey: PixPaymentConfirmationResponse;
  /** send pix transaction using bank agency and account */
  paymentByBankData: PixPaymentConfirmationResponse;
};

export type PixMutationCreateKeyArgs = {
  input: PixCreateKeyInput;
};

export type PixMutationDeleteKeyArgs = {
  input: PixDeleteKeyInput;
};

export type PixMutationAcceptPortabilityRequestArgs = {
  input: PixAcceptRequestInput;
};

export type PixMutationSendPortabilityRequestArgs = {
  input: PixSendPortabilityRequestInput;
};

export type PixMutationSendClaimRequestArgs = {
  input: PixSendClaimRequestInput;
};

export type PixMutationPaymentByKeyArgs = {
  input: PixPaymentInput;
};

export type PixMutationPaymentByBankDataArgs = {
  input: PixBankDataInput;
};

export type PixPaymentConfirmationResponse = {
  __typename?: 'PixPaymentConfirmationResponse';
  /** Key ID */
  transactionApiId: Scalars['ID'];
  /** type of pix */
  pixType?: Maybe<Scalars['String']>;
  /** id of scheduled */
  scheduledApiId?: Maybe<Scalars['String']>;
  /** id of scheduled */
  scheduledTo?: Maybe<FormattableDate>;
  /** date of creation */
  createdAt?: Maybe<FormattableDate>;
  /** receiver information */
  receiver?: Maybe<PixPaymentRecipientResponse>;
};

export type PixPaymentInput = {
  /** payment initiation */
  keyMechanism: EPixKeyMechanism;
  /** key details */
  keyValue: Scalars['String'];
  /** amount currency */
  amount: Scalars['Float'];
  /** transaction description */
  description?: Maybe<Scalars['String']>;
  /** customer password */
  password: Scalars['String'];
  /** request date time */
  payAt?: Maybe<Scalars['String']>;
  /** request date time */
  appRequestDateTime?: Maybe<Scalars['String']>;
  /** externalUniqueId of customer */
  externalUniqueId: Scalars['String'];
};

export type PixPaymentRecipient = {
  __typename?: 'PixPaymentRecipient';
  /** recipient name */
  name: Scalars['String'];
  /** masked recipient identifier (cpf/cnpj) */
  document: Scalars['String'];
  /** document label */
  documentLabel: EPixDocumentLabel;
  /** encrypted recipient identifier (cpf/cnpj) */
  encryptedDocument: Scalars['String'];
  /** externalUniqueId */
  externalUniqueId: Scalars['String'];
};

export type PixPaymentRecipientResponse = {
  __typename?: 'PixPaymentRecipientResponse';
  /** name of the recipient */
  name?: Maybe<Scalars['String']>;
  /** masked document of the recipient */
  document?: Maybe<Scalars['String']>;
};

export type PixPaymentSlipReceipt = {
  __typename?: 'PixPaymentSlipReceipt';
  /** file slip receipt in base64 */
  file: Scalars['String'];
};

export type PixPaymentSlipReceiptInput = {
  /** slip receipt in base64 */
  type: EPixSlipReceiptType;
  /** payment apiId */
  apiId: Scalars['String'];
};

export type PixRecipientBankDataInput = {
  /** recipient name */
  name: Scalars['String'];
  /** recipient document */
  encryptedDocument: Scalars['String'];
  /** recipient institution agency */
  institutionAgency: Scalars['String'];
  /** recipient institution account */
  institutionAccount: Scalars['String'];
  /** account type */
  accountType: EPixAccountType;
  /** person type */
  personType: EPixPersonType;
  /** bank ispb */
  ispb: Scalars['String'];
};

export type PixSendClaimRequestInput = {
  /** Key type */
  type: EPixKeyType;
  /** Key value */
  value: Scalars['String'];
};

export type PixSendPortabilityRequestInput = {
  /** Key type */
  type: EPixKeyType;
  /** Key value */
  value: Scalars['String'];
};

export type PixStaticQrCode = {
  __typename?: 'PixStaticQrCode';
  /** qrcode in base64 */
  qrcode: Scalars['String'];
  /** data to pix copia e cola */
  payload: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  maxInitialInvestment?: Maybe<Scalars['Float']>;
  monthlyInvestment?: Maybe<Scalars['Float']>;
  goalAmount?: Maybe<Scalars['Float']>;
  goalName?: Maybe<Scalars['String']>;
  maxLiquidity?: Maybe<Scalars['Float']>;
  goalDate?: Maybe<Scalars['Date']>;
};

export type PortfolioCreationOption = {
  __typename?: 'PortfolioCreationOption';
  optionType?: Maybe<CreationOptionType>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  rawUrl?: Maybe<Scalars['String']>;
  regularUrl?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
  thumbUrl?: Maybe<Scalars['String']>;
};

export type PortfolioOptions = {
  __typename?: 'PortfolioOptions';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<PortfolioCreationOption>>>;
};

export type Product = {
  __typename?: 'Product';
  percentage?: Maybe<Scalars['Float']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  minimumInitialInvestment?: Maybe<Scalars['Float']>;
  minimumFinancialMovement?: Maybe<Scalars['Float']>;
  type?: Maybe<InstrumentType>;
  symbol?: Maybe<Scalars['String']>;
  apiId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  investorType?: Maybe<Scalars['String']>;
};

export type ProductAllocation = {
  __typename?: 'ProductAllocation';
  allocation?: Maybe<Scalars['String']>;
};

export type ProductPercentage = {
  __typename?: 'ProductPercentage';
  newInitialRootValue?: Maybe<Scalars['Float']>;
  newMonthlyRootValue?: Maybe<Scalars['Float']>;
};

export type ProductToWallet = {
  __typename?: 'ProductToWallet';
  conflict?: Maybe<ProductPercentage>;
  productAdded?: Maybe<Product>;
  isNotAllowedToAddInstrument?: Maybe<Scalars['Boolean']>;
  isFeasibleToAddInstrument?: Maybe<Scalars['Boolean']>;
  isInstrumentAlreadyAdded?: Maybe<Scalars['Boolean']>;
};

export type QueryCashArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryContactInformationArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryGlobalSummaryArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryTradeArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryWealthArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryTransactionArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryExchangeArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryPixArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryChannelsArgs = {
  customerId?: Maybe<Scalars['ID']>;
};

export type QueryInstrumentValidationArgs = {
  apiIds?: Maybe<Array<Scalars['String']>>;
};

export type QueryInstrumentsArgs = {
  pageIndex?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<InstrumentClass>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<InstrumentOrderByField>;
  isAscending?: Maybe<Scalars['Boolean']>;
  brandApiId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  marketIndex?: Maybe<CoreMarketIndicators>;
};

export type QueryInstrumentDetailsArgs = {
  apiIds?: Maybe<Array<Scalars['String']>>;
};

export type QueryInstrumentsFilterArgs = {
  pageIndex?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<InstrumentClass>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<InstrumentOrderByField>;
  isAscending?: Maybe<Scalars['Boolean']>;
  brandApiId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  marketIndex?: Maybe<CoreMarketIndicators>;
  investorType?: Maybe<InvestorType>;
};

export type QueryMaxPercentageArgs = {
  instrument?: Maybe<InputProduct>;
};

export type QueryGetProductAllocationArgs = {
  instrument?: Maybe<InputProduct>;
};

export enum RiskGrade {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export type RiskGrades = {
  __typename?: 'RiskGrades';
  value?: Maybe<ERiskGrades>;
  formatted?: Maybe<Scalars['String']>;
};

export type RiskSignatureResponse = {
  __typename?: 'RiskSignatureResponse';
  type?: Maybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  stateLevel?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  hasAllocationSystem?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<Plan>;
  allocations?: Maybe<Array<Maybe<Allocations>>>;
  chart?: Maybe<Array<Maybe<Chart>>>;
  chartFormatted?: Maybe<Array<Maybe<ChartFormatted>>>;
  allocationCategories?: Maybe<Array<Maybe<Allocations>>>;
};

export type Status = {
  __typename?: 'Status';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Date']>;
};

export type StringFormattableField = {
  __typename?: 'StringFormattableField';
  value?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

export type SubType = {
  __typename?: 'SubType';
  name?: Maybe<Scalars['String']>;
  allocations?: Maybe<Array<Maybe<SubTypeAllocations>>>;
};

export type SubTypeAllocations = {
  __typename?: 'SubTypeAllocations';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<FormattableAllocationsType>;
  allocValue?: Maybe<FormattableAllocationsValue>;
  isLocked?: Maybe<Scalars['Boolean']>;
  apiId?: Maybe<Scalars['String']>;
  maxPercentage?: Maybe<FormattableAllocationsValue>;
};

export type SubTypeAllocationsResume = {
  __typename?: 'SubTypeAllocationsResume';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<FormattableAllocationsTypeResume>;
  allocValue?: Maybe<FormattableAllocationsValueResume>;
  isLocked?: Maybe<Scalars['Boolean']>;
  apiId?: Maybe<Scalars['String']>;
};

export type SubTypeResume = {
  __typename?: 'SubTypeResume';
  name?: Maybe<Scalars['String']>;
  allocations?: Maybe<Array<Maybe<SubTypeAllocationsResume>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
  /** Real time ticker price at datetime for the given symbols */
  getTradeTickerPriceAtDateTime?: Maybe<TradeTickerPriceAtDateTime>;
  /** Real time positions prices */
  getTradePositionsPrices: Array<Maybe<TradePositionPrice>>;
  /** Real time position prices by symbol - details */
  getTradePositionBySymbolPrices: Array<Maybe<TradePositionPrice>>;
  /** Real time orders status by clOrderIds */
  getTradeOrderData?: Maybe<TradeOrder>;
  /** Real time watchlist prices */
  getTradeWatchlistPrices: Array<Maybe<TradeWatchlistPrice>>;
  /** Real time trade home prices (positions and watchlist) */
  getTradeHomePrices: TradeHomePrice;
};

export type SubscriptionGetTradeTickerPriceAtDateTimeArgs = {
  symbols: Array<Scalars['String']>;
};

export type SubscriptionGetTradePositionBySymbolPricesArgs = {
  symbol: Scalars['String'];
};

export type SubscriptionGetTradeOrderDataArgs = {
  filter?: Maybe<TradeOrderDataInput>;
};

export type SubscriptionGetTradeHomePricesArgs = {
  filter: TradeHomePricesInput;
};

export type TermSignatureResponse = {
  __typename?: 'TermSignatureResponse';
  type?: Maybe<Scalars['String']>;
};

/** Someone's trade information */
export type Trade = {
  __typename?: 'Trade';
  /** The id of the customer you are trying to query a trade module from */
  customerId?: Maybe<Scalars['ID']>;
  /** The id of the trade account to this customer */
  portfolioApiId?: Maybe<Scalars['ID']>;
  /** (SUBSCRIPTION TEST) return a list of tickers */
  tickers?: Maybe<Array<Maybe<TradeTicker>>>;
  /** Return the account state */
  accountState?: Maybe<TradeAccountState>;
  /** Return a list of all instruments detailed */
  allInstruments?: Maybe<TradePaginatedInstrument>;
  /** Return a list of customer's instruments detailed */
  instruments?: Maybe<Array<Maybe<TradeInstrument>>>;
  /** Return a customer's instrument (required a ticker param) */
  instrument?: Maybe<TradeInstrument>;
  /** Return a list of positions */
  positions?: Maybe<TradePaginatedPosition>;
  /**
   * Return the positions summary of this client
   * @deprecated Use accountState instead
   */
  positionSummary?: Maybe<TradePositionSummary>;
  /** Return a list of orders */
  orders?: Maybe<TradePaginatedOrder>;
  /** Return a order (required a orderId param) */
  order?: Maybe<TradeOrder>;
  /** Return a list of quotes */
  quotes?: Maybe<Array<Maybe<TradeQuote>>>;
  /** Given a ticker, returns all history of a ticker value */
  tickerPriceHistory?: Maybe<Array<Maybe<TradeTickerPriceAtDateTime>>>;
  /** Given a symbol, returns last history of symbol value */
  lastSymbolPrice?: Maybe<TradeTickerPriceAtDateTime>;
  /** Customer's trade account state, shows what need to be done to activate trade account */
  dashStatus?: Maybe<DashStatus>;
  /** Trade's chart data */
  chart: TradeChart;
  /**
   * Return a list of movements (transactions and orders)
   * @deprecated Use trade.transactions instead
   */
  movements?: Maybe<TradePaginatedMovement>;
  /** Return a list of movements of Transaction type */
  transactions?: Maybe<TradeTransactions>;
  /** Return a status of trade api and portfolioApiId */
  status?: Maybe<TradeStatus>;
  /** Return a list of watchlist */
  watchlist: TradePaginatedWatchlist;
  /** @deprecated Use trade.companyAnalysis instead */
  companyInformations: TradeCompanyInformations;
  /**
   * Return a company analysis object
   * @deprecated Use instrument.abount and instrument.indicators
   */
  companyAnalysis?: Maybe<TradeCompanyInformations>;
  /** Return a list of financial statements formatted by month */
  statements: TradePaginatedStatement;
  /** Return a base64 of monthly statement */
  statementMonthly?: Maybe<TradeStatementDownload>;
  /** Return a base64 of consolidated statement */
  statementConsolidated?: Maybe<TradeStatementDownload>;
};

/** Someone's trade information */
export type TradeAllInstrumentsArgs = {
  filter?: Maybe<TradeFilterInput>;
};

/** Someone's trade information */
export type TradeInstrumentsArgs = {
  filter?: Maybe<TradeInstrumentInput>;
};

/** Someone's trade information */
export type TradeInstrumentArgs = {
  ticker: Scalars['String'];
};

/** Someone's trade information */
export type TradePositionsArgs = {
  filter?: Maybe<TradeFilterInput>;
};

/** Someone's trade information */
export type TradeOrdersArgs = {
  filter?: Maybe<TradeOrderFilterInput>;
};

/** Someone's trade information */
export type TradeOrderArgs = {
  orderId: Scalars['ID'];
};

/** Someone's trade information */
export type TradeQuotesArgs = {
  tickersQuotes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Someone's trade information */
export type TradeTickerPriceHistoryArgs = {
  ticker: Scalars['String'];
  chartPeriod: ETickerPriceHistoryPeriod;
};

/** Someone's trade information */
export type TradeLastSymbolPriceArgs = {
  symbol: Scalars['String'];
};

/** Someone's trade information */
export type TradeChartArgs = {
  options?: Maybe<TradeChartInput>;
};

/** Someone's trade information */
export type TradeSymbolChartArgs = {
  options?: Maybe<TradeSymbolChartInput>;
};

/** Someone's trade information */
export type TradeMovementsArgs = {
  page: PageInfoInput;
};

/** Someone's trade information */
export type TradeTransactionsArgs = {
  page: PageInfoInput;
};

/** Someone's trade information */
export type TradeWatchlistArgs = {
  filter: TradeWatchlistInput;
};

/** Someone's trade information */
export type TradeCompanyInformationsArgs = {
  symbol: Scalars['String'];
};

/** Someone's trade information */
export type TradeCompanyAnalysisArgs = {
  symbol: Scalars['String'];
};

/** Someone's trade information */
export type TradeStatementsArgs = {
  page: PageInfoInput;
};

/** Someone's trade information */
export type TradeStatementMonthlyArgs = {
  date: Scalars['String'];
};

export type TradeAccountProjectedBalances = {
  __typename?: 'TradeAccountProjectedBalances';
  /** balance to liquidate at date */
  value: FormattableBigDecimal;
  /** date to liquidate this balance */
  date: FormattableDate;
  /** label to show above balance to liquidate */
  label: Scalars['String'];
  /** array of operations to liquidate at this date */
  operations?: Maybe<Array<Maybe<TradeAccountProjectedBalancesOperations>>>;
};

export type TradeAccountProjectedBalancesOperations = {
  __typename?: 'TradeAccountProjectedBalancesOperations';
  /** description of operation */
  description: Scalars['String'];
  /** date to liquidate */
  operationDate: FormattableDate;
  /** value to liquidate at operationDate */
  value: FormattableBigDecimal;
  /** composition of value to liquidate (quantity x unit value) */
  composition: Scalars['String'];
};

/** state of trade account */
export type TradeAccountState = {
  __typename?: 'TradeAccountState';
  /** id of trade account on core */
  id?: Maybe<Scalars['ID']>;
  /** id of trade account on api */
  portfolioApiId?: Maybe<Scalars['ID']>;
  /** value of gross balance */
  grossBalance?: Maybe<FormattableBigDecimal>;
  /** value of net balance */
  netBalance?: Maybe<FormattableBigDecimal>;
  /**
   * value of balance of trade account
   * @deprecated Use availableBalanceToInvest instead
   */
  unrealizedNetWorth?: Maybe<FormattableBigDecimal>;
  /** trade account total earnings */
  totalEarnings?: Maybe<FormattableBigDecimal>;
  /** trade account total earnings percentage */
  totalEarningsPercentage?: Maybe<FormattablePercentage>;
  /** button label to be rendered to user (with chart) */
  button?: Maybe<Scalars['String']>;
  /** button action (with chart) */
  action?: Maybe<Scalars['String']>;
  /** trade account today balance */
  todayBalance?: Maybe<FormattableBigDecimal>;
  /** trade account available balance to invest */
  availableBalanceToInvest?: Maybe<FormattableBigDecimal>;
  /** trade account balance to withdraw */
  withdrawalBalance?: Maybe<FormattableBigDecimal>;
  /** trade account invested balance (sum of positions) */
  positionsBalance?: Maybe<FormattableBigDecimal>;
  /** trade account balance to liquidate at few days */
  toLiquidateBalance?: Maybe<FormattableBigDecimal>;
  /** array of values to liquidate at few days */
  projectedBalances?: Maybe<Array<Maybe<TradeAccountProjectedBalances>>>;
  /** trade account blocked balance */
  blockedBalance?: Maybe<FormattableBigDecimal>;
  /** array of buttons with actions that the user can perform */
  buttons: Array<TradeButton>;
};

export type TradeButton = {
  __typename?: 'TradeButton';
  /** the button text label */
  label: Scalars['String'];
  /** the button action */
  action: ETradeButtonAction;
};

export type TradeChart = {
  __typename?: 'TradeChart';
  /** Available filters. */
  filters: Array<TradeChartFilter>;
  /** Default seleced, or parameter, filter; its an index present in filters field. */
  defaultFilter: Scalars['Int'];
  /** Available chart types. */
  types: Array<ETradeChartType>;
  /** Default selected chart type. */
  defaultType: Scalars['Int'];
  /** Array of available series for Y axis. */
  axisY: Array<TradeChartAxisY>;
  /** Default serie of y axis. */
  defaultAxisY: Scalars['Int'];
  /** Array of available series for X axis. */
  axisX: Array<TradeChartAxisX>;
  /** Default serie of X axis. */
  defaultAxisX: Scalars['Int'];
  /** Last total balance value of array */
  lastTotalBalance: FormattableBigDecimal;
};

export type TradeChartTypesArgs = {
  options?: Maybe<TradeChartTypesInput>;
};

/** Data serie present in X axis */
export type TradeChartAxisX = {
  __typename?: 'TradeChartAxisX';
  /** Key of the serie. */
  key: ETradeChartAxisX;
  /** String description of the serie. */
  description: Scalars['String'];
  /** List of entries (dates) in serie. */
  values: Array<Scalars['String']>;
};

/** Data serie present in X axis */
export type TradeChartAxisXValuesArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** Data serie present in Y axis */
export type TradeChartAxisY = {
  __typename?: 'TradeChartAxisY';
  /** Key of the serie. */
  key: ETradeChartAxisY;
  /** String description of the serie. */
  description: Scalars['String'];
  /** List of entries in serie. */
  values: Array<Maybe<FormattableTradeValues>>;
};

export type TradeChartFilter = {
  __typename?: 'TradeChartFilter';
  /** Key of the filter. */
  key: ETradeChartFilter;
  /** String description of the filter. */
  description: Scalars['String'];
};

/** CHART */
export type TradeChartInput = {
  /** Selected chart filter. */
  filter?: Maybe<ETradeChartFilter>;
};

export type TradeChartTypesInput = {
  /** Limit of chart types to be returned. */
  limit?: Maybe<Scalars['Int']>;
};

export type TradeCompanyInformations = {
  __typename?: 'TradeCompanyInformations';
  balanceSheet: Array<Maybe<TradeCompanyInformationsData>>;
  financialIndicators: Array<Maybe<TradeCompanyInformationsData>>;
  profile?: Maybe<TradeCompanyInformationsProfile>;
};

export type TradeCompanyInformationsData = {
  __typename?: 'TradeCompanyInformationsData';
  context?: Maybe<Scalars['String']>;
  item?: Maybe<FormattableIndicatorNumber>;
};

export type TradeCompanyInformationsProfile = {
  __typename?: 'TradeCompanyInformationsProfile';
  sector?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  employees?: Maybe<Scalars['BigDecimal']>;
  description?: Maybe<Scalars['String']>;
};

export type TradeDateRangeInput = {
  /** init date of the range, should be in YYYY-MM-DD format */
  startDate?: Maybe<Scalars['String']>;
  /** end date of the range, should be in YYYY-MM-DD format */
  endDate?: Maybe<Scalars['String']>;
};

/** formatted trade type object */
export type TradeDirection = {
  __typename?: 'TradeDirection';
  /** direction enum key (from core) */
  value?: Maybe<ETradeDirection>;
  /** direction formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type TradeErrorMessage = {
  __typename?: 'TradeErrorMessage';
  /** title of error message */
  title?: Maybe<Scalars['String']>;
  /** description of error message */
  description?: Maybe<Scalars['String']>;
};

/** input to filter with paginated data */
export type TradeFilterInput = {
  /** ticker symbol to filter all instruments */
  search?: Maybe<Scalars['String']>;
  /** array of tickers symbols to filter all instruments */
  symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** pagination info */
  page: PageInfoInput;
};

export type TradeHomeBroker = {
  __typename?: 'TradeHomeBroker';
  /** text of the Home Broker button */
  buttonText?: Maybe<Scalars['String']>;
  /** home broker status can be enabled, disabled or requested */
  status?: Maybe<ETradeHomeBrokerStatus>;
};

export type TradeHomeLastPrice = {
  __typename?: 'TradeHomeLastPrice';
  /** last symbol price reported, e.g. 42.12 */
  price: FormattableBigDecimal;
  /** symbol price date */
  date: Scalars['String'];
  /** symbol earnings by day (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange: FormattablePercentage;
};

export type TradeHomeLastPriceDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type TradeHomePosition = {
  __typename?: 'TradeHomePosition';
  /** position's current value (quantity * price) */
  currentValue?: Maybe<FormattableBigDecimal>;
  /** position's total earnings by day (price - avgPrice) / avgPrice */
  totalEarnings?: Maybe<FormattablePercentage>;
  /** position's current profit difference */
  profitPrice?: Maybe<FormattableBigDecimal>;
};

export type TradeHomePrice = {
  __typename?: 'TradeHomePrice';
  /** symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** symbol last price data */
  lastPrice: TradeHomeLastPrice;
  /** symbol position data */
  position?: Maybe<TradeHomePosition>;
};

export type TradeHomePricesInput = {
  /** pagination info to positions */
  pagePositions: PageInfoInput;
  /** pagination info to watchlist */
  pageWatchlist: PageInfoInput;
};

/** instruments data */
export type TradeInstrument = {
  __typename?: 'TradeInstrument';
  /** ticker code */
  ticker: Scalars['String'];
  /** description of ticker company */
  description: Scalars['String'];
  /** type of instrument */
  type?: Maybe<TradeTypeInstrument>;
  /** Minimum quantity for trade. If lotSize is set, then the specified value must be in lots. */
  minQuantity?: Maybe<Scalars['Int']>;
  /** Maximum quantity for trade. If lotSize is set, then the specified value must be in lots. */
  maxQuantity?: Maybe<Scalars['Int']>;
  /** Number of shares you buy in one transaction. Used for the stock market. */
  lotSize: Scalars['Int'];
  /** id of watchlist item */
  watchlistItemId?: Maybe<Scalars['String']>;
  /** risk profile */
  riskProfile?: Maybe<TradeInstrumentRiskProfile>;
  about?: Maybe<TradeInstrumentAbout>;
  indicators?: Maybe<TradeInstrumentIndicators>;
};

export type TradeInstrumentAbout = {
  __typename?: 'TradeInstrumentAbout';
  name?: Maybe<TradeInstrumentAboutField>;
  document?: Maybe<TradeInstrumentAboutField>;
  segment?: Maybe<TradeInstrumentAboutField>;
  type?: Maybe<TradeInstrumentAboutField>;
  administrator?: Maybe<TradeInstrumentAboutField>;
  managementType?: Maybe<TradeInstrumentAboutField>;
  description?: Maybe<Scalars['String']>;
};

export type TradeInstrumentAboutField = {
  __typename?: 'TradeInstrumentAboutField';
  value?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TradeInstrumentIndicators = {
  __typename?: 'TradeInstrumentIndicators';
  referencePeriod?: Maybe<Scalars['String']>;
  list?: Maybe<Array<Maybe<TradeInstrumentIndicatorsList>>>;
};

export type TradeInstrumentIndicatorsDescription = {
  __typename?: 'TradeInstrumentIndicatorsDescription';
  value?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
};

export type TradeInstrumentIndicatorsList = {
  __typename?: 'TradeInstrumentIndicatorsList';
  description?: Maybe<TradeInstrumentIndicatorsDescription>;
  shortName?: Maybe<Scalars['String']>;
  longName?: Maybe<Scalars['String']>;
  value?: Maybe<FormattableTradeInstrumentsIndicator>;
};

/** input with data to filter instruments */
export type TradeInstrumentInput = {
  /** ticker symbol to filter all instruments */
  search?: Maybe<Scalars['String']>;
  /** array of tickers symbols to filter all instruments */
  tickers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** trade instrument risk profile object */
export type TradeInstrumentRiskProfile = {
  __typename?: 'TradeInstrumentRiskProfile';
  /** risk tolerance for current instrument */
  instrumentRiskTolerance?: Maybe<TradeRiskToleranceType>;
  /** risk tolerance for current cunstomer */
  customerRiskTolerance?: Maybe<TradeRiskToleranceType>;
  /** list of all risk tolerance profiles */
  riskToleranceProfiles?: Maybe<Array<Maybe<TradeRiskToleranceProfiles>>>;
  /** flag to indicate if customer can operate current instrument */
  canOperate?: Maybe<Scalars['Boolean']>;
};

export type TradeMarketAvailable = {
  __typename?: 'TradeMarketAvailable';
  /** true if market is available for operations */
  isMarketAvailable?: Maybe<Scalars['Boolean']>;
  /** market status type */
  marketStatus?: Maybe<TradeMarketStatus>;
  /** market status description */
  description?: Maybe<Scalars['String']>;
  /** market status inner description for one line usage */
  innerDescription?: Maybe<Scalars['String']>;
  /** trade negotiation available type */
  negotiationAvailable?: Maybe<TradeNegotiationAvailable>;
};

/** formatted trade market status type object */
export type TradeMarketStatus = {
  __typename?: 'TradeMarketStatus';
  /** market status code */
  value?: Maybe<ETradeMarketStatus>;
  /** market formatted status */
  formatted?: Maybe<Scalars['String']>;
};

/** movements of trade data (transactions or orders) */
export type TradeMovement = {
  __typename?: 'TradeMovement';
  /** id of trade portfolio */
  portfolioApiId: Scalars['ID'];
  /** movements's direction object */
  direction?: Maybe<TradeTransactionDirection>;
  /** movements's value */
  value: FormattableBigDecimal;
  /**
   * movements's amount
   * @deprecated Use value instead
   */
  amount: FormattableBigDecimal;
  /**
   * movements's net value
   * @deprecated Use value instead
   */
  netValue: FormattableBigDecimal;
  /**
   * movements's create at date, formatted as tring
   * @deprecated Use date instead
   */
  createdAt?: Maybe<Scalars['String']>;
  /**
   * movements's operated at date, formatted as tring
   * @deprecated Use date instead
   */
  operatedAt?: Maybe<Scalars['String']>;
  /**
   * movements's liquidated at date, formatted as tring
   * @deprecated Use date instead
   */
  liquidatedAt?: Maybe<Scalars['String']>;
  /** movements's description */
  description?: Maybe<Scalars['String']>;
  /** movements's type */
  type?: Maybe<TradeMovementType>;
  /** movements's order id (only if type is order) */
  orderId?: Maybe<Scalars['ID']>;
  /** movements's ticker (only if type is order) */
  ticker?: Maybe<Scalars['String']>;
  /** movements's instrument name (only if type is order) */
  instrumentName?: Maybe<Scalars['String']>;
  /** movements's type object (only if type is order) */
  orderType?: Maybe<TradeDirection>;
  /**
   * movements's status object (only if type is order)
   * @deprecated Use status instead
   */
  orderStatus?: Maybe<TradeOrderStatus>;
  /** movements's transaction id (only if type is transaction) */
  transactionApiId?: Maybe<Scalars['ID']>;
  /** movements's type (only if type is transaction) */
  transactionType?: Maybe<TradeTransactionType>;
  /**
   * movements's status (only if type is transaction)
   * @deprecated Use status instead
   */
  transactionStatus?: Maybe<TradeTransactionStatus>;
  /** movements's status (for transaction or order type) */
  status?: Maybe<TradeMovementStatus>;
  /** movements's date, formatted as string */
  date?: Maybe<Scalars['String']>;
  /** movements's date, formattable type */
  dateFormatted?: Maybe<FormattableDate>;
};

/** movements of trade data (transactions or orders) */
export type TradeMovementCreatedAtArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** movements of trade data (transactions or orders) */
export type TradeMovementOperatedAtArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** movements of trade data (transactions or orders) */
export type TradeMovementLiquidatedAtArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** movements of trade data (transactions or orders) */
export type TradeMovementDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** formatted trade movement type object */
export type TradeMovementStatus = {
  __typename?: 'TradeMovementStatus';
  /** movement status enum key */
  value?: Maybe<ETradeMovementStatus>;
  /** movement status formatted */
  formatted?: Maybe<Scalars['String']>;
};

/** formatted trade type object */
export type TradeMovementType = {
  __typename?: 'TradeMovementType';
  /** movement type enum key (from core) */
  value?: Maybe<ETradeMovementType>;
  /** movement type formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type TradeMutation = {
  __typename?: 'TradeMutation';
  /** The id of the customer you are trying to query a trade module from */
  customerId?: Maybe<Scalars['ID']>;
  /** (SUBSCRIPTION TEST) return a new ticker detail, including a new value on array */
  changeTickerDetail?: Maybe<TradeTicker>;
  /** create a new order for this customer */
  createOrder?: Maybe<Scalars['Boolean']>;
  /** create new order for this customer with real-time feedback */
  createTradeOrder?: Maybe<TradeOrder>;
  /** include/remove symbol on watchlist */
  updateTradeWatchlist?: Maybe<Scalars['Boolean']>;
  /** update the average price of an order type custody */
  updateOrderQuoteValue?: Maybe<Scalars['Boolean']>;
  /**
   * allow customer to operate with wrong risk profile
   * @deprecated Use acceptTradeOutsideRiskProfile
   */
  allowOperateNonCompliant?: Maybe<Scalars['Boolean']>;
  /** customer acceptance to buying assets outside of their risk profile */
  acceptTradeOutsideRiskProfile?: Maybe<Scalars['Boolean']>;
  /** request access to home broker */
  requestHomeBrokerAccess?: Maybe<Scalars['Boolean']>;
};

export type TradeMutationCreateOrderArgs = {
  order: TradeOrderInput;
};

export type TradeMutationCreateTradeOrderArgs = {
  order: TradeOrderInput;
};

export type TradeMutationUpdateTradeWatchlistArgs = {
  filter: TradeWatchlistMutationInput;
};

export type TradeMutationUpdateOrderQuoteValueArgs = {
  order: TradeUpdateOrderQuoteValue;
};

export type TradeMutationAcceptTradeOutsideRiskProfileArgs = {
  instrument: AcceptTradeOutsideRiskProfileInput;
};

export type TradeNegotiationAvailable = {
  __typename?: 'TradeNegotiationAvailable';
  /** info of customer can buy symbols */
  buy?: Maybe<TradeNegotiationAvailableDetail>;
  /** info of customer can sell symbols */
  sell?: Maybe<TradeNegotiationAvailableDetail>;
};

export type TradeNegotiationAvailableDetail = {
  __typename?: 'TradeNegotiationAvailableDetail';
  /** true if customer can operate symbols */
  state?: Maybe<ETradeNegotiationButtonState>;
  /** action of button */
  action?: Maybe<ETradeNegotiationStatus>;
  /** label of button */
  button?: Maybe<Scalars['String']>;
  /** message when customer can not negociate */
  message?: Maybe<Scalars['String']>;
};

export type TradeOrder = {
  __typename?: 'TradeOrder';
  /** Order's id */
  id: Scalars['ID'];
  /** ClOrderId - external guid of this order */
  clOrderId?: Maybe<Scalars['String']>;
  /** Order's direction */
  direction: TradeDirection;
  /** Order's limit price, only to limit orders */
  limitPrice?: Maybe<FormattableBigDecimal>;
  /** Value traded on this order */
  value: FormattableBigDecimal;
  /** Value executed on this order */
  executedPrice?: Maybe<FormattableBigDecimal>;
  /** Order's date, that updated the last status, formatted as string */
  statusDate?: Maybe<Scalars['String']>;
  /** Description additional to order's status */
  statusText?: Maybe<Scalars['String']>;
  /** Order's symbol */
  ticker: Scalars['String'];
  /** Order's status */
  status: TradeOrderStatus;
  /** Order's type */
  type: TradeOrderType;
  /** Quantity of symbol traded on this order */
  quantity: Scalars['Int'];
  /** Order's description */
  description: Scalars['String'];
  /** Order's status message after creation of order - feedback */
  statusMessage?: Maybe<TradeOrderStatusMessage>;
  /** Order's movement type */
  movementType?: Maybe<TradeMovementType>;
  /** Flag to indicate if the order's quote value can the changed */
  canChangeQuoteValue?: Maybe<Scalars['Boolean']>;
  /** Order's label for any extra description */
  label?: Maybe<Scalars['String']>;
};

export type TradeOrderStatusDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** GETTRADEORDERDATA */
export type TradeOrderDataInput = {
  /** ClOrderIds - external guid of orders to filter */
  clOrderIds: Array<Scalars['String']>;
};

/** input to filter with paginated data */
export type TradeOrderFilterInput = {
  /** ticker symbol to filter all instruments */
  search?: Maybe<Scalars['String']>;
  /** array of tickers symbols to filter all instruments */
  symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** array of status to filter all instruments */
  status?: Maybe<Array<Maybe<ETradeOrderStatus>>>;
  /** pagination info */
  page: PageInfoInput;
};

/** input with data to create a new order */
export type TradeOrderInput = {
  /** direction of this order (Buy or Sell) */
  direction: ETradeDirection;
  /** quantity of this order */
  quantity: Scalars['Int'];
  /** unit value of this order */
  value: Scalars['Float'];
  /** ticker symbol of this order */
  ticker: Scalars['String'];
};

/** formatted trade type object */
export type TradeOrderStatus = {
  __typename?: 'TradeOrderStatus';
  /** order status enum key (from core) */
  value?: Maybe<ETradeOrderStatus>;
  /** order status formatted */
  formatted?: Maybe<Scalars['String']>;
};

/** Order's status message after creation of order - feedback */
export type TradeOrderStatusMessage = {
  __typename?: 'TradeOrderStatusMessage';
  /** order status message title */
  title: Scalars['String'];
  /** order status message label */
  label: Scalars['String'];
  /** order status message description */
  description: Scalars['String'];
};

/** formatted trade type object */
export type TradeOrderType = {
  __typename?: 'TradeOrderType';
  /** order type enum key (from core) */
  value?: Maybe<ETradeOrderType>;
  /** order type formatted */
  formatted?: Maybe<Scalars['String']>;
};

/** instruments paginated data */
export type TradePaginatedInstrument = {
  __typename?: 'TradePaginatedInstrument';
  /** current page info */
  page: PageInfo;
  /** list of instruments for current page */
  instruments: Array<TradeInstrument>;
};

/** movements paginated data */
export type TradePaginatedMovement = {
  __typename?: 'TradePaginatedMovement';
  /** current page info */
  page: PageInfo;
  /** list of movements for current page */
  movements: Array<TradeMovement>;
};

/** orders paginated data */
export type TradePaginatedOrder = {
  __typename?: 'TradePaginatedOrder';
  /** current page info */
  page: PageInfo;
  /** list of orders for current page */
  orders: Array<TradeOrder>;
};

/** positions paginated data with unrealizedNetWorth */
export type TradePaginatedPosition = {
  __typename?: 'TradePaginatedPosition';
  /** value of balance of positions */
  unrealizedNetWorth: FormattableBigDecimal;
  /** current page info */
  page: PageInfo;
  /** array of positions detailed */
  positions: Array<TradePosition>;
};

/** paginated trade statement type */
export type TradePaginatedStatement = {
  __typename?: 'TradePaginatedStatement';
  /** current page info */
  page: PageInfo;
  /** list of dates for current page */
  list: Array<TradeStatement>;
  /** whether to show 2021 Positions Statement, that will help customers declare their 'Imposto de Renda' */
  showPositionsStatement?: Maybe<Scalars['Boolean']>;
};

/** watchlist paginated data */
export type TradePaginatedWatchlist = {
  __typename?: 'TradePaginatedWatchlist';
  /** id of watchlist item */
  watchlistItemId: Scalars['ID'];
  /** current page info */
  page: PageInfo;
  /** array of watchlist symbols detailed */
  watchlist: Array<Maybe<TradeWatchlist>>;
};

/** data of position detailed */
export type TradePosition = {
  __typename?: 'TradePosition';
  /** id of position detailed */
  id: Scalars['ID'];
  /** position's ticker symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** quantity of ticker in this position */
  quantity: Scalars['Int'];
  /** last ticker price reported for position, e.g. 42.12 */
  price?: Maybe<FormattableBigDecimal>;
  /** direction of this position (buy or sell) */
  direction: TradeDirection;
  /** Average price of position trades. */
  avgPrice: FormattableBigDecimal;
  /** position's current value (quantity * price) */
  currentValue?: Maybe<FormattableBigDecimal>;
  /** position's total earnings by day (price - avgPrice) / avgPrice */
  totalEarnings?: Maybe<FormattablePercentage>;
  /** position's earnings by day (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange?: Maybe<FormattablePercentage>;
  /** history intra-day for this symbol */
  chart?: Maybe<Array<Maybe<TradeTickerPriceAtDateTime>>>;
  /** description of symbol company */
  description?: Maybe<Scalars['String']>;
  /** number of shares you buy in one transaction. Used for the stock market. */
  lotSize?: Maybe<Scalars['Int']>;
};

export type TradePositionPrice = {
  __typename?: 'TradePositionPrice';
  /** position's unique identifier */
  id: Scalars['ID'];
  /** position's ticker symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** postion's ticker quantity */
  quantity: Scalars['Int'];
  /** last ticker price reported for position, e.g. 42.12 */
  price?: Maybe<FormattableBigDecimal>;
  /** postion's ticker price date */
  date?: Maybe<Scalars['String']>;
  /** position's average price */
  avgPrice?: Maybe<FormattableBigDecimal>;
  /** position's current value (quantity * price) */
  currentValue?: Maybe<FormattableBigDecimal>;
  /** position's total earnings by day (price - avgPrice) / avgPrice */
  totalEarnings?: Maybe<FormattablePercentage>;
  /** position's earnings by day (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange?: Maybe<FormattablePercentage>;
  /** position's current profit difference */
  profitPrice?: Maybe<FormattableBigDecimal>;
};

export type TradePositionPriceDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** data of position summary */
export type TradePositionSummary = {
  __typename?: 'TradePositionSummary';
  /** value average price of all tickers */
  avgPrice?: Maybe<Scalars['Currency']>;
  /** % calc by unrealizedNetWorth / avgPrice */
  earnings?: Maybe<FormattablePercentage>;
  /** value available to buy/sell on trade account */
  unrealizedNetWorth?: Maybe<FormattableBigDecimal>;
  /** value of balance of trade account */
  currentValue?: Maybe<FormattableBigDecimal>;
};

export type TradeQuote = {
  __typename?: 'TradeQuote';
  ticker: Scalars['String'];
  change: Scalars['BigDecimal'];
  changePercent: Scalars['BigDecimal'];
  askPrice: FormattableBigDecimal;
  bidPrice: FormattableBigDecimal;
  lastPrice: FormattableBigDecimal;
  openPrice: FormattableBigDecimal;
  highPrice: FormattableBigDecimal;
  lowPrice: FormattableBigDecimal;
  prevClosePrice: FormattableBigDecimal;
  volume: Scalars['BigDecimal'];
  /** Value of 1 pip in the account currency, used for calculating risks and trade value in the order dialog for buy orders. */
  buyPipValue?: Maybe<Scalars['BigDecimal']>;
  /** Value of 1 pip in the account currency, used for calculating risks and trade value in the order dialog for sell orders. */
  sellPipValue?: Maybe<Scalars['BigDecimal']>;
};

/** trade risk tolerance profiles object */
export type TradeRiskToleranceProfiles = {
  __typename?: 'TradeRiskToleranceProfiles';
  /** risk tolerance for current profile */
  type?: Maybe<TradeRiskToleranceType>;
  /** list of instruments for current profile */
  availableInstruments?: Maybe<Scalars['String']>;
};

/** trade risk tolerance object */
export type TradeRiskToleranceType = {
  __typename?: 'TradeRiskToleranceType';
  /** risk tolerance value */
  value?: Maybe<ETradeRiskTolerance>;
  /** risk tolerance formatted */
  formatted?: Maybe<Scalars['String']>;
};

/** trade statement type */
export type TradeStatement = {
  __typename?: 'TradeStatement';
  /** year */
  year: Scalars['String'];
  /** list of months */
  months: Array<TradeStatementMonth>;
};

/** trade statement download type */
export type TradeStatementDownload = {
  __typename?: 'TradeStatementDownload';
  /** code base64 to generate PDF */
  base64: Scalars['String'];
};

/** trade statement list of months type */
export type TradeStatementMonth = {
  __typename?: 'TradeStatementMonth';
  /** date */
  date: Scalars['String'];
  /** month formatted */
  month: Scalars['String'];
};

export type TradeStatus = {
  __typename?: 'TradeStatus';
  /** true if customer has an trade account created */
  enabled?: Maybe<Scalars['Boolean']>;
  /** true if customer has MFA activated */
  enabledMFA: Scalars['Boolean'];
  /** true if customer can operate at the trade */
  tradingAvailable: Scalars['Boolean'];
  /** portfolio api id of trade account */
  portfolioApiId?: Maybe<Scalars['ID']>;
  /** market available type */
  marketAvailable?: Maybe<TradeMarketAvailable>;
  /** true if customer can buy */
  enabledBuy?: Maybe<Scalars['Boolean']>;
  /** true if customer must be moved to MFA flow */
  moveToMFAFlow?: Maybe<Scalars['Boolean']>;
  /** home broker type */
  homeBroker?: Maybe<TradeHomeBroker>;
};

export type TradeSymbolChartTypesArgs = {
  options?: Maybe<TradeChartTypesInput>;
};

/** SYMBOL CHART */
export type TradeSymbolChartInput = {
  /** Symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** Selected chart filter. */
  filter?: Maybe<ETickerPriceHistoryPeriod>;
  /** The data sort direction dates must follow e.g desc */
  dateDirection?: Maybe<ETradeDataSort>;
};

/** trade symbol status object */
export type TradeSymbolStatus = {
  __typename?: 'TradeSymbolStatus';
  /** symbol status value */
  value?: Maybe<ETradeMarketDataSymbolStatus>;
  /** symbol status message */
  message?: Maybe<Scalars['String']>;
};

/** information about actions or crypto currencies */
export type TradeTicker = {
  __typename?: 'TradeTicker';
  /** id of ticker  */
  id: Scalars['String'];
  /** description of ticker */
  ticker: Scalars['String'];
  /** ticker data in real time */
  details: Array<Maybe<TradeTickerDetail>>;
};

/** detailed informations about tickers over time */
export type TradeTickerDetail = {
  __typename?: 'TradeTickerDetail';
  /** quantity of ticker details in real time */
  quantity: Scalars['BigDecimal'];
  /** average price of ticker in real time  */
  avgPrice: FormattableBigDecimal;
};

/** A ticker price at a datetime */
export type TradeTickerPriceAtDateTime = {
  __typename?: 'TradeTickerPriceAtDateTime';
  /** Symbol, e.g. PTR4 */
  symbol?: Maybe<Scalars['String']>;
  /** ticker price's date, formatted as string */
  date?: Maybe<Scalars['String']>;
  /** Its price, e.g. 42.12 */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Its best price to sell, e.g. 42.12 */
  bestOfferSell?: Maybe<Scalars['BigDecimal']>;
  /** Its best price to buy, e.g. 42.12 */
  bestOfferBuy?: Maybe<Scalars['BigDecimal']>;
  /** symbol's earnings by day (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange?: Maybe<FormattablePercentage>;
  /** error message to show on clients */
  message?: Maybe<TradeErrorMessage>;
  /** status of this symbol on Bovespa */
  status?: Maybe<TradeSymbolStatus>;
};

/** A ticker price at a datetime */
export type TradeTickerPriceAtDateTimeDateArgs = {
  format?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type TradeTransaction = Transaction & {
  __typename?: 'TradeTransaction';
  /** id of trade portfolio */
  portfolioApiId: Scalars['ID'];
  /** movements's direction object */
  direction?: Maybe<TradeTransactionDirection>;
  /** transaction value */
  value?: Maybe<FormattableBigDecimal>;
  /**
   * transaction type
   * @deprecated Use trade.transactions.label.type instead
   */
  type?: Maybe<TradeMovementType>;
  /** transaction detail type (if null transaction doesn't have detail query) */
  detailType?: Maybe<TransactionType>;
  /** transaction status (for transaction or order type) */
  status?: Maybe<TradeMovementStatus>;
  /**
   * transaction description
   * @deprecated Use trade.transactions.label.description or trade.transactions.label.smallDescription instead
   */
  description?: Maybe<Scalars['String']>;
  /** transaction order id (only if type is order) */
  orderId?: Maybe<Scalars['ID']>;
  /** transaction type object (only if type is order) */
  orderType?: Maybe<TradeDirection>;
  /** transaction ticker (only if type is order) */
  ticker?: Maybe<Scalars['String']>;
  /** transaction instrument name (only if type is order) */
  instrumentName?: Maybe<Scalars['String']>;
  /** transaction transaction id (only if type is transaction) */
  transactionApiId?: Maybe<Scalars['ID']>;
  /** transaction type (only if type is transaction) */
  transactionType?: Maybe<TradeTransactionType>;
  /** transaction date, formattable type */
  date?: Maybe<FormattableDate>;
  /** transaction label (for transaction or order type) */
  label?: Maybe<TradeTransactionLabel>;
  /** return id based on type (transactionApiId for transactions, otherwise return orderId) */
  refId?: Maybe<Scalars['ID']>;
};

export type TradeTransactionDate = TransactionDate & {
  __typename?: 'TradeTransactionDate';
  /** date as formattable date */
  date: FormattableDate;
  /** list os transactions for current date */
  transactions: Array<TradeTransaction>;
};

/** formatted trade transaction type object */
export type TradeTransactionDirection = {
  __typename?: 'TradeTransactionDirection';
  /** transaction direction enum key (from core) */
  value?: Maybe<ETradeTransactionDirection>;
  /** transaction direction formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type TradeTransactionLabel = {
  __typename?: 'TradeTransactionLabel';
  /** trade transaction type */
  type?: Maybe<TradeMovementType>;
  /** trade transaction description to use on extract detailed */
  description?: Maybe<Scalars['String']>;
  /** trade transaction description to use on home page */
  smallDescription?: Maybe<Scalars['String']>;
};

/** formatted trade transaction type object */
export type TradeTransactionStatus = {
  __typename?: 'TradeTransactionStatus';
  /** transaction status enum key */
  value?: Maybe<ETradeTransactionStatus>;
  /** transaction status formatted */
  formatted?: Maybe<Scalars['String']>;
};

/** formatted trade transaction type object */
export type TradeTransactionType = {
  __typename?: 'TradeTransactionType';
  /** transaction type enum key (from core) */
  value?: Maybe<ETradeTransactionType>;
  /** transaction type formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type TradeTransactions = Transactions & {
  __typename?: 'TradeTransactions';
  /** current page info */
  page: PageInfo;
  /** list of dates in the extract */
  list: Array<TradeTransactionDate>;
  /** list of transactions without grouping by date */
  transactions: Array<TradeTransaction>;
};

/** formatted trade type object */
export type TradeTypeInstrument = {
  __typename?: 'TradeTypeInstrument';
  /** type instrument formatted */
  formatted?: Maybe<Scalars['String']>;
};

export type TradeUpdateOrderQuoteValue = {
  /** id of the custody order to update */
  id: Scalars['String'];
  /** new quote value */
  quoteValue: Scalars['Float'];
};

/** data of watchlist detailed */
export type TradeWatchlist = {
  __typename?: 'TradeWatchlist';
  /** watchlist's symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** last symbol price reported, e.g. 34.56 */
  price?: Maybe<FormattableBigDecimal>;
  /** daily percentual change of this symbol, (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange?: Maybe<FormattablePercentage>;
  /** description of symbol company */
  description?: Maybe<Scalars['String']>;
  /** number of shares you buy in one transaction. Used for the stock market. */
  lotSize?: Maybe<Scalars['Int']>;
};

/** WATCHLIST */
export type TradeWatchlistInput = {
  /** flag to include instruments data on query result */
  includeInstrument?: Maybe<Scalars['Boolean']>;
  /** pagination info */
  page: PageInfoInput;
};

export type TradeWatchlistMutationInput = {
  /** watchlist's symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** id of watchlist to remove symbol */
  watchlistItemId?: Maybe<Scalars['String']>;
  /** direction of operation */
  direction: ETradeWatchlistDirection;
};

/** data of watchlist detailed */
export type TradeWatchlistPrice = {
  __typename?: 'TradeWatchlistPrice';
  /** watchlist's symbol, e.g. PETR4 */
  symbol: Scalars['String'];
  /** last symbol price reported, e.g. 34.56 */
  price?: Maybe<FormattableBigDecimal>;
  /** daily percentual change of this symbol, (price - previousClosePrice) / previousClosePrice */
  dailyPercentualChange?: Maybe<FormattablePercentage>;
};

export type Transaction = {
  /** transaction value */
  value?: Maybe<FormattableBigDecimal>;
  /** transaction date */
  date?: Maybe<FormattableDate>;
  /** transaction type */
  detailType?: Maybe<TransactionType>;
};

export type TransactionAmount = {
  __typename?: 'TransactionAmount';
  value: Scalars['BigDecimal'];
  formatted: Scalars['String'];
};

/** transactions grouped by date */
export type TransactionDate = {
  /** current date as formattable date */
  date: FormattableDate;
  /** list os transactions for current date */
  transactions: Array<Transaction>;
};

export type TransactionDetailDepositArgs = {
  transactionId: Scalars['String'];
};

export type TransactionDetailPaymentArgs = {
  transactionId: Scalars['String'];
};

export type TransactionDetailTransferArgs = {
  transactionId: Scalars['String'];
};

export type TransactionDetailOrderArgs = {
  transactionId: Scalars['String'];
};

export type TransactionDetailWithdrawArgs = {
  transactionId: Scalars['String'];
};

export type TransactionDetailChargingFeesArgs = {
  transactionId: Scalars['String'];
};

/** used for transaction source and destination */
export type TransactionMovement = {
  __typename?: 'TransactionMovement';
  /** transaction movement title */
  title: Scalars['String'];
  /** transaction movement subtitle */
  subtitle: Scalars['String'];
};

export type TransactionStatus = {
  __typename?: 'TransactionStatus';
  value?: Maybe<ETransactionStatus>;
  formatted?: Maybe<Scalars['String']>;
};

export type TransactionType = {
  __typename?: 'TransactionType';
  value?: Maybe<ETransactionType>;
  formatted?: Maybe<Scalars['String']>;
};

export type Transactions = {
  /** current page info */
  page: PageInfo;
  /** list of dates in transactions */
  list: Array<TransactionDate>;
};

export type TransactionsDateRangeInput = {
  __typename?: 'TransactionsDateRangeInput';
  /** init date of the range */
  initDate: FormattableDate;
  /** end date of the range */
  endDate: FormattableDate;
};

export type TransactionsOptionsSort = {
  /** orders in relation to orderBy, should be asc or desc */
  order: EGlobalTransactionOrder;
  /** sorts the column */
  orderBy: EGlobalTransactionOrderBy;
};

export type TransferDetail = {
  __typename?: 'TransferDetail';
  /** The id of the transaction your are trying to query */
  transactionId?: Maybe<Scalars['ID']>;
  /** transaction amount according to FormattableBigDecimal */
  amount?: Maybe<FormattableBigDecimal>;
  /** transaction date according to FormattableDate */
  date?: Maybe<FormattableDate>;
  /** transaction createdDate according to FormattableDate */
  createdDate?: Maybe<FormattableDate>;
  isPending?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TransactionStatus>;
  type?: Maybe<TransactionType>;
  fromPortfolio?: Maybe<Scalars['String']>;
  toPortfolio?: Maybe<Scalars['String']>;
  isTotal?: Maybe<Scalars['Boolean']>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  toPortfolios?: Maybe<Array<Maybe<TrasferPortfolios>>>;
  fromPortfolioName?: Maybe<Scalars['String']>;
};

export type TrasferPortfolios = {
  __typename?: 'TrasferPortfolios';
  portfolioId?: Maybe<Scalars['String']>;
  portfolioName?: Maybe<Scalars['String']>;
  /** transaction amount to portfolio according to FormattableBigDecimal */
  amount?: Maybe<FormattableBigDecimal>;
};

export type ValidatedInstrumentData = {
  __typename?: 'ValidatedInstrumentData';
  dependentOnRisks?: Maybe<Array<Maybe<InstrumentRiskDependent>>>;
  dependentOnTerms?: Maybe<Array<Maybe<InstrumentTermDependent>>>;
};

export type WalletResume = {
  __typename?: 'WalletResume';
  goalName?: Maybe<Scalars['String']>;
  imageURI?: Maybe<Scalars['String']>;
  minInitialInvestment?: Maybe<IFormattableValues>;
  minFinancialMovement?: Maybe<IFormattableValues>;
  maxLiquidity?: Maybe<IFormattableValues>;
  riskType?: Maybe<RiskGrades>;
  goalDate?: Maybe<IFormattableDateValues>;
  goalAmount?: Maybe<IFormattableValues>;
  allocations?: Maybe<Array<Maybe<Allocations>>>;
  allocationCategories?: Maybe<Array<Maybe<Allocations>>>;
  chart?: Maybe<Array<Maybe<ChartResume>>>;
  chartFormatted?: Maybe<Array<Maybe<ChartResumeFormatted>>>;
  dependentOnRisks?: Maybe<Array<Maybe<InstrumentRiskDependent>>>;
  requestRisks?: Maybe<Scalars['Boolean']>;
  dependentOnTerms?: Maybe<Array<Maybe<InstrumentTermDependent>>>;
  requestTerms?: Maybe<Scalars['Boolean']>;
  portfolioType?: Maybe<OptionType>;
};

export type WalletResumeQueryResolver = {
  __typename?: 'WalletResumeQueryResolver';
  type?: Maybe<Scalars['String']>;
  data?: Maybe<WalletResume>;
};

export type Wealth = {
  __typename?: 'Wealth';
  customerId: Scalars['String'];
  transactions?: Maybe<PaginatedWealthTransaction>;
  /** Customer information to minimum deposit, portfolio name, and portfolio balance. Used to create new deposit transactions on portfolios. */
  investmentTransactionInfos?: Maybe<WealthInvestmentTransactionInfo>;
  /** Customer's wealth account state, shows if user need to take an action to activate accont. */
  dashStatus?: Maybe<DashStatus>;
  /** Balance data used on wealth tab. */
  balances?: Maybe<Balance>;
  /** Portfolios that a customer have */
  portfolios?: Maybe<Array<WealthPortfolio>>;
};

export type WealthTransactionsArgs = {
  page?: Maybe<WealthPageInfoInput>;
};

export type WealthInvestmentTransactionInfosArgs = {
  portfolioId: Scalars['String'];
};

export type WealthBalancesArgs = {
  options?: Maybe<WealthBalancesInput>;
};

export type WealthBalancesInput = {
  /** portfolio id */
  portfolio?: Maybe<Scalars['String']>;
};

export type WealthChartButton = {
  __typename?: 'WealthChartButton';
  /** title of the button */
  title?: Maybe<Scalars['String']>;
  /** subtitle of the button */
  subtitle?: Maybe<Scalars['String']>;
};

export type WealthInvestmentTransactionInfo = {
  __typename?: 'WealthInvestmentTransactionInfo';
  /** Recomended minimum deposit on this portfolio */
  minimumDeposit?: Maybe<WealthInvestmentTransactionMinimumDeposit>;
  /** Customer Portfolio name */
  name?: Maybe<Scalars['String']>;
  /** Portfolio gross total balance */
  totalBalance?: Maybe<FormattableBigDecimal>;
};

export type WealthInvestmentTransactionMinimumDeposit = {
  __typename?: 'WealthInvestmentTransactionMinimumDeposit';
  /** Recomended value to deposit */
  minimumValueToDeposit?: Maybe<FormattableBigDecimal>;
  /** Monlty investment deposit created by customer */
  monthlyInvestment?: Maybe<FormattableBigDecimal>;
};

export type WealthPageInfoInput = {
  /** page's items size */
  size: Scalars['Int'];
  /** page's index starting from 1 */
  index: Scalars['Int'];
};

export type WealthPortfolio = {
  __typename?: 'WealthPortfolio';
  /** Portfolio's unique identifier */
  id: Scalars['String'];
  /** Portfolio's display name */
  name: Scalars['String'];
};

/** Union of all old transactions types */
export type WealthTransaction = {
  __typename?: 'WealthTransaction';
  id?: Maybe<Scalars['ID']>;
  customer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  operatedAt?: Maybe<Scalars['String']>;
  liquidatedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['String']>;
  scheduleDate?: Maybe<Scalars['String']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  isPending?: Maybe<Scalars['Boolean']>;
  isFromCheckingAccount?: Maybe<Scalars['Boolean']>;
  isTotal?: Maybe<Scalars['Boolean']>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  fromInvoice?: Maybe<Scalars['String']>;
  fromAccount?: Maybe<Scalars['String']>;
  fromPortfolio?: Maybe<Scalars['String']>;
  fromPortfolioName?: Maybe<Scalars['String']>;
  toAccount?: Maybe<Scalars['String']>;
  toPortfolio?: Maybe<Scalars['String']>;
  toPortfolios?: Maybe<Scalars['String']>;
  portfolioName?: Maybe<Scalars['String']>;
  transactionCost?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountAgency?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountDigit?: Maybe<Scalars['String']>;
};

/** Union of all old transactions types */
export type WealthTransactionOperatedAtArgs = {
  format?: Maybe<Scalars['String']>;
};

/** Union of all old transactions types */
export type WealthTransactionLiquidatedAtArgs = {
  format?: Maybe<Scalars['String']>;
};

/** Union of all old transactions types */
export type WealthTransactionCreatedAtArgs = {
  format?: Maybe<Scalars['String']>;
};

/** Union of all old transactions types */
export type WealthTransactionScheduleAtArgs = {
  format?: Maybe<Scalars['String']>;
};

/** Union of all old transactions types */
export type WealthTransactionScheduleDateArgs = {
  format?: Maybe<Scalars['String']>;
};

export type WithdrawDetail = {
  __typename?: 'WithdrawDetail';
  /** The id of the transaction your are trying to query */
  transactionId?: Maybe<Scalars['ID']>;
  /** transaction amount according to FormattableBigDecimal */
  amount?: Maybe<FormattableBigDecimal>;
  status?: Maybe<TransactionStatus>;
  type?: Maybe<TransactionType>;
  /** transaction date created according to FormattableDate */
  createdAt?: Maybe<FormattableDate>;
  /** transaction date scheduled according to FormattableDate */
  scheduledAt?: Maybe<FormattableDate>;
  /** transaction date liquidated according to FormattableDate */
  liquidatedAt?: Maybe<FormattableDate>;
  toAccount?: Maybe<Scalars['String']>;
  fromPortfolio?: Maybe<Scalars['String']>;
  isPending?: Maybe<Scalars['Boolean']>;
  isTotal?: Maybe<Scalars['Boolean']>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountAgency?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountDigit?: Maybe<Scalars['String']>;
  /** transaction cost according to FormattableBigDecimal */
  transactionCost?: Maybe<FormattableBigDecimal>;
  /** indicate if withdraw is made by product */
  isWithdrawalByProduct?: Maybe<Scalars['Boolean']>;
  /** indicate if the withdraw is to warren account */
  toWarrenAccount?: Maybe<Scalars['String']>;
  /** more information by product */
  withdrawInfo?: Maybe<WithdrawInfo>;
};

export type WithdrawInfo = {
  __typename?: 'WithdrawInfo';
  /** products infos */
  products?: Maybe<Array<Maybe<WithdrawInfoProduct>>>;
  /** name of the source portfolio */
  fromPortfolioName?: Maybe<Scalars['String']>;
  /** module name */
  moduleName?: Maybe<Scalars['String']>;
  /** days to liquidation */
  daysToLiquidation?: Maybe<Scalars['String']>;
  /** maximum date to liquidation */
  maxLiquidationDate?: Maybe<FormattableDate>;
};

export type WithdrawInfoProduct = {
  __typename?: 'WithdrawInfoProduct';
  /** name of the product */
  productName?: Maybe<Scalars['String']>;
  /** net value */
  netValue?: Maybe<FormattableBigDecimal>;
  /** days to liquidation */
  daysToLiquidation?: Maybe<Scalars['String']>;
  /** date to liquidation */
  liquidationDate?: Maybe<FormattableDate>;
};

export type CashExtractQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
  dateFormat: Scalars['String'];
}>;

export type AutoApprovedAccountQueryVariables = Exact<{
  customerId?: Maybe<Scalars['ID']>;
}>;

export type AutoApprovedAccountQuery = { __typename?: 'Query' } & {
  content?: Maybe<
    { __typename?: 'GlobalSummary' } & {
      bankAccount?: Maybe<
        { __typename?: 'GlobalSummaryBankAccount' } & Pick<
          GlobalSummaryBankAccount,
          | 'name'
          | 'document'
          | 'bankName'
          | 'bankCode'
          | 'agency'
          | 'agencyNumber'
          | 'agencyDigit'
          | 'account'
          | 'accountNumber'
          | 'accountDigit'
        > & {
            labels: { __typename?: 'GlobalSummaryBankAccountLabels' } & Pick<
              GlobalSummaryBankAccountLabels,
              'name' | 'document'
            >;
          }
      >;
    }
  >;
};

export type GetCustomerFullNameQueryVariables = Exact<{
  customerId?: Maybe<Scalars['ID']>;
}>;

export type GetCustomerFullNameQuery = { __typename?: 'Query' } & {
  contactInformation?: Maybe<
    { __typename?: 'ContactInformation' } & Pick<ContactInformation, 'fullName'>
  >;
};

export type HomeCashQueryVariables = Exact<{ [key: string]: never }>;

export type HomeDashStatusQueryVariables = Exact<{ [key: string]: never }>;

export type HomeDashStatusQuery = { __typename?: 'Query' } & {
  globalSummary?: Maybe<
    { __typename?: 'GlobalSummary' } & {
      dashStatus?: Maybe<
        { __typename?: 'DashStatus' } & {
          card?: Maybe<
            { __typename?: 'DashStatusCard' } & Pick<
              DashStatusCard,
              'message' | 'title' | 'button' | 'action'
            > & {
                image?: Maybe<
                  { __typename?: 'DashStatusCardImage' } & {
                    web?: Maybe<
                      { __typename?: 'DashStatusCardImagePlatform' } & Pick<
                        DashStatusCardImagePlatform,
                        'full' | 'regular' | 'small'
                      >
                    >;
                  }
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type HomeDistributionQueryVariables = Exact<{ [key: string]: never }>;

export type HomeDistributionQuery = { __typename?: 'Query' } & {
  globalSummary?: Maybe<
    { __typename?: 'GlobalSummary' } & {
      sections?: Maybe<
        Array<
          Maybe<
            { __typename?: 'GlobalSummarySection' } & {
              type?: Maybe<
                { __typename?: 'GlobalSummarySectionType' } & Pick<
                  GlobalSummarySectionType,
                  'value' | 'formatted'
                >
              >;
              allocValue: { __typename?: 'FormattablePercentage' } & Pick<
                FormattablePercentage,
                'value' | 'formatted'
              >;
              amount: { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'value' | 'formatted'
              >;
            }
          >
        >
      >;
    }
  >;
};

export type HomeHeroQueryVariables = Exact<{
  dateFormat: Scalars['String'];
  includeHeroImage: Scalars['Boolean'];
}>;

export type HomeInboxQueryVariables = Exact<{ [key: string]: never }>;

export type InvestmentAccountIdQueryVariables = Exact<{ [key: string]: never }>;

export type InvestmentAccountIdQuery = { __typename?: 'Query' } & {
  cash?: Maybe<
    { __typename?: 'Cash' } & {
      status?: Maybe<
        { __typename?: 'CashStatus' } & Pick<CashStatus, 'investmentAccountId'>
      >;
    }
  >;
};

export type OverViewPortfolioSuggestedListQueryVariables = Exact<{
  customerId?: Maybe<Scalars['ID']>;
}>;

export type OverViewPortfolioSuggestedListQuery = { __typename?: 'Query' } & {
  globalSummary?: Maybe<
    { __typename?: 'GlobalSummary' } & {
      suggestedPortfolios: Array<
        Maybe<
          { __typename?: 'GlobalSummaryPortfolioSuggested' } & Pick<
            GlobalSummaryPortfolioSuggested,
            'brandName' | 'id' | 'name' | 'status'
          >
        >
      >;
    }
  >;
};

export type OverViewPortfolioSuggestedDetailsQueryVariables = Exact<{
  customerId?: Maybe<Scalars['ID']>;
  portfolioId: Scalars['ID'];
}>;

export type OverViewPortfolioSuggestedDetailsQuery = {
  __typename?: 'Query';
} & {
  globalSummary?: Maybe<
    { __typename?: 'GlobalSummary' } & {
      suggestedPortfolioDetails: {
        __typename?: 'GlobalSummarySuggestedPortfolioDetails';
      } & {
        allocation: Array<
          { __typename?: 'GlobalSummaryPortfolioSuggestionAllocation' } & {
            name?: Maybe<
              { __typename?: 'GlobalSummaryInvestmentClassType' } & Pick<
                GlobalSummaryInvestmentClassType,
                'value' | 'formatted'
              >
            >;
            allocValue: { __typename?: 'FormattablePercentage' } & Pick<
              FormattablePercentage,
              'value' | 'formatted'
            >;
          }
        >;
        features: Array<
          { __typename?: 'GlobalSummaryPortfolioSuggestionFeature' } & Pick<
            GlobalSummaryPortfolioSuggestionFeature,
            'title' | 'detail'
          >
        >;
        productClasses: Array<
          {
            __typename?: 'GlobalSummaryPortfolioSuggestionProductClass';
          } & Pick<GlobalSummaryPortfolioSuggestionProductClass, 'type'> & {
              allocValue: { __typename?: 'FormattablePercentage' } & Pick<
                FormattablePercentage,
                'value' | 'formatted'
              >;
              products: Array<
                Maybe<
                  {
                    __typename?: 'GlobalSummaryPortfolioSuggestionProduct';
                  } & Pick<
                    GlobalSummaryPortfolioSuggestionProduct,
                    'id' | 'name'
                  > & {
                      allocValue: {
                        __typename?: 'FormattablePercentage';
                      } & Pick<FormattablePercentage, 'value' | 'formatted'>;
                    }
                >
              >;
            }
        >;
      };
    }
  >;
};

export type PorfolioSuggestedUpdateStatusMutationVariables = Exact<{
  customerId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  status: EGlobalSummaryPortfolioStatus;
}>;

export type PorfolioSuggestedUpdateStatusMutation = {
  __typename?: 'Mutation';
} & {
  globalSummary?: Maybe<
    { __typename?: 'GlobalSummaryMutation' } & Pick<
      GlobalSummaryMutation,
      'updatePortfolioStatus'
    >
  >;
};

export type NewsQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;

export type NewsQuery = { __typename?: 'Query' } & {
  channels?: Maybe<
    { __typename?: 'Channels' } & Pick<Channels, 'customerId'> & {
        newsFeed: Array<
          Maybe<
            { __typename?: 'News' } & Pick<
              News,
              | 'id'
              | 'articleTitle'
              | 'articleBody'
              | 'articleSubtitle'
              | 'dashboardTitle'
              | 'dashboardSubtitle'
              | 'dashboardBackground'
              | 'url'
              | 'code'
            >
          >
        >;
      }
  >;
};

export type OverViewExtractQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
  dateFormat: Scalars['String'];
}>;

export type GetAccountStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllInstrumentsQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
}>;

export type GetAllInstrumentsQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      allInstruments?: Maybe<
        { __typename?: 'TradePaginatedInstrument' } & {
          page: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'index' | 'size' | 'hasMore' | 'amount'
          >;
          instruments: Array<
            { __typename?: 'TradeInstrument' } & Pick<
              TradeInstrument,
              'ticker' | 'description' | 'lotSize'
            >
          >;
        }
      >;
    }
  >;
};

export type GetInstrumentStatisticsQueryVariables = Exact<{
  tickersQuotes?: Maybe<
    Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  >;
}>;

export type GetInstrumentStatisticsQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      quotes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TradeQuote' } & Pick<TradeQuote, 'volume'> & {
                lastPrice: { __typename?: 'FormattableBigDecimal' } & Pick<
                  FormattableBigDecimal,
                  'formatted'
                >;
                openPrice: { __typename?: 'FormattableBigDecimal' } & Pick<
                  FormattableBigDecimal,
                  'formatted'
                >;
                lowPrice: { __typename?: 'FormattableBigDecimal' } & Pick<
                  FormattableBigDecimal,
                  'formatted'
                >;
                highPrice: { __typename?: 'FormattableBigDecimal' } & Pick<
                  FormattableBigDecimal,
                  'formatted'
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetInstrumentSubscriptionSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
}>;

export type GetInstrumentSubscriptionSubscription = {
  __typename?: 'Subscription';
} & {
  getTradePositionBySymbolPrices: Array<
    Maybe<
      { __typename?: 'TradePositionPrice' } & Pick<
        TradePositionPrice,
        'symbol' | 'quantity'
      > & {
          currentValue?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
          avgPrice?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
          totalEarnings?: Maybe<
            { __typename?: 'FormattablePercentage' } & Pick<
              FormattablePercentage,
              'value' | 'formatted'
            >
          >;
          price?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
        }
    >
  >;
};

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetOrderQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      order?: Maybe<
        { __typename?: 'TradeOrder' } & Pick<
          TradeOrder,
          | 'id'
          | 'clOrderId'
          | 'statusDate'
          | 'statusText'
          | 'ticker'
          | 'label'
          | 'canChangeQuoteValue'
          | 'quantity'
          | 'description'
        > & {
            direction: { __typename?: 'TradeDirection' } & Pick<
              TradeDirection,
              'formatted' | 'value'
            >;
            limitPrice?: Maybe<
              { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'formatted' | 'value'
              >
            >;
            value: { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'formatted' | 'value'
            >;
            executedPrice?: Maybe<
              { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'formatted' | 'value'
              >
            >;
            status: { __typename?: 'TradeOrderStatus' } & Pick<
              TradeOrderStatus,
              'formatted' | 'value'
            >;
            type: { __typename?: 'TradeOrderType' } & Pick<
              TradeOrderType,
              'formatted' | 'value'
            >;
            movementType?: Maybe<
              { __typename?: 'TradeMovementType' } & Pick<
                TradeMovementType,
                'value' | 'formatted'
              >
            >;
          }
      >;
    }
  >;
};

export type GetOrdersQueryVariables = Exact<{
  tickers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetPendingOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPositionInstrumentQueryVariables = Exact<{
  tickers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetPositionMainQueryVariables = Exact<{
  page: Scalars['Int'];
}>;

export type GetPositionMainQuery = { __typename?: 'Query' } & {
  dataPositions?: Maybe<
    { __typename?: 'Trade' } & {
      positions?: Maybe<
        { __typename?: 'TradePaginatedPosition' } & {
          page: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'index' | 'size' | 'amount' | 'hasMore'
          >;
          positions: Array<
            { __typename?: 'TradePosition' } & Pick<
              TradePosition,
              'symbol' | 'quantity'
            > & {
                currentValue?: Maybe<
                  { __typename?: 'FormattableBigDecimal' } & Pick<
                    FormattableBigDecimal,
                    'value' | 'formatted'
                  >
                >;
                totalEarnings?: Maybe<
                  { __typename?: 'FormattablePercentage' } & Pick<
                    FormattablePercentage,
                    'value' | 'formatted'
                  >
                >;
                dailyPercentualChange?: Maybe<
                  { __typename?: 'FormattablePercentage' } & Pick<
                    FormattablePercentage,
                    'value' | 'formatted'
                  >
                >;
                price?: Maybe<
                  { __typename?: 'FormattableBigDecimal' } & Pick<
                    FormattableBigDecimal,
                    'value' | 'formatted'
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type GetPositionSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPositionSubscriptionSubscription = {
  __typename?: 'Subscription';
} & {
  tradePositionsPrice: Array<
    Maybe<
      { __typename?: 'TradePositionPrice' } & Pick<
        TradePositionPrice,
        'symbol' | 'quantity' | 'date'
      > & {
          currentValue?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
          totalEarnings?: Maybe<
            { __typename?: 'FormattablePercentage' } & Pick<
              FormattablePercentage,
              'value' | 'formatted'
            >
          >;
          dailyPercentualChange?: Maybe<
            { __typename?: 'FormattablePercentage' } & Pick<
              FormattablePercentage,
              'value' | 'formatted'
            >
          >;
          price?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
        }
    >
  >;
};

export type GetPriceQueryQueryVariables = Exact<{
  ticker: Scalars['String'];
}>;

export type GetPriceQueryQuery = { __typename?: 'Query' } & {
  priceData?: Maybe<
    { __typename?: 'Trade' } & {
      lastSymbolPrice?: Maybe<
        { __typename?: 'TradeTickerPriceAtDateTime' } & Pick<
          TradeTickerPriceAtDateTime,
          'price' | 'bestOfferBuy' | 'bestOfferSell' | 'date'
        > & {
            message?: Maybe<
              { __typename?: 'TradeErrorMessage' } & Pick<
                TradeErrorMessage,
                'title' | 'description'
              >
            >;
          }
      >;
    }
  >;
};

export type GetPriceSubscriptionSubscriptionVariables = Exact<{
  ticker: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetPriceSubscriptionSubscription = {
  __typename?: 'Subscription';
} & {
  getTradeTickerPriceAtDateTime?: Maybe<
    { __typename?: 'TradeTickerPriceAtDateTime' } & Pick<
      TradeTickerPriceAtDateTime,
      'price' | 'bestOfferBuy' | 'bestOfferSell' | 'date'
    >
  >;
};

export type SymbolChartQueryVariables = Exact<{
  symbol: Scalars['String'];
  filter: ETickerPriceHistoryPeriod;
}>;

export type GetTickerPriceHistoryQueryVariables = Exact<{
  ticker: Scalars['String'];
  chartPeriod: ETickerPriceHistoryPeriod;
}>;

export type GetTickerPriceHistoryQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      tickerPriceHistory?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TradeTickerPriceAtDateTime' } & Pick<
              TradeTickerPriceAtDateTime,
              'symbol' | 'date' | 'price'
            >
          >
        >
      >;
    }
  >;
};

export type GetTradeAccountStatementQueryVariables = Exact<{
  index: Scalars['Int'];
  size: Scalars['Int'];
}>;

export type GetTradeAccountStatementQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      statements: { __typename?: 'TradePaginatedStatement' } & Pick<
        TradePaginatedStatement,
        'showPositionsStatement'
      > & {
          page: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'index' | 'size' | 'amount' | 'hasMore'
          >;
          list: Array<
            { __typename?: 'TradeStatement' } & Pick<TradeStatement, 'year'> & {
                months: Array<
                  { __typename?: 'TradeStatementMonth' } & Pick<
                    TradeStatementMonth,
                    'date' | 'month'
                  >
                >;
              }
          >;
        };
    }
  >;
};

export type GetTradeCompanyInformationsQueryVariables = Exact<{
  symbol: Scalars['String'];
}>;

export type GetTradeCompanyInformationsQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      companyAnalysis?: Maybe<
        { __typename?: 'TradeCompanyInformations' } & {
          balanceSheet: Array<
            Maybe<
              { __typename?: 'TradeCompanyInformationsData' } & Pick<
                TradeCompanyInformationsData,
                'context'
              > & {
                  item?: Maybe<
                    { __typename?: 'FormattableIndicatorNumber' } & Pick<
                      FormattableIndicatorNumber,
                      'value' | 'formatted'
                    >
                  >;
                }
            >
          >;
          financialIndicators: Array<
            Maybe<
              { __typename?: 'TradeCompanyInformationsData' } & Pick<
                TradeCompanyInformationsData,
                'context'
              > & {
                  item?: Maybe<
                    { __typename?: 'FormattableIndicatorNumber' } & Pick<
                      FormattableIndicatorNumber,
                      'value' | 'formatted'
                    >
                  >;
                }
            >
          >;
          profile?: Maybe<
            { __typename?: 'TradeCompanyInformationsProfile' } & Pick<
              TradeCompanyInformationsProfile,
              'sector' | 'industry' | 'employees' | 'description'
            >
          >;
        }
      >;
    }
  >;
};

export type GetTradePriceQueryQueryVariables = Exact<{
  ticker: Scalars['String'];
}>;

export type GetTradePriceQueryQuery = { __typename?: 'Query' } & {
  history?: Maybe<
    { __typename?: 'Trade' } & {
      tickerPriceHistory?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TradeTickerPriceAtDateTime' } & Pick<
              TradeTickerPriceAtDateTime,
              'symbol' | 'date' | 'price'
            >
          >
        >
      >;
    }
  >;
};

export type GetWatchlistQueryVariables = Exact<{
  page: Scalars['Int'];
}>;

export type GetWatchlistQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      watchlist: { __typename?: 'TradePaginatedWatchlist' } & {
        page: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'index' | 'size' | 'amount' | 'hasMore'
        >;
        watchlist: Array<
          Maybe<
            { __typename?: 'TradeWatchlist' } & Pick<
              TradeWatchlist,
              'symbol'
            > & {
                price?: Maybe<
                  { __typename?: 'FormattableBigDecimal' } & Pick<
                    FormattableBigDecimal,
                    'formatted'
                  >
                >;
                dailyPercentualChange?: Maybe<
                  { __typename?: 'FormattablePercentage' } & Pick<
                    FormattablePercentage,
                    'value' | 'formatted'
                  >
                >;
              }
          >
        >;
      };
    }
  >;
};

export type GetWatchlistSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetWatchlistSubscriptionSubscription = {
  __typename?: 'Subscription';
} & {
  getTradeWatchlistPrices: Array<
    Maybe<
      { __typename?: 'TradeWatchlistPrice' } & Pick<
        TradeWatchlistPrice,
        'symbol'
      > & {
          price?: Maybe<
            { __typename?: 'FormattableBigDecimal' } & Pick<
              FormattableBigDecimal,
              'value' | 'formatted'
            >
          >;
          dailyPercentualChange?: Maybe<
            { __typename?: 'FormattablePercentage' } & Pick<
              FormattablePercentage,
              'value' | 'formatted'
            >
          >;
        }
    >
  >;
};

export type TradeChartQueryVariables = Exact<{
  filter: ETradeChartFilter;
}>;

export type TradeChartQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      chart: { __typename?: 'TradeChart' } & {
        filters: Array<
          { __typename?: 'TradeChartFilter' } & Pick<
            TradeChartFilter,
            'key' | 'description'
          >
        >;
        lastTotalBalance: { __typename?: 'FormattableBigDecimal' } & Pick<
          FormattableBigDecimal,
          'value' | 'formatted'
        >;
        axisX: Array<
          { __typename?: 'TradeChartAxisX' } & Pick<
            TradeChartAxisX,
            'values' | 'description'
          >
        >;
        axisY: Array<
          { __typename?: 'TradeChartAxisY' } & Pick<
            TradeChartAxisY,
            'description'
          > & {
              values: Array<
                Maybe<
                  | ({ __typename?: 'FormattableBigDecimal' } & Pick<
                      FormattableBigDecimal,
                      'value' | 'formatted'
                    >)
                  | ({ __typename?: 'FormattablePercentage' } & Pick<
                      FormattablePercentage,
                      'value' | 'formatted'
                    >)
                >
              >;
            }
        >;
      };
    }
  >;
};

export type TradeDashStatusQueryVariables = Exact<{ [key: string]: never }>;

export type TradeDashStatusQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      dashStatus?: Maybe<
        { __typename?: 'DashStatus' } & {
          card?: Maybe<
            { __typename?: 'DashStatusCard' } & Pick<
              DashStatusCard,
              'message' | 'title' | 'button' | 'action'
            > & {
                image?: Maybe<
                  { __typename?: 'DashStatusCardImage' } & {
                    web?: Maybe<
                      { __typename?: 'DashStatusCardImagePlatform' } & Pick<
                        DashStatusCardImagePlatform,
                        'full' | 'regular' | 'small'
                      >
                    >;
                  }
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type TradingMainQueryVariables = Exact<{ [key: string]: never }>;

export type GetInstrumentQueryVariables = Exact<{
  ticker: Scalars['String'];
}>;

export type TradeOrderMutationMutationVariables = Exact<{
  qty: Scalars['Int'];
  value: Scalars['Float'];
  ticker: Scalars['String'];
  direction: ETradeDirection;
}>;

export type TradeOrderMutationMutation = { __typename?: 'Mutation' } & {
  trade?: Maybe<
    { __typename?: 'TradeMutation' } & Pick<TradeMutation, 'createOrder'>
  >;
};

export type CreateTradeOrderWithFeedbackMutationVariables = Exact<{
  qty: Scalars['Int'];
  value: Scalars['Float'];
  ticker: Scalars['String'];
  direction: ETradeDirection;
}>;

export type TradeRiskProfileMutationVariables = Exact<{
  ticker: Scalars['String'];
  risk: Scalars['String'];
}>;

export type TradeRiskProfileMutation = { __typename?: 'Mutation' } & {
  trade?: Maybe<
    { __typename?: 'TradeMutation' } & Pick<
      TradeMutation,
      'acceptTradeOutsideRiskProfile'
    >
  >;
};

export type TradeStatusQueryVariables = Exact<{ [key: string]: never }>;

export type TradeStatusQuery = { __typename?: 'Query' } & {
  trade?: Maybe<
    { __typename?: 'Trade' } & {
      status?: Maybe<
        { __typename?: 'TradeStatus' } & Pick<
          TradeStatus,
          'tradingAvailable' | 'enabledMFA'
        > & {
            homeBroker?: Maybe<
              { __typename?: 'TradeHomeBroker' } & Pick<
                TradeHomeBroker,
                'status' | 'buttonText'
              >
            >;
            marketAvailable?: Maybe<
              { __typename?: 'TradeMarketAvailable' } & Pick<
                TradeMarketAvailable,
                'isMarketAvailable' | 'description'
              > & {
                  marketStatus?: Maybe<
                    { __typename?: 'TradeMarketStatus' } & Pick<
                      TradeMarketStatus,
                      'value' | 'formatted'
                    >
                  >;
                  negotiationAvailable?: Maybe<
                    { __typename?: 'TradeNegotiationAvailable' } & {
                      buy?: Maybe<
                        {
                          __typename?: 'TradeNegotiationAvailableDetail';
                        } & Pick<
                          TradeNegotiationAvailableDetail,
                          'state' | 'action' | 'button' | 'message'
                        >
                      >;
                      sell?: Maybe<
                        {
                          __typename?: 'TradeNegotiationAvailableDetail';
                        } & Pick<
                          TradeNegotiationAvailableDetail,
                          'state' | 'action' | 'button' | 'message'
                        >
                      >;
                    }
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type TradeBrokerAccessMutationVariables = Exact<{
  [key: string]: never;
}>;

export type TradeBrokerAccessMutation = { __typename?: 'Mutation' } & {
  trade?: Maybe<
    { __typename?: 'TradeMutation' } & Pick<
      TradeMutation,
      'requestHomeBrokerAccess'
    >
  >;
};

export type TradeTransactionsQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
}>;

export type UpdateOrderQuoteValueMutationVariables = Exact<{
  orderId: Scalars['String'];
  value: Scalars['Float'];
}>;

export type UpdateOrderQuoteValueMutation = { __typename?: 'Mutation' } & {
  trade?: Maybe<
    { __typename?: 'TradeMutation' } & Pick<
      TradeMutation,
      'updateOrderQuoteValue'
    >
  >;
};

export type UpdateTradeWatchlistMutationVariables = Exact<{
  symbol: Scalars['String'];
  direction: ETradeWatchlistDirection;
}>;

export type UpdateTradeWatchlistMutation = { __typename?: 'Mutation' } & {
  trade?: Maybe<
    { __typename?: 'TradeMutation' } & Pick<
      TradeMutation,
      'updateTradeWatchlist'
    >
  >;
};

export type GetChargingFeeQueryVariables = Exact<{
  transaction: Scalars['String'];
}>;

export type GetChargingFeeQuery = { __typename?: 'Query' } & {
  transaction?: Maybe<
    { __typename?: 'TransactionDetail' } & {
      chargingFees?: Maybe<
        { __typename?: 'ChargingFeeDetail' } & Pick<
          ChargingFeeDetail,
          'transactionId' | 'description'
        > & {
            amount?: Maybe<
              { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'value' | 'formatted'
              >
            >;
            date?: Maybe<
              { __typename?: 'FormattableDate' } & Pick<
                FormattableDate,
                'value' | 'formatted'
              >
            >;
            status?: Maybe<
              { __typename?: 'TransactionStatus' } & Pick<
                TransactionStatus,
                'value' | 'formatted'
              >
            >;
            type: { __typename?: 'TransactionType' } & Pick<
              TransactionType,
              'value' | 'formatted'
            >;
          }
      >;
    }
  >;
};

export type GetDepositQueryVariables = Exact<{
  transactionId: Scalars['String'];
}>;

export type GetDepositQuery = { __typename?: 'Query' } & {
  transaction?: Maybe<
    { __typename?: 'TransactionDetail' } & {
      deposit?: Maybe<
        { __typename?: 'DepositDetail' } & Pick<
          DepositDetail,
          | 'transactionId'
          | 'isPending'
          | 'bankCode'
          | 'bankName'
          | 'bankAgency'
          | 'bankAccount'
          | 'isDepositByInvoice'
          | 'portfolioName'
          | 'barCode'
          | 'docToPDF'
          | 'canBeDeleted'
        > & {
            amount?: Maybe<
              { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'value' | 'formatted'
              >
            >;
            createdAt?: Maybe<
              { __typename?: 'FormattableDate' } & Pick<
                FormattableDate,
                'value' | 'formatted'
              >
            >;
            operatedAt?: Maybe<
              { __typename?: 'FormattableDate' } & Pick<
                FormattableDate,
                'value' | 'formatted'
              >
            >;
            status?: Maybe<
              { __typename?: 'TransactionStatus' } & Pick<
                TransactionStatus,
                'value' | 'formatted'
              >
            >;
            type?: Maybe<
              { __typename?: 'TransactionType' } & Pick<
                TransactionType,
                'value' | 'formatted'
              >
            >;
            transactionInvest?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ITransactionInvest' } & Pick<
                    ITransactionInvest,
                    'name' | 'type'
                  > & {
                      amount?: Maybe<
                        { __typename?: 'FormattableBigDecimal' } & Pick<
                          FormattableBigDecimal,
                          'value' | 'formatted'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >;
    }
  >;
};

export type GetInternalTransfersQueryVariables = Exact<{
  customerId: Scalars['ID'];
  transactionId: Scalars['String'];
}>;

export type GetInternalTransfersQuery = { __typename?: 'Query' } & {
  transaction?: Maybe<
    { __typename?: 'TransactionDetail' } & {
      transfer?: Maybe<
        { __typename?: 'TransferDetail' } & Pick<
          TransferDetail,
          'fromPortfolioName'
        > & {
            toPortfolios?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'TrasferPortfolios' } & Pick<
                    TrasferPortfolios,
                    'portfolioName'
                  > & {
                      amount?: Maybe<
                        { __typename?: 'FormattableBigDecimal' } & Pick<
                          FormattableBigDecimal,
                          'formatted'
                        >
                      >;
                    }
                >
              >
            >;
            amount?: Maybe<
              { __typename?: 'FormattableBigDecimal' } & Pick<
                FormattableBigDecimal,
                'formatted'
              >
            >;
            status?: Maybe<
              { __typename?: 'TransactionStatus' } & Pick<
                TransactionStatus,
                'value' | 'formatted'
              >
            >;
            date?: Maybe<
              { __typename?: 'FormattableDate' } & Pick<
                FormattableDate,
                'formatted'
              >
            >;
            createdDate?: Maybe<
              { __typename?: 'FormattableDate' } & Pick<
                FormattableDate,
                'formatted'
              >
            >;
          }
      >;
    }
  >;
};

export type GetAccountInfosQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountInfosQuery = { __typename?: 'Query' } & {
  main?: Maybe<
    { __typename?: 'Cash' } & {
      summary?: Maybe<
        { __typename?: 'CashSummary' } & {
          netBalance: { __typename?: 'FormattableBigDecimal' } & Pick<
            FormattableBigDecimal,
            'value' | 'formatted'
          >;
        }
      >;
      status?: Maybe<
        { __typename?: 'CashStatus' } & Pick<
          CashStatus,
          'enabled' | 'investmentAccountId'
        >
      >;
      bankAccount?: Maybe<
        { __typename?: 'CashBankAccount' } & Pick<
          CashBankAccount,
          | 'name'
          | 'document'
          | 'bankName'
          | 'bankCode'
          | 'agency'
          | 'agencyDigit'
          | 'account'
          | 'accountDigit'
        >
      >;
    }
  >;
};

export type AddProductToWalletMutationVariables = Exact<{
  instrument?: Maybe<InputProduct>;
}>;

export type AddProductToWalletMutation = { __typename?: 'Mutation' } & {
  main?: Maybe<
    { __typename?: 'ProductToWallet' } & Pick<
      ProductToWallet,
      | 'isNotAllowedToAddInstrument'
      | 'isFeasibleToAddInstrument'
      | 'isInstrumentAlreadyAdded'
    > & {
        conflict?: Maybe<
          { __typename?: 'ProductPercentage' } & Pick<
            ProductPercentage,
            'newInitialRootValue' | 'newMonthlyRootValue'
          >
        >;
        productAdded?: Maybe<
          { __typename?: 'Product' } & Pick<
            Product,
            | 'percentage'
            | 'isLocked'
            | 'minimumInitialInvestment'
            | 'minimumFinancialMovement'
            | 'type'
            | 'symbol'
            | 'apiId'
            | 'name'
            | 'investorType'
          >
        >;
      }
  >;
};

export type WealthDashStatusQueryVariables = Exact<{ [key: string]: never }>;

export type WealthDashStatusQuery = { __typename?: 'Query' } & {
  wealth?: Maybe<
    { __typename?: 'Wealth' } & {
      dashStatus?: Maybe<
        { __typename?: 'DashStatus' } & {
          card?: Maybe<
            { __typename?: 'DashStatusCard' } & Pick<
              DashStatusCard,
              'message' | 'title' | 'button' | 'action'
            > & {
                image?: Maybe<
                  { __typename?: 'DashStatusCardImage' } & {
                    web?: Maybe<
                      { __typename?: 'DashStatusCardImagePlatform' } & Pick<
                        DashStatusCardImagePlatform,
                        'full' | 'regular' | 'small'
                      >
                    >;
                  }
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type GetLastStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastStateQuery = { __typename?: 'Query' } & {
  getLastState?: Maybe<
    { __typename?: 'State' } & Pick<
      State,
      'stateLevel' | 'key' | 'hasAllocationSystem'
    > & {
        plan?: Maybe<
          { __typename?: 'Plan' } & Pick<
            Plan,
            | 'maxInitialInvestment'
            | 'monthlyInvestment'
            | 'goalAmount'
            | 'goalName'
            | 'maxLiquidity'
            | 'goalDate'
          >
        >;
        allocations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Allocations' } & Pick<
                Allocations,
                | 'isRoot'
                | 'id'
                | 'name'
                | 'type'
                | 'value'
                | 'isLocked'
                | 'percent'
                | 'apiId'
              >
            >
          >
        >;
        chart?: Maybe<
          Array<
            Maybe<{ __typename?: 'Chart' } & Pick<Chart, 'label' | 'value'>>
          >
        >;
        chartFormatted?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ChartFormatted' } & {
                label?: Maybe<
                  { __typename?: 'FormattableAllocationsType' } & Pick<
                    FormattableAllocationsType,
                    'value' | 'formatted'
                  >
                >;
                allocValue?: Maybe<
                  { __typename?: 'FormattableAllocationsValue' } & Pick<
                    FormattableAllocationsValue,
                    'value' | 'formatted'
                  >
                >;
                subType?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'SubType' } & Pick<SubType, 'name'> & {
                          allocations?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'SubTypeAllocations' } & Pick<
                                  SubTypeAllocations,
                                  'name' | 'isLocked' | 'apiId'
                                > & {
                                    type?: Maybe<
                                      {
                                        __typename?: 'FormattableAllocationsType';
                                      } & Pick<
                                        FormattableAllocationsType,
                                        'value' | 'formatted'
                                      >
                                    >;
                                    allocValue?: Maybe<
                                      {
                                        __typename?: 'FormattableAllocationsValue';
                                      } & Pick<
                                        FormattableAllocationsValue,
                                        'value' | 'formatted'
                                      >
                                    >;
                                    maxPercentage?: Maybe<
                                      {
                                        __typename?: 'FormattableAllocationsValue';
                                      } & Pick<
                                        FormattableAllocationsValue,
                                        'value' | 'formatted'
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
            >
          >
        >;
        allocationCategories?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Allocations' } & Pick<
                Allocations,
                | 'isRoot'
                | 'id'
                | 'name'
                | 'type'
                | 'value'
                | 'isLocked'
                | 'percent'
                | 'apiId'
              >
            >
          >
        >;
      }
  >;
};

export type InstrumentValidationQueryVariables = Exact<{
  apiIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type InstrumentValidationQuery = { __typename?: 'Query' } & {
  instrumentValidation?: Maybe<
    { __typename?: 'InstrumentValidationQueryResult' } & {
      data?: Maybe<
        { __typename?: 'ValidatedInstrumentData' } & {
          dependentOnRisks?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstrumentRiskDependent' } & Pick<
                  InstrumentRiskDependent,
                  'apiId' | 'name' | 'riskScore' | 'riskGrade'
                >
              >
            >
          >;
          dependentOnTerms?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstrumentTermDependent' } & Pick<
                  InstrumentTermDependent,
                  'apiId' | 'name'
                > & {
                    documents?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'InstrumentDetailContent' } & Pick<
                            InstrumentDetailContent,
                            'title' | 'description' | 'url'
                          >
                        >
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type WealthInstrumentsQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
  category?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<InstrumentClass>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<InstrumentOrderByField>;
  isAscending?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  marketIndex?: Maybe<CoreMarketIndicators>;
}>;

export type WealthInstrumentsQuery = { __typename?: 'Query' } & {
  instruments?: Maybe<
    { __typename?: 'InstrumentQueryResult' } & {
      data?: Maybe<
        { __typename?: 'InstrumentList' } & Pick<
          InstrumentList,
          | 'pageSize'
          | 'totalPages'
          | 'totalRecords'
          | 'fixedIncomeTotalRecords'
          | 'otherTotalRecords'
          | 'variableIncomeTotalRecords'
        > & {
            instruments?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'InstrumentSummary' } & Pick<
                    InstrumentSummary,
                    | 'name'
                    | 'riskScore'
                    | 'instrumentBoxApiId'
                    | 'category'
                    | 'subCategory'
                    | 'instrumentDetailApiId'
                    | 'minimumFinancialMovement'
                    | 'minimumInitialInvestment'
                    | 'lastTwelveMonthsInterestRate'
                    | 'daysToLiquidationAtWithdraw'
                    | 'profitability'
                    | 'indexBasedInterest'
                    | 'indexName'
                    | 'daysToExpiration'
                    | 'riskGrade'
                    | 'investorType'
                    | 'benchmarkFormatted'
                  > & {
                      liquidity?: Maybe<
                        { __typename?: 'IntFormatableField' } & Pick<
                          IntFormatableField,
                          'formatted' | 'value'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >;
    }
  >;
};

export type RemoveProductFromWalletMutationVariables = Exact<{
  instrument?: Maybe<InputProduct>;
}>;

export type RemoveProductFromWalletMutation = { __typename?: 'Mutation' } & {
  main?: Maybe<
    { __typename?: 'Product' } & Pick<
      Product,
      | 'percentage'
      | 'isLocked'
      | 'minimumInitialInvestment'
      | 'minimumFinancialMovement'
      | 'type'
      | 'symbol'
      | 'apiId'
      | 'name'
      | 'investorType'
    >
  >;
};

export type ResetQueryVariables = Exact<{ [key: string]: never }>;

export type WalletResumeQueryVariables = Exact<{ [key: string]: never }>;

export type WalletResumeQuery = { __typename?: 'Query' } & {
  walletResume?: Maybe<
    { __typename?: 'WalletResumeQueryResolver' } & {
      data?: Maybe<
        { __typename?: 'WalletResume' } & Pick<
          WalletResume,
          'requestRisks' | 'requestTerms'
        > & {
            allocations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Allocations' } & Pick<
                    Allocations,
                    'apiId' | 'value' | 'boxApiId' | 'type'
                  >
                >
              >
            >;
            chart?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ChartResume' } & Pick<
                    ChartResume,
                    'label' | 'value'
                  >
                >
              >
            >;
            chartFormatted?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ChartResumeFormatted' } & {
                    label?: Maybe<
                      { __typename?: 'FormattableAllocationsType' } & Pick<
                        FormattableAllocationsType,
                        'value' | 'formatted'
                      >
                    >;
                    allocValue?: Maybe<
                      { __typename?: 'FormattableAllocationsValue' } & Pick<
                        FormattableAllocationsValue,
                        'value' | 'formatted'
                      >
                    >;
                    subType?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'SubTypeResume' } & Pick<
                            SubTypeResume,
                            'name'
                          > & {
                              allocations?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'SubTypeAllocationsResume';
                                    } & Pick<
                                      SubTypeAllocationsResume,
                                      'name' | 'isLocked' | 'apiId'
                                    > & {
                                        type?: Maybe<
                                          {
                                            __typename?: 'FormattableAllocationsTypeResume';
                                          } & Pick<
                                            FormattableAllocationsTypeResume,
                                            'value' | 'formatted'
                                          >
                                        >;
                                        allocValue?: Maybe<
                                          {
                                            __typename?: 'FormattableAllocationsValueResume';
                                          } & Pick<
                                            FormattableAllocationsValueResume,
                                            'value' | 'formatted'
                                          >
                                        >;
                                      }
                                  >
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
                >
              >
            >;
            maxLiquidity?: Maybe<
              { __typename?: 'IFormattableValues' } & Pick<
                IFormattableValues,
                'formatted'
              >
            >;
            minInitialInvestment?: Maybe<
              { __typename?: 'IFormattableValues' } & Pick<
                IFormattableValues,
                'formatted' | 'value'
              >
            >;
            minFinancialMovement?: Maybe<
              { __typename?: 'IFormattableValues' } & Pick<
                IFormattableValues,
                'formatted' | 'value'
              >
            >;
            riskType?: Maybe<
              { __typename?: 'RiskGrades' } & Pick<RiskGrades, 'formatted'>
            >;
            dependentOnTerms?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'InstrumentTermDependent' } & Pick<
                    InstrumentTermDependent,
                    'apiId' | 'name'
                  > & {
                      documents?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'InstrumentDetailContent' } & Pick<
                              InstrumentDetailContent,
                              'title' | 'description' | 'url'
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
            dependentOnRisks?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'InstrumentRiskDependent' } & Pick<
                    InstrumentRiskDependent,
                    'apiId' | 'name' | 'riskScore' | 'riskGrade'
                  >
                >
              >
            >;
          }
      >;
    }
  >;
};

export type WealthTransactionsQueryVariables = Exact<{
  size: Scalars['Int'];
  index: Scalars['Int'];
}>;

export type UpdateLockMutationVariables = Exact<{
  updateLockParams?: Maybe<InputUpdateLock>;
}>;

export type UpdateLockMutation = { __typename?: 'Mutation' } & {
  main: Mutation['updateLock'];
};

export type UpdateProductPercentageMutationVariables = Exact<{
  updateProductPercentageParams?: Maybe<InputUpdateProductPercentage>;
}>;

export type UpdateProductPercentageMutation = { __typename?: 'Mutation' } & {
  main?: Maybe<
    { __typename?: 'ProductPercentage' } & Pick<
      ProductPercentage,
      'newInitialRootValue' | 'newMonthlyRootValue'
    >
  >;
};

export const GetFragmentTransactionsFragmentDoc = gql`
  fragment getFragmentTransactions on GlobalTransactions {
    page {
      index
      size
      amount
    }
    transactions {
      id
      date {
        value
        formatted(options: { format: $dateFormat })
      }
      from {
        title
        subtitle
      }
      to {
        title
        subtitle
      }
      type {
        value
        formatted
      }
      typeV2 {
        value
        formatted
      }
      amount {
        value
        formatted
      }
      status {
        value
        formatted
      }
      movement {
        value
      }
    }
  }
`;
export const GetFragmentLastTransactionsByModuleFragmentDoc = gql`
  fragment getFragmentLastTransactionsByModule on TransactionDetail {
    list: rangedPagedList(
      filter: { page: { size: 3, index: 1 }, module: $transactionsModule }
    ) {
      ...getFragmentTransactions
    }
  }
  ${GetFragmentTransactionsFragmentDoc}
`;
export const GetFragmentTransactionsFiltersFragmentDoc = gql`
  fragment getFragmentTransactionsFilters on GlobalTransactions {
    filters {
      defaultDatePeriod {
        key
        description
      }
      datePeriods {
        key
        description
      }
      defaultMovementType {
        key
        description
      }
      movementTypes {
        key
        description
      }
    }
  }
`;
export const AutoApprovedAccountDocument = gql`
  query AutoApprovedAccount($customerId: ID) {
    content: globalSummary(customerId: $customerId) {
      bankAccount {
        name
        document
        bankName
        bankCode
        agency
        agencyNumber
        agencyDigit
        account
        accountNumber
        accountDigit
        labels {
          name
          document
        }
      }
    }
  }
`;
export const GetCustomerFullNameDocument = gql`
  query getCustomerFullName($customerId: ID) {
    contactInformation(customerId: $customerId) {
      fullName
    }
  }
`;

export const HomeDashStatusDocument = gql`
  query HomeDashStatus {
    globalSummary {
      dashStatus {
        card {
          message
          title
          button
          action
          image {
            web {
              full
              regular
              small
            }
          }
        }
      }
    }
  }
`;
export const HomeDistributionDocument = gql`
  query HomeDistribution {
    globalSummary {
      sections {
        type {
          value
          formatted
        }
        allocValue {
          value(options: { multiplied: true })
          formatted
        }
        amount {
          value
          formatted
        }
      }
    }
  }
`;
export const InvestmentAccountIdDocument = gql`
  query InvestmentAccountId {
    cash {
      status {
        investmentAccountId
      }
    }
  }
`;
export const OverViewPortfolioSuggestedListDocument = gql`
  query OverViewPortfolioSuggestedList($customerId: ID) {
    globalSummary(customerId: $customerId) {
      suggestedPortfolios {
        brandName
        id
        name
        status
      }
    }
  }
`;
export const OverViewPortfolioSuggestedDetailsDocument = gql`
  query OverViewPortfolioSuggestedDetails($customerId: ID, $portfolioId: ID!) {
    globalSummary(customerId: $customerId) {
      suggestedPortfolioDetails(input: { portfolioId: $portfolioId }) {
        allocation {
          name {
            value
            formatted
          }
          allocValue {
            value
            formatted
          }
        }
        features {
          title
          detail
        }
        productClasses {
          type
          allocValue {
            value
            formatted
          }
          products {
            id
            name
            allocValue {
              value
              formatted
            }
          }
        }
      }
    }
  }
`;
export const PorfolioSuggestedUpdateStatusDocument = gql`
  mutation PorfolioSuggestedUpdateStatus(
    $customerId: ID!
    $portfolioId: ID!
    $status: EGlobalSummaryPortfolioStatus!
  ) {
    globalSummary(customerId: $customerId) {
      updatePortfolioStatus(
        input: { portfolioId: $portfolioId, status: $status }
      )
    }
  }
`;
export const NewsDocument = gql`
  query News($customerId: String!) {
    channels {
      customerId
      newsFeed(customerId: $customerId) {
        id
        articleTitle
        articleBody
        articleSubtitle
        dashboardTitle
        dashboardSubtitle
        dashboardBackground
        url
        code
      }
    }
  }
`;
export const GetAllInstrumentsDocument = gql`
  query GetAllInstruments($size: Int!, $index: Int!, $search: String) {
    trade {
      allInstruments(
        filter: { page: { size: $size, index: $index }, search: $search }
      ) {
        page {
          index
          size
          hasMore
          amount
        }
        instruments {
          ticker
          description
          lotSize
        }
      }
    }
  }
`;
export const GetInstrumentStatisticsDocument = gql`
  query GetInstrumentStatistics($tickersQuotes: [String]) {
    trade {
      quotes(tickersQuotes: $tickersQuotes) {
        lastPrice {
          formatted
        }
        openPrice {
          formatted
        }
        lowPrice {
          formatted
        }
        highPrice {
          formatted
        }
        volume
      }
    }
  }
`;
export const GetOrderDocument = gql`
  query GetOrder($orderId: ID!) {
    trade {
      order(orderId: $orderId) {
        id
        clOrderId
        direction {
          formatted
          value
        }
        limitPrice {
          formatted
          value
        }
        value {
          formatted
          value
        }
        executedPrice {
          formatted
          value
        }
        direction {
          formatted
          value
        }
        statusDate(format: "DD/MM/YYYY [às] HH:mm")
        statusText
        ticker
        status {
          formatted
          value
        }
        type {
          formatted
          value
        }
        movementType {
          value
          formatted
        }
        label
        canChangeQuoteValue
        quantity
        description
        statusText
      }
    }
  }
`;
export const GetPositionMainDocument = gql`
  query getPositionMain($page: Int!) {
    dataPositions: trade {
      positions(filter: { page: { size: 10, index: $page } }) {
        page {
          index
          size
          amount
          hasMore
        }
        positions {
          symbol
          currentValue {
            value
            formatted
          }
          totalEarnings {
            value
            formatted(options: { addSign: true })
          }
          dailyPercentualChange {
            value
            formatted(options: { addSign: true })
          }
          price {
            value
            formatted
          }
          quantity
        }
      }
    }
  }
`;
export const GetPositionSubscriptionDocument = gql`
  subscription getPositionSubscription {
    tradePositionsPrice: getTradePositionsPrices {
      symbol
      currentValue {
        value
        formatted
      }
      totalEarnings {
        value
        formatted(options: { addSign: true })
      }
      dailyPercentualChange {
        value
        formatted(options: { addSign: true })
      }
      price {
        value
        formatted
      }
      quantity
      date(format: "YYYY-MM-DDTHH:mm:ss.sss", timezone: "UTC")
    }
  }
`;
export const GetPriceQueryDocument = gql`
  query getPriceQuery($ticker: String!) {
    priceData: trade {
      lastSymbolPrice(symbol: $ticker) {
        price
        bestOfferBuy
        bestOfferSell
        date(format: "YYYY-MM-DDTHH:mm:ss.sssZ")
        message {
          title
          description
        }
      }
    }
  }
`;
export const GetTickerPriceHistoryDocument = gql`
  query GetTickerPriceHistory(
    $ticker: String!
    $chartPeriod: ETickerPriceHistoryPeriod!
  ) {
    trade {
      tickerPriceHistory(ticker: $ticker, chartPeriod: $chartPeriod) {
        symbol
        date(format: "YYYY-MM-DDTHH:mm:ss.sssZ")
        price
      }
    }
  }
`;
export const GetTradeAccountStatementDocument = gql`
  query getTradeAccountStatement($index: Int!, $size: Int!) {
    trade {
      statements(page: { index: $index, size: $size }) {
        page {
          index
          size
          amount
          hasMore
        }
        list {
          year
          months {
            date
            month
          }
        }
        showPositionsStatement
      }
    }
  }
`;
export const GetTradeCompanyInformationsDocument = gql`
  query GetTradeCompanyInformations($symbol: String!) {
    trade {
      companyAnalysis(symbol: $symbol) {
        balanceSheet {
          context
          item {
            value
            formatted
          }
        }
        financialIndicators {
          context
          item {
            value
            formatted
          }
        }
        profile {
          sector
          industry
          employees
          description
        }
      }
    }
  }
`;
export const GetTradePriceQueryDocument = gql`
  query getTradePriceQuery($ticker: String!) {
    history: trade {
      tickerPriceHistory(ticker: $ticker, chartPeriod: LAST_YEAR) {
        symbol
        date(format: "YYYY-MM-DDTHH:mm:ss.sssZ")
        price
      }
    }
  }
`;
export const GetWatchlistDocument = gql`
  query GetWatchlist($page: Int!) {
    trade {
      watchlist(
        filter: { includeInstrument: false, page: { size: 5, index: $page } }
      ) {
        page {
          index
          size
          amount
          hasMore
        }
        watchlist {
          symbol
          price {
            formatted
          }
          dailyPercentualChange {
            value
            formatted
          }
        }
      }
    }
  }
`;
export const GetWatchlistSubscriptionDocument = gql`
  subscription GetWatchlistSubscription {
    getTradeWatchlistPrices {
      symbol
      price {
        value
        formatted
      }
      dailyPercentualChange {
        value
        formatted(options: { addSign: true })
      }
    }
  }
`;
export const TradeChartDocument = gql`
  query TradeChart($filter: ETradeChartFilter!) {
    trade {
      chart(options: { filter: $filter }) {
        filters {
          key
          description
        }
        lastTotalBalance {
          value
          formatted
        }
        axisX {
          values(format: "YYYY-MM-DDTHH:mm:ss.sssZ")
          description
        }
        axisY {
          values {
            ... on FormattablePercentage {
              value(options: { multiplied: true })
              formatted
            }
            ... on FormattableBigDecimal {
              value
              formatted
            }
          }
          description
        }
      }
    }
  }
`;
export const TradeDashStatusDocument = gql`
  query TradeDashStatus {
    trade {
      dashStatus {
        card {
          message
          title
          button
          action
          image {
            web {
              full
              regular
              small
            }
          }
        }
      }
    }
  }
`;
export const TradeOrderMutationDocument = gql`
  mutation TradeOrderMutation(
    $qty: Int!
    $value: Float!
    $ticker: String!
    $direction: ETradeDirection!
  ) {
    trade {
      createOrder(
        order: {
          direction: $direction
          quantity: $qty
          value: $value
          ticker: $ticker
        }
      )
    }
  }
`;
export const CreateTradeOrderWithFeedbackDocument = gql`
  mutation CreateTradeOrderWithFeedback(
    $qty: Int!
    $value: Float!
    $ticker: String!
    $direction: ETradeDirection!
  ) {
    trade {
      createTradeOrder(
        order: {
          direction: $direction
          quantity: $qty
          value: $value
          ticker: $ticker
        }
      ) {
        status {
          value
        }
        statusMessage {
          title
          label
          description
        }
      }
    }
  }
`;
export const TradeStatusDocument = gql`
  query TradeStatus {
    trade {
      status {
        homeBroker {
          status
          buttonText
        }
        tradingAvailable
        enabledMFA
        marketAvailable {
          isMarketAvailable
          marketStatus {
            value
            formatted
          }
          description
          negotiationAvailable {
            buy {
              state
              action
              button
              message
            }
            sell {
              state
              action
              button
              message
            }
          }
        }
      }
    }
  }
`;
export const UpdateOrderQuoteValueDocument = gql`
  mutation updateOrderQuoteValue($orderId: String!, $value: Float!) {
    trade {
      updateOrderQuoteValue(order: { id: $orderId, quoteValue: $value })
    }
  }
`;
export const UpdateTradeWatchlistDocument = gql`
  mutation updateTradeWatchlist(
    $symbol: String!
    $direction: ETradeWatchlistDirection!
  ) {
    trade {
      updateTradeWatchlist(filter: { symbol: $symbol, direction: $direction })
    }
  }
`;
export const GetChargingFeeDocument = gql`
  query getChargingFee($transaction: String!) {
    transaction {
      chargingFees(transactionId: $transaction) {
        transactionId
        description
        amount {
          value
          formatted
        }
        date {
          value
          formatted(options: { format: "DD/MM/YYYY" })
        }
        status {
          value
          formatted
        }
        type {
          value
          formatted
        }
      }
    }
  }
`;
export const GetDepositDocument = gql`
  query GetDeposit($transactionId: String!) {
    transaction {
      deposit(transactionId: $transactionId) {
        transactionId
        amount {
          value
          formatted
        }
        createdAt {
          value
          formatted(
            options: {
              format: "YYYY/MM/DDTHH:mm:ss"
              timezone: "America/Sao_Paulo"
            }
          )
        }
        operatedAt {
          value
          formatted(
            options: {
              format: "YYYY/MM/DDTHH:mm:ss"
              timezone: "America/Sao_Paulo"
            }
          )
        }
        isPending
        bankCode
        bankName
        bankAgency
        bankAccount
        isDepositByInvoice
        portfolioName
        barCode
        docToPDF
        status {
          value
          formatted
        }
        type {
          value
          formatted
        }
        canBeDeleted
        transactionInvest {
          name
          type
          amount {
            value
            formatted
          }
        }
      }
    }
  }
`;
export const GetInternalTransfersDocument = gql`
  query getInternalTransfers($customerId: ID!, $transactionId: String!) {
    transaction(customerId: $customerId) {
      transfer(transactionId: $transactionId) {
        fromPortfolioName
        toPortfolios {
          portfolioName
          amount {
            formatted
          }
        }
        amount {
          formatted
        }
        status {
          value
          formatted
        }
        date {
          formatted(options: { format: "DD/MM/YYYY [às] HH:mm" })
        }
        createdDate {
          formatted(options: { format: "DD/MM/YYYY [às] HH:mm" })
        }
      }
    }
  }
`;
export const GetAccountInfosDocument = gql`
  query getAccountInfos {
    main: cash {
      summary {
        netBalance {
          value
          formatted
        }
      }
      status {
        enabled
        investmentAccountId
      }
      bankAccount {
        name
        document
        bankName
        bankCode
        agency
        agencyDigit
        account
        accountDigit
      }
    }
  }
`;
export const AddProductToWalletDocument = gql`
  mutation AddProductToWallet($instrument: InputProduct) {
    main: addProductToWallet(instrument: $instrument) {
      conflict {
        newInitialRootValue
        newMonthlyRootValue
      }
      productAdded {
        percentage
        isLocked
        minimumInitialInvestment
        minimumFinancialMovement
        type
        symbol
        apiId
        name
        investorType
      }
      isNotAllowedToAddInstrument
      isFeasibleToAddInstrument
      isInstrumentAlreadyAdded
    }
  }
`;
export const WealthDashStatusDocument = gql`
  query WealthDashStatus {
    wealth {
      dashStatus {
        card {
          message
          title
          button
          action
          image {
            web {
              full
              regular
              small
            }
          }
        }
      }
    }
  }
`;
export const GetLastStateDocument = gql`
  query GetLastState {
    getLastState {
      stateLevel
      key
      hasAllocationSystem
      plan {
        maxInitialInvestment
        monthlyInvestment
        goalAmount
        goalName
        maxLiquidity
        goalDate
      }
      allocations {
        isRoot
        id
        name
        type
        value
        isLocked
        percent
        apiId
      }
      chart {
        label
        value
      }
      chartFormatted {
        label {
          value
          formatted
        }
        allocValue {
          value
          formatted
        }
        subType {
          name
          allocations {
            name
            type {
              value
              formatted
            }
            allocValue {
              value
              formatted
            }
            isLocked
            apiId
            maxPercentage {
              value
              formatted
            }
          }
        }
      }
      allocationCategories {
        isRoot
        id
        name
        type
        value
        isLocked
        percent
        apiId
      }
    }
  }
`;
export const InstrumentValidationDocument = gql`
  query InstrumentValidation($apiIds: [String!]) {
    instrumentValidation(apiIds: $apiIds) {
      data {
        dependentOnRisks {
          apiId
          name
          riskScore
          riskGrade
        }
        dependentOnTerms {
          apiId
          name
          documents {
            title
            description
            url
          }
        }
      }
    }
  }
`;
export const WealthInstrumentsDocument = gql`
  query WealthInstruments(
    $size: Int!
    $index: Int!
    $category: Int
    $categoryName: InstrumentClass
    $search: String
    $orderBy: InstrumentOrderByField
    $isAscending: Boolean
    $brandId: String
    $startDate: String
    $endDate: String
    $marketIndex: CoreMarketIndicators
  ) {
    instruments(
      pageSize: $size
      pageIndex: $index
      category: $category
      categoryName: $categoryName
      search: $search
      orderBy: $orderBy
      isAscending: $isAscending
      brandApiId: $brandId
      startDate: $startDate
      endDate: $endDate
      marketIndex: $marketIndex
    ) {
      data {
        pageSize
        totalPages
        totalRecords
        fixedIncomeTotalRecords
        otherTotalRecords
        variableIncomeTotalRecords
        instruments {
          name
          riskScore
          instrumentBoxApiId
          category
          subCategory
          instrumentDetailApiId
          minimumFinancialMovement
          minimumInitialInvestment
          lastTwelveMonthsInterestRate
          daysToLiquidationAtWithdraw
          profitability
          liquidity {
            formatted
            value
          }
          indexBasedInterest
          indexName
          daysToExpiration
          riskGrade
          investorType
          benchmarkFormatted
        }
      }
    }
  }
`;
export const RemoveProductFromWalletDocument = gql`
  mutation RemoveProductFromWallet($instrument: InputProduct) {
    main: removeProductFromWallet(instrument: $instrument) {
      percentage
      isLocked
      minimumInitialInvestment
      minimumFinancialMovement
      type
      symbol
      apiId
      name
      investorType
    }
  }
`;
export const ResetDocument = gql`
  query Reset {
    reset
  }
`;
export const WalletResumeDocument = gql`
  query walletResume {
    walletResume {
      data {
        allocations {
          apiId
          value
          boxApiId
          type
        }
        chart {
          label
          value
        }
        chartFormatted {
          label {
            value
            formatted
          }
          allocValue {
            value
            formatted
          }
          subType {
            name
            allocations {
              name
              type {
                value
                formatted
              }
              allocValue {
                value
                formatted
              }
              isLocked
              apiId
            }
          }
        }
        maxLiquidity {
          formatted
        }
        minInitialInvestment {
          formatted
          value
        }
        minFinancialMovement {
          formatted
          value
        }
        riskType {
          formatted
        }
        dependentOnTerms {
          apiId
          name
          documents {
            title
            description
            url
          }
        }
        dependentOnRisks {
          apiId
          name
          riskScore
          riskGrade
        }
        requestRisks
        requestTerms
      }
    }
  }
`;
export const UpdateLockDocument = gql`
  mutation UpdateLock($updateLockParams: InputUpdateLock) {
    main: updateLock(updateLockParams: $updateLockParams)
  }
`;
export const UpdateProductPercentageDocument = gql`
  mutation UpdateProductPercentage(
    $updateProductPercentageParams: InputUpdateProductPercentage
  ) {
    main: updateProductPercentage(
      updateProductPercentageParams: $updateProductPercentageParams
    ) {
      newInitialRootValue
      newMonthlyRootValue
    }
  }
`;
