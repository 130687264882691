
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ButtonPrimary, Heading } from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ActionTypes,
  DashStatusMixin
} from '@/modules/common/mixins/dash-status';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { ProgressiveImage } from '@/modules/common/components/ProgressiveImage';
import { ICustomer } from '@/types/models';
import { IDashStatusWithImage } from '@/modules/common/services/http/dash-status';
import { Categories } from '@/types/models/Analytics';

@Component({
  components: {
    ProgressiveImage,
    ButtonPrimary,
    Heading
  }
})
export default class AccountStateCard extends mixins(DashStatusMixin) {
  readonly NebraskaColors = NebraskaColors;
  readonly customer?: ICustomer = CustomerModule.getCustomer;
  isOpenFirstAccessUnifiedAccountModal = false;

  @Prop({ type: String, required: true })
  readonly category!: Categories;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Object, required: true })
  readonly dashStatus!: IDashStatusWithImage;

  @Prop({ type: String, default: '' })
  readonly investmentAccountId!: string;

  @Prop({ type: Boolean, default: false })
  readonly isCustomerBlocked!: boolean;

  @Emit('dash-status-click')
  emitDashStatusActionClickEvent(dashStatusAction?: string | null) {
    return dashStatusAction;
  }

  @Emit('open-neologica-modal')
  openNeologicaModal() {}

  onClick(action?: string) {
    if (action === ActionTypes.EnableBroker) {
      this.openNeologicaModal();
    } else {
      this.emitDashStatusActionClickEvent(this.dashStatus?.action);
      this.goToAction({
        action: action || this.dashStatus?.action || '',
        category: this.category,
        investmentAccountId: this.investmentAccountId,
        email: this.customer?.email || '',
        isCustomerBlocked: this.isCustomerBlocked
      });
    }
  }

  get computedWebImage() {
    return this.dashStatus?.image?.web?.small;
  }
}
