import {
  Options,
  YAxisOptions,
  TooltipOptions,
  SeriesOptionsType,
  PlotColumnOptions,
  XAxisLabelsOptions,
  TooltipFormatterContextObject
} from 'highcharts';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { moneyFormat } from '@/modules/common/helpers/currency';

const textPrimaryColor = NebraskaColors.textPrimary;
const textSecondaryColor = NebraskaColors.textSecondary;

function getPointersTooltip(point: TooltipFormatterContextObject) {
  return `<tr>
            <td style="color:${
              point.color
            }; padding: 0; padding-bottom: 4px; font-size: 18px;">\u25CF</td>
            <td style="padding: 0; padding-left: 8px; padding-right: 12px; padding-bottom: 4px; font-weight: 400; font-size: 12px; color: ${textPrimaryColor};">
              ${[point.series.name]}
            </td>
            <td style="padding: 0; font-weight: 400; padding-bottom: 4px; font-size: 12px; color: ${textSecondaryColor}; text-align: right;">
              ${[moneyFormat(point.y)]}
            </td>
          </tr>`;
}

function getTotalTooltip(
  pointers: string[],
  context: TooltipFormatterContextObject
) {
  const totalValue = context.points!.reduce((acc, curr) => {
    return acc + curr.y;
  }, 0);

  return `<div>
            <p style="font-size: 14px; font-weight: 700; margin-bottom: 12px; color: ${textPrimaryColor};">
              ${context.x}
            </p>
            <table>
              <tbody>
                ${pointers.join('')}
                <tr>
                  <td style="padding: 0; color: ${textPrimaryColor};">Total</td>
                  <td style="padding: 0; padding-right: 12px;"></td>
                  <td style="padding: 0; font-weight: 400; font-size: 12px; color: ${textPrimaryColor}; text-align: right;">
                    ${moneyFormat(totalValue)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>`;
}

function configureTooltipFormatter(context: TooltipFormatterContextObject) {
  const pointers = context.points!.map(point => getPointersTooltip(point));
  return getTotalTooltip(pointers, context);
}

function getLabelsConfiguration(): XAxisLabelsOptions {
  return {
    style: {
      color: NebraskaColors.textSecondary,
      fontSize: '14px'
    },
    autoRotationLimit: 0,
    y: 30
  };
}

function getColumnConfiguration(): PlotColumnOptions {
  return {
    stacking: 'normal',
    pointWidth: 30,
    pointPadding: 0,
    groupPadding: 0,
    borderWidth: 0
  };
}

function getTooltipConfiguration(): TooltipOptions {
  return {
    shared: true,
    shadow: false,
    useHTML: true,
    borderWidth: 1,
    borderRadius: 8,
    padding: 16,
    borderColor: NebraskaColors.dividerPrimary,
    backgroundColor: NebraskaColors.backgroundSecondary,
    formatter: function () {
      const formatter = configureTooltipFormatter(this);
      return formatter;
    }
  };
}

function getyAxisConfiguration(): YAxisOptions {
  return {
    title: {
      text: ''
    },
    labels: {
      style: {
        color: NebraskaColors.textSecondary,
        fontSize: '12px'
      },
      align: 'right',
      reserveSpace: true,
      x: 20,
      y: 2
    },
    gridLineColor: NebraskaColors.dividerPrimary,
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    opposite: true,
    maxPadding: 0.1
  };
}

export function configureBarChartOptions(series: SeriesOptionsType[]): Options {
  return {
    chart: {
      type: 'column',
      spacingTop: 15,
      backgroundColor: NebraskaColors.backgroundSecondary
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [
        'Jan/22',
        'Feb/22',
        'Mar/22',
        'Abr/22',
        'Mai/22',
        'Jun/22',
        'Jul/22',
        'Ago/22',
        'Set/22',
        'Out/22',
        'Nov/22',
        'Dez/22'
      ],
      startOnTick: true,
      endOnTick: true,
      labels: getLabelsConfiguration()
    },
    yAxis: getyAxisConfiguration(),
    tooltip: getTooltipConfiguration(),
    plotOptions: {
      column: getColumnConfiguration()
    },
    series
  };
}
