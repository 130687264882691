import { render, staticRenderFns } from "./PasswordResetView.vue?vue&type=template&id=a186a542&scoped=true&"
import script from "./PasswordResetView.vue?vue&type=script&lang=ts&"
export * from "./PasswordResetView.vue?vue&type=script&lang=ts&"
import style0 from "./PasswordResetView.vue?vue&type=style&index=0&id=a186a542&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a186a542",
  null
  
)

export default component.exports