
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Heading,
  InputText,
  ButtonPrimary,
  InputPassword,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { getString } from '@/modules/common/helpers/resources';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SignInStep, ISignInFormData } from './types';
import { getValidators, EmailErrors, PasswordErrors } from '../../validate';
import {
  IRecaptcha,
  Recaptcha,
  RecaptchaActions
} from '@/modules/authentication/components/Recaptcha';
const handledErrors = [
  {
    type: 'error_login_wrong_login_or_password',
    message: PasswordErrors.Wrong
  },
  { type: 'not registered', message: EmailErrors.NotFound }
];
@Component({
  components: {
    Heading,
    Recaptcha,
    InputText,
    ButtonPrimary,
    InputPassword,
    BaseText
  }
})
export default class SignInForm extends Vue {
  @Prop({ type: [Object, String, Error], required: false, default: () => '' })
  readonly error!: any;

  @Prop({ type: Boolean, required: false, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: String, required: false, default: 'email' })
  readonly step!: SignInStep;

  readonly NebraskaColors = NebraskaColors;
  private serverError = '';
  private showError = false;
  private validators = getValidators();
  private statusType = '';
  formData: ISignInFormData = {
    email: '',
    password: '',
    token: ''
  };

  @Watch('step')
  private hideErrors() {
    this.showError = false;
    this.statusType = '';
  }

  @Watch('error')
  private handleError() {
    this.resetRecaptcha();
    this.showError = true;
    if (this.error && typeof this.error.message === 'string') {
      const handledError = handledErrors.find(error =>
        (this.error.message as string).includes(error.type)
      );
      this.serverError = handledError
        ? handledError.message
        : getString('error_default');
      if (this.error.code === 'account_not_found') {
        this.serverError = this.error.message;
      }
    } else if (this.error) {
      this.serverError = getString('error_default');
    } else {
      this.serverError = '';
    }
  }

  @Emit('track-signin-blur-event')
  trackAmplitudeInputEvent(event: Event) {
    return event;
  }

  async inputBlur(event: Event) {
    this.trackAmplitudeInputEvent(event);
    await this.validate();
    if (this.currentValidator.isValid()) return (this.showError = false);
    this.showError = true;
  }

  inputChanged() {
    this.serverError = '';
    this.showError = false;
    this.statusType = '';
  }

  private async validate() {
    try {
      await this.currentValidator.validate(this.formData, false);
    } catch {
      /* esse bloco precisa ficar vazio, porque o `YupValidation`
        da um throw por padrão quando erro de validação é encontrado */
    }
  }

  async onSubmit() {
    this.statusType = 'success';
    this.showError = false;
    this.trimEmail();
    await this.validate();
    if (this.currentValidator.isValid() && !this.showRecaptcha()) {
      this.$emit('submit', this.formData);
    }
  }

  private trimEmail() {
    this.formData.email = this.formData.email.trim();
  }

  getError(field: string) {
    if (!this.showError) return '';
    this.statusType = 'error';
    return this.currentValidator.getError(field) || this.serverError;
  }

  private showRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;
    if (this.step === 'password' && recaptcha) {
      recaptcha.onChallenge(RecaptchaActions.SIGN_IN);
      return true;
    } else {
      return false;
    }
  }

  private resetRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;
    if (recaptcha) recaptcha.onReset();
  }

  onRecaptchaToken(token: string) {
    this.formData.token = token;
    this.$emit('submit', this.formData);
  }

  private get currentValidator() {
    return this.validators[this.step];
  }

  get submitDisabled() {
    return (
      this.showError && (!this.currentValidator.isValid() || !!this.serverError)
    );
  }
}
