import { object, string } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

export enum EmailErrors {
  Required = 'Informe o e-mail',
  Invalid = 'Ops! E-mail inválido',
  NotFound = 'E-mail não cadastrado'
}

export enum PasswordErrors {
  Required = 'Informe a senha',
  Wrong = 'E-mail ou senha inválidos.'
}

const emailStepSchema = object().shape({
  email: string().required(EmailErrors.Required).email(EmailErrors.Invalid)
});

const passwordStepSchema = object().shape({
  password: string().required(PasswordErrors.Required)
});

export function getEmailStepValidator() {
  return new YupValidation(emailStepSchema);
}

export function getPasswordStepValidator() {
  return new YupValidation(passwordStepSchema);
}

export function getValidators() {
  return {
    email: getEmailStepValidator(),
    password: getPasswordStepValidator()
  };
}
