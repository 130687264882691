
import { Vue, Prop, Emit, Component } from 'vue-property-decorator';
import { NavBar } from '@/modules/common/components/__deprecated__/NavBar';
import { SideSheet } from '@/modules/common/components/SideSheet';

@Component({
  components: { SideSheet, NavBar }
})
export default class Curtain extends Vue {
  @Prop({ type: String })
  public navbarTitle?: string;

  @Prop({ type: String })
  public centerTitle?: string;

  @Prop({ type: Number, default: 1000 })
  public openDelay?: number;

  @Prop({ type: String })
  private background?: string;

  @Prop({ type: String })
  private backgroundColor?: string;

  @Prop({ type: String })
  private textColor?: string;

  private isCurtainOpen = true;

  private async mounted() {
    if (this.openDelay) {
      await setTimeout(() => {
        this.isCurtainOpen = false;
      }, this.openDelay);

      await setTimeout(() => {
        this.opened();
      }, this.openDelay + 500);
    }
  }

  @Emit()
  private opened() {}

  private get computedBackground() {
    if (this.background) {
      return {
        backgroundImage: `url(${this.background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    } else if (this.backgroundColor) {
      return {
        backgroundColor: this.backgroundColor
      };
    }

    return null;
  }
}
