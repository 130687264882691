
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ListDisplay,
  ContentDisplay,
  BaseText,
  ProgressStep
} from '@warrenbrasil/nebraska-web';
import { IOrderReceipt } from '@/modules/wealth/services/wealth-orders/types';
import { IStep } from '../../types';

@Component({
  components: {
    ContentDisplay,
    ListDisplay,
    BaseText,
    ProgressStep
  }
})
export default class WithdrawSteps extends Vue {
  @Prop({ type: Object, required: true }) orderData!: IOrderReceipt;
  @Prop({ type: Array, default: () => [] }) progress!: IStep[];

  get isCanceled() {
    return this.orderData?.status?.label === 'Cancelado';
  }

  get amountLabel() {
    const values = ['Cotizado', 'Concluído'];
    return values.includes(this.orderData?.status?.label)
      ? 'Bruto'
      : 'Solicitado';
  }

  get netValueLabel() {
    return this.orderData?.status?.label === 'Processando'
      ? 'Valor líquido aproximado'
      : 'Valor líquido';
  }
}
