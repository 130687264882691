
import { Component, Vue } from 'vue-property-decorator';

import { Grid } from '@warrenbrasil/nebraska-web';

import { HomeSecondaryLinks } from '@/modules/home/components/HomeSecondaryLinks';
import {
  Module,
  ModuleType
} from '../HomeSecondaryLinks/HomeSecondaryLinks.vue';
import { trackIncomeHistoryLink } from '../HomeSecondaryLinks/track-events';
import { namespace } from 'vuex-class';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    HomeSecondaryLinks,
    Grid
  }
})
export default class Homewidgets extends Vue {
  @customerModule.State('isImpersonated')
  readonly isImpersonated!: boolean;

  @customerModule.Getter('isCustomerPJ')
  readonly isCustomerPJ!: boolean;

  private get leftModules(): Module[] {
    return [
      {
        type: ModuleType.INTERNAL,
        icon: 'BB0050',
        name: 'Performance',
        path: 'investments/performance',
        id: 'history',
        trackFunction: trackIncomeHistoryLink
      },
      {
        type: ModuleType.INTERNAL,
        icon: 'BB0020',
        name: 'Alocação',
        path: 'products/allocation',
        id: 'alocation'
      },
      {
        type: ModuleType.INTERNAL,
        icon: 'ED0020',
        name: 'Relatório de Investimentos',
        path: 'investments/statement-download/investment-report',
        id: 'statement'
      }
    ];
  }

  private get rightModules(): Module[] {
    return [
      {
        type: ModuleType.LANDING_PAGE,
        icon: 'BA0040',
        name: 'Câmbio',
        path: 'https://lp.warren.com.br/warren-cambio',
        id: 'Cambio'
      },
      {
        type: ModuleType.LANDING_PAGE,
        icon: 'BC0010',
        name: 'Consórcio',
        path: 'https://turn2c.com/warren/',
        id: 'Consorcio'
      },
      {
        type: ModuleType.LANDING_PAGE,
        icon: 'BA0011',
        name: 'Crédito Imobiliário e Veicular',
        path: 'https://bext.vc/warren',
        id: 'credito_imobiliario'
      },
      {
        type: ModuleType.LANDING_PAGE,
        icon: 'BA0050',
        name: 'Previdência',
        path: 'https://lp.warren.com.br/previdencia',
        id: 'Previdencia'
      },
      {
        type: ModuleType.LANDING_PAGE,
        icon: 'BC0030',
        name: 'Seguros',
        path: 'https://lp.warren.com.br/warren-seguros',
        id: 'Seguros'
      }
    ];
  }
}
