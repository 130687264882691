
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ContentDisplay,
  HeadingDisplay,
  ListDisplay
} from '@warrenbrasil/nebraska-web';
import { IGetSuggestionDetailsResponse } from '@/modules/wealth/services/investment-suggestions';
import * as captions from './constants';

@Component({
  components: {
    ContentDisplay,
    HeadingDisplay,
    ListDisplay
  }
})
export default class SuggestionDetails extends Vue {
  @Prop({ type: Object, required: true })
  readonly suggestionDetails!: IGetSuggestionDetailsResponse;

  @Prop({ type: Boolean, required: true })
  readonly isInvestment!: boolean;

  get summaryCaption() {
    return this.isInvestment
      ? captions.investmentSummaryCaption
      : captions.redemptionSummaryCaption;
  }

  get portfolioCaption() {
    if (this.suggestionDetails.portfolio.managementType === 'Manual') return '';
    return this.isInvestment
      ? captions.investmentPortfolioCaption
      : captions.redemptionPortfolioCaption;
  }

  get showExpireDate() {
    const { expiredAt } = this.suggestionDetails.transaction;
    return expiredAt && expiredAt.formatted && expiredAt.formatted !== '';
  }

  get amountLabel() {
    return this.isInvestment
      ? 'Valor total do investimento'
      : 'Valor total bruto';
  }

  get productAmountLabel() {
    return this.isInvestment
      ? 'Valor do investimento'
      : 'Valor bruto do resgate   ';
  }

  get showProducts() {
    return (
      this.suggestionDetails.transaction.products &&
      this.suggestionDetails.portfolio.managementType === 'Manual'
    );
  }
}
