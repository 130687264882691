export const validateFile = (file: File, maxSize: number) => {
  let errorMessage = '';
  if (!file) {
    errorMessage = 'Não foi possível carregar a imagem';
    return errorMessage;
  }

  if (!/pdf|jpeg|jpg|png/i.test(file.type)) {
    errorMessage =
      'Arquivo incompatível. Tente novamente com um arquivo no formato jpg, png ou pdf.';
  }

  if (file.size > maxSize) {
    errorMessage =
      'O arquivo é muito grande. Tente novamente com um arquivo com até 10MB.';
  }

  return errorMessage;
};
