
import { Component, Prop, Vue } from 'vue-property-decorator';

import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

const scriptAttributes = {
  charset: 'UTF-8',
  id: 'consent-receipt-script',
  src: `${process.env.VUE_APP_ONETRUST_URL}/consent-receipt-scripts/scripts/otconsent-1.0.min.js`,
  type: 'text/javascript',
  'data-ot-consent-register-event': 'script-init',
  'data-ot-id': process.env.VUE_APP_ONETRUST_ID as string
};

@Component
export default class OneTrustForm extends Vue {
  @Prop({ type: String, required: false })
  readonly collectionPoint?: string;

  private created() {
    if (
      FeatureFlagsModule.isEnabled(FeatureFlags.ONETRUST) &&
      this.collectionPoint &&
      !this.hasOneTrustScript()
    ) {
      this.includeOneTrustScript();
    }
  }

  private destroyed() {
    document.getElementById(scriptAttributes.id)?.remove();
  }

  private hasOneTrustScript() {
    return !!document.getElementById(scriptAttributes.id);
  }

  private includeOneTrustScript() {
    const script = document.createElement('script');
    Object.entries(scriptAttributes).forEach(([key, value]) =>
      script.setAttribute(key, value)
    );
    document.head.appendChild(script);
  }

  private isProductionEnvironment() {
    return process.env.NODE_ENV === 'production';
  }

  get collectionPointIdWithSuffix() {
    if (!this.collectionPoint) return '';
    return `${this.collectionPoint}-${
      this.isProductionEnvironment() ? 'active' : 'draft'
    }`;
  }
}
