
import { Component, Vue } from 'vue-property-decorator';

import { WarrenSession } from '@/modules/common/services/session';
import {
  ICommitSetupTokenResponse,
  checkSetupToken,
  commitSetupToken,
  getThemeByParam
} from '@/modules/common/services/http/account';

import {
  NavBar,
  BaseIcon,
  InputPassword,
  InputText,
  ButtonPrimary,
  Heading,
  BaseText,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { Notification } from '@/modules/common/components/Notification';

import { NotificationTypes } from '@/modules/common/services/http/notification';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { verifyPassword } from '@/modules/authentication/helpers';
import { debounce } from 'lodash-es';

@Component({
  components: {
    Notification,
    LoaderIndicator,
    NavBar,
    BaseIcon,
    InputPassword,
    InputText,
    ButtonPrimary,
    Heading,
    BaseText,
    BrandImage
  }
})
export default class PasswordSetupView extends Vue {
  private isLoading = true;
  private comittingSetup = false;

  private setupToken = '';

  private email = '';
  private password = '';
  private passwordConfirm = '';

  private passwordScore = 0;
  private passwordMessage = '';
  private isPasswordValid = false;
  private progressColor = NebraskaColors.statusInfo;

  readonly NebraskaColors = NebraskaColors;
  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;

  private async mounted() {
    this.setupToken = this.$route.params.token;

    await checkSetupToken({ hash: this.setupToken }).then(
      response => {
        this.isLoading = false;
        this.email = response.email;
      },
      _error => {
        this.isLoading = false;
        this.$notify({
          group: 'wrn-setup-notification',
          text: 'OPS! ESTA CHAVE DE ACESSO TEMPORÁRIA É INVÁLIDA',
          type: NotificationTypes.error
        });
      }
    );
  }

  private async setupPassword() {
    this.comittingSetup = true;
    await commitSetupToken({
      hash: this.setupToken,
      password: this.password
    }).then(
      response => {
        WarrenSession.set(StorageKeys.AccessToken, response.accessToken);
        WarrenSession.set(StorageKeys.CustomerId, response.user._id);
        response.user.clientType.b2bPartner
          ? this.loginPartner(response)
          : this.loginWhitelabel();
      },
      _error => {
        this.comittingSetup = false;
        this.$notify({
          group: 'wrn-setup-notification',
          text: 'Não foi possivel registrar a senha.',
          type: NotificationTypes.error
        });
      }
    );
  }

  private loginWhitelabel() {
    WarrenSession.set(StorageKeys.WhitelabelTheme, 'whitelabel');
    // eslint-disable-next-line promise/catch-or-return
    getThemeByParam('email', this.email)
      .then(theme =>
        localStorage.setItem(StorageKeys.Theme, JSON.stringify(theme))
      )
      .finally(() => this.$router.push({ name: 'v3' }));
  }

  private loginPartner(data: ICommitSetupTokenResponse) {
    WarrenSession.set(StorageKeys.Partner, JSON.stringify(data.user));
    WarrenSession.set(StorageKeys.IsPartnerAccountSession, 'true');

    this.$router.push({ name: 'v3' });
  }

  private get enableButton() {
    return this.isPasswordValid && this.password === this.passwordConfirm;
  }

  private debounceVerifyPassword = debounce(this.verifyPassword, 300);

  onInputChange() {
    this.isPasswordValid = false;
    this.debounceVerifyPassword();
  }

  private async verifyPassword() {
    const response = await verifyPassword(this.password);
    if (response) {
      this.passwordScore = response.passwordScore;
      this.passwordMessage = response.passwordMessage;
      this.isPasswordValid = response.isPasswordValid;
      this.progressColor = response.progressColor;
    }
  }

  private get passwordStrengthColor() {
    if (this.passwordScore <= 0.1) {
      return NebraskaColors.statusNegative;
    } else {
      return this.progressColor;
    }
  }
}
