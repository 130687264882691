
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { FaceMatchTemplate } from '../FaceMatchTemplate';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HelperViewContextual } from '@warrenbrasil/nebraska-web';
import { AnnouncementContent } from './page-content-by-context';

@Component({
  components: { FaceMatchTemplate, HelperViewContextual }
})
export default class AnnouncementAuthentication extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Emit('go-to-next-step')
  public goToNextStep() {}

  @Emit('go-back-event')
  goBackEvent() {}

  @Emit('go-to-help-center')
  goToHelpCenter() {}

  @Prop({ type: Object, required: true })
  readonly pageContent!: AnnouncementContent;
}
