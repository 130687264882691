
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { InputPassword } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { FastRegisterAPI } from '../../services/api';

enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

@Component({
  components: { InputPassword }
})
export default class PasswordValidator extends Vue {
  @Prop({ type: String }) private value!: string;

  private modifiedListeners: any;

  private fastRegisterAPI = new FastRegisterAPI();

  private passwordInputTimeout?: NodeJS.Timeout;
  private passwordTimeoutTime = 1000;

  private passwordTip = '';
  private passwordProgress = 0;
  private progressColor = NebraskaColors.statusAlert;

  private isValid = false;

  private created() {
    this.validatePassword();

    this.modifiedListeners = {
      ...this.$listeners
    };
  }

  public onInputPassword(value: string) {
    this.verifying(true);

    if (this.passwordInputTimeout) {
      clearTimeout(this.passwordInputTimeout);
    }

    let skipPasswordAPIVerification = false;

    if (value) {
      const valueIsLessThanSevenDigits = value.length < 8;
      const valueAtSevenDigits = value.length === 7;
      const backspaceEvent = (event as InputEvent).data === null;

      if (
        valueIsLessThanSevenDigits ||
        (valueAtSevenDigits && backspaceEvent)
      ) {
        skipPasswordAPIVerification = true;
      }
    } else {
      skipPasswordAPIVerification = true;
    }

    if (!skipPasswordAPIVerification) {
      this.passwordInputTimeout = setTimeout(() => {
        this.validatePassword();
      }, this.passwordTimeoutTime);
    } else {
      this.verifying(false);

      const isPasswordInputEmpty = !value || value.length === 0;

      this.passwordTip =
        'Por favor, crie uma senha com pelo menos 8 caracteres.';
      this.passwordProgress = isPasswordInputEmpty ? 0 : 0.15;
      this.progressColor = NebraskaColors.statusAlert;
    }

    this.$emit('input', value);
  }

  public get fieldStatus(): InputStatusType {
    return this.passwordTip && !this.isValid
      ? InputStatusType.Error
      : InputStatusType.Default;
  }

  private async validatePassword() {
    if (this.passwordInputTimeout) {
      clearTimeout(this.passwordInputTimeout);
    }

    const response = await this.fastRegisterAPI.verifyPassword({
      password: this.value
    });

    this.verifying(false);

    this.passwordTip = response.tip;
    this.passwordProgress = Math.min(response.score * 0.3, 1);

    switch (response.score) {
      case 0:
        this.passwordProgress = 0.15;
        this.progressColor = NebraskaColors.statusAlert;
        break;
      case 1:
      case 2:
        this.progressColor = NebraskaColors.statusAlert;
        break;
      case 3:
      case 4:
        this.progressColor = NebraskaColors.statusPositive;
        break;
    }

    this.isValid = response.score >= 3;
    this.validated();
  }

  private get getPasswordTestColor() {
    if (this.passwordProgress < 0.1) {
      return NebraskaColors.textSecondary;
    } else {
      return this.progressColor;
    }
  }

  @Emit()
  private validated() {
    return this.isValid;
  }

  @Emit()
  private verifying(value: boolean) {
    return value;
  }
}
