import { httpClient } from '@/modules/common/services/http/http-client';
import { StatementBody, DailyBrokerageNoteBody } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getPositionsStatement(year: number): Promise<StatementBody> {
  return httpClientWithDefaultOptions.get('/v1/downloadPositionsStatement', {
    params: { year }
  });
}

export function getSubscriptionsStatement(
  year: number
): Promise<StatementBody> {
  return httpClientWithDefaultOptions.get(
    '/v1/downloadSubscriptionsStatement',
    {
      params: { year }
    }
  );
}

export function getPublicOfferingsStatement(
  year: number
): Promise<StatementBody> {
  return httpClientWithDefaultOptions.get(
    '/v1/downloadPublicOfferingsStatement',
    {
      params: { year }
    }
  );
}

export function getMonthlyStatement(date: string): Promise<StatementBody> {
  return httpClientWithDefaultOptions.get('/v1/downloadMonthlyStatement', {
    params: { date }
  });
}

export function getConsolidatedStatement(): Promise<StatementBody> {
  return httpClientWithDefaultOptions.get('/v1/downloadConsolidatedStatement');
}

export function getDailyBrokerageNote(
  path: string
): Promise<DailyBrokerageNoteBody> {
  return httpClientWithDefaultOptions.get('/v1/downloadBrokerageNote', {
    params: { path }
  });
}
