
import { Vue, Component, Emit } from 'vue-property-decorator';
import { ActionableHelperView } from '@warrenbrasil/nebraska-web';
import * as trackEvents from './track-events';

@Component({
  components: { ActionableHelperView }
})
export default class GenericError extends Vue {
  @Emit('on-retry')
  public onRetry() {
    trackEvents.trackTryAgainClick();
  }

  mounted() {
    trackEvents.trackLoadGenericError();
  }
}
