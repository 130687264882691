import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackErrorLoad() {
  trackAmplitudeEvent({
    page_screen: 'error',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackErrorBodyCloseClick() {
  trackAmplitudeEvent({
    page_screen: 'error',
    event_type: 'click',
    event_name: 'close',
    page_screen_section: 'body'
  });
}

export function trackErrorTryAgainClick() {
  trackAmplitudeEvent({
    page_screen: 'error',
    event_type: 'click',
    event_name: 'try_again',
    page_screen_section: 'body'
  });
}

export function trackSuccessLoad(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'success_selfie_delivery'
      : 'success_selfie_delivery_onboarding',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSuccessBodyBackClick(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'success_selfie_delivery'
      : 'success_selfie_delivery_onboarding',
    event_type: 'click',
    event_name: 'go_home',
    page_screen_section: 'body'
  });
}

export function trackInfoLoad(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding ? 'review_data' : 'review_data_onboarding',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackInfoBodyBackClick(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding ? 'review_data' : 'review_data_onboarding',
    event_type: 'click',
    event_name: 'go_home',
    page_screen_section: 'body'
  });
}

export function trackCompletedLoad(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'registration_completed'
      : 'registration_completed_onboarding',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackCompleteBodyBackClick(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'registration_completed'
      : 'registration_completed_onboarding',
    event_type: 'click',
    event_name: 'go_home',
    page_screen_section: 'body'
  });
}
