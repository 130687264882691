
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseIcon,
  ButtonPrimary,
  NavBar,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { getString } from '@/modules/common/helpers/resources';
import { moneyViewer } from '@/modules/common/helpers/currency';
import { WarrenSession } from '@/modules/common/services/session';
import { RouteMixin } from '@/modules/common/mixins/route';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { CustomerModule } from '@/modules/common/store/CustomerModule';

import { Conversation } from '@/modules/conversation/components/Conversation';
import {
  IConversationAnswerData,
  IConversationQuestion,
  IConversationQuestionMessage,
  IConversationQuestionCheckbox
} from '@/modules/conversation/services/conversation';

import { WelcomeCurtain } from '@/modules/common/components/WelcomeCurtain';
import { Curtain } from '@/modules/common/components/Curtain';
import { ICustomer } from '@/types/models';
import {
  getCustomer,
  getThemeByReferralId
} from '@/modules/common/services/http/account';

import { SuitabilityModule } from '../../store';
import {
  IBrandConversationDataInformation,
  IConversationDataSuitability,
  ISuitabilityCreated,
  ISuitabilityUserCreated,
  SuitabilityQuestionId,
  ISuitability
} from '../../types';
import {
  isImpersonate,
  isWarrenManaged
} from '@/modules/common/services/partner';
import {
  trackGetIncome,
  trackSuitability,
  trackSuitabilityFinished
} from './track-events';
import { finishSuitabilityV2, finishSuitabilityV3 } from '../../services/api';
import redirectTo, {
  RedirectList
} from '@/modules/onboarding/helpers/redirect-to';

interface URLParams {
  new: string;
  invited: string;
  name: string;
  referralId: string;
  email: string;
}
@Component({
  components: {
    WelcomeCurtain,
    LoaderIndicator,
    Conversation,
    NavBar,
    BaseIcon,
    Curtain,
    ButtonPrimary,
    ContextColorProvider
  }
})
export default class SuitabilityView extends mixins(RouteMixin) {
  private get hideFinishInput() {
    return (
      !this.conversationQuestion ||
      (this.conversationQuestion &&
        this.conversationQuestion.id !== 'final' &&
        this.conversationQuestion.id !== 'final_login')
    );
  }

  private get primary() {
    return this.isWhitelabel()
      ? NebraskaColors.elementPrimary
      : NebraskaColors.brandPrimary;
  }

  private get overPrimary() {
    return this.isWhitelabel()
      ? NebraskaColors.textNegative
      : NebraskaColors.brandOverPrimary;
  }

  public URLParams!: URLParams;

  private brandInformation?: IBrandConversationDataInformation;

  private conversationQuestion: IConversationQuestion = {
    id: '',
    buttons: [],
    inputs: [],
    radios: [],
    checkbox: [],
    messages: [],
    responses: []
  };

  private suitaConversationAnswerData: IConversationDataSuitability = {
    id: '',
    answers: {},
    context: 'suitability',
    subcontext: ''
  };

  private username = '';

  private store = SuitabilityModule;

  private getString = getString;
  private NebraskaColors = NebraskaColors;
  private money = moneyViewer;

  private hasCurtain = false;
  private firstLoading = true;
  private sessionWelcome? = false;

  private brandNameFromFastRegister = '';

  private customerModule = CustomerModule;

  private customer?: ICustomer;

  // @ts-ignore
  private suitaFinishedUser: ISuitabilityUserCreated = null;
  // @ts-ignore
  private newSuitaFinishedUser: ISuitabilityCreated = null;
  // @ts-ignore
  private createdUserSuita: ISuitability = null;

  private isPartner = false;

  public async mounted() {
    this.isPartner = this.$route.query.context === 'b2b';
    if (this.$route.params.referralId) {
      await getThemeByReferralId(this.$route.params.referralId).then(
        partnerInfo => {
          this.brandInformation = {
            brandReferral: this.$route.params.referralId,
            brandNameInitial: partnerInfo.tradeMark[0],
            brandDomain: partnerInfo.theme.brandDomain
          };
        }
      );
    }

    const isUserLogged = !!WarrenSession.get('warren.accounts.accessToken');
    this.hasCurtain = !isUserLogged;

    if (!isUserLogged) {
      this.fastRegisterRedirection();
    } else {
      this.customer = this.customerModule.getCustomer;

      const startedWithoutUser = !this.customer;
      const isPartnerCreated = WarrenSession.get('warren.accounts.userB2b');
      if (startedWithoutUser && !isPartnerCreated) {
        await this.getUser();
      }

      if (this.customer && this.customer.name) {
        this.username = this.customer.name;
      }

      this.sessionWelcome =
        this.customer && !this.customer.isSuitabilityFinished;

      const hasBrandNameOnLocalStorage = !!localStorage.getItem(
        'warren.fastRegister.tradeMark'
      );

      if (hasBrandNameOnLocalStorage) {
        this.brandNameFromFastRegister = localStorage.getItem(
          'warren.fastRegister.tradeMark'
        )!;
      }

      this.firstLoading = false;

      if (isUserLogged) {
        this.startSuitability();
      }
    }
  }

  private async getUser() {
    try {
      const { customer } = await getCustomer();
      this.customerModule.setCustomer(customer);
      this.customer = customer;
    } catch {
      this.customer = undefined;
    }
  }

  private fastRegisterRedirection() {
    const fastRegisterParams: {
      username?: string;
      referralId?: string;
      brandName?: string;
      isAffiliate?: string;
      email?: string;
    } = {};

    const isB2BRelated = this.brandInformation;

    if (isB2BRelated) {
      fastRegisterParams.isAffiliate = 'true';
      fastRegisterParams.brandName = this.brandInformation!.brandDomain;
      fastRegisterParams.referralId = this.brandInformation!.brandReferral;
      localStorage.setItem(StorageKeys.WhitelabelTheme, 'whitelabel');
    } else {
      const expectedFastRegisterParams = this.$route.query;
      localStorage.removeItem(StorageKeys.WhitelabelTheme);
      localStorage.removeItem(StorageKeys.Theme);

      const isInvited = expectedFastRegisterParams.invited;

      if (isInvited) {
        fastRegisterParams.referralId =
          expectedFastRegisterParams.referralId as string;
      }
    }

    let fullURLParams = this.getURLParams<any>();
    if (fullURLParams) {
      fullURLParams.referralId = null;

      fullURLParams = {
        ...fullURLParams,
        ...fastRegisterParams
      };
    }

    this.$router.push({ path: `signup`, query: fullURLParams });
  }

  private get shouldRedirectToSDUISuitability() {
    const isSuitaBeforeSignUp =
      this.URLParams?.new === 'true' && !!this.brandInformation?.brandReferral;
    return isWarrenManaged() && !isImpersonate() && !isSuitaBeforeSignUp;
  }

  private async startSuitability() {
    this.setSuitabilityState();

    if (this.shouldRedirectToSDUISuitability) {
      redirectTo(RedirectList.Suitability);
      return;
    }

    this.conversationQuestion = await this.store.fetchNextQuestion(
      this.suitaConversationAnswerData
    );

    this.$forceUpdate();
  }

  private isWhitelabel() {
    return !!localStorage.getItem(StorageKeys.WhitelabelTheme);
  }

  private setSuitabilityState() {
    this.setConversationSubcontext();
    this.setAnswerDataBasedOnURLParams();
    this.runInitialAnalyticsTasks();
  }

  private setConversationSubcontext() {
    this.suitaConversationAnswerData.subcontext = 'suitability_regulated';

    if (this.brandInformation) {
      this.suitaConversationAnswerData = {
        ...this.suitaConversationAnswerData,
        isAffiliate: true,
        referralId: this.brandInformation.brandReferral
      };
    }
  }

  private setAnswerDataBasedOnURLParams() {
    this.URLParams = this.getURLParams<URLParams>();
    this.suitaConversationAnswerData.fullpath = window.location.href;

    const email = this.URLParams.email
      ? decodeURIComponent(this.URLParams.email)
      : '';

    if (this.URLParams.new) {
      this.suitaConversationAnswerData.forceNew = 'true';
    }

    if (this.URLParams.invited) {
      this.username = decodeURI(this.URLParams.name);
      this.suitaConversationAnswerData.referralId = this.URLParams.referralId;
      this.suitaConversationAnswerData.isInvited = 'true';
      this.suitaConversationAnswerData.answers = {
        email_already_taken: email,
        question_name: decodeURI(this.URLParams.name)
      };
    } else {
      if (this.URLParams.name) {
        this.suitaConversationAnswerData.answers = {
          ...this.suitaConversationAnswerData.answers,
          question_name: decodeURI(this.URLParams.name)
        };
      }

      if (email) {
        this.suitaConversationAnswerData.answers = {
          ...this.suitaConversationAnswerData.answers,
          question_email: email
        };
      }
    }
  }

  // Performs required initial analytics tasks
  private runInitialAnalyticsTasks() {
    trackSuitability('Suitability Started');
  }

  private async nextMessageData(answerObj: IConversationAnswerData) {
    this.suitaConversationAnswerData.id = answerObj.messageId;
    this.suitaConversationAnswerData = {
      ...this.suitaConversationAnswerData,
      answers: {
        ...this.suitaConversationAnswerData.answers,
        [answerObj.messageId]: answerObj.value
      }
    };

    if (answerObj.messageId === SuitabilityQuestionId.Occupation) {
      this.suitaConversationAnswerData = {
        ...this.suitaConversationAnswerData,
        answers: {
          ...this.suitaConversationAnswerData.answers,
          [SuitabilityQuestionId.Occupation]: answerObj.value[0]
        }
      };
    }

    if (answerObj.messageId === SuitabilityQuestionId.Name) {
      this.username = answerObj.value as string;
    }

    if (this.brandInformation) {
      this.suitaConversationAnswerData.referralId =
        this.brandInformation.brandReferral;
    }

    this.conversationQuestion = await this.store.fetchNextQuestion(
      this.suitaConversationAnswerData
    );

    this.runQuestionAnalyticsTask();

    this.$forceUpdate();

    if (this.conversationQuestion.id === 'final') {
      this.runMKTAnalyticsTask();
      this.postSuitability();
    }
  }

  // Performs MKT analytics tests
  private runMKTAnalyticsTask() {
    const hasMKTAnalytics =
      this.suitaConversationAnswerData.answers.question_earnings &&
      this.suitaConversationAnswerData.answers.question_total_invested;

    if (hasMKTAnalytics) {
      trackGetIncome({
        incomeValue:
          +this.suitaConversationAnswerData.answers.question_earnings!,
        totalInvested:
          +this.suitaConversationAnswerData.answers.question_total_invested!
      });
    }
  }

  // Performs required subsequent analytics tasks
  private runQuestionAnalyticsTask() {
    trackSuitability('Suitability Question Answered');
  }

  /* TODO: Later this can be deleted and dealt within Conversation component, this function had to be created since
     some of our current conversations messages use the `conversationQuestion.responses` and others don't, in this case we can't assume
     the generic usage, so this must be handled by the front-end but it SHOULD be handled by the api */
  private setSuitabilityUserQuestionMessages(
    userAnswer: string | string[]
  ): IConversationQuestionMessage[] {
    const userAnswersMessages: IConversationQuestionMessage[] = [];

    switch (this.conversationQuestion.id) {
      case SuitabilityQuestionId.Name:
        userAnswersMessages.push(
          this.createUserQuestionMessage(
            this.conversationQuestion.responses[0],
            true
          )
        );
        break;
      case SuitabilityQuestionId.Age:
        userAnswersMessages.push(
          this.createUserQuestionMessage(userAnswer as string, true)
        );
        break;
      case SuitabilityQuestionId.Occupation:
        userAnswersMessages.push(
          this.createUserQuestionMessage(userAnswer[1], true)
        );
        break;
      case SuitabilityQuestionId.Income:
      case SuitabilityQuestionId.Earnings:
      case SuitabilityQuestionId.Deposits:
      case SuitabilityQuestionId.Assets:
      case SuitabilityQuestionId.TotalInvested:
        userAnswersMessages.push(
          this.createUserQuestionMessage(this.money(+userAnswer, true), true)
        );
        break;
      case SuitabilityQuestionId.Email:
        userAnswersMessages.push(
          this.createUserQuestionMessage(userAnswer, true)
        );
        break;
      case SuitabilityQuestionId.InvitedWelcome2:
        userAnswersMessages.push(
          this.createUserQuestionMessage(
            this.conversationQuestion.buttons[0].label.title,
            true
          )
        );
        break;
      case SuitabilityQuestionId.Password:
        if (typeof userAnswer === 'string') {
          const hiddenPassword = userAnswer.replace(/./g, '•');

          userAnswersMessages.push(
            this.createUserQuestionMessage(hiddenPassword, true)
          );
        }
        break;
      default:
        if (this.conversationQuestion.checkbox.length !== 0) {
          userAnswersMessages.push(
            this.createUserQuestionMessage(
              this.getArrayResponse(userAnswer as string[]),
              true
            )
          );
        } else if (this.conversationQuestion.radios.length !== 0) {
          userAnswersMessages.push(
            this.createUserQuestionMessage(
              this.conversationQuestion.radios.filter(
                radio => radio.value === userAnswer
              )[0].label.title,
              true
            )
          );
        } else if (this.conversationQuestion.selects?.length !== 0) {
          userAnswersMessages.push(
            this.createUserQuestionMessage(userAnswer[1], true)
          );
        } else {
          userAnswersMessages.push(
            this.createUserQuestionMessage(
              this.conversationQuestion.buttons.filter(
                button => button.value === userAnswer
              )[0].label.title,
              true
            )
          );
        }
        break;
    }

    return userAnswersMessages;
  }

  private createUserQuestionMessage(
    text: string | string[],
    isUserResponse?: boolean
  ): IConversationQuestionMessage {
    return {
      type: 'string',
      value: text,
      userResponse: isUserResponse || false
    };
  }

  private getArrayResponse(stringArray: string[]): string {
    const productNameArray: string[] = [];
    let productsText = '';

    this.conversationQuestion.checkbox.forEach(
      (checkbox: IConversationQuestionCheckbox) => {
        if (stringArray.includes(checkbox.value)) {
          productNameArray.push(checkbox.label.title);
        }
      }
    );

    productNameArray.forEach((productName, index) => {
      productsText +=
        productNameArray.length - 1 !== index
          ? `${productName}, `
          : productName;
    });

    return productsText;
  }

  private async postSuitability() {
    let customerB2C;

    if (this.isPartner) {
      this.suitaFinishedUser = await finishSuitabilityV2(
        this.suitaConversationAnswerData
      );
    } else {
      this.newSuitaFinishedUser = await finishSuitabilityV3(
        this.suitaConversationAnswerData,
        this.customer!._id
      );
      await this.getUser();
    }

    if (!this.isPartner) {
      customerB2C = { _id: this.newSuitaFinishedUser.customer } as ICustomer;
    }

    localStorage.removeItem(StorageKeys.FastRegisterSuitability);
    if (this.brandNameFromFastRegister) {
      localStorage.removeItem('warren.fastRegister.tradeMark');
    }

    WarrenSession.set('warren.context.tutorial', 'true');
    this.createdUserSuita = (
      customerB2C ? this.newSuitaFinishedUser : this.suitaFinishedUser
    ) as ISuitability;

    trackSuitabilityFinished({
      market: this.createdUserSuita.question_market,
      interest: this.createdUserSuita.question_interest,
      read: this.createdUserSuita.question_read,
      isNewCustomer: WarrenSession.isEmpty('warren.accounts.accessToken'),
      earnings: Number(
        this.suitaConversationAnswerData.answers.question_earnings
      ),
      education: this.suitaConversationAnswerData.answers.question_education
    });

    if (this.suitaFinishedUser?.accessToken) {
      this.customerModule.setCustomer(this.suitaFinishedUser.user);
      const hasPartnerInfo = WarrenSession.get('warren.accounts.userB2b');
      WarrenSession.set(
        hasPartnerInfo
          ? 'warren.accounts.impersonateAccessToken'
          : 'warren.accounts.accessToken',
        this.suitaFinishedUser.accessToken
      );
      CustomerModule.setIsImpersonated(isImpersonate());
    }
  }

  // this does not happen only for leads, because a customer could be re-doing suitability. Someone pls align this with
  // whoever uses this metric to define business assumptions and BI

  private redirectOnFinish() {
    switch (this.conversationQuestion.id) {
      case 'final':
        this.$router.push({ path: '/suitability/my-profile' });
        break;
      case 'final_login':
        if (this.conversationQuestion.customerInfo!.isAffiliate) {
          const brand =
            this.conversationQuestion.customerInfo?.brandDomain || 'whitelabel';
          this.$router.push({ name: 'signin', query: { brand } });
        } else {
          this.$router.push({ name: 'signin' });
        }
        break;
      default:
        this.$router.push({ name: 'signin' });
        break;
    }
  }

  private get getTitleText() {
    if (this.brandInformation) {
      return getString('affiliate_lead_funnel_step_discover_profile');
    }

    return getString('profile_view_navbar_title');
  }

  private NavBackClick() {
    this.navBarReturn();
  }

  private navBarReturn() {
    this.$router.push({ name: 'v3' });
  }

  private get setSuitabilityTrademark() {
    let trademarkInitialForConversationBubble = '';
    if (this.brandInformation) {
      trademarkInitialForConversationBubble =
        this.brandInformation.brandNameInitial;
    }

    if (this.brandNameFromFastRegister) {
      trademarkInitialForConversationBubble = this.brandNameFromFastRegister[0];
    }

    return trademarkInitialForConversationBubble;
  }
}
