import { render, staticRenderFns } from "./BrokerageNotes.vue?vue&type=template&id=5282cbda&scoped=true&"
import script from "./BrokerageNotes.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BrokerageNotes.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BrokerageNotes.vue?vue&type=style&index=0&id=5282cbda&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5282cbda",
  null
  
)

export default component.exports