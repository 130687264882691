
import { parse as parseDate } from 'date-fns';
import { debounce } from 'lodash-es';
import scrollToElement from 'scroll-to-element';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonPrimary, BaseText } from '@warrenbrasil/nebraska-web';

import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { Radio } from '@/modules/common/components/__deprecated__/Radio';
import { MoneyInput } from '@/modules/common/components/__deprecated__/MoneyInput';

import { ICustomer } from '@/types/models/Customer';
import { IPersonalProfile, IInvestmentProfile } from '@/types/models';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { getString, getEnum } from '@/modules/common/helpers/resources';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { dateViewer } from '@/modules/common/helpers/date';

import { ECustomerMaritialStatus, IPersonalData } from '../../types';
import { RegisterTemplate } from '../../components/RegisterTemplate';
import { personalDataValidate } from './helpers';
import {
  trackPageView,
  createEventData,
  trackFinishPersonalDataEvent
} from '../../helpers/track-personal-events';
import { register } from '../../services/register/api';

const defaultUser = {
  personalProfile: {},
  investmentProfile: { deposit: 0, totalInvested: 0 }
};

@Component({
  components: {
    FormField,
    Radio,
    TheMask,
    ButtonPrimary,
    RegisterTemplate,
    BaseText,
    MoneyInput
  }
})
export default class PersonalData extends Vue {
  @Prop({ type: Function, default: () => {} })
  private onRegisterFinished!: (data: any) => void;

  @Prop({ type: Object, default: () => defaultUser })
  private user!: ICustomer;

  private debouncedBlurErrorCheck = debounce(this.blurErrorCheck, 100);

  private personalProfile: IPersonalProfile = {};
  private investmentProfile: IInvestmentProfile = {};

  private errors: YupValidationErrors = {};
  private singleErrors: YupValidationErrors = {};

  private isLoading = false;
  private getString = getString;
  private isRegisterStarted = false;
  private isMobileDisabled = true;

  private getEnum = getEnum;

  private phoneTokens = {
    P: {
      pattern: /^[0-9*#+]+$/
    }
  };

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private mounted() {
    trackPageView();
    this.personalProfile = this.user.personalProfile!;
    this.investmentProfile = {
      ...defaultUser.investmentProfile,
      ...this.user.investmentProfile!
    };
    this.isRegisterStarted = this.user.isRegisterStarted || false;
    this.isMobileDisabled = !!this.personalProfile.mobile;

    if (this.personalProfile!.birthDate) {
      const date = this.user.personalProfile!.birthDate;
      if (date) this.personalProfile.birthDate = dateViewer(date.toString());
    }

    if (!this.user.personalProfile?.sex) this.personalProfile.sex = 'female';

    if (this.user.personalProfile?.mobile) {
      this.personalProfile.mobile = this.user.personalProfile.mobile.replace(
        '+55',
        ''
      );
    }
  }

  private getPOSTData() {
    const depositData: number = parseInt(
      this.investmentProfile!.deposit!.toString(),
      10
    );
    const deposit: number = Number.isInteger(depositData) ? depositData : 0;
    const investedData: number = parseInt(
      this.investmentProfile!.totalInvested!.toString(),
      10
    );
    const totalInvested: number = Number.isInteger(investedData)
      ? investedData
      : 0;

    const date = this.personalProfile.birthDate || '';
    const birthdate = parseDate(date.toString(), 'dd/MM/yyyy', new Date());
    return {
      user: {
        personalProfile: {
          ...this.personalProfile,
          mobile: `+55${this.personalProfile.mobile}`,
          birthDate: birthdate
        },
        investmentProfile: {
          totalInvested,
          deposit
        }
      }
    };
  }

  private get showPersonalData() {
    return !this.user.isRegisterVerified;
  }

  private get showPartnerName() {
    switch (this.personalProfile.maritalStatus) {
      case 'casado-brasileiro-nato':
      case 'casado-brasileiro-naturalizado':
      case 'casado-estrangeiro':
      case 'uniao-estavel':
        return true;
      default:
        return false;
    }
  }

  private get showPartnerCpf() {
    switch (this.personalProfile.maritalStatus) {
      case ECustomerMaritialStatus.Married:
      case ECustomerMaritialStatus.MarriedNaturalized:
      case ECustomerMaritialStatus.StableUnion:
        return true;
      default:
        return false;
    }
  }

  private get showPartnerRne() {
    return (
      this.personalProfile.maritalStatus ===
      ECustomerMaritialStatus.MarriedForeign
    );
  }

  private handleTrackingEvent(field: string) {
    const [, fieldName] = field.split('.');
    createEventData(fieldName, this.personalProfile, this.investmentProfile);
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) return err[0];

    const singleErr = this.singleErrors[path];
    if (singleErr) return singleErr[0];

    return '';
  }

  private focusOnErrorElement() {
    const form = this.$refs.registerForm as HTMLFormElement;
    const errorElement = form.querySelector('.wrn-color-error');
    if (!errorElement) return;
    const errorField = (errorElement.parentElement || {}).parentElement;
    if (!errorField) return;
    scrollToElement(errorField, {
      offset: 0,
      duration: 300
    });
  }

  private async blurErrorCheck(key: string) {
    try {
      this.handleTrackingEvent(key);
      const data = this.getPOSTData();
      await personalDataValidate.validate({
        personalProfile: {
          ...data.user.personalProfile,
          isRegisterStarted: this.isRegisterStarted
        },
        investmentProfile: {
          ...this.investmentProfile
        }
      });

      this.errors[key] = [];
      this.singleErrors[key] = [];
      this.$forceUpdate();
    } catch (errors) {
      this.errors[key] = errors[key];
      this.singleErrors[key] = errors[key];
      this.$forceUpdate();
    }
  }

  private async onSubmit() {
    if (this.isLoading) return null;
    trackFinishPersonalDataEvent();

    const data = this.getPOSTData();

    const userPersonalData = data.user.personalProfile;

    const userFormData: IPersonalData = {
      user: {
        personalProfile: {
          _id: userPersonalData._id,
          customer: data.user.personalProfile.customer,
          fullName: userPersonalData.fullName,
          maritalStatus: userPersonalData.maritalStatus,
          sex: userPersonalData.sex,
          cpf: userPersonalData.cpf,
          mobile: userPersonalData.mobile,
          birthDate: userPersonalData.birthDate,
          motherName: userPersonalData.motherName
        },
        investmentProfile: {
          ...data.user.investmentProfile
        }
      }
    };

    const maritalStatus = data.user.personalProfile.maritalStatus;

    if (
      maritalStatus === ECustomerMaritialStatus.Married ||
      maritalStatus === ECustomerMaritialStatus.MarriedNaturalized ||
      maritalStatus === ECustomerMaritialStatus.StableUnion
    ) {
      userFormData.user.personalProfile.partnerName =
        data.user.personalProfile.partnerName;

      userFormData.user.personalProfile.partnerCpf =
        data.user.personalProfile.partnerCpf;

      userFormData.user.personalProfile.partnerForeignRne = '';
    } else if (maritalStatus === ECustomerMaritialStatus.MarriedForeign) {
      userFormData.user.personalProfile.partnerName =
        data.user.personalProfile.partnerName;

      userFormData.user.personalProfile.partnerForeignRne =
        data.user.personalProfile.partnerForeignRne;

      userFormData.user.personalProfile.partnerCpf = '';
    } else {
      userFormData.user.personalProfile.partnerName = '';
      userFormData.user.personalProfile.partnerCpf = '';
      userFormData.user.personalProfile.partnerForeignRne = '';
    }

    try {
      await personalDataValidate.validate({
        personalProfile: {
          ...data.user.personalProfile,
          isRegisterStarted: this.isRegisterStarted
        },
        investmentProfile: {
          ...this.investmentProfile
        }
      });

      this.errors = {};
    } catch (errors) {
      this.errors = errors;

      this.$nextTick(() => {
        this.focusOnErrorElement();
      });

      return null;
    }
    try {
      this.isLoading = true;
      if (this.isRegisterStarted) {
        // @ts-ignore
        delete data.user.personalProfile.mobile;
      }

      const response = await register(userFormData);
      this.isLoading = false;

      this.$emit('nextStep', response);
      this.onRegisterFinished(response);
    } catch (err) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          err.description ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });

      this.isLoading = false;
    }
  }
}
