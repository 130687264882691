import { EStatusTypes } from './components/Processing/helpers/processingHelpers';

export enum EFaceMatchSteps {
  REGISTER_STATUS,
  ANNOUNCEMENT,
  ID_WALL,
  PROCESSING,
  STATUS
}

export enum ERegistrationUpdateStatus {
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  CONCLUDED = 'CONCLUDED',
  ANNOUNCEMENT = 'ANNOUNCEMENT'
}

export type FaceMatchStatusType = {
  status: StatusType | string;
  statusType: EStatusTypes;
  title: string;
  subtitle: string;
  icon: string;
};

type StatusType = 'completed' | 'success' | 'error' | 'info';
