
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { EProcessingSteps } from './types';
import { EFaceMatchStatus } from '@/modules/authentication/services/face-match/types';
import {
  getAuthenticationProfileByCustomerId,
  getCustomerIdentificationStatus
} from '@/modules/authentication/services/face-match';
import { EStatusTypes } from './helpers/processingHelpers';
import { namespace } from 'vuex-class';
import { trackLoadProcessing } from './track-events';
import LoadingText from './components/LoadingText/LoadingText.vue';
import { PageContext } from '../Announcement/page-content-by-context';

const customerModule = namespace('CustomerModule');

/**
 * O processo de chamada a API foi dividido em 3 partes;
 * Cada etapa do processo pode demorar até 8 segundos;
 * A atualização dos dados, na idWall, pode levar até 24 segundos;
 */

const LIMIT_OF_REQUESTS = 3;
const TIME_CALL_WITH_RESPONSE = 2000;
const TIME_CALL_WITHOUT_RESPONSE = 8000;
const TIME_CALL_CONCLUDED = 1000;

@Component({ components: { LoadingText } })
export default class Processing extends Vue {
  @customerModule.Getter('getCustomerId')
  private customerId!: string;

  private processingStep: EProcessingSteps = EProcessingSteps.PENDING;
  private numberOfRequests = 0;
  private hasResponse = false;
  private registerStatus: EStatusTypes = EStatusTypes.error;

  @Prop({ type: String, default: PageContext.DEFAULT })
  readonly context!: PageContext;

  @Emit('next-step')
  private nextStep() {
    return this.registerStatus;
  }

  mounted() {
    trackLoadProcessing(this.context === PageContext.ONBOARDING);
    setTimeout(() => {
      this.initializeAnimationProcess();
    }, TIME_CALL_WITHOUT_RESPONSE);
  }

  private get currentStep() {
    return { processingStep: this.getCurrentStepValue(this.processingStep) };
  }

  private get waitTime(): number {
    return this.hasResponse
      ? TIME_CALL_WITH_RESPONSE
      : TIME_CALL_WITHOUT_RESPONSE;
  }

  private getCurrentStepValue = (stepName: EProcessingSteps) => {
    return stepName;
  };

  private handleCallToAPIWithinTimeOut(ms: number) {
    const promise = this.checkNeedCallToAPI();
    return new Promise(resolve =>
      setTimeout(() => {
        // eslint-disable-next-line promise/catch-or-return
        promise.then(resolve);
      }, ms)
    );
  }

  private async initializeAnimationProcess() {
    this.processingStep = EProcessingSteps.PRE_PROCESSING;
    await this.handleCallToAPIWithinTimeOut(this.waitTime);

    this.processingStep = EProcessingSteps.PROCESSING;
    await this.handleCallToAPIWithinTimeOut(this.waitTime);

    this.processingStep = EProcessingSteps.UNDER_ANALYSIS;
    await this.handleCallToAPIWithinTimeOut(this.waitTime);

    setTimeout(() => {
      this.processingStep = EProcessingSteps.CONCLUDED;
      this.nextStep();
    }, TIME_CALL_CONCLUDED);
  }

  private async checkNeedCallToAPI() {
    return !this.hasResponse ? this.getAuthenticationProfile() : null;
  }

  private async getAuthenticationProfile() {
    this.incrementNumberOfRequests();
    try {
      const response =
        this.context === PageContext.ONBOARDING
          ? await getCustomerIdentificationStatus(this.customerId)
          : await getAuthenticationProfileByCustomerId(this.customerId);
      this.setResponse(response?.faceMatchStatus);
    } catch {
      this.setRegisterStatus(EStatusTypes.error);
    }
  }

  private incrementNumberOfRequests() {
    this.numberOfRequests++;
  }

  private setRegisterStatus(status: EStatusTypes) {
    this.registerStatus = status;
  }

  private checkLimitOfRequests() {
    return this.numberOfRequests === LIMIT_OF_REQUESTS;
  }

  private setResponse(registerStatus?: EFaceMatchStatus) {
    if (registerStatus) {
      this.checkCustomerFaceMatchStatus(registerStatus);
    } else {
      if (this.checkLimitOfRequests()) {
        this.hasResponse = true;
        this.setRegisterStatus(EStatusTypes.noResponse);
      }
    }
  }

  private checkCustomerFaceMatchStatus(status: EFaceMatchStatus) {
    switch (status) {
      case EFaceMatchStatus.Valid:
        this.setRegisterStatus(EStatusTypes.success);
        this.hasResponse = true;
        break;
      case EFaceMatchStatus.InvalidDoc:
      case EFaceMatchStatus.InvalidFaceMatch:
      case EFaceMatchStatus.InvalidDocFaceMatch:
      case EFaceMatchStatus.Invalid:
        this.setRegisterStatus(EStatusTypes.info);
        this.hasResponse = true;
        break;
      case EFaceMatchStatus.Processing:
        if (this.checkLimitOfRequests()) {
          this.hasResponse = true;
          this.setRegisterStatus(EStatusTypes.noResponse);
        }
        break;
    }
  }
}
