
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseIcon,
  BaseText,
  Button,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos
} from '@warrenbrasil/nebraska-web';

@Component({ components: { BaseIcon, BaseText, Button, BrandImage } })
export default class MobileBannerToApp extends Vue {
  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;

  private get isAndroid() {
    return /android/i.test(navigator.userAgent);
  }

  private get NebraskaColors() {
    return NebraskaColors;
  }
}
