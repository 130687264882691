
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import * as trackEvents from './track-events';
import pageContentByContext, { PageContext } from './page-content-by-context';
import { Announcement as AnnouncementOnboarding } from '@/modules/onboarding/components/Announcement';
import AnnouncementAuthentication from './AnnouncementAuthentication.vue';

@Component({
  components: {
    AnnouncementOnboarding,
    AnnouncementAuthentication
  }
})
export default class Announcement extends Vue {
  @Prop({ type: String, default: PageContext.DEFAULT })
  readonly context!: PageContext;

  @Emit('next-step')
  public goToNextStep() {
    trackEvents.trackBodyStartClick(this.isOnboarding);
  }

  @Emit('go-back-event')
  goBackEvent() {}

  mounted() {
    trackEvents.trackAnnouncementLoad(this.isOnboarding);
  }

  get isOnboarding() {
    return this.context === PageContext.ONBOARDING;
  }

  get pageContent() {
    return pageContentByContext[this.context] || pageContentByContext.default;
  }

  goToHelpCenter() {
    trackEvents.trackHeaderHelpClick(this.isOnboarding);
    this.$router.push({ name: 'help-center' });
  }
}
