
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EarningsInformation } from '@/modules/trade/views/Earnings/components/EarningsInformation';
@Component({
  components: {
    EarningsInformation
  }
})
export default class EarningsHeader extends Vue {
  @Prop({ type: String, required: true })
  readonly totalReceived!: string;

  @Prop({ type: String, required: true })
  readonly futureEarnings!: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;
}
