
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getString } from '@/modules/common/helpers/resources';
import { Checkbox } from '@/modules/common/components/__deprecated__/Checkbox';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { UserTermsLink } from '../UserTermsLink';

@Component({ components: { Checkbox, FormField, UserTermsLink } })
export default class UserTerms extends Vue {
  @Prop({ type: String, required: false })
  private error?: string;

  @Prop({ type: Boolean, required: true })
  private value!: boolean;

  private get label() {
    return getString('common_field_terms_label_termsofuse').replace(
      /\\n /g,
      ''
    );
  }
}
