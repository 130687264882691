
import { Component, Vue, Prop } from 'vue-property-decorator';

import { TransactionBadge } from '@/modules/common/components/TransactionBadge';
import { BaseCell } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseCell,
    TransactionBadge
  }
})
export default class StatusMargin extends Vue {
  @Prop({ type: String, required: true })
  readonly status!: string;

  @Prop({ type: String, required: true })
  readonly text!: string;
}
