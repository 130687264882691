
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';

import { ContextColorProvider } from '@warrenbrasil/nebraska-web';
import { getString } from '@/modules/common/helpers/resources';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { IPersonalProfile } from '@/types/models';

import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { Radio } from '@/modules/common/components/__deprecated__/Radio';
import {
  ProfessionInput,
  ProfessionList
} from '@/modules/common/components/ProfessionInput';

import { TheMask } from 'vue-the-mask';

interface FormErrors {
  profession?: boolean;
  workOrganization?: boolean;
  workCnpj?: boolean;
}

@Component({
  components: {
    FormField,
    ProfessionInput,
    Radio,
    ContextColorProvider,
    TheMask
  }
})
export default class ProfessionForm extends Vue {
  @Prop({ type: Object, required: false })
  private userProfession?: IPersonalProfile;

  @Prop({ type: String, default: '' })
  private error!: string;

  @Emit('change-page-ready-to-submit')
  public onCloseProfessionList() {
    this.handleProfessionTrackingEvents('profession');
    this.change();
    return !this.errorKeys.profession;
  }

  @Emit('change-page-ready-to-submit')
  public onOpenProfessionList() {
    return false;
  }

  private modifiedProfession: IPersonalProfile = {};
  private errorKeys: FormErrors = {};
  private workCnpjError = '';

  private get organizationLabel() {
    return getString('common_field_workorganization_label');
  }

  @Watch('userProfession', { deep: true, immediate: true })
  private updateProfession() {
    if (this.userProfession) {
      this.modifiedProfession = this.userProfession!;
    }
    this.$forceUpdate();
  }

  private getError(value: string): string {
    switch (value) {
      case 'workOrganization':
        if (this.errorKeys.workOrganization) {
          return getString('common_field_workorganization_error_required');
        } else {
          return '';
        }
      case 'workCnpj':
        if (this.errorKeys.workCnpj) {
          return this.workCnpjError;
        } else {
          return '';
        }
      case 'profession':
        if (
          !!this.modifiedProfession!.profession &&
          !this.modifiedProfession!.professionId
        ) {
          return 'Atualizamos o campo de ocupação. Por favor, selecione sua ocupação atual.';
        } else if (this.errorKeys.profession) {
          return 'Selecione uma ocupação';
        } else {
          return this.error;
        }
      default:
        return '';
    }
  }

  private isWhitelabel() {
    return !!localStorage.getItem(StorageKeys.WhitelabelTheme);
  }

  @Emit('handle-profession-form-tracking')
  private handleProfessionTrackingEvents(field: string) {
    return `personalProfile.${field}`;
  }

  private handleWorkOrganizationChange() {
    this.handleProfessionTrackingEvents('workOrganization');
    this.change();
  }

  private setProfession(option: ProfessionList) {
    this.modifiedProfession!.professionId = option.cbo;
    this.modifiedProfession!.profession = option.profession;
    this.change();
  }

  private setWorkOrganization(value: string) {
    this.modifiedProfession!.workOrganization = value;
    this.change();
  }

  private hasProfessionRadioInput(isCurrentlyWorking: boolean) {
    this.errorKeys.workOrganization = true;

    this.modifiedProfession!.workOrganizationCnpj = isCurrentlyWorking
      ? ''
      : undefined;
    this.modifiedProfession!.workOrganization = isCurrentlyWorking
      ? ''
      : undefined;

    if (!isCurrentlyWorking) {
      this.modifiedProfession!.workOrganization = '';
      this.modifiedProfession!.workOrganizationCnpj = '';
    }

    this.handleProfessionTrackingEvents('hasProfession');
    this.change();
  }

  @Emit()
  private change() {
    this.errorKeys.profession = !this.modifiedProfession!.professionId;
    this.errorKeys.workOrganization =
      !this.modifiedProfession!.workOrganization;

    this.errorKeys.workCnpj = !this.isValidCnpj(
      this.modifiedProfession?.workOrganizationCnpj
    );

    this.$forceUpdate();
    return this.modifiedProfession;
  }

  private validateUpdatedProfessionList() {
    this.modifiedProfession!.professionId = undefined;
  }

  private isValidCnpj(cnpj?: string) {
    if (!cnpj) {
      this.workCnpjError = 'Informe o CNPJ da instuição que você trabalha.';
      return false;
    }

    this.workCnpjError = 'Informe um CNPJ válido.';

    return cnpj.length === 14;
  }
}
