export const sections = [
  {
    icon: 'ic_question_mark',
    title: 'O que é?',
    text: 'É um serviço que permite alocar ativos financeiros para realizar operações estruturadas, day trade, mercado a termo, contratos de mercado futuro e demais modalidades da Bolsa de Valores.'
  },
  {
    icon: 'ic_qualified',
    title: 'Como funciona?',
    text: 'Basta solicitar uma alocação de margem de garantia para algum ativo que você tenha disponível, atualmente é permitido alocar: Dinheiro da Conta Warren, Ações, BDRs, ETFs, Títulos Públicos, CDB, LCI e LCA.'
  },
  {
    icon: 'ic_qualified',
    title: 'Onde utilizar?',
    text: 'Com uma margem de garantia, é possível realizar operações de alavancagem no mercado de derivativos ou operações não alavancadas no mercado à vista, através do Home Broker.'
  },
  {
    icon: 'ic_qualified',
    title: 'Quem pode utilizar?',
    text: 'Investidores com perfil de risco moderado e agressivo que possuem ativos aptos para serem alocados, além de acesso ao Home Broker. Também é destinado para investidores que operam na bolsa de valores através de Assessores Warren.'
  }
];
