import Axios from 'axios';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split
} from '@apollo/client/core';
import { setContext } from '@apollo/link-context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { getAccessToken } from '@/modules/common/services/access-token';

const maxAttempts = 5;
let attempts = 0;

async function fetcher<ResponseType>(url: string): Promise<ResponseType> {
  const response = await Axios.get<ResponseType>(url);
  const parsedData = await response.data;
  return parsedData;
}

const httpLinkConfig =
  process.env.NODE_ENV === 'test'
    ? {
        uri: process.env.VUE_APP_GRAPHQL_API_URL,
        fetch: fetcher
      }
    : {
        uri: process.env.VUE_APP_GRAPHQL_API_URL
      };

const httpLink = new HttpLink(httpLinkConfig);

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WSS_URL!,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      return {
        'access-token': getAccessToken(),
        'X-Local-Time-Zone': 'America/Sao_Paulo'
      };
    }
  }
});

function attemptsSubscriptions() {
  attempts++;

  if (attempts > maxAttempts) {
    // @ts-ignore
    subscriptionClient.reconnect = false;
    subscriptionClient.close(true, true);
  }
}

// @ts-ignore at the time of writing the field is private and untyped
const subscriptionClient = wsLink.subscriptionClient as SubscriptionClient;
// @ts-ignore
subscriptionClient.maxConnectTimeGenerator.duration = () =>
  // @ts-ignore
  subscriptionClient.maxConnectTimeGenerator.max;
subscriptionClient.onReconnecting(() => {
  attemptsSubscriptions();
});

subscriptionClient.onDisconnected(() => {
  attemptsSubscriptions();
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink as any,
  httpLink
);

function createLinkContext() {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'access-token': getAccessToken(),
        'X-Local-Time-Zone': 'America/Sao_Paulo'
      }
    };
  });

  return authLink.concat(link as any);
}

export const apolloClient = new ApolloClient({
  link: createLinkContext(),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
});
