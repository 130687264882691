
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import { RouteMixin } from '@/modules/common/mixins/route';
import { InstrumentDetails } from './components/InstrumentDetails';
import {
  ETradeDirection,
  ETradeOrderStatus,
  TradeOrderStatusMessage
} from '@/modules/trade/types/instrument';
import {
  Tab,
  Tabs,
  TabPanel,
  IconButton,
  TabPanels,
  TabList,
  HelperViewContextual
} from '@warrenbrasil/nebraska-web';
import { Order, OrderType } from './components/Order';
import { StatusIntrumentV2 } from './components/StatusIntrumentV2';
import { ConfirmOrder } from './components/ConfirmOrder';
import { OrderErrorModal } from './components/OrderErrorModal';

import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

import { OrderSteps } from './types';
import { IInstrumentStatus } from '@/modules/trade/services/signalr';
import {
  createOrder,
  OrderDirection,
  InstrumentStatus
} from '@/modules/trade/services/instruments';
import { OrderStatus } from '@/modules/trade/types';
import {
  trackOrderConfirmationClick,
  trackChangeOrderTabClick
} from '@/modules/trade/views/Instrument/trackers';
import { AvailableBalanceData } from '@/modules/trade/services/unified-account/types';
import { OrderFiltersQueryParamsKeys } from '@/modules/trade/views/Orders/types';
import { throttle } from 'lodash-es';
import { datadogLogs } from '@datadog/browser-logs';
import { AccountStatus } from '@/modules/trade/services/account';

const tradePositionsModule = namespace('tradeModule');
const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    Tabs,
    TabList,
    Tab,
    IconButton,
    TabPanels,
    TabPanel,
    Order,
    ConfirmOrder,
    InstrumentDetails,
    OrderErrorModal,
    StatusIntrumentV2,
    HelperViewContextual
  }
})
export default class InstrumentSidebar extends mixins(RouteMixin) {
  @tradePositionsModule.State('userHasBrokerAccount')
  readonly userHasBrokerAccount!: boolean;

  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isCustomerBlocked!: boolean;

  @Prop({ type: Number, required: true })
  readonly bestBuyPrice!: number;

  @Prop({ type: Number, required: true })
  readonly bestSellPrice!: number;

  @Prop({ type: Number, required: true })
  readonly quantityInstrument!: number;

  @Prop({ type: String, required: true })
  readonly instrumentTitle!: string;

  @Prop({ type: String, required: true })
  readonly ticker!: string;

  @Prop({ type: Boolean, required: false })
  readonly canOperate!: boolean;

  @Prop({ type: Object })
  readonly instrumentStatus!: IInstrumentStatus;

  @Prop({ type: Object, required: true })
  readonly status!: AccountStatus;

  @Prop({ type: Object, required: true })
  readonly instrument!: InstrumentStatus;

  @Prop({ type: String, default: '' })
  readonly description!: string;

  private currentTabIndex = 0;
  private OrderType = OrderType;
  public loadingOrder = false;
  private isError = false;
  private qty = 0;
  private value = 0;
  private direction: ETradeDirection | OrderDirection = ETradeDirection.Buy;
  private statusMessage?: TradeOrderStatusMessage | null;
  private orderStatus?: ETradeOrderStatus | OrderStatus | null;
  private action = OrderSteps.Form;
  readonly OrderSteps = OrderSteps;

  availableBalanceDataUnifiedAccount!: AvailableBalanceData;

  get computedOrderFormStyle() {
    return {
      display: this.action === 'ORDER_FORM' ? 'block' : 'none'
    };
  }

  get balanceUser() {
    return this.availableBalanceDataUnifiedAccount?.availableToOperateValue
      ?.formatted;
  }

  get isTradeNewBrokerFeatureFlagEnabled() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW
    );
  }

  get isUserDisabledToBuyAndSell() {
    return (
      !this.userHasBrokerAccount && this.isTradeNewBrokerFeatureFlagEnabled
    );
  }

  @Emit('on-accept-risk-terms')
  emitAcceptRiskTerms() {}

  @Emit('open-enable-broker-account-modal')
  emitOpenEnableBrokerAccountModal() {}

  onLoadUserAvailableBalanceUnifiedAccount(
    availableBalance: AvailableBalanceData
  ) {
    this.availableBalanceDataUnifiedAccount = availableBalance;
  }

  private async createOrder() {
    this.loadingOrder = true;
    trackOrderConfirmationClick(
      this.getOrderType,
      this.ticker,
      this.description,
      this.instrument.type!.formatted!
    );

    try {
      await this.createOrderBFF();
    } catch {
      this.isError = true;
    } finally {
      this.loadingOrder = false;
    }
  }

  private async createOrderBFF() {
    const body = {
      symbol: this.ticker,
      orderDirection: this.direction as unknown as OrderDirection,
      quantity: this.qty,
      price: this.value
    };
    const result = await createOrder(body);

    this.statusMessage = result.statusMessage;
    this.orderStatus = result.status.value;
    this.action = OrderSteps.Feedback;
  }

  sendOrderButtonDisabledLog = throttle((message: string) => {
    datadogLogs.logger.info('order-button-disabled', {
      reason: message,
      ticker: this.ticker
    });
  }, 60000);

  private redirectToOrders() {
    this.$router.push({
      name: 'orders',
      query: {
        [OrderFiltersQueryParamsKeys.Instrument]: this.ticker
      }
    });
  }

  private confirmOrder(quantity: number, price: number) {
    this.qty = quantity;
    this.value = price;

    this.action = OrderSteps.Confirmation;
  }

  private onBackInstrument() {
    this.action = OrderSteps.Form;
  }

  private get getOrderType() {
    return this.currentTabIndex === OrderType.Buy
      ? OrderType.Buy
      : OrderType.Sell;
  }

  @Watch('currentTabIndex')
  private changeOrderDirection() {
    this.direction =
      this.currentTabIndex === 0 ? ETradeDirection.Buy : ETradeDirection.Sell;

    trackChangeOrderTabClick(this.direction, this.ticker);
  }

  private closeModal() {
    this.isError = false;
    this.action = OrderSteps.Form;
  }
}
