
import { debounce } from 'lodash-es';
import scrollToElement from 'scroll-to-element';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';

import { getAddressByZipCode } from '@/modules/common/services/http/maps';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { getString, getEnum } from '@/modules/common/helpers/resources';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';

import { ICustomer } from '@/types/models/Customer';

import { IAddress } from '../../types';
import { RegisterTemplate } from '../../components/RegisterTemplate';
import { addressValidation } from './helpers';
import {
  trackPageView,
  createEventData,
  trackFinishAddressDataEvent
} from '../../helpers/track-address-events';
import { register } from '../../services/register/api';
import { isAddressDocumentNeeded } from '../../services/account-bff/api';
import { AddressDocumentModalUpload } from '@/modules/onboarding/register/components/AddressDocumentModalUpload';

@Component({
  components: {
    FormField,
    TheMask,
    ButtonPrimary,
    RegisterTemplate,
    AddressDocumentModalUpload
  }
})
export default class Address extends Vue {
  // tslint:disable-next-line:no-empty
  @Prop({ type: Function, default: () => {} })
  private onRegisterFinished!: (data: any) => void;

  @Prop({ type: Object, default: { address: {} } })
  private user!: ICustomer;

  private debouncedBlurErrorCheck = debounce(this.blurErrorCheck, 500);

  public zipcodeAddress: Partial<IAddress> = {};
  public openAddressDocumentModal = false;
  private savedAddressData?: ICustomer = undefined;

  // @ts-ignore
  private address: IAddress = {};
  private errors: YupValidationErrors = {};
  private singleErrors: YupValidationErrors = {};
  private isLoading = false;
  private getString = getString;
  private getEnum = getEnum;
  private NebraskaColors = NebraskaColors;

  private async mounted() {
    // @ts-ignore
    this.address = this.user.address;
    trackPageView();

    if (this.address.city) {
      this.zipcodeAddress.city = this.address.city;
    }

    if (this.address.state) {
      this.zipcodeAddress.state = this.address.state;
    }
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) {
      return err[0];
    }

    const singleErr = this.singleErrors[path];
    if (singleErr) {
      return singleErr[0];
    }

    return '';
  }

  private getPOSTData() {
    return {
      user: {
        address: this.address
      }
    };
  }

  private handleTrackingEvent(field: string) {
    const [, fieldName] = field.split('.');
    createEventData(fieldName);
  }

  private focusOnErrorElement() {
    const form = this.$refs.addressForm;
    // @ts-ignore
    const errorElement = form.querySelector('.wrn-color-error') || {};
    // @ts-ignore
    const errorField = (errorElement.parentElement || {}).parentElement;

    scrollToElement(errorField, {
      offset: 0,
      duration: 300
    });
  }

  private clearErrorBlockedFields() {
    this.errors['address.city'] = [];
    this.errors['address.state'] = [];
  }

  private async findZipCode() {
    this.zipcodeAddress = {};
    if (this.address.zipcode.length === 8) {
      const response = await getAddressByZipCode(this.address.zipcode);
      this.zipcodeAddress = { ...response };
      this.address.street = response.street;
      this.address.neighborhood = response.neighborhood;
      this.address.city = response.city;
      this.address.state = response.state;

      this.clearErrorBlockedFields();
    }
  }

  private async blurErrorCheck(key: string) {
    try {
      this.handleTrackingEvent(key);
      const data = this.getPOSTData();
      await addressValidation.validate(data.user);

      this.errors[key] = [];
      this.singleErrors[key] = [];
      this.$forceUpdate();
    } catch (errors) {
      this.errors[key] = errors[key];
      this.singleErrors[key] = errors[key];
      this.$forceUpdate();
    }
  }

  private goToNextStep() {
    this.$emit('nextStep', this.savedAddressData);
    this.onRegisterFinished(this.savedAddressData);
  }

  public closeAddressDocumentUploadModal() {
    this.openAddressDocumentModal = false;
  }

  public onSuccessAddressDocumentUpload() {
    this.closeAddressDocumentUploadModal();
    this.$notify({
      group: 'wrn-register-notifications',
      text: 'Comprovante residencial enviado.',
      type: NotificationTypes.success
    });
    setTimeout(() => {
      this.goToNextStep();
    }, 2000);
  }

  public onErrorAddressDocumentUpload(errorMessage: string) {
    this.$notify({
      group: 'wrn-register-notifications',
      text: errorMessage,
      type: NotificationTypes.error
    });
  }

  private async onSubmit() {
    if (this.isLoading) return;
    trackFinishAddressDataEvent();
    const data = this.getPOSTData();
    try {
      await addressValidation.validate(data.user);
      this.errors = {};
    } catch (errors) {
      this.errors = errors;
      this.$nextTick(this.focusOnErrorElement);
      return;
    }
    this.isLoading = true;
    try {
      this.savedAddressData = await register(data);
      const { shouldUploadAddressDocument } = await isAddressDocumentNeeded(
        this.user._id
      );
      if (shouldUploadAddressDocument) {
        this.openAddressDocumentModal = shouldUploadAddressDocument;
        return;
      }
      this.goToNextStep();
    } catch (err) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          err.description ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });
    } finally {
      this.isLoading = false;
    }
  }
}
