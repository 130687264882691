import { httpClient } from '@/modules/common/services/http/http-client';
import { ICostsList, ICostsDetails } from './types';

export const bffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getCostsList(year: string): Promise<ICostsList> {
  const currentYear = year || new Date().getFullYear();

  return bffInvestments.get(`v1/customer/getCostsList/${currentYear}`);
}

export function getCostsDetails(
  year: string,
  month: string
): Promise<ICostsDetails> {
  return bffInvestments.get(`v1/customer/getCostsDetails`, {
    params: {
      year,
      month
    }
  });
}
