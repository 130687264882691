
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  Heading,
  BaseText,
  BaseIcon,
  NavBar
} from '@warrenbrasil/nebraska-web';

const processingStepsText = [
  'Aguardando retorno.',
  'Obtendo dados.',
  'Processando.'
];

@Component({
  components: {
    Heading,
    BaseText,
    BaseIcon,
    NavBar
  }
})
export default class LoadingText extends Vue {
  @Prop({ type: Number })
  public processingStep!: number;

  public readonly steps = processingStepsText;

  get currentStep() {
    return this.processingStep - 1;
  }

  get totalSteps() {
    return processingStepsText.length + 1;
  }

  public getIconName(stepIndex: number) {
    return stepIndex === this.currentStep ? 'ic_spinner' : 'ic_check_simple';
  }
}
