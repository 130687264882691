import { EFaceMatchSteps } from './types';
import { RegisterStatus } from './components/RegisterStatus';
import { Announcement } from './components/Announcement';
import { IdWall } from './components/IdWall';
import { Processing } from './components/Processing';
import { FaceMatchStatus } from './components/FaceMatchStatus';

export const FaceMatchComponent = {
  [EFaceMatchSteps.REGISTER_STATUS]: RegisterStatus,
  [EFaceMatchSteps.ANNOUNCEMENT]: Announcement,
  [EFaceMatchSteps.ID_WALL]: IdWall,
  [EFaceMatchSteps.PROCESSING]: Processing,
  [EFaceMatchSteps.STATUS]: FaceMatchStatus
};
