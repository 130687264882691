
import { Component, Vue } from 'vue-property-decorator';
import { HelperView } from '@warrenbrasil/nebraska-web';
import { EarningsAccordions } from '../EarningsAccordions';
import { EarningsIncomingsFilters } from './components/EarningsIncomingsFilters';
import { EarningsInformation } from '@/modules/trade/views/Earnings/components/EarningsInformation';
import { SelectOption } from '@/modules/trade/views/Earnings/components/types';

@Component({
  components: {
    EarningsAccordions,
    EarningsIncomingsFilters,
    EarningsInformation,
    HelperView
  }
})
export default class EarningsIncomings extends Vue {
  accordionHeaders = [
    {
      title: 'Novembro de 2022',
      id: 1,
      subtitle: 'R$ 40,77'
    },
    {
      title: 'Data não definida',
      id: 2,
      subtitle: 'R$ 40,77'
    }
  ];

  onSelectFilter(filter: SelectOption) {
    console.log('FILTER SELECTED', filter);
    // TO DO Remove this line when the service is ready
  }
}
