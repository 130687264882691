
import { Vue, Component, Mixins, Ref } from 'vue-property-decorator';

import {
  ContextColorProvider,
  NavBar,
  Modal,
  ModalButton,
  Heading,
  BaseText,
  ButtonPrimary,
  Link,
  InputText
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { RouteMixin } from '@/modules/common/mixins/route';
import {
  IRecaptcha,
  Recaptcha,
  RecaptchaActions
} from '@/modules/authentication/components/Recaptcha';
import { getThemeByReferralId } from '@/modules/common/services/http/account';

import { FastRegisterAPI } from '../../services/api';
import { IFastRegisterLeadRequest } from '../../types';
import { getValidators, trackWrongEmail } from '../../helpers';
import { AutoCompleteSelector } from '../AutoCompleteSelector';
import { emailSuggester, knownServices } from './email-suggester';

interface URLParams {
  referralId?: string;
  brandName?: string;
  isAffiliate?: string;
  username?: string;
}
enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

@Component({
  components: {
    Modal,
    NavBar,
    ModalButton,
    ContextColorProvider,
    Recaptcha,
    AutoCompleteSelector,
    Heading,
    BaseText,
    ButtonPrimary,
    Link,
    InputText
  }
})
export default class FastRegisterEmailStep extends Mixins(RouteMixin) {
  private readonly NebraskaColors = NebraskaColors;

  private formData = { email: '' };
  private loadingAPIValidation = false;

  private validator = getValidators().email;
  private formValid = false;

  private errorMessage: string | null = '';
  private fastRegisterAPI = new FastRegisterAPI();

  private suggestedEmail = '';
  private emailDomainList: string[] = [];
  private displayAutoComplete = false;
  private autoFillSearch = '';

  private URLParams: URLParams = {};
  private modalOpen = false;

  private goToLoginButtonLabel = 'ir para o login';
  private resetPasswordButtonLabel = 'recuperar senha';

  @Ref()
  private readonly emailAutoComplete!: Vue;

  private created() {
    knownServices.forEach(entry => {
      this.emailDomainList.push(`@${entry.domain}`);
    });
  }

  private async setEmailValue(value: string) {
    if (this.formData.email !== value) {
      this.formData.email = typeof value === 'string' ? value.trim() : '';

      this.displayAutoComplete = value.includes('@');
      this.autoFillSearch = this.displayAutoComplete ? value.split('@')[1] : '';
      this.suggestedEmail = '';
    }

    try {
      await this.validator.validate(this.formData, false);
    } catch {
      /* esse bloco precisa ficar vazio, porque o `YupValidation`
        da um throw por padrão quando erro de validação é encontrado */
    }

    const validateErrorOnlyWhenFormIsValid = this.validator.isValid();

    this.formValid = this.validator.isValid();

    if (validateErrorOnlyWhenFormIsValid) {
      this.validadeError();
    }
  }

  private async validateEmail() {
    await this.validator.validate(this.formData, false);

    this.formValid = this.validator.isValid();
    this.validadeError();
  }

  private async sendForm() {
    this.loadingAPIValidation = true;

    const emailSuggestion = emailSuggester(this.formData.email);
    const hasSuggestion =
      !!emailSuggestion.suggestion! && !emailSuggestion.isKnownDomain!;

    if (hasSuggestion && !this.suggestedEmail) {
      this.suggestedEmail = emailSuggestion.suggestion!;
      this.loadingAPIValidation = false;
      return;
    }

    await this.validateEmail();

    if (this.formValid) {
      this.suggestedEmail = '';

      if (!this.showRecaptcha()) {
        this.onRecaptchaToken('');
      }
    } else {
      this.loadingAPIValidation = false;
    }
  }

  private async selectedEmailDomain(value: string) {
    const emailWithoutDomain = this.formData.email.split('@')[0];
    const domainWithSign = value;

    this.formData.email = emailWithoutDomain + domainWithSign;
    this.closeEmailAutoFill();

    try {
      await this.validator.validate(this.formData, false);
    } catch (error) {
      /* esse bloco precisa ficar vazio, porque o `YupValidation`
        da um throw por padrão quando erro de validação é encontrado */
    }

    this.formValid = this.validator.isValid();
    this.validadeError();
  }

  private selectEmailSuggestion() {
    this.formData.email = this.suggestedEmail;
    this.suggestedEmail = '';
  }

  private closeEmailAutoFill() {
    this.displayAutoComplete = false;
  }

  private get fieldStatus(): InputStatusType {
    return this.errorMessage ? InputStatusType.Error : InputStatusType.Default;
  }

  private validadeError() {
    this.validator.getError('email')
      ? (this.errorMessage = this.validator.getError('email'))
      : (this.errorMessage = '');
  }

  private closeModal() {
    this.modalOpen = false;
  }

  private redirectToLogin() {
    this.$router.push({ name: 'signin' });
  }

  private redirectToReset() {
    this.$router.push({ name: 'reset' });
  }

  private showRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;

    if (recaptcha) {
      recaptcha.onChallenge(RecaptchaActions.SIGN_UP_EMAIL);
      return true;
    } else {
      return false;
    }
  }

  private async onRecaptchaToken(recaptchaToken: string) {
    let canRegister = true;

    const submitData: IFastRegisterLeadRequest = {
      token: recaptchaToken,
      email: this.formData.email,
      isAffiliate: false,
      fullPath: window.location.href
    };

    this.URLParams = this.$route.query;

    const hasReferralId = this.URLParams.referralId;
    const isB2BRelated = this.URLParams.brandName;

    if (hasReferralId) {
      if (isB2BRelated) {
        await getThemeByReferralId(this.URLParams.referralId!).then(
          res => {
            const partner = res;

            if (partner) {
              submitData.isAffiliate = true;
              submitData.brandDomain = partner.theme.brandDomain;
              submitData.referralId = this.URLParams.referralId;
            }
          },
          _rej => {
            this.errorMessage = 'Não foi possivel fazer o cadastro.';
            this.loadingAPIValidation = false;
            canRegister = false;
          }
        );
      } else {
        submitData.referralId = this.URLParams.referralId;
      }
    }

    if (canRegister) {
      this.fastRegisterAPI.registerFastRegisterLead(submitData).then(
        res => {
          this.loadingAPIValidation = false;
          this.resetRecaptcha();
          this.$emit('completed', res.customerId, submitData.email);
        },
        err => {
          this.errorMessage = err.description;
          if (err.message === 'signup_email_already_in_use') {
            trackWrongEmail();
            this.modalOpen = true;
          }
          this.loadingAPIValidation = false;
          this.resetRecaptcha();
        }
      );
    }
  }

  private resetRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;
    if (recaptcha) recaptcha.onReset();
  }
}
