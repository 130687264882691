
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getBrandInitial } from '@/modules/common/services/partner';
import { IPartnerInfo } from '@/types/models/Customer';

import { ConversationStringMessage } from '@/modules/conversation/components/messages';
import { Notification } from '@/modules/common/components/Notification';
import { RegisterThemeProvider } from '../RegisterThemeProvider';

@Component({
  components: { ConversationStringMessage, Notification, RegisterThemeProvider }
})
export default class RegisterTemplate extends Vue {
  @Prop({ type: String, default: '' })
  private conversationText!: string;

  @Prop({ type: Object })
  private partnerInfos?: IPartnerInfo;

  private showContent = false;

  private conversationFinished() {
    this.showContent = true;
  }

  private get partnerBrandName() {
    return getBrandInitial(this.partnerInfos);
  }

  private mounted() {
    if (!this.conversationText) {
      this.conversationFinished();
    }
  }
}
