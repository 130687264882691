import { isValid as isValidCPF } from 'cpf';
import { isValidPhone } from '@/modules/common/helpers/phone';
import { object, string, boolean } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

export enum EmailErrors {
  Required = 'Informe o e-mail',
  Invalid = 'Ops! E-mail inválido',
  NotFound = 'E-mail não cadastrado'
}

export enum NameErrors {
  Required = 'Informe como quer ser chamado',
  Wrong = 'Nome inválido'
}

export enum PasswordErrors {
  Required = 'Informe a senha',
  Min = 'A senha deve conter no minimo 8 caracteres',
  Wrong = 'Senha inválida'
}

export enum TermErrors {
  Required = 'Você deve aceitar os termos.'
}

export enum CpfErrors {
  Required = 'CPF é obrigatório',
  Invalid = 'Informe um CPF válido'
}

export enum MobileErrors {
  Required = 'Celular é obrigatório',
  Invalid = 'Seu telefone deve conter no mínimo 9 digitos'
}

const emailStepSchema = object().shape({
  email: string().required(EmailErrors.Required).email(EmailErrors.Invalid)
});

const signUpStepSchema = object().shape({
  name: string().required(NameErrors.Required),
  password: string()
    .required(PasswordErrors.Required)
    .min(8, PasswordErrors.Min),
  cpf: string()
    .test({ test: isValidCPF, message: CpfErrors.Invalid })
    .required(CpfErrors.Required),
  mobile: string()
    .transform((_, originalValue: string) => originalValue.replace(/\D/g, ''))
    .test({ test: isValidPhone, message: MobileErrors.Invalid })
    .required(MobileErrors.Required),
  term: boolean().oneOf([true], TermErrors.Required)
});

export function getEmailStepValidator() {
  return new YupValidation(emailStepSchema);
}

export function getSignUpStepValidator() {
  return new YupValidation(signUpStepSchema);
}

export function getValidators() {
  return {
    email: getEmailStepValidator(),
    signUp: getSignUpStepValidator()
  };
}
