
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { ContentDisplay } from '@warrenbrasil/nebraska-web';
import { OrderDetailsField } from '../OrderDetailsField';

import { OrderContent } from '@/modules/trade/services/orders';

@Component({
  components: {
    ContentDisplay,
    OrderDetailsField
  }
})
export default class OrderDetailsContent extends Vue {
  @Prop({ type: Object, required: true })
  readonly content!: OrderContent;

  @Prop({ type: String, required: false })
  readonly buttonLabel?: string;

  @Prop({ type: Boolean, default: false })
  readonly buttonDisabled!: boolean;

  @Emit('on-button-click')
  emitOnButtonClick() {}

  get footerType() {
    return this.buttonLabel ? 'button' : 'caption';
  }

  get footerLabel() {
    return this.buttonLabel || this.content.caption;
  }
}
