import { httpClient } from '@/modules/common/services/http/http-client';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { Address } from '@/modules/account/services/resources/types';
import {
  InvestmentProfile,
  RegulatoryUpdateAnnouncementContentResponse,
  RegulatoryUpdatePersonalData
} from '../types';

const httpAccountBff = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
});

export function getRegulatoryUpdateAnnouncementContent(): Promise<RegulatoryUpdateAnnouncementContentResponse> {
  return httpAccountBff.get(
    'v3/customer/regulatoryUpdate/getRegulatoryAnnouncement',
    {
      params: {
        customerId: CustomerModule.getCustomerId
      }
    }
  );
}

export async function getPersonalData(): Promise<RegulatoryUpdatePersonalData> {
  const personalDataResponse = await httpAccountBff.get(
    'v2/customer/regulatoryUpdate/getPersonalData',
    {
      params: {
        customerId: CustomerModule.getCustomerId
      }
    }
  );

  personalDataResponse.personalData.maritalStatus =
    personalDataResponse.personalData.maritalStatus.key;

  return personalDataResponse.personalData;
}

export function savePersonalData(
  personalData: RegulatoryUpdatePersonalData
): Promise<void> {
  return httpAccountBff.post('v2/customer/regulatoryUpdate/savePersonalData', {
    customerId: CustomerModule.getCustomerId,
    personalData
  });
}

export async function getAddress(): Promise<Address> {
  const addressResponse = await httpAccountBff.get(
    'v1/customer/regulatoryUpdate/getAddress',
    {
      params: {
        customerId: CustomerModule.getCustomerId
      }
    }
  );

  addressResponse.address.state = addressResponse.address.state.key;
  addressResponse.address.zipcode = addressResponse.address.zipcode.formatted;
  return addressResponse.address;
}

export function saveAddress(address: Address): Promise<void> {
  return httpAccountBff.post('v1/customer/regulatoryUpdate/saveAddress', {
    customerId: CustomerModule.getCustomerId,
    address: {
      ...address,
      complement: address.complement || '',
      zipcode: address.zipcode.replace('-', '')
    }
  });
}

export async function getInvestmentProfile(): Promise<InvestmentProfile> {
  const investmentProfileResponse = await httpAccountBff.get(
    'v1/customer/regulatoryUpdate/getInvestmentProfile',
    {
      params: {
        customerId: CustomerModule.getCustomerId
      }
    }
  );

  return {
    totalInvested:
      investmentProfileResponse.investmentProfile.totalInvested.value,
    deposit: investmentProfileResponse.investmentProfile.deposit.value
  };
}

export function saveInvestmentProfile(
  investmentProfile: InvestmentProfile
): Promise<void> {
  return httpAccountBff.post(
    'v1/customer/regulatoryUpdate/saveInvestmentProfile',
    {
      customerId: CustomerModule.getCustomerId,
      investmentProfile
    }
  );
}

export function verifyPassword(password: string) {
  return httpAccountBff.post('v1/customer/regulatoryUpdate/verifyPassword', {
    customerId: CustomerModule.getCustomerId,
    password
  });
}

export function acceptTerms() {
  return httpAccountBff.put('/v2/customer/regulatoryUpdate/acceptTerm', {
    customerId: CustomerModule.getCustomerId
  });
}
