
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { InputMoney } from '@warrenbrasil/nebraska-web';

import { getString } from '@/modules/common/helpers/resources';
import { IProfessionalProfile } from '@/types/models/Customer/ProfessionalProfile';
import { isGreaterThanMinimalDeposit } from '@/modules/common/helpers/number';

export enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

@Component({ components: { InputMoney } })
export default class FinancialDataForm extends Vue {
  @Prop({ required: true })
  public investmentProfile!: IProfessionalProfile;

  public getString = getString;

  public depositFieldStatus = InputStatusType.Default;

  public mounted() {
    this.validateDepositMinValue();
  }

  @Emit('validate-field')
  private validateDepositMinValue() {
    const isDepositValid = isGreaterThanMinimalDeposit(
      this.investmentProfile.deposit
    );
    return { field: 'deposit', isValid: isDepositValid };
  }

  public checkDepositMinValue(newDeposit: number) {
    this.investmentProfile.deposit = newDeposit;
    const isDepositValid = isGreaterThanMinimalDeposit(
      this.investmentProfile.deposit
    );
    if (isDepositValid) {
      this.depositFieldStatus = InputStatusType.Default;
    } else {
      this.depositFieldStatus = InputStatusType.Error;
    }

    this.validateDepositMinValue();
  }
}
