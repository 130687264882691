export enum EProcessingSteps {
  INITIAL,
  PENDING,
  PRE_PROCESSING,
  PROCESSING,
  UNDER_ANALYSIS,
  CONCLUDED
}

export interface IRegisterStatus {
  status: string;
  title: string;
  subtitle: string;
}
