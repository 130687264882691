
import { Component, Mixins, Prop, Emit } from 'vue-property-decorator';

import { RouteMixin } from '@/modules/common/mixins/route';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  FormField,
  BaseIcon,
  ContextColorProvider,
  Heading,
  BaseText,
  InputText,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';

import { Checkbox } from '@/modules/common/components/__deprecated__/Checkbox';
import { Feature } from '@/modules/common/components/Feature';
import { OneTrustForm } from './components/OneTrustForm';

import {
  IRecaptcha,
  Recaptcha
} from '@/modules/authentication/components/Recaptcha';
import { IMFALoginResponse } from '@/modules/authentication/services/mfa';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { cpfMask, phoneMask } from '@/modules/common/helpers/masks';
import { FeatureFlags } from '@/types/models/FeatureFlags';

import { FastRegisterAPI } from '../../services/api';
import { IFastRegisterSignUpRequest } from '../../types';
import { getValidators, trackSendForm } from '../../helpers';
import {
  ConfirmResponse,
  confirmMembershipTerms
} from '../MembershipTermsModal';
import { PasswordValidator } from '../PasswordValidator';

type FormInput = 'name' | 'password' | 'cpf' | 'mobile' | 'term';

enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

@Component({
  components: {
    ContextColorProvider,
    FormField,
    BaseIcon,
    Checkbox,
    PasswordValidator,
    Feature,
    Recaptcha,
    OneTrustForm,
    Heading,
    BaseText,
    InputText,
    ButtonPrimary
  }
})
export default class FastRegisterSignUpStep extends Mixins(RouteMixin) {
  @Prop({ type: String, required: true })
  private readonly customerId!: string;

  @Prop({ type: String, required: true })
  private readonly email!: string;

  @Prop({ type: String, required: false })
  private readonly brandDomain?: string;

  private readonly NebraskaColors = NebraskaColors;

  private formData = {
    name: '',
    password: '',
    cpf: '',
    mobile: '',
    term: true
  };

  private validator = getValidators().signUp;
  private formValid = false;
  private passwordValid = false;

  private verifyingPassword = false;
  private loadingAPIValidation = false;

  private errorMessages: { [key: string]: string | null } = {};
  private fastRegisterAPI = new FastRegisterAPI();

  private get FeatureFlags() {
    return FeatureFlags;
  }

  public maskCpf(value: string) {
    this.mask('cpf', value);
  }

  public maskMobile(value: string) {
    this.mask('mobile', value);
  }

  private mask(formField: FormInput, value: string) {
    if (formField === 'cpf') this.formData.cpf = cpfMask(value.trim());
    else if (formField === 'mobile')
      this.formData.mobile = phoneMask(value.trim());
  }

  public onBlur(formField: FormInput, value: string | boolean) {
    this.validateForm(formField, value);
  }

  public validatePassword(value: string) {
    this.validateForm('password', value);
  }

  private async validateForm(formField: FormInput, value: string | boolean) {
    const whitespaceValidation =
      typeof value === 'string' &&
      (value as string)[(value as string).length - 2] !== ' ' &&
      !!(value as string)[(value as string).length - 2];

    let content =
      typeof value === 'string' && !whitespaceValidation ? value.trim() : value;

    if (formField === 'cpf') content = cpfMask(content as string);
    else if (formField === 'mobile') content = phoneMask(content as string);

    this.formData = {
      ...this.formData,
      [formField]: content
    };

    try {
      await this.validator.validate(this.formData, false);
    } catch (error) {
      /* esse bloco precisa ficar vazio, porque o `YupValidation`
        da um throw por padrão quando erro de validação é encontrado */
    }

    this.formValid = this.validator.isValid();
    this.validadeError(formField);
  }

  private async validadeError(id: string) {
    this.validator.getError(id)
      ? (this.errorMessages[id] = this.validator.getError(id))
      : (this.errorMessages[id] = '');

    this.$forceUpdate();
  }

  private sendForm() {
    if (!this.formValid) return;

    this.loadingAPIValidation = true;

    if (!this.showRecaptcha()) this.onRecaptchaToken('');
  }

  private showRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;
    if (!recaptcha) return false;
    recaptcha.onChallenge();
    return true;
  }

  private async onRecaptchaToken(recaptchaToken: string) {
    trackSendForm();
    const { acceptedTerms, close } =
      (await confirmMembershipTerms()) as ConfirmResponse;

    if (acceptedTerms) {
      const submitData: IFastRegisterSignUpRequest = {
        token: recaptchaToken,
        customerId: this.customerId,
        name: this.formData.name,
        password: this.formData.password,
        cpf: this.formData.cpf,
        mobile: `+55${this.formData.mobile}`,
        acceptedTerms: this.formData.term
      };

      await this.signUp(submitData);
    }

    this.loadingAPIValidation = false;
    close();
  }

  private async signUp(submitData: IFastRegisterSignUpRequest) {
    return this.fastRegisterAPI.signUpFastRegisterLead(submitData).then(
      res => {
        this.resetRecaptcha();
        this.loadingAPIValidation = false;
        this.loginHandler(res);
      },
      error => {
        this.resetRecaptcha();
        if (error?.description) {
          const isCpfError = /cpf/i.test(error.description);
          if (isCpfError) this.errorMessages.cpf = error.description;
        }
      }
    );
  }

  private resetRecaptcha() {
    const recaptcha = this.$refs.recaptcha as unknown as IRecaptcha;
    if (recaptcha) recaptcha.onReset();
  }

  private loginHandler(data: IMFALoginResponse) {
    localStorage.setItem(StorageKeys.FastRegisterSuitability, 'true');
    localStorage.setItem(
      StorageKeys.Brand,
      JSON.stringify(data?.brand || null)
    );

    if (data.user.clientType.whitelabel) {
      localStorage.setItem(StorageKeys.WhitelabelTheme, 'whitelabel');

      if (data.partnerInfos.companyName) {
        localStorage.setItem(
          StorageKeys.Trademark,
          data.partnerInfos.tradeMark
        );
      }
    }
    localStorage.setItem(StorageKeys.AccessToken, data.accessToken);
    localStorage.setItem(StorageKeys.CustomerId, data.user._id!);
    this.completed();
  }

  private isPasswordVerified(value: boolean) {
    this.passwordValid = value;
  }

  private isVerifyingPassword(value: boolean) {
    this.verifyingPassword = value;
  }

  private showOneTrustFields() {
    // TODO: create a service to get brand features without customer (public)
    return this.brandDomain === 'geocapital';
  }

  @Emit()
  private completed() {}

  private get collectionPoint() {
    return this.showOneTrustFields()
      ? '96148e6c-e653-4495-a821-3e974c0ab936'
      : '';
  }

  public getFieldStatus(field: string): InputStatusType {
    return this.errorMessages[field]
      ? InputStatusType.Error
      : InputStatusType.Default;
  }
}
