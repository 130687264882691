import { ICosts } from '@/types/models/Portfolio';
import { InstrumentTypeFormatted } from '@/modules/trade/types';
import { OrderDetailsResponse } from '@/modules/trade/services/orders';

export interface IGetInstrumentsFilters {
  maxInitialInvestment?: number;
  minMonthlyInvestment?: number;
  goalAmount?: number;
  goal?: string;
  goalDate?: Date;
  maxLiquidity?: number;
}

export interface IPorfolioBalances {
  totalBalance: number;
  totalEarnings: number;
  currentEarnings: number;
  totalEarningsPercentage: number;
  balanceLastUpdatedAt: Date;
  balanceLastUpdatedAtFormatted: string;
  netBalance: number;
  netEarnings: number;
  incomeTax?: number;
  financialOperationTax?: number;
  grossEarnings?: number;
  accountAvailableBalance?: number;
  grossWorth?: number;
  totalGrossWorth?: number;
  totalGrossEarnings?: number;
  totalNetEarnings?: number;
  totalNetWorth?: number;
  costs?: ICosts[];
}

export interface IDefaultProps {
  label: string;
  value: string;
  valueDescription: string;
}

export interface IPerformanceHistoryOverview {
  performanceHistoryOverview: IDefaultProps[];
  updatedAt: {
    value: string;
    formatted: string;
  };
}

export interface IEvolution {
  apiId: string;
  title: string;
  performance: number;
  performanceOverMarketIndex: number;
  marketIndex: string;
}

export interface IAllocation {
  typeName: string;
  typecolor: string;
  allocValue: number;
}

export enum EPortfolioInvestmentBehaviorToggle {
  AUTOMATIC = 0,
  MANUAL = 10
}

export interface IBackgroundImagesUrls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface IPhotos {
  id: string;
  user: {
    name: string;
    profile: string;
  };
  urls: IPhotoUrls;
}

export interface IPhotoUrls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface IBackgroundImages {
  next: number;
  last: number;
  photos: IPhotos[];
}

export interface IQueryBackgroundImages {
  query: string | object;
  page: number;
  perPage: number;
}

export interface PortfolioImage {
  raw?: string;
  full?: string;
  regular?: string;
  small?: string;
  thumb?: string;
}

export interface PortfolioPaidEventDetails {
  name: string;
  value: string;
}

export interface PortfolioPaidEvent {
  title: string;
  description: string;
  value: string;
  details?: PortfolioPaidEventDetails[];
}

export interface PortfolioPaidEventConfigurationForm {
  description: string;
  title: string;
  selectedIndex: string;
  options: {
    value: string;
    formatted: string;
  }[];
}

export interface PortfolioPaidEventConfiguration {
  enabled: boolean;
  blockedStatus: {
    title: string;
    description: string;
  };
  destination: {
    value: string;
    formatted: string;
  };
  form: PortfolioPaidEventConfigurationForm;
}

export interface Portfolio {
  type: {
    value: string;
    formatted: string;
  };
  warningText: string | null;
  image: PortfolioImage;
  totalGrossWorth: {
    value: number;
    formatted: string;
  };
  paidEvents: PortfolioPaidEvent[];
  paidEventsConfiguration: PortfolioPaidEventConfigurationForm | null;
  updatedAt: {
    value: number;
    formatted: string;
  };
}

export interface PortfolioChartAxisValues {
  value: number;
  formatted: string;
  benchmarkComparison?: {
    value: number;
    formatted: string;
    label: string;
  };
}

export interface PortfolioChartAxisRange {
  start: {
    value: string;
    formatted: string;
  };
  end: {
    value: string;
    formatted: string;
  };
}

export interface PortfolioChartAxisX {
  description: string;
  range: PortfolioChartAxisRange;
  values: PortfolioChartAxisValues[];
}

export enum PortfolioChartAxisYValues {
  Portfolio = 'Portfolio',
  Profit = 'Profit'
}

export interface PortfolioChartAxisY {
  name: string;
  type: PortfolioChartAxisYValues | string;
  values: PortfolioChartAxisValues[];
}

export interface PortfolioChartFilter {
  active: boolean;
  key: string;
  label: string;
  value: number | string;
  summaryLabel: string;
}

export interface PortfolioChartSummary {
  profitValue: {
    value: number;
    formatted: string;
  };
  return: {
    value: number;
    formatted: string;
  };
  benchmarkReturn: {
    value: number;
    formatted: string;
  };
  returnComparedToBenchmark: {
    value: number;
    formatted: string;
  };
}

export interface PortfolioChart {
  axisX: PortfolioChartAxisX[];
  axisY: PortfolioChartAxisY[];
  periodFilters: PortfolioChartFilter[];
  benchmarks: PortfolioChartFilter[];
  summary: PortfolioChartSummary | null;
  lastUpdate: string;
  updatedAt: {
    value: number;
    formatted: string;
  };
}

export interface PortfolioChartParams {
  portfolioId: string;
  periodFilter: string | number;
  benchmark?: string | number;
}

export type WealthChartParams = Omit<PortfolioChartParams, 'portfolioId'>;

export interface PortfolioSectionTypeRowItem {
  title: string;
  value: string;
  subValue: string | null;
  color: string;
  isHideable: boolean;
}

export interface PortfolioSectionTypeRow {
  name: string;
  symbol: string;
  items: PortfolioSectionTypeRowItem[];
}

export interface PortfolioSectionType {
  title: string;
  rows: PortfolioSectionTypeRow[];
}

export interface PortfolioSection {
  name: string;
  color: string;
  balance: {
    value: number;
    formatted: string;
  };
  quantity: {
    value: number;
    formatted: string;
  } | null;
  percentageAllocatedOnPortfolio: {
    value: number;
    formatted: string;
  };
  types: PortfolioSectionType[];
}

export interface PortfolioSectorAllocationResponse {
  title: string;
  updatedAt: {
    value: number;
    formatted: string;
  };
  totalBalance: {
    value: number;
    formatted: string;
  };
  sections: PortfolioSection[];
}

export enum ChipType {
  positive = 'positive',
  negative = 'negative',
  alert = 'alert',
  information = 'information'
}

export enum OrderAction {
  detail = 'detail',
  none = 'none'
}

export interface PortfolioOrder {
  id: string;
  instrument: {
    symbol: string;
    name: string;
    type: InstrumentTypeFormatted | null;
    quantity: {
      value: number;
      formatted: string;
    } | null;
  };
  title: string;
  date: {
    value: string;
    formatted: string;
  };
  grossValue: {
    value: number;
    formatted: string;
  };
  price: {
    value: number;
    formatted: string;
  };
  status: {
    value: ChipType;
    formatted: string;
  };
  action: OrderAction;
  details: OrderDetailsResponse;
}

export interface PortfolioOrdersPagination {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalPages: number;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export interface PortfolioOrdersFilterOption {
  value: string;
  formatted: string;
  selected: boolean;
}

export interface PortfolioOrdersFilters {
  type: PortfolioOrdersFilterOption[];
}

export interface PortfolioOrdersParams {
  portfolioId: string;
  pageIndex: number;
  pageSize: number;
  type?: string;
}

export interface PortfolioOrdersResponse {
  orders: PortfolioOrder[];
  filters: PortfolioOrdersFilters;
  pagination: PortfolioOrdersPagination;
  updatedAt: {
    value: string;
    formatted: string;
  };
}
export interface RiskProfile {
  value: string;
  formatted: string;
  description: string;
}

export interface PortfolioDetailItem {
  label: string;
  value: string;
}

export interface PortfolioStrategy {
  description: string;
}

export interface PortfolioRiskProfile {
  userRiskProfile: RiskProfile;
  portfolioRiskProfile: RiskProfile;
  buttonLabel: string;
}

export interface PortfolioFaqDetails {
  url: string | null;
  id: string | null;
}

export interface PortfolioAboutData {
  strategy: PortfolioStrategy;
  riskProfile: PortfolioRiskProfile;
  details: PortfolioDetailItem[];
  faq: PortfolioFaqDetails;
}

export interface PortfolioAboutResponse {
  about: PortfolioAboutData;
}

export interface PortfolioMontlhyStatementListFilterOption {
  active: boolean;
  value: string | number;
  label: string;
}

export interface PortfolioMontlhyStatementListFilters {
  years: PortfolioMontlhyStatementListFilterOption[];
}

export interface PortfolioMonthlyStatement {
  month: {
    value: number;
    formatted: string;
  };
  hasStatement: boolean;
}

export interface PortfolioMonthlyStatementsListParams {
  portfolioId: string;
  year?: string | number | null;
}

export interface PortfolioMonthlyStatementsList {
  portfolio: {
    name: string;
  };
  updatedAt: {
    value: string;
    formatted: string;
  };
  filters: PortfolioMontlhyStatementListFilters;
  statements: PortfolioMonthlyStatement[];
}

export interface PortfolioBrokerageNotesListFilterOption {
  active: boolean;
  value: string | number;
  label: string;
}

export interface PortfolioBrokerageNotesListFilters {
  years: PortfolioBrokerageNotesListFilterOption[];
}

export interface PortfolioBrokerageNote {
  date: {
    value: string;
    formatted: string;
  };
  file: {
    url: string;
    name: string;
  };
}

export interface PortfolioBrokerageNotesByMonth {
  label: string;
  brokerageNotes: PortfolioBrokerageNote[];
}

export interface PortfolioBrokerageNotesListParams {
  portfolioId: string;
  year?: string | number | null;
}

export interface PortfolioBrokerageNotesList {
  portfolio: {
    name: string;
  };
  updatedAt: {
    value: string;
    formatted: string;
  };
  filters: PortfolioBrokerageNotesListFilters;
  months: PortfolioBrokerageNotesByMonth[];
}

export interface PortfolioPerformanceFilterOption {
  active: boolean;
  value: number | string;
  label: number | string;
}

export interface PortfolioPerformanceFilters {
  years: PortfolioPerformanceFilterOption[];
}

export interface PortfolioPerformanceBenchmark {
  label: string;
  percentage: {
    value: number;
    formatted: string;
  };
}

export interface PortfolioPerformancePeriod {
  date: {
    short: string;
    long: string;
  };
  return: {
    value: number;
    formatted: string;
  };
  profitValue: {
    value: number;
    formatted: string;
  };
  benchmarks: PortfolioPerformanceBenchmark[];
}

export interface PortfolioPerformanceParams {
  portfolioId: string;
  year?: number | string | null;
}

export interface PortfolioPerformanceResponse {
  portfolio: {
    name: string;
  };
  updatedAt: {
    value: string;
    formatted: string;
  };
  filters: PortfolioPerformanceFilters;
  periods: PortfolioPerformancePeriod[];
}

export interface UpdatePaidEventsDestinationBody {
  portfolioId: string;
  paidEventDestination: string;
}

export interface UpdatePaidEventsDestinationResponse {
  status: string;
  title: string;
  description: string;
}

export interface GetMonthlyStatementParams {
  portfolioId: string;
  year: number | string;
  month: number;
}

export interface MonthlyStatement {
  base64: string;
}
