var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"background-bar",class:{ 'wrn-rounded': _vm.radius },style:({
    height: `${_vm.customHeight}px`,
    'background-color': _vm.backgroundColor
  })},[_c('div',{staticClass:"progress-status-bar",class:{ 'wrn-rounded': _vm.radius },style:({
      width: _vm.computedProgress,
      height: `${_vm.customHeight}px`,
      background: _vm.progressColor
    })},[(_vm.step)?_c('span',{staticClass:"progress-step",style:({
        width: `${_vm.customHeight}px`,
        height: `${_vm.customHeight}px`
      })}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }