import { number, object } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

const schema = object().shape({
  totalInvested: number().required('Informe o valor em aplicações financeiras'),
  deposit: number()
    .test({
      test: (value: number) => value >= 100,
      message: `O valor aproximado dos seus bens deve ser no mínimo R$ 100`
    })
    .required(`O valor aproximado dos seus bens deve ser no mínimo R$ 100`)
});

export default new YupValidation(schema);
