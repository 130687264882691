
import { Component, Vue } from 'vue-property-decorator';

import {
  ContextColorProvider,
  StatusMessage,
  ButtonPrimary,
  BaseText,
  Heading
} from '@warrenbrasil/nebraska-web';

import { PageHeader } from './components/PageHeader';
import { FirstAccessSections } from './components/FirstAccessSections';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    ContextColorProvider,
    FirstAccessSections,
    ButtonPrimary,
    StatusMessage,
    PageHeader,
    BaseText,
    Heading
  }
})
export default class CollateralMarginFirstAccess extends Vue {
  readonly NebraskaColors = NebraskaColors;
  isLoading = false;

  onBackClick() {
    this.$router.back();
  }

  redirectToAlocateCollateralMargin() {
    this.$router.push({ name: 'elegible-assets' });
  }
}
