
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  Heading,
  BaseText,
  BaseIcon,
  Footer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { AnnouncementContent } from '@/modules/authentication/views/FaceMatch/components/Announcement/page-content-by-context';

@Component({
  components: { Heading, BaseText, BaseIcon, Footer, ButtonPrimary }
})
export default class AnnouncementOnboarding extends Vue {
  @Emit('go-to-next-step')
  public goToNextStep() {}

  @Prop({ type: Object, required: true })
  readonly pageContent!: AnnouncementContent;

  readonly NebraskaColors = NebraskaColors;
}
