import { GtmTracker } from '@/modules/common/services/analytics';

const trackRegisterUploadPIIDataEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_upload_pii_data',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

function trackInsensitiveEvent(
  event_name: string,
  event_target: string,
  isClickEvent = false
) {
  trackRegisterUploadPIIDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    event_target,
    page_screen_section: 'form'
  });
}

export function trackFinishUploadDataEvent() {
  trackRegisterUploadPIIDataEvent({
    event_type: 'click',
    event_name: 'finish_upload_pii_data'
  });
}

export function trackPageView() {
  trackRegisterUploadPIIDataEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

type TrackEventType = {
  upload_pii_document: Function;
};

const trackEventName: TrackEventType = {
  upload_pii_document: (eventValue: string) =>
    trackInsensitiveEvent('upload_pii_document', eventValue, true)
};

export const createEventData = (eventKey: string, eventValue: string) => {
  trackEventName[eventKey as keyof TrackEventType](eventValue);
};
