import { ICustomer } from '@/types/models';
import {
  useDatadogRum,
  DatadogRumContext
} from '@warrenbrasil/web-observability/monitor';

export default class DatadogRum {
  public static context: DatadogRumContext | undefined;
  public static customer: ICustomer | null = null;

  public static init() {
    const DATADOG_RUM_APPLICATION_ID =
      process.env.VUE_APP_DATADOG_RUM_CLIENT_ID!;
    const appVersion =
      process.env.VUE_APP_WEB_PLATFORM_VERSION ||
      process.env.VUE_APP_COMMIT_SHA ||
      '?';
    const clientToken: string = process.env.VUE_APP_DATADOG_RUM_TOKEN!;
    const parsedEnvironment = process.env.VUE_APP_ENVIRONMENT || 'localhost';

    this.context = useDatadogRum({
      clientToken,
      applicationId: DATADOG_RUM_APPLICATION_ID,
      env: parsedEnvironment,
      service: 'web-platform',
      trackInteractions: true,
      trackFrustrations: true,
      sampleRate: 20,
      replaySampleRate: 1,
      version: appVersion,
      beforeSend: event => {
        event.view.url = event.view.url.replace('#/', '');
      },
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        {
          match: 'https://api.warren.com.br',
          propagatorTypes: ['tracecontext']
        },
        {
          match: 'https://bff.warren.com.br',
          propagatorTypes: ['tracecontext']
        },
        {
          match: 'https://graphql.warren.com.br',
          propagatorTypes: ['tracecontext']
        }
      ]
    });
  }

  public static setUser(customer: ICustomer) {
    try {
      if (this.context) {
        this.context.setUser({
          id: customer._id
        });
        return;
      }
      throw new Error();
    } catch {
      throw new Error('Occurred an error on setting user data');
    }
  }

  public static removeUser() {
    try {
      if (this.context) {
        this.context.removeUser();
        return;
      }
      throw new Error();
    } catch {
      throw new Error('Occurred an error on remove user data');
    }
  }
}
