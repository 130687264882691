
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  BaseText,
  HeadingDisplay,
  ButtonIconContextual
} from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/Money';
import { BalancesLoader } from './components/BalancesLoader';
import { BalancesError } from './components/BalancesError';
import { TransitModal } from '@/modules/common/components/TransitModal';
import { ReserveModal } from '@/modules/common/components/ReserveModal';
import {
  getBalances,
  IBalancesResponse
} from '@/modules/transaction/services/unified-account';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

@Component({
  components: {
    BaseText,
    Money,
    BalancesLoader,
    BalancesError,
    HeadingDisplay,
    ButtonIconContextual,
    TransitModal,
    ReserveModal
  }
})
export default class Balances extends Vue {
  @Emit('open-future-transactions')
  emitOpenFutureTransactions() {}

  isTransitModalOpen = false;
  isReserveModalOpen = false;

  isLoading = false;
  hasError = false;

  balances = {
    balanceLastUpdatedAt: '',
    accountAmount: '',
    reservedAmount: '',
    totalReservedAmount: '',
    totalReservedAmountDetail: '',
    totalTransitAmount: '',
    wealthProjectedAmount: '',
    tradeTotalValue: '',
    tradeValues: [{ label: '', value: '' }],
    isNegativeTransitAmount: false
  };

  get balanceLastUpdatedAtFormatted() {
    return this.balances.balanceLastUpdatedAt
      ? `Atualizado em ${this.balances.balanceLastUpdatedAt}`
      : '';
  }

  get isTransitAmountEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_TRANSIT_AMOUNT);
  }

  created() {
    this.getBalances();
  }

  async getBalances() {
    this.hasError = false;
    this.isLoading = true;
    try {
      const data = await getBalances();
      this.mountBalancesData(data);
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private mountBalancesData(data: IBalancesResponse) {
    this.balances = {
      accountAmount: data.accountAmount.formatted,
      reservedAmount: data.reservedAmount.formatted,
      totalReservedAmount: data.totalReservedAmount.amount.formatted,
      totalReservedAmountDetail:
        data.totalReservedAmount.details.reservedAmount.formatted,
      balanceLastUpdatedAt: data.balanceLastUpdatedAt.formatted || '',
      totalTransitAmount: data.totalTransitAmount.amount.formatted,
      wealthProjectedAmount:
        data.totalTransitAmount.details.wealth.projectedCreditAmount.formatted,
      tradeTotalValue:
        data.totalTransitAmount.details.trade.totalValue.formatted,
      tradeValues:
        data.totalTransitAmount.details.trade.items.map(item => {
          return {
            label: item.label,
            value: item.value.formatted
          };
        }) || [],
      isNegativeTransitAmount: data.totalTransitAmount.amount.value < 0 || false
    };
  }

  handleTransitModal() {
    this.isTransitModalOpen = !this.isTransitModalOpen;
  }

  handleReserveModal() {
    this.isReserveModalOpen = !this.isReserveModalOpen;
  }
}
