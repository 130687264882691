export enum DashStatusButtonActions {
  Search = 'SEARCH',
  Transaction = 'TRANSACTION',
  DepositConversation = 'DEPOSIT_CONVERSATION',
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  Earnings = 'EARNINGS',
  CollateralMargin = 'COLLATERAL_MARGIN',
  BrokerageNotes = 'BROKERAGE_NOTES'
}
