
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ListDisplay } from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/MoneyV2';

import {
  OrderContentFieldTypes,
  OrderContentField,
  ContentFieldValueFormatted
} from '@/modules/trade/services/orders';
import { getCurrencyTextColor } from '@/modules/common/helpers/currency';

@Component({
  components: {
    ListDisplay
  }
})
export default class OrderDetailsField extends Vue {
  @Prop({ type: Object, required: true })
  readonly field!: OrderContentField;

  get fieldProps() {
    return this.generateFieldProps(this.field.value, this.field.type);
  }

  get valueComponent() {
    if (this.field.type === OrderContentFieldTypes.hidableText) return Money;
    return 'p';
  }

  generateFieldProps(
    value: ContentFieldValueFormatted,
    type: OrderContentFieldTypes
  ) {
    switch (type) {
      case OrderContentFieldTypes.monetaryColoredText:
        return {
          valueColor: getCurrencyTextColor(value.value as number)
        };
      case OrderContentFieldTypes.chip:
        return {
          chipType: 'status',
          chipLabel: value.formatted,
          chipColor: value.value
        };
      default:
        return {};
    }
  }
}
