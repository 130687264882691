import {
  PaginationData,
  InstrumentImage,
  DateFormatted,
  NumberFormatted,
  StringFormatted,
  Formatted,
  ChipType,
  ChipFormatted,
  OrderStatus,
  OrderTypes,
  InstrumentType
} from '@/modules/trade/types';

export interface GetOrdersParams {
  itemsPerPage: number;
  page: number;
  date: string;
  status?: string;
  type?: string;
  symbol?: string;
}

export enum OrderDirection {
  Sell = 'Sell',
  Buy = 'Buy'
}

export interface OrderFormatted {
  value: OrderStatus;
  formatted: string;
}

export interface Order {
  id: string;
  description: string;
  type: Formatted<OrderTypes>;
  quantity: NumberFormatted;
  pricePerShare: NumberFormatted;
  movementTotalPrice: NumberFormatted;
  executedAt: DateFormatted;
  liquidatedAt: DateFormatted;
  status: Formatted<ChipType>;
  clickable: boolean;
  instrument: {
    name: string;
    symbol: string;
    type: Formatted<InstrumentType> | null;
    image: InstrumentImage | null;
  };
}

export enum OrdersStatusFilterOptions {
  All = 'All',
  Placing = 'Placing',
  Inactive = 'Inactive',
  Working = 'Working',
  Rejected = 'Rejected',
  Filled = 'Filled',
  PartiallyFilled = 'Partially_filled',
  Cancelled = 'Cancelled',
  Expired = 'Expired'
}

export interface InstrumentFilter {
  type: InstrumentType;
  symbol: string;
  description: string;
  image: InstrumentImage | null;
}

export interface InstrumentsFilter {
  selected: string;
  available: [];
}

export type FilterValue = string | 'All';

export type FilterFormatted = Formatted<FilterValue>;

export interface FilterOptions {
  selected: FilterFormatted | null;
  available: FilterFormatted[];
}

export interface OrdersFilters {
  instruments: InstrumentsFilter;
  status: FilterOptions;
  type: FilterOptions;
  dateFilter: FilterOptions;
}

export interface GetOrdersResponse {
  orders: Order[];
  filters: OrdersFilters;
  pagination: PaginationData;
}

export interface OrderSummary {
  id: string;
  description: string;
  movementTotalPrice: NumberFormatted;
  executedAt: DateFormatted;
  status: ChipFormatted;
  clickable: boolean;
}

export interface GetOrdersSummaryResponse {
  orders: OrderSummary[];
  pagination: PaginationData;
}

export interface GetOrdersSummaryParams {
  itemsPerPage: number;
  page: number;
  symbol?: string;
}

export enum OrderContentFieldTypes {
  chip = 'chip',
  text = 'text',
  monetaryColoredText = 'monetaryColoredText',
  hidableText = 'hidableText'
}

export type ContentFieldValueFormatted = NumberFormatted | ChipFormatted;

export interface OrderContentField {
  label: string;
  type: OrderContentFieldTypes;
  value: ContentFieldValueFormatted;
}

export interface OrderContent {
  fields: OrderContentField[];
  caption: string | null;
}

export interface OrderDetailsResponse {
  id: string;
  description: string | null;
  head: string;
  title: string;
  type?: StringFormatted;
  instrument: {
    symbol: string;
    type: Formatted<InstrumentType> | null;
    image: InstrumentImage | null;
  };
  content: OrderContent[];
}

export interface ChangePortabilityAveragePriceResponse {
  status: string;
  title: string;
  message: string;
}

export interface GetCustomerOrdersInstrumentsResponse {
  items: InstrumentFilter[];
  pagination: PaginationData;
}

export interface GetCustomerOrdersInstrumentsParams {
  query: string;
  page: number;
  itemsPerPage: number;
}
