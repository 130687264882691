
import { Component, Vue } from 'vue-property-decorator';
import { getString } from '@/modules/common/helpers/resources';
import { BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { BaseText }
})
export default class UserTermsLink extends Vue {
  private get termsLink() {
    return getString('common_link_terms_termsofuse');
  }

  private get termsText() {
    return getString('common_field_terms_docs_termsofuse');
  }
}
