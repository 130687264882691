
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTable } from '@warrenbrasil/nebraska-web';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { Earning } from '@/modules/trade/services/earnings';
import { EarningsTableStatus } from './types';
@Component({
  components: {
    DataTable,
    InstrumentNameCell
  }
})
export default class EarningsTable extends Vue {
  @Prop({ type: Array, required: true })
  readonly data!: Earning[];

  @Prop({ type: String, required: true })
  readonly status!: EarningsTableStatus;

  columns = [
    {
      align: 'left',
      component: InstrumentNameCell,
      getter: (earning: Earning) => ({
        symbol: earning.instrument.symbol,
        name: earning.instrument.name
      }),
      label: 'Ativo'
    },
    {
      align: 'right',
      getter: (earning: Earning) => earning.liquidationDate.formatted,
      label: 'Período'
    },
    {
      align: 'right',
      getter: 'description',
      label: 'Descrição'
    },
    {
      align: 'right',
      getter: 'quantity',
      label: 'Quantidade'
    },
    {
      align: 'right',
      getter: (earning: Earning) => earning.provisionado.formatted,
      label: 'Provisionado (R$)'
    },
    {
      align: 'right',
      getter: (earning: Earning) => earning.valorIr.formatted,
      label: 'Desconto IR (R$)'
    },
    {
      align: 'right',
      getter: (earning: Earning) => earning.valorLiq.formatted,
      label: 'Valor líquido (R$)'
    }
  ];
}
