import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IPortfolioSheetData,
  ICreatePortfolio,
  ITermsSignature
} from './variable-income-types';

export const investmentsHttpClient = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export const tradeHttpClient = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/trade/v1`
});

export function getPortfolioSheetData(
  id: string
): Promise<IPortfolioSheetData> {
  return investmentsHttpClient.get(`/v1/portfolio/variableIncome/${id}/sheet`);
}

export function createPortfolio(
  portfolioTemplateId: string,
  acceptanceTermId: string,
  amount = 0
): Promise<ICreatePortfolio> {
  return investmentsHttpClient.post(`/v1/portfolio/variableIncome`, {
    portfolioTemplateId,
    acceptanceTermId,
    amount
  });
}

export function termsSignature(instrumentId: string): Promise<ITermsSignature> {
  return tradeHttpClient.post(`/terms/signature`, {
    instrumentId
  });
}
