const mockEarnings = [
  {
    instrument: {
      name: 'Ambev S.A.',
      symbol: 'ABEV3',
      image: {
        small:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        medium:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        large:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png'
      }
    },
    description: 'description',
    quantity: 10,
    provisionado: {
      value: 10,
      formatted: 'R$ 10,00'
    },
    valorIr: {
      value: 12,
      formatted: 'R$ 12,00'
    },
    valorLiq: {
      value: 14,
      formatted: 'R$ 14,00'
    },
    liquidationDate: {
      value: '16',
      formatted: '02/05/22'
    }
  },
  {
    instrument: {
      name: 'Ambev S.A.',
      symbol: 'ABEV3',
      image: {
        small:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        medium:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        large:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png'
      }
    },
    description: 'description',
    quantity: 15,
    provisionado: {
      value: 13.99,
      formatted: 'R$ 13,99'
    },
    valorIr: {
      value: 2,
      formatted: 'R$ 2,00'
    },
    valorLiq: {
      value: 12.77,
      formatted: 'R$ 12,77'
    },
    liquidationDate: {
      value: '16',
      formatted: '02/06/22'
    }
  },
  {
    instrument: {
      name: 'Ambev S.A.',
      symbol: 'ABEV3',
      image: {
        small:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        medium:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png',
        large:
          'https://warren.com.br/assets/images/pages/trade/logos/ABEV-2x.png'
      }
    },
    description: 'description',
    quantity: 7,
    provisionado: {
      value: 15,
      formatted: 'R$ 15,00'
    },
    valorIr: {
      value: 1,
      formatted: 'R$ 1,00'
    },
    valorLiq: {
      value: 14,
      formatted: 'R$ 14,00'
    },
    liquidationDate: {
      value: '16',
      formatted: '02/07/22'
    }
  }
];

const mockEarningsChart = {
  totalReceived: {
    value: 10,
    formatted: 'R$ 10,00'
  },
  futureEarnings: {
    value: 10,
    formatted: 'R$ 12,99'
  },
  chart: {
    filters: [],
    axisX: [
      {
        key: 'key',
        description: 'description',
        values: [
          'Jav/22',
          'Feb/22',
          'Mar/22',
          'Abr/22',
          'Mai/22',
          'Jun/22',
          'Jul/22',
          'Ago/22',
          'Set/22',
          'Out/22',
          'Nov/22',
          'Dez/22'
        ]
      }
    ],
    axisY: [
      {
        key: 'Fiis',
        description: 'Fiis',
        values: [
          {
            value: 329.25,
            formatted: 'R$ 329.25'
          },
          {
            value: 278.12,
            formatted: 'R$ 278.12'
          },
          {
            value: 500,
            formatted: 'R$ 500'
          },
          {
            value: 400,
            formatted: 'R$ 400'
          },
          {
            value: 392,
            formatted: 'R$ 392'
          },
          {
            value: 329.25,
            formatted: 'R$ 329.25'
          },
          {
            value: 278.12,
            formatted: 'R$ 278.12'
          },
          {
            value: 500,
            formatted: 'R$ 500'
          },
          {
            value: 400,
            formatted: 'R$ 400'
          },
          {
            value: 392,
            formatted: 'R$ 392'
          },
          {
            value: 239,
            formatted: 'R$ 239'
          },
          {
            value: 100,
            formatted: 'R$ 100'
          }
        ]
      },
      {
        key: 'JSC',
        description: 'JSC',
        values: [
          {
            value: 135.38,
            formatted: 'R$ 135.38'
          },
          {
            value: 89.2,
            formatted: 'R$ 89.2'
          },
          {
            value: 117.2,
            formatted: 'R$ 117.2'
          },
          {
            value: 300.5,
            formatted: 'R$ 300.5'
          },
          {
            value: 204,
            formatted: 'R$ 204'
          },
          {
            value: 135.38,
            formatted: 'R$ 135.38'
          },
          {
            value: 89.2,
            formatted: 'R$ 89.2'
          },
          {
            value: 117.2,
            formatted: 'R$ 117.2'
          },
          {
            value: 300.5,
            formatted: 'R$ 300.5'
          },
          {
            value: 204,
            formatted: 'R$ 204'
          },
          {
            value: 320,
            formatted: 'R$ 320'
          },
          {
            value: 129,
            formatted: 'R$ 129'
          }
        ]
      },
      {
        key: 'Dividendos',
        description: 'Dividendos',
        values: [
          {
            value: 201.75,
            formatted: 'R$ 201.75'
          },
          {
            value: 103.4,
            formatted: 'R$ 103.4'
          },
          {
            value: 89.2,
            formatted: 'R$ 89.2'
          },
          {
            value: 200.2,
            formatted: 'R$ 200.2'
          },
          {
            value: 189,
            formatted: 'R$ 189'
          },
          {
            value: 201.75,
            formatted: 'R$ 201.75'
          },
          {
            value: 103.4,
            formatted: 'R$ 103.4'
          },
          {
            value: 89.2,
            formatted: 'R$ 89.2'
          },
          {
            value: 200.2,
            formatted: 'R$ 200.2'
          },
          {
            value: 189,
            formatted: 'R$ 189'
          },
          {
            value: 178,
            formatted: 'R$ 178'
          },
          {
            value: 120,
            formatted: 'R$ 120'
          }
        ]
      },
      {
        key: 'Bonificação',
        description: 'Bonificação',
        values: [
          {
            value: 21.75,
            formatted: 'R$ 21.75'
          },
          {
            value: 12,
            formatted: 'R$ 12'
          },
          {
            value: 4.4,
            formatted: 'R$ 4.4'
          },
          {
            value: 30.1,
            formatted: 'R$ 30.1'
          },
          {
            value: 3,
            formatted: 'R$ 3'
          },
          {
            value: 21.75,
            formatted: 'R$ 21.75'
          },
          {
            value: 12,
            formatted: 'R$ 12'
          },
          {
            value: 4.4,
            formatted: 'R$ 4.4'
          },
          {
            value: 30.1,
            formatted: 'R$ 30.1'
          },
          {
            value: 3,
            formatted: 'R$ 3'
          },
          {
            value: 0,
            formatted: 'R$ 0'
          },
          {
            value: 10,
            formatted: 'R$ 10'
          }
        ]
      }
    ]
  }
};

export const mockEarningsData = {
  mockEarnings,
  mockEarningsChart
};
