
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import {
  ConclusionStep,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { FaceMatchStatusType } from '../../types';
import { EStatusTypes } from '../../components/Processing/helpers/processingHelpers';
import * as trackEvents from './track-events';
import { PageContext } from '../Announcement/page-content-by-context';

@Component({
  components: {
    ConclusionStep,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class FaceMatchStatus extends Vue {
  @Prop({ required: true, type: Object })
  readonly faceMatchStatus!: FaceMatchStatusType;

  @Prop({ type: String, default: PageContext.DEFAULT })
  readonly context!: PageContext;

  readonly EStatusType = EStatusTypes;

  public get isOnboarding(): boolean {
    return this.context === PageContext.ONBOARDING;
  }

  public goHome() {
    const faceMatchStatusType = this.faceMatchStatus.statusType;
    const mapperFaceMatchStatusType: {
      [key in EStatusTypes]: () => void;
    } = {
      [EStatusTypes.error]: () => trackEvents.trackErrorBodyCloseClick(),
      [EStatusTypes.info]: () =>
        trackEvents.trackInfoBodyBackClick(this.isOnboarding),
      [EStatusTypes.success]: () =>
        trackEvents.trackSuccessBodyBackClick(this.isOnboarding),
      [EStatusTypes.noResponse]: () =>
        trackEvents.trackCompleteBodyBackClick(this.isOnboarding)
    };

    if (faceMatchStatusType) {
      mapperFaceMatchStatusType[faceMatchStatusType]();
    }

    this.$router.push({ name: 'home' });
  }

  public handleClick() {
    if (this.faceMatchStatus.statusType === EStatusTypes.error) {
      trackEvents.trackErrorTryAgainClick();
      this.nextStep();
    } else {
      this.goHome();
    }
  }

  mounted() {
    this.checkFaceMatchStatus();
  }

  private checkFaceMatchStatus() {
    const faceMatchStatusType = this.faceMatchStatus.statusType;
    const mapperFaceMatchStatusType: {
      [key in EStatusTypes]: () => void;
    } = {
      [EStatusTypes.error]: () => trackEvents.trackErrorLoad(),
      [EStatusTypes.info]: () => trackEvents.trackInfoLoad(this.isOnboarding),
      [EStatusTypes.success]: () =>
        trackEvents.trackSuccessLoad(this.isOnboarding),
      [EStatusTypes.noResponse]: () =>
        trackEvents.trackCompletedLoad(this.isOnboarding)
    };

    if (faceMatchStatusType) {
      mapperFaceMatchStatusType[faceMatchStatusType]();
    }
  }

  @Emit('next-step')
  private nextStep() {}
}
