
import { Component, Vue, Prop } from 'vue-property-decorator';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { DataTable } from '@warrenbrasil/nebraska-web';
import { StatusMargin } from './components/StatusMargin';
import { HistoryMarginTableStatus } from '@/modules/trade/views/CollateralMargin/types';
import { MarginHistory } from '@/modules/trade/services/collateral-margin';
import { TagMarginType } from './enums';

@Component({
  components: {
    InstrumentNameCell,
    DataTable
  }
})
export default class HistoryTable extends Vue {
  @Prop({ type: String, required: true })
  readonly status!: HistoryMarginTableStatus;

  @Prop({ type: Array, required: true })
  readonly data!: MarginHistory[];

  columns = [
    {
      align: 'left',
      component: InstrumentNameCell,
      getter: (marginHistory: MarginHistory) => ({
        symbol: marginHistory.origin?.value,
        name: marginHistory.origin?.description
      }),
      label: 'Origem'
    },
    {
      align: 'left',
      component: InstrumentNameCell,
      getter: (marginHistory: MarginHistory) => ({
        symbol: marginHistory.date?.value,
        name: marginHistory.date?.description
      }),
      label: 'Data'
    },
    {
      align: 'right',
      getter: (marginHistory: MarginHistory) => marginHistory.quantity?.value,
      label: 'Quantidade'
    },
    {
      align: 'right',
      getter: (marginHistory: MarginHistory) => marginHistory.total?.value,
      label: 'Valor total'
    },
    {
      align: 'right',
      component: StatusMargin,
      getter: (marginHistory: MarginHistory) => ({
        status: this.getStatusMarginHistory(marginHistory),
        text: marginHistory.status?.value
      }),
      label: 'Status'
    },
    {
      type: 'button',
      getter: (marginHistory: MarginHistory) => ({
        icon: 'ic_chevron_right',
        size: '26px',
        label: marginHistory.origin?.description,
        showButton: true
      })
    }
  ];

  redirectToMargimEvent(marginHistory: MarginHistory) {
    console.log('Ir para a pagina dessa margin', marginHistory);
    // TO DO REMOVE THIS LINE WHEN THE REDIRECT IS DONE
  }

  getStatusMarginHistory(marginHistory: MarginHistory): TagMarginType {
    switch (marginHistory.status?.value) {
      case 'alocated':
        return TagMarginType.success;
      case 'failed':
        return TagMarginType.alert;
      case 'requested':
        return TagMarginType.info;
      default:
        return TagMarginType.info;
    }
  }
}
