import { object, string } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { isString } from '@/modules/common/helpers/string';

function iValidCnpj(cnpj: string) {
  return cnpj.length === 14;
}

const schema = object().shape({
  personalProfile: object().shape({
    nationality: string()
      .test({
        test: isString,
        message: 'Nacionalidade é obrigatória.'
      })
      .required('Nacionalidade é obrigatória.'),

    birthState: string()
      .test({
        test: isString,
        message: 'Estado onde nasceu é obrigatório.'
      })
      .required('Estado onde nasceu é obrigatório.'),

    birthCity: string()
      .test({
        test: isString,
        message: 'Informe o nome da cidade onde nasceu.'
      })
      .required('Informe o nome da cidade onde nasceu.'),

    education: string()
      .test({
        test: isString,
        message: 'Escolaridade é obrigatória.'
      })
      .required('Escolaridade é obrigatória.'),

    profession: string()
      .test({
        test: isString,
        message: 'Informe uma ocupação válida.'
      })
      .required('Por favor, selecione sua ocupação.'),

    workOrganization: string()
      .nullable()
      .when(['hasProfession'], {
        is: (hasProfession: boolean) => hasProfession,
        then: string()
          .test({
            test: isString,
            message: 'Informe uma instituição válida.'
          })
          .required('Informe a instuição que você trabalha.'),
        otherwise: string()
      }),

    workOrganizationCnpj: string()
      .nullable()
      .when(['hasProfession'], {
        is: (hasProfession: boolean) => hasProfession,
        then: string()
          .test({
            test: iValidCnpj,
            message: 'Informe um CNPJ válido.'
          })
          .required('Informe o CNPJ da instuição que você trabalha.'),
        otherwise: string()
      })
  })
});

export default new YupValidation(schema);
