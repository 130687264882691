
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  TwoColumnsContainer,
  TwoColumnsContainerLoader
} from '@/modules/common/components/TwoColumnsContainer';
import { AccountStateCard } from '@/modules/common/components/cards/AccountStateCard';
import { Feature } from '@/modules/common/components/Feature';
import { Performance } from './components/Performance';
import { PerformanceChart } from '@/modules/wealth/components/PerformanceChart';
import { WealthContent } from './components/WealthContent';
import { InvestmentTransactionTutorial } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionTutorial';
import { WealthChartAction } from '@/modules/wealth/components/WealthChartAction';
import { ContextColorProvider } from '@warrenbrasil/nebraska-web';
import { FeatureSurvey } from '@/modules/common/components/FeatureSurvey';
import { WealthViewActions } from './components/WealthViewActions';
import { WealthHeading } from '@/modules/wealth/components/WealthHeading';

import { IPortfolioList } from '@/types/models/Portfolio';
import { Categories } from '@/types/models/Analytics';

import {
  IDashStatusCardApi,
  IProfitValue
} from '@/modules/wealth/services/investment/investment-types';

import { WarrenModules } from '@/types/navigation';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { PortfolioModule } from '@/store/modules/PortfolioModule';
import { isSuitabilityPending } from '@/router/helpers/shouldRedirectToSuitability';
import { RegulatoryUpdateWarning } from '@/modules/common/components/RegulatoryUpdateWarning';
import { getDashStatus, getCustomerBalance } from '../../services/investment';

import {
  trackCreateNewPortfolio,
  trackDashStatusButtonClick,
  trackGoToInvestmentFlow,
  trackLoadPortfolios
} from './track-events';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    AccountStateCard,
    TwoColumnsContainer,
    TwoColumnsContainerLoader,
    ContextColorProvider,
    WealthContent,
    WealthChartAction,
    Feature,
    InvestmentTransactionTutorial,
    FeatureSurvey,
    WealthViewActions,
    RegulatoryUpdateWarning,
    WealthHeading,
    Performance,
    PerformanceChart
  }
})
export default class WealthView extends Vue {
  private isLoading = true;
  private hasBalanceError = false;
  private balanceLoading = true;

  customerBalance = {
    totalBalanceFormatted: '',
    profitValues: [] as IProfitValue[],
    hasHistoryCut: false,
    historyCutFormatted: '',
    totalBalanceUpdatedAtFormatted: ''
  };

  private dashStatus?: IDashStatusCardApi = undefined;

  private Categories = Categories;

  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isOverdue!: boolean;

  public readonly WarrenModules = WarrenModules;

  public readonly isSuitabilityPending = isSuitabilityPending();

  private get portfolios(): IPortfolioList[] {
    return PortfolioModule.portfolios;
  }

  private async beforeMount() {
    await this.loadDashStatus();

    await Promise.all([this.fetchUserPortfolios(), this.setBalances()]);

    this.isLoading = false;
    trackLoadPortfolios();
  }

  private async loadDashStatus() {
    try {
      const { wealthStatus } = await getDashStatus();
      this.dashStatus = wealthStatus?.card as IDashStatusCardApi;
    } catch (error) {
      console.error(error);
    }
  }

  private fetchUserPortfolios() {
    PortfolioModule.fetchPortfolios();
  }

  private async setBalances() {
    try {
      this.balanceLoading = true;
      this.hasBalanceError = false;

      const { customerBalance } = await this.getCustomerBalance();
      this.customerBalance = {
        totalBalanceFormatted: customerBalance.totalGrossWorth.formatted,
        profitValues: customerBalance.profitValues,
        hasHistoryCut: customerBalance.hasHistoryCut,
        historyCutFormatted: customerBalance.historyCutDate?.formatted || '',
        totalBalanceUpdatedAtFormatted:
          customerBalance.totalBalanceUpdatedAtFormatted
      };
    } catch (error) {
      this.hasBalanceError = true;
    } finally {
      this.balanceLoading = false;
    }
  }

  private async getCustomerBalance(reset = true) {
    return await getCustomerBalance({
      isReset: reset
    });
  }

  private goToWalletCreate() {
    trackCreateNewPortfolio();

    this.$router.push({ name: 'catalog' });
  }

  private goToInvestmentFlow() {
    trackGoToInvestmentFlow();
  }

  private get FeatureFlags() {
    return FeatureFlags;
  }

  public get showCreateWalletButton() {
    return (
      !this.isSuitabilityPending &&
      this.dashStatus?.button?.toLowerCase() !== 'criar carteira'
    );
  }

  private handleDashButtonsEvents(dashStatusAction?: string | null) {
    return trackDashStatusButtonClick(dashStatusAction);
  }
}
