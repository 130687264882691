
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ListDisplay,
  ContentDisplay,
  BaseText,
  ProgressStep,
  HeadingDisplay,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { IOrderReceipt } from '@/modules/wealth/services/wealth-orders/types';
import { IStep } from '../../types';

@Component({
  components: {
    ListDisplay,
    ContentDisplay,
    BaseText,
    ProgressStep,
    HeadingDisplay,
    InitialAvatarIcon
  }
})
export default class InvestmentSteps extends Vue {
  @Prop({ type: Object, required: true }) orderData!: IOrderReceipt;
  @Prop({ type: Array, default: () => [] }) progress!: IStep[];

  get isCanceled() {
    return this.orderData?.status?.label === 'Cancelado';
  }

  get isSchedule() {
    return this.orderData?.status?.label === 'Agendado';
  }
}
