import { GtmTracker } from '@/modules/common/services/analytics';

const trackRegisterAddressDataEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_address_data',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

function trackSensitiveEvent(event_name: string, isClickEvent = false) {
  trackRegisterAddressDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    page_screen_section: 'form'
  });
}

export function trackFinishAddressDataEvent() {
  trackRegisterAddressDataEvent({
    event_type: 'click',
    event_name: 'finish_address_data'
  });
}

export function trackPageView() {
  trackRegisterAddressDataEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

type TrackEventType = {
  newzipcode: Function;
  street: Function;
  number: Function;
  complement: Function;
  neighborhood: Function;
  city: Function;
  state: Function;
};

const trackEventName: TrackEventType = {
  newzipcode: () => trackSensitiveEvent('fill_cep'),
  street: () => trackSensitiveEvent('fill_address'),
  number: () => trackSensitiveEvent('fill_address_number'),
  complement: () => trackSensitiveEvent('fill_address_complement'),
  neighborhood: () => trackSensitiveEvent('fill_neighborhood'),
  city: () => trackSensitiveEvent('fill_city'),
  state: () => trackSensitiveEvent('select_state', true)
};

export const createEventData = (eventKey: string) => {
  trackEventName[eventKey as keyof TrackEventType]();
};
