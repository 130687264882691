import { GtmTracker } from '@/modules/common/services/analytics';
import { IDocument } from '../types';

const trackRegisterPIIDataEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_pii_data',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

function trackInsensitiveEvent(
  event_name: string,
  event_target: string,
  isClickEvent = false
) {
  trackRegisterPIIDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    event_target,
    page_screen_section: 'form'
  });
}

function trackSensitiveEvent(event_name: string, isClickEvent = false) {
  trackRegisterPIIDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    page_screen_section: 'form'
  });
}

export function trackFinishPIIDataEvent() {
  trackRegisterPIIDataEvent({
    event_type: 'click',
    event_name: 'finish_register_pii_data'
  });
}

export function trackPageView() {
  trackRegisterPIIDataEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

type TrackEventType = {
  number: Function;
  issueDate: Function;
  type: Function;
  acceptedTerms: Function;
  issuedBy: Function;
  state: Function;
};

const trackEventName: TrackEventType = {
  number: () => trackSensitiveEvent('fill_document_number'),
  issueDate: () => trackSensitiveEvent('fill_document_issue_date'),
  type: (eventValue: string) =>
    trackInsensitiveEvent('select_document', eventValue, true),
  acceptedTerms: () => trackSensitiveEvent('agree', true),
  issuedBy: () =>
    trackSensitiveEvent('select_document_issue_institution', true),
  state: () => trackSensitiveEvent('select_document_issue_state', true)
};

export const createEventData = (
  eventKey: string,
  documentData?: Partial<IDocument>
) => {
  const eventFunction = trackEventName[eventKey as keyof TrackEventType];

  return eventFunction(
    documentData ? documentData[eventKey as keyof IDocument] : ''
  );
};
