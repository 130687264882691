import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackHeaderBackClickFromUnderAnalysis(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'data_on_analises'
      : 'data_on_analises_onboarding',
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'header'
  });
}

export function trackHeaderBackClickFromRegisteredData(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'document_selfie'
      : 'document_selfie_onboarding',
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'header'
  });
}

export function trackHeaderBackClickFromAnnouncement(isOnboarding: boolean) {
  trackAmplitudeEvent({
    page_screen: !isOnboarding
      ? 'document_security'
      : 'document_security_onboarding',
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'header'
  });
}
