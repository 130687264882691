import { DashStatusCard } from '@/modules/common/services/graphql/server/server.sdk';
import {
  trackAmplitudeEvent,
  trackOnboardingAmplitudeEvent
} from '@/modules/home/helpers/track-amplitude-event';

export function trackHomePageView() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackDashStatusButtonClick(dashStatusCard: DashStatusCard) {
  if (!dashStatusCard.action) return;

  const eventAction = dashStatusCard.action as keyof DashStatusCard['action'];

  const dashActionsEvents = {
    start_register: 'insert_data_register',
    verify_email: 'resend_email',
    start_suitability: 'discover_profile'
  };

  trackOnboardingAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: dashActionsEvents[eventAction]
  });
}

export function trackWorthErrorLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view_error_worth'
  });
}

export function trackReloadWorthErrorClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'hero',
    event_name: 'try_again_worth',
    event_target: 'Tentar novamente'
  });
}
