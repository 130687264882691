export interface IPortfolioSheet {
  header: IHeader;
  benefits: IBenefits;
  allocation: IAllocation;
  about: IAbout;
  riskProfile: IRiskProfile;
}

export interface IHeader {
  isManagedByWarren: boolean;
  title: string;
  description: string;
  image: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
}

export interface IBenefits {
  title: string;
  rows: {
    icon: string;
    title: string;
    description: string;
  }[];
}

export interface IAllocation {
  title: string;
  hoverInformation: {
    title: string;
    subtitle: string;
  };
  sections: IAllocationSection[];
}

export interface IAllocationSection {
  name: string;
  color: string;
  percentageAllocatedOnPortfolio: {
    value: number;
    formatted: string;
  };
  hoverInformation: {
    title: string;
    subtitle: string;
  };
  items: IAllocationSectionItem[];
}

export interface IAllocationSectionItem {
  title: string;
  subtitle: string;
  risk: string;
  weight: string;
}

export interface IAbout {
  title: string;
  rows: IAboutRow[];
}

export interface IAboutRow {
  label: string;
  description?: string;
  value: string;
}

enum ERiskTypes {
  Passive = 'Passive',
  PassiveModerated = 'PassiveModerated',
  Moderated = 'Moderated',
  AggressiveModerated = 'AggressiveModerated',
  Aggressive = 'Aggressive',
  Aggressive2 = 'Aggressive2'
}

export interface IRiskProfile {
  portfolioRiskProfile: {
    value: ERiskTypes;
    formatted: string;
    description: string;
  };
  buttonLabel: string;
}

export interface IPeriod {
  askForInvestorType: boolean;
  period: {
    value: string;
    formatted: string;
  };
}

export interface IRisk {
  apiId: string;
  drawdown: {
    formatted: string;
    value: number;
    summary: IRiskSummary;
  };
  expectedReturn: {
    value: {
      performanceFixedRate: number;
      marketIndex: string;
    };
    formatted: string;
    summary: IRiskSummary;
  };
  liquidity: {
    value: number;
    formatted: string;
    summary: IRiskSummary;
  };
  recommended: boolean;
  risk: {
    value: number;
    formatted: string;
  };
  volatility: {
    value: {
      minimumVolatility: number;
      maximumVolatility: number;
    };
    formatted: string;
    summary: IRiskSummary;
  };
}

export interface IRiskSummary {
  description: string;
  label: string;
  details: ISummaryDetail[] | null;
}

export interface ISummaryDetail {
  fontStyle: string;
  label: string;
  value: string;
}

export interface ICreatePortfolio {
  sheetCreate: {
    title: string;
    portfolioInfo: {
      apiId: string;
      title: string;
      items: {
        label: string;
        value: string;
      }[];
      buttonLabel: string;
    };
  };
}

export interface IInvestmentProfile {
  value: string;
  formatted: string;
}
