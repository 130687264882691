
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { getRegulatoryUpdateAnnouncementContent } from '../../services';
import {
  CustomerType,
  RegulatoryUpdateAnnouncementContentPF
} from '../../types';

import { RegulatoryUpdateAnnouncementPF } from '../../components/RegulatoryUpdateAnnouncementPF';
import { RegulatoryUpdateAnnouncementPJ } from '../../components/RegulatoryUpdateAnnouncementPJ';
import { trackEvent, trackStart, trackView } from './track-events';
import { EventKnownProperties } from '@warrenbrasil/web-analytics';

@Component({
  components: {
    RegulatoryUpdateAnnouncementPF,
    RegulatoryUpdateAnnouncementPJ
  }
})
export default class RegulatoryUpdateAnnouncement extends Vue {
  @Prop({ type: Boolean, required: true })
  public showContent!: boolean;

  @Emit()
  private setIsLoading(_isLoading: boolean) {}

  @Emit()
  private setHasError(_hasError: boolean) {}

  @Emit()
  private removeConfirmClose() {}

  @Emit()
  public goToNextPage() {
    trackStart();
  }

  @Emit()
  private changeTracker(
    _newTracker: (remainingProps: EventKnownProperties) => void
  ) {}

  private customerType?: CustomerType;

  public screenContent: RegulatoryUpdateAnnouncementContentPF | undefined;

  mounted() {
    this.fetchScreenContent();
    this.changeTracker(trackEvent);
  }

  public async fetchScreenContent() {
    this.setIsLoading(true);
    this.setHasError(false);

    try {
      const { customerType, content } =
        await getRegulatoryUpdateAnnouncementContent();

      trackView();

      this.customerType = customerType;
      this.screenContent = content;

      if (!this.isCustomerPF) {
        this.removeConfirmClose();
      }
    } catch (e) {
      this.setHasError(true);
    }

    this.setIsLoading(false);
  }

  public get isCustomerPF() {
    return this.customerType === CustomerType.PF;
  }
}
