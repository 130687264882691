import { GtmTracker } from '@/modules/common/services/analytics';
import { IPersonalProfile } from '@/types/models';

const trackRegisterAdditionalDataEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_additional_data',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

function trackInsensitiveEvent(
  event_name: string,
  event_target: string,
  isClickEvent = false
) {
  trackRegisterAdditionalDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    event_target,
    page_screen_section: 'form'
  });
}

function trackSensitiveEvent(event_name: string, isClickEvent = false) {
  trackRegisterAdditionalDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    page_screen_section: 'form'
  });
}

export function trackFinishAdditionalDataEvent() {
  trackRegisterAdditionalDataEvent({
    event_type: 'click',
    event_name: 'finish_additional_data'
  });
}

export function trackPageView() {
  trackRegisterAdditionalDataEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

type TrackEventType = {
  birthCity: Function;
  hasProfession: Function;
  profession: Function;
  workOrganization: Function;
  birthState: Function;
  nationality: Function;
};

const trackEventName: TrackEventType = {
  birthCity: () => trackSensitiveEvent('fill_city_from'),
  hasProfession: () => trackSensitiveEvent('select_employed', true),
  profession: (eventValue: string) =>
    trackInsensitiveEvent('select_occupation', eventValue),
  workOrganization: (eventValue: string) =>
    trackInsensitiveEvent('fill_company_name', eventValue),
  birthState: (eventValue: string) =>
    trackInsensitiveEvent('select_state_from', eventValue, true),
  nationality: (eventValue: string) =>
    trackInsensitiveEvent('select_nationality', eventValue, true)
};

export const createEventData = (
  eventKey: string,
  personalProfile?: IPersonalProfile
) => {
  const eventFunction = trackEventName[eventKey as keyof TrackEventType];

  return eventFunction(
    personalProfile ? personalProfile[eventKey as keyof IPersonalProfile] : ''
  );
};
