import { render, staticRenderFns } from "./InvestmentSteps.vue?vue&type=template&id=1f57ffc5&scoped=true&"
import script from "./InvestmentSteps.vue?vue&type=script&lang=ts&"
export * from "./InvestmentSteps.vue?vue&type=script&lang=ts&"
import style0 from "./InvestmentSteps.vue?vue&type=style&index=0&id=1f57ffc5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f57ffc5",
  null
  
)

export default component.exports