
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseIcon, NavBar, Tab } from '@warrenbrasil/nebraska-web';
import { MenuItem } from './types';

@Component({
  components: {
    BaseIcon,
    NavBar,
    Tab
  }
})
export default class NavbarMenu extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isTransparent?: boolean;

  @Prop({ type: Array, required: true })
  readonly menu!: MenuItem[];

  isMenuItemActive(item: MenuItem) {
    return this.$route.matched.some(matched => matched.name === item.to);
  }

  getMenuItemHref(item: MenuItem) {
    return `#/${item.to}`;
  }

  getIcon(item: MenuItem) {
    return this.isMenuItemActive(item)
      ? item.icons.active
      : item.icons.inactive;
  }

  changeTab(item: MenuItem) {
    this.$emit('tab-select', item.label);
    this.$router.push({ name: item.to });
  }
}
