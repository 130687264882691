import { httpClient } from '@/modules/common/services/http/http-client';
import { ICatalog } from './catalog-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/portfolios/v1`
});

export function getCatalog(): Promise<ICatalog> {
  return http.get(`/catalog`);
}
