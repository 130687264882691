import { IBrand } from '@/modules/common/services/local-storage';
import { ICustomer, IPartnerInfo } from '@/types/models/Customer';

export interface ILoginResponse {
  accessToken: string;
  partnerInfos: IPartnerInfo;
  user: ICustomer;
  brand: IBrand | null;
  isBrandOwner: boolean;
}

export type GetThemeByParamType = 'email' | 'domain';

export interface ICheckSetupTokenResponse {
  email: string;
  isWhiteLabel: boolean;
}

export interface ICommitSetupTokenResponse {
  user: ICustomer;
  accessToken: string;
  refreshToken: string;
}

export interface InstantLoginResponse {
  user: ICustomer;
  brand: IBrand | null;
  accessToken: string;
  refreshToken: string;
}

export interface WootricResponse {
  age: number;
  apiId: string;
  name: string;
  email: string;
  profession: string;
  firstDepositAt: string;
  birthDate: string;
  ageRange: string;
  sex: string;
  state: string;
  totalBalance: number;
  totalBalanceRange: string;
  patrimony: number;
  patrimonyRange: string;
  computedProfileType: string;
  enableNPSSurvey: boolean;
}

export interface IVerifyEmailTokenRequest {
  hash: string;
  step: string;
}

export interface ICheckResetTokenRequest {
  hash: string;
}

export interface ICommitResetTokenRequest {
  hash: string;
  password: string;
}

export interface IUnblockTokenRequest {
  hash: string;
  type: string;
}

export interface ICheckSetupTokenRequest {
  hash: string;
}

export interface ICommitSetupTokenRequest {
  hash: string;
  password: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
  token?: string;
}

export interface IChatToken {
  token: string;
}

export interface ResetPasswordRequest {
  email: string;
  token?: string;
}

export interface InstantLoginRequest {
  hash: string;
}

export interface MembershipTermsResponse {
  title: string;
  description: string;
  contracts: Contracts[];
}

interface Contracts {
  code: string;
  title: string;
  description: string;
  action: Actions;
}

interface Actions {
  title: string;
  url: string;
}

export enum RegulatoryUpdateStatus {
  BLOCKED = 'blocked',
  UP_TO_DATE = 'up_to_date',
  ABOUT_TO_BE_BLOCKED = 'about_to_be_blocked'
}

export interface RegulatoryUpdateStatusResponse {
  status: RegulatoryUpdateStatus;
}

export interface DepositInformation {
  type: string;
  bank: string;
  agency: string;
  account: string;
}

export interface GetCustomerAccountStatusResponse {
  clientEnabled: boolean;
  depositInformation: DepositInformation;
}
