import {
  InstrumentImage,
  InstrumentTypeFormatted,
  StringFormatted,
  NumberFormatted
} from '@/modules/trade/types';

export enum CustomOperationStatus {
  Settlement = 'Settlement',
  Progress = 'Progress'
}

export interface CustomOperationStatusFormatted {
  value: CustomOperationStatus;
  formatted: string;
}

export interface Instrument {
  symbol: string;
  image: InstrumentImage | null;
  type: InstrumentTypeFormatted | null;
}

export interface CustomOperationSummary {
  id: string;
  instrument: Instrument;
  strategy: StringFormatted;
  totalEarnings: NumberFormatted;
  totalFinancialResult: NumberFormatted;
  quantity: NumberFormatted;
  status: CustomOperationStatusFormatted;
  dueDate: StringFormatted;
}

export interface GetCustomOperationsSummaryResponse {
  operations: CustomOperationSummary[];
}

export interface StrikeOperation {
  label: string;
  value: NumberFormatted;
}

export interface MonitoringBarrier {
  type: string;
  level: NumberFormatted;
  distance: NumberFormatted;
}

export interface CustomOperation {
  id: string;
  instrument: Instrument;
  strategy: StringFormatted;
  countdownInDays: StringFormatted;
  datePeriod: StringFormatted;
  initialDate: StringFormatted;
  dueDate: StringFormatted;
  countdownPercentage: NumberFormatted;
  initialPrice: NumberFormatted;
  quantity: NumberFormatted;
  lastPrice: NumberFormatted;
  instrumentFinancialVariation: NumberFormatted;
  operationFinancialVariation: NumberFormatted;
  dividends: NumberFormatted;
  totalEarnings: NumberFormatted;
  status: CustomOperationStatusFormatted;
  strikeOptions?: StrikeOperation[];
  monitoring?: MonitoringBarrier[];
}
