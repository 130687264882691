import { ICustomer } from '@/types/models';

export enum SuitabilityQuestionId {
  Name = 'question_name',
  Age = 'question_age',
  Income = 'question_income',
  Occupation = 'question_occupation',
  Earnings = 'question_earnings',
  TotalInvested = 'question_total_invested',
  Invest = 'question_invest',
  InvestmentTime = 'question_investment_time',
  Administrate = 'question_administrate',
  Education = 'question_education',
  Economy = 'question_economy',
  Product = 'question_product',
  Autonomy = 'question_autonomy',
  Preference = 'question_preference',
  Planning = 'question_planning',
  Motivation = 'question_motivation',
  Deposits = 'question_deposits',
  Assets = 'question_assets',
  Context = 'question_context',
  Interest = 'question_interest',
  Email = 'question_email',
  EmailTaken = 'email_already_taken',
  InvitedWelcome2 = 'initial_invited_welcome_2',
  Password = 'question_password'
}

export interface IBrandConversationDataInformation {
  brandReferral: string;
  brandNameInitial: string;
  brandDomain?: string;
}

export interface IConversationDataSuitability {
  id: string;
  answers: IConversationDataAnswersSuitability;
  context: string;
  subcontext: string;
  fullpath?: string;
  referralId?: string;
  isAffiliate?: true;
  isInvited?: string;
  forceNew?: string;
}

export interface ISuitabilityUserCreated {
  accessToken: string;
  refreshToken: string;
  sameProfileCounter: number;
  suitability: ISuitability;
  user: ICustomer;
}

enum ERiskProfile {
  PASSIVE = 'PASSIVE',
  MODERATED = 'MODERATED',
  AGGRESSIVE = 'AGGRESSIVE'
}

export interface ISuitabilityCreated {
  _id: string;
  customer: string;
  updatedAt: Date;
  version: string;
  question_interest: string;
  question_learning: string;
  question_market: string;
  question_preference: string;
  question_read: string;
  question_spend: string;
  question_advice: string;
  question_isretired: string;
  question_lottery: string;
  question_investment_experience: string;
  question_invest: string;
  question_product: Array<string>;
  question_investment_time: string;
  question_after_invest: string;
  question_autonomy: string;
  question_context: string;
  question_motivation: string;
  question_planning: string;
  question_economy: string;
  question_investment_duration: string;
  question_frequency: string;
  question_tolerance: string;
  computedRiskTolerance: string;
  computedRiskToleranceString: ERiskProfile;
}

interface IConversationDataAnswersSuitability {
  question_name?: string;
  question_age?: string;
  question_income?: string;
  question_occupation?: string;
  question_earnings?: string;
  question_total_invested?: string;
  question_education?: string;
  question_invest?: string;
  question_administrate?: string;
  question_economy?: string;
  question_product?: string;
  question_investment_time?: string;
  question_autonomy?: string;
  question_preference?: string;
  question_planning?: string;
  question_deposits?: string;
  question_assets?: string;
  question_motivation?: string;
  question_context?: string;
  question_interest?: string;
  question_email?: string;
  email_already_taken?: string;
  initial_invited_welcome_2?: string;
}

export interface ISuitability {
  customer?: string;
  question_name?: string;
  question_income?: number;
  question_age?: number;
  question_interest?: Foo;
  question_learning?: Foo;
  question_market?: Foo;
  question_preference?: Foo;
  question_read?: Foo;
  question_spend?: Foo;
  question_advice?: Foo;
  question_isretired?: Foo;
  question_lottery?: Foo;
  question_investment_experience?: Foo;
  question_investments?: Foo;
  // Computed
  computedRiskTolerance?: number;
  computedProfileType?: string;
}

export enum Foo {
  Unknown = 'unknown',
  Active = 'active',
  Passive = 'passive'
}
