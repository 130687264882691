import store from '@/store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {
  ConversationAPI,
  IConversationQuestion
} from '@/modules/conversation/services/conversation';
import { IConversationDataSuitability } from '../types';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'SuitabilityModule',
  store
})
export class SuitabilityState extends VuexModule {
  public conversationAnswer: IConversationDataSuitability = {
    id: '',
    answers: {},
    context: 'suitability',
    subcontext: 'suitability_regulated'
  };

  public conversationQuestion: Partial<IConversationQuestion> = {};

  @Mutation
  public setConversationData(value: IConversationDataSuitability) {
    this.conversationAnswer = value;
  }

  @Mutation
  public setConversationQuestion(value: IConversationQuestion) {
    this.conversationQuestion = value;
  }

  @Action({ rawError: true })
  public async fetchNextQuestion(
    conversationData: IConversationDataSuitability
  ): Promise<IConversationQuestion> {
    const conversationAPI = new ConversationAPI();
    const response = await conversationAPI.getConversationQuestion(
      conversationData
    );

    this.context.commit('setConversationData', conversationData);
    this.context.commit('setConversationQuestion', response);

    return response;
  }
}
