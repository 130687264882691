
import { Component, Vue } from 'vue-property-decorator';

import {
  ContextColorProvider,
  ButtonPrimary,
  Heading
} from '@warrenbrasil/nebraska-web';
import {
  getCollateralMarginSummary,
  checkCollateralMarginFirstAccess,
  MarginsData,
  Margins
} from '@/modules/trade/services/collateral-margin';
import { MarginHeader } from './components/MarginHeader';
import { HistoryTable } from './components/HistoryTable';
import { PageHeader } from './components/PageHeader';
import { HistoryMarginTableStatus } from './types';

@Component({
  components: {
    ContextColorProvider,
    ButtonPrimary,
    MarginHeader,
    HistoryTable,
    PageHeader,
    Heading
  }
})
export default class CollateralMargin extends Vue {
  status: HistoryMarginTableStatus = HistoryMarginTableStatus.Loading;
  HistoryMarginTableStatus = HistoryMarginTableStatus;
  data: MarginsData = { margins: {} as Margins, history: [] } as MarginsData;

  get totalAllocatedMarginFormatted() {
    return this.data.margins?.totalAllocated?.data?.formatted ?? '';
  }

  get totalMarginInUseFormatted() {
    return this.data.margins?.inUsed?.data?.formatted ?? '';
  }

  get totalAvailableMarginFormatted() {
    return this.data.margins?.available?.data?.formatted ?? '';
  }

  get isLoading() {
    return this.status === HistoryMarginTableStatus.Loading;
  }

  created() {
    this.checkCollateralMarginFirstAccess();
    this.fetchCollateralMarginSummary();
  }

  onBackClick() {
    this.$router.back();
  }

  allocateCollateralMargin() {
    this.$router.push({
      name: 'elegible-assets'
    });
  }

  private async checkCollateralMarginFirstAccess() {
    const isFirstAccess = await checkCollateralMarginFirstAccess();
    if (isFirstAccess) {
      this.$router.push({
        name: 'first-access-collateral-margin'
      });
    }
  }

  private async fetchCollateralMarginSummary() {
    this.status = HistoryMarginTableStatus.Loading;
    try {
      this.data = await getCollateralMarginSummary();
    } catch (error) {
      this.status = HistoryMarginTableStatus.Error;
    } finally {
      this.status = HistoryMarginTableStatus.Default;
    }
  }
}
