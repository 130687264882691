import { object, string } from 'yup';
import { isString } from '@/modules/common/helpers/string';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

const schema = object().shape({
  address: object().shape({
    zipcode: string()
      .test({
        test: isString,
        message: 'Informe um CEP válido.'
      })
      .required('Por favor, informe seu CEP corretamente.'),

    street: string()
      .test({
        test: isString,
        message: 'Insira o seu endereço completo com rua e bairro.'
      })
      .required('Insira o seu endereço completo com rua e bairro.'),

    number: string()
      .test({
        test: isString,
        message: 'Insira o número da residência.'
      })
      .required('Insira o número da residência.'),

    neighborhood: string()
      .test({
        test: isString,
        message: 'Insira o bairro aonde você reside.'
      })
      .required('Insira o bairro aonde você reside.'),

    city: string()
      .test({
        test: isString,
        message: 'Insira a cidade que você reside.'
      })
      .required('Insira a cidade que você reside.'),

    state: string()
      .test({
        test: isString,
        message: 'Insira o estado que você reside.'
      })
      .required('Insira o estado que você reside.')
  })
});

export default new YupValidation(schema);
