import { render, staticRenderFns } from "./PaidEvents.vue?vue&type=template&id=2a4cb0cc&scoped=true&"
import script from "./PaidEvents.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PaidEvents.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PaidEvents.vue?vue&type=style&index=0&id=2a4cb0cc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4cb0cc",
  null
  
)

export default component.exports