
import { Component, Watch, Prop, Vue, Emit } from 'vue-property-decorator';
import { IRecaptcha } from '.';

@Component
export default class RecaptchaDisabled extends Vue implements IRecaptcha {
  @Prop({ type: Boolean, default: false })
  public challenge!: boolean;

  @Prop({ type: Boolean, default: false })
  public reset!: boolean;

  @Watch('challenge', { deep: true })
  public onChallenge() {
    this.emitToken('');
  }

  @Emit('token')
  emitToken(_token: string) {}

  @Watch('reset', { deep: true })
  public onReset() {}
}
