
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Heading, Row, ButtonSecondary } from '@warrenbrasil/nebraska-web';
import {
  AccordionWrapper,
  Accordion
} from '@/modules/common/components/Accordion';
import { trackYearlyNoteDownload } from '../../trackers';
import {
  ITradeBrokerageNotesByYear,
  ITradeBrokerageNote,
  getYearlyBrokerageNote
} from '@/modules/investments/services/trade';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Accordion,
    AccordionWrapper,
    Heading,
    Row,
    ButtonSecondary
  }
})
export default class NoteList extends Vue {
  @Prop({ type: Object, required: true })
  readonly yearlyNotes!: ITradeBrokerageNotesByYear;

  @Emit('on-daily-note-click')
  emitOnDailyNoteClick(dailyNote: ITradeBrokerageNote) {
    return dailyNote;
  }

  @Emit('on-notify-user')
  emitOnNotifyUser() {}

  readonly NebraskaColors = NebraskaColors;
  isFetchingYearlyNote = false;

  async requestYearlyNote(yearlyNote: ITradeBrokerageNotesByYear) {
    this.isFetchingYearlyNote = true;
    try {
      trackYearlyNoteDownload(yearlyNote.year!);
      const { url } = await getYearlyBrokerageNote(yearlyNote.year);

      window.open(url, '_blank');
    } catch {
      this.emitOnNotifyUser();
    } finally {
      this.isFetchingYearlyNote = false;
    }
  }
}
