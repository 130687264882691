import Vue, { VueConstructor } from 'vue';
import { apolloProvider } from '@/modules/common/services/graphql/apollo-provider';

import { Portal, PortalPropsData } from './types';

const getRandom = () => String(Math.ceil(Math.random() * 1000));

export const makePortal = <P = any>(
  PortalComponent: VueConstructor,
  propsData?: PortalPropsData<P>
): Portal => {
  const parent = propsData?.parent || document.body;
  const element = document.createElement('div');
  parent.appendChild(element);

  let portalComponent: Vue;

  const close = () => {
    propsData?.onClose?.();
    portalComponent?.$destroy();
    element.remove();

    if (propsData?.useHistoryState) {
      window.removeEventListener('popstate', close);
      history.back();
    }
  };

  if (propsData?.useHistoryState) {
    history.pushState({ portal: getRandom() }, '');
    window.addEventListener('popstate', close);
  }

  if (propsData?.timeout) {
    setTimeout(close, propsData?.timeout);
  }

  const open = () => {
    portalComponent = new PortalComponent({
      apolloProvider,
      propsData: { ...propsData, close }
    });
    portalComponent.$mount();
    element.append(portalComponent.$el);
  };

  return { open, close, element };
};

export const dialogPortal = async <P = any>(
  Component: VueConstructor,
  propsData?: PortalPropsData<P>
) => {
  const props = propsData || {};
  return new Promise((resolve, reject) => {
    const portal = makePortal(Component, {
      ...props,
      confirm: resolve,
      cancel: reject
    });
    portal.open();
  });
};
