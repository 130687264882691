
import { Component, Vue } from 'vue-property-decorator';

import { StatusMessage } from '@warrenbrasil/nebraska-web';
import { sections } from './sections';

@Component({
  components: {
    StatusMessage
  }
})
export default class FirstAccessSections extends Vue {
  readonly sections = sections;
}
