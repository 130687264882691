// @ts-ignore
import { compareTwoStrings } from 'string-similarity';

export function compareStringsPercentage(str1: string, str2: string) {
  if (!str1 || !str2) return;
  return compareTwoStrings(str1, str2);
}

const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const knownServices: IKnownDomain[] = [
  { domain: 'icloud.com', accuracy: 0.6 },
  { domain: 'live.com', accuracy: 0.6 },
  { domain: 'gmail.com', accuracy: 0.6 },
  { domain: 'hotmail.com', accuracy: null },
  { domain: 'yahoo.com', accuracy: null },
  { domain: 'outlook.com', accuracy: null },
  { domain: 'outlook.com.br', accuracy: 0.8 },
  { domain: 'uol.com.br', accuracy: 0.8 },
  { domain: 'bol.com.br', accuracy: 0.8 }
];

export interface IKnownDomain {
  domain: string;
  accuracy: number | null;
}

function findMatch(domain: string): string {
  const domainsSimilarities = knownServices.map(service =>
    compareStringsPercentage(service.domain, domain)
  );
  const higherSimilarity = Math.max(...domainsSimilarities);
  const bestServiceMatch =
    knownServices[domainsSimilarities.indexOf(higherSimilarity)];

  if (bestServiceMatch && bestServiceMatch.accuracy! > higherSimilarity)
    return '';
  return bestServiceMatch.domain;
}
function getSuggestion(account: string, domain: string): string {
  const domainSuggestion = findMatch(domain);
  const suggestion = domainSuggestion ? `${account}@${domainSuggestion}` : '';
  return suggestion;
}
function isKnown(domain: string): boolean {
  const knownDomain: string[] = knownServices.map(element => element.domain);
  return knownDomain.includes(domain);
}
export function emailSuggester(email: string): IEmailSuggesterResult {
  const isValid = REGEX_VALIDATE_EMAIL.test(email);
  if (!isValid) return { email, isValid, suggestion: '', isKnownDomain: false };
  const emailParts = email.split('@');
  const isKnownDomain = isKnown(emailParts[1]);
  return {
    email,
    isValid,
    isKnownDomain,
    suggestion: isKnownDomain
      ? email
      : getSuggestion(emailParts[0], emailParts[1])
  };
}
export interface IEmailSuggesterResult {
  email: string;
  suggestion?: string;
  isValid: boolean;
  isKnownDomain?: boolean;
}
