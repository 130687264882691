
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  Tab,
  Tabs,
  TabList,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { PersonalBankDataTab } from './components/PersonalBankDataTab';
import { PersonalContactTab } from './components/PersonalContactTab';
import { PersonalDataTab } from './components/PersonalDataTab';
import { UnderApprovalNotification } from './components/UnderApprovalNotification';
import { UserTemplate } from '@/modules/account/components/UserTemplate';
import { getString } from '@/modules/common/helpers/resources';
import { getCustomer } from '@/modules/common/services/http/account';
import { updateCustomer } from '@/modules/account/services/account';
import { bankAccountDelete } from '@/modules/transaction/services/bank-account';
import { passwordConfirmation } from '@/modules/common/components/PasswordDialog';
import { CustomerResponse, ICustomer } from '@/types/models';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { RegulationUpdateInfo } from './components/RegulationUpdateInfo';
import { AccountUpdateTab } from './types';
import * as trackEvents from './track-events';
import { IMFAStatus, hasMFA } from '@/modules/authentication/services/mfa';

import scrollToElement from 'scroll-to-element';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    LoaderIndicator,
    PersonalBankDataTab,
    PersonalContactTab,
    PersonalDataTab,
    RegulationUpdateInfo,
    Tab,
    TabList,
    Tabs,
    UnderApprovalNotification,
    UserTemplate
  }
})
export default class UpdateUserAccountView extends Vue {
  @CustomerModule.Action('setCustomer')
  readonly setCustomerOnModule!: (customer: ICustomer) => void;

  @CustomerModule.Getter('isRegulatoryStatusAboutToBeBlocked')
  readonly isRegulatoryStatusAboutToBeBlocked!: boolean;

  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isRegulatoryStatusBlocked!: boolean;

  readonly NebraskaColors = NebraskaColors;
  private isLoading = true;
  private customer: any = {};
  private removedBanksIds: string[] = [];
  private selectedIndex = 0;
  private updatedCustomer = {} as ICustomer;

  private submitName = getString('account_button_save');
  private tabs = [
    AccountUpdateTab.Personal,
    AccountUpdateTab.Contact,
    AccountUpdateTab.Finance
  ];

  private title = getString('dash_menu_my_account_update');
  private fields: Record<string, boolean> = {};
  private isSubmitDisabled = false;

  private get component() {
    const components = [
      'PersonalDataTab',
      'PersonalContactTab',
      'PersonalBankDataTab'
    ];
    return components[this.selectedIndex];
  }

  private get hasRegulationUpdate(): boolean {
    return (
      this.isRegulatoryStatusAboutToBeBlocked || this.isRegulatoryStatusBlocked
    );
  }

  private async created() {
    const userHasMfaActive: IMFAStatus = await hasMFA();
    if (!userHasMfaActive.enabled) {
      this.goToMFAPage();
      return;
    }
    this.isLoading = true;
    // eslint-disable-next-line promise/catch-or-return
    getCustomer()
      .then(({ customer }: CustomerResponse) => this.setCustomer(customer))
      .then(this.shouldRedirectToRegister)
      .finally(() => (this.isLoading = false));
  }

  mounted() {
    trackEvents.trackViewLoad();
  }

  private goToMFAPage() {
    this.$router.push({ name: 'security' });
  }

  private shouldRedirectToRegister() {
    if (!this.customer.isRegisterVerified) {
      window.location.assign('#/register');
    }
  }

  getTabLabel(tab: AccountUpdateTab) {
    return getString(tab);
  }

  private removeBanks(ids: string[]) {
    this.removedBanksIds = ids;
  }

  private setTab(index: number) {
    const tab = this.tabs[index];
    const invalidInput = document.querySelector('input:invalid');

    trackEvents.trackTabClick(tab, this.getTabLabel(tab));

    if (!invalidInput) {
      this.selectedIndex = index;
    } else {
      this.$notify({
        group: 'user-template-notifications',
        text: 'Verifique se o preenchimento dos campos está correto',
        type: NotificationTypes.error
      });
      scrollToElement(invalidInput, { offset: -120, duration: 300 });
    }
  }

  private setCustomer(customer: ICustomer) {
    this.customer = customer;
  }

  private redirectToHome(hasRegulationUpdate: boolean) {
    if (hasRegulationUpdate) {
      this.setCustomerOnModule(this.customer);
      return this.$router.push({ name: 'home' });
    }
  }

  private async submit() {
    trackEvents.trackSaveClick();

    const password = await passwordConfirmation();
    this.isLoading = true;

    await this.removeBanksRequest();
    await updateCustomer({ user: this.customer, password })
      .then(response => {
        this.updatedCustomer = response;
      })
      .finally(() => (this.isLoading = false));

    const hasRegulationUpdate = this.hasRegulationUpdate;

    this.setCustomer(this.updatedCustomer);
    this.redirectToHome(hasRegulationUpdate);

    return 'Seus dados foram atualizados';
  }

  private async removeBanksRequest() {
    if (!this.removedBanksIds.length) return;
    const promises = this.removedBanksIds.map(bankAccountDelete);
    return Promise.all(promises)
      .catch(() =>
        this.$notify({
          group: 'user-template-notifications',
          text: 'Não foi possível remover a conta bancária',
          type: NotificationTypes.error
        })
      )
      .finally(() => (this.removedBanksIds = []));
  }

  public validateField({
    field,
    isValid
  }: {
    field: string;
    isValid: boolean;
  }) {
    this.fields[field] = isValid;
    this.checkSubmitDisabled();
  }

  private checkSubmitDisabled() {
    this.isSubmitDisabled = Object.values(this.fields).some(
      isValid => !isValid
    );
  }
}
