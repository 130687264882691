
import { Component, Vue } from 'vue-property-decorator';
import { ApolloQueryResult } from '@apollo/client';

import {
  ActionableHelperView,
  ContextColorProvider,
  Heading,
  Pagination,
  Row,
  SkeletonLoader,
  HelperView,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { Notification } from '@/modules/common/components/Notification';

import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  getPositionsStatement,
  getSubscriptionsStatement,
  getPublicOfferingsStatement,
  getMonthlyStatement,
  getConsolidatedStatement
} from '@/modules/trade/services/notes-statements';
import {
  trackPositionsStatementDownload,
  trackSubscriptionsStatementDownload,
  trackMonthlyPositionsStatementDownload,
  trackConsolidatedPositionsDownload,
  trackPublicOfferingsStatementDownload
} from './trackers';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  GetTradeAccountStatementQuery,
  GetTradeAccountStatementDocument,
  TradePaginatedStatement
} from '@/modules/common/services/graphql/server/server.sdk';

@Component({
  components: {
    ActionableHelperView,
    LoaderIndicator,
    Heading,
    Notification,
    Pagination,
    Row,
    SkeletonLoader,
    ContextColorProvider,
    HelperView
  }
})
export default class TradeAccountStatementView extends Vue {
  totalPages?: number;
  pageIndex = 1;
  isLoading = true;
  isFileLoading = false;
  showPositionsStatement = false;
  statements: TradePaginatedStatement | null = null;
  private isError = false;
  private recordsByPage = 8;
  readonly NebraskaColors = NebraskaColors;

  created() {
    this.loadData(this.pageIndex);
  }

  get showError() {
    return this.isError && !this.isLoading;
  }

  get hasStatements() {
    return this.statements && this.statements!.list.length !== 0;
  }

  async loadData(page: number) {
    try {
      this.isLoading = true;
      this.isError = false;

      const { data }: ApolloQueryResult<GetTradeAccountStatementQuery> =
        await this.$apollo.query({
          query: GetTradeAccountStatementDocument,
          variables: {
            size: this.recordsByPage,
            index: page
          }
        });

      this.statements = data!.trade!.statements;
      this.showPositionsStatement = Boolean(
        data!.trade!.statements.showPositionsStatement
      );
      this.totalPages = data!.trade!.statements!.page!.amount!;
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchConsolidatedFile() {
    try {
      this.isFileLoading = true;
      trackConsolidatedPositionsDownload();

      const { base64 } = await getConsolidatedStatement();

      this.download(base64, 'Extrato Consolidado.pdf');
    } catch {
      this.notify();
    } finally {
      this.isFileLoading = false;
    }
  }

  async fetchMonthlyFile(month: string, date: string, year: string) {
    try {
      this.isFileLoading = true;
      trackMonthlyPositionsStatementDownload(month, year);

      const { base64 } = await getMonthlyStatement(date);

      this.download(base64, `Extrato de ${month} de ${year}.pdf`);
    } catch (error) {
      this.notify();
    } finally {
      this.isFileLoading = false;
    }
  }

  async fetchPositionsStatement2023File() {
    try {
      this.isFileLoading = true;
      trackPositionsStatementDownload(2023);

      const data = await getPositionsStatement(2023);

      this.download(data.base64, `Extrato em 31/12/2023`);
    } catch (error) {
      this.notify();
    } finally {
      this.isFileLoading = false;
    }
  }

  async fetchSubscriptionsStatement2023File() {
    try {
      this.isFileLoading = true;
      trackSubscriptionsStatementDownload(2023);

      const data = await getSubscriptionsStatement(2023);

      this.download(data.base64, `Extrato de subscrições`);
    } catch {
      this.notify();
    } finally {
      this.isFileLoading = false;
    }
  }

  async fetchPublicOfferingsStatement2023File() {
    try {
      this.isFileLoading = true;
      trackPublicOfferingsStatementDownload(2023);

      const data = await getPublicOfferingsStatement(2023);

      this.download(data.base64, `Extrato de ofertas públicas`);
    } catch {
      this.notify();
    } finally {
      this.isFileLoading = false;
    }
  }

  onChangePage(page: number) {
    this.pageIndex = page;
    this.loadData(this.pageIndex);
  }

  private notify() {
    this.$notify({
      group: 'wrn-trade-account-statement',
      text: `Não foi possível realizar o download do seu extrato`,
      type: NotificationTypes.error
    });
  }

  private download(file: string, fileName: string) {
    const downloadLink = document.createElement('a');

    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
