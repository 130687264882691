export interface IAuthenticationProfile {
  id?: string;
  customer?: string;
  faceMatchStatus?: EFaceMatchStatus;
  faceMatchToken?: string;
  faceMatchReportId?: string;
  createdAt?: Date;
  deletedAt?: Date;
  isApprovedBy?: 'manually' | 'automatically';
  whoApproved?: string;
}

export type IAuthenticationProfileResponse = Omit<
  IAuthenticationProfile,
  'faceMatchReportId'
>;

export type ICustomerIdentification = {
  status: EStatus;
  action?: EFaceMatchAction;
  createdAt?: Date;
};

export enum EFaceMatchStatus {
  InvalidDoc = 'InvalidDoc',
  InvalidFaceMatch = 'InvalidFaceMatch',
  InvalidDocFaceMatch = 'InvalidDocFaceMatch',
  Processing = 'Processing',
  Deleted = 'Deleted',
  Invalid = 'Invalid',
  Valid = 'Valid'
}

export enum EStatus {
  unknown = 'unknown',
  ocr_facematch_valid = 'ocr_facematch_valid',
  ocr_facematch_invalid = 'ocr_facematch_invalid',
  ocr_facematch_processing = 'ocr_facematch_processing',
  ocr_facematch_processing_manually = 'ocr_facematch_processing_manually'
}

export enum EFaceMatchAction {
  ocr_facematch_action = 'ocr_facematch_action',
  dashboard_action = 'dashboard_action'
}
