import { GtmTracker } from '@/modules/common/services/analytics';

const trackSignUpEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackSignUpEvent({
    event_type: 'load',
    event_name: 'view'
  });

  trackSignUpEvent({
    event_type: 'load',
    event_name: 'signup_start',
    is_conversion: true
  });
}

export function trackSendEmail() {
  trackSignUpEvent({
    event_type: 'click',
    event_name: 'send_email',
    is_conversion: true
  });
}

export function trackWrongEmail() {
  trackSignUpEvent({
    event_type: 'click',
    event_name: 'wrong_email'
  });
}

export function trackSendForm() {
  trackSignUpEvent({
    event_type: 'click',
    page_screen_section: 'form',
    event_name: 'send_form'
  });
}
