import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEventAuthentication = GtmTracker.trackerWithDefaultProps({
  page_screen: 'data_on_analises',
  digital_experience: 'investor',
  experience_section: 'home'
});

const trackAmplitudeEventOnboarding = GtmTracker.trackerWithDefaultProps({
  page_screen: 'data_on_analises_onboarding',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackLoadUnderAnalysis(isOnboarding: boolean) {
  const trackAmplitudeEvent = isOnboarding
    ? trackAmplitudeEventOnboarding
    : trackAmplitudeEventAuthentication;

  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackBodyBackClick(isOnboarding: boolean) {
  const trackAmplitudeEvent = isOnboarding
    ? trackAmplitudeEventOnboarding
    : trackAmplitudeEventAuthentication;

  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'body'
  });
}

export function trackHelpClick(isOnboarding: boolean) {
  const trackAmplitudeEvent = isOnboarding
    ? trackAmplitudeEventOnboarding
    : trackAmplitudeEventAuthentication;

  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'click_help',
    page_screen_section: 'header'
  });
}
