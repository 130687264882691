import { Component, Vue } from 'vue-property-decorator';

import { customerAPI } from '@/modules/common/services/http/customer';
import { WarrenModules } from '@/types/navigation';
import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';
import {
  trackTradeDepositClick,
  trackTradeTransactionClick,
  trackTradeWithdrawClick,
  trackSearchButtonClick
} from '@/modules/trade/views/TradeView/trackers';
import { DashStatusButtonActions } from '@/modules/trade/types/dash-status';

export enum ActionTypes {
  StartRegister = 'start_register',
  StartSuitability = 'start_suitability',
  FirstDeposit = 'first_deposit',
  FirstTradeDeposit = 'first_trade_deposit',
  FixRegister = 'fix_register',
  VerifyEmail = 'verify_email',
  SetPassword = 'set_password',
  StartGenericDeposit = 'start_deposit',
  StartCashDeposit = 'start_cash_deposit',
  StartWealthDeposit = 'start_wealth_deposit',
  StartTradeDeposit = 'start_trade_deposit',
  StartTradeTransaction = 'start_trade_transaction',
  StartBuilder = 'start_builder',
  SelectStock = 'select_stock',
  UpdateToRegulation = 'update_to_regulation',
  EnableBroker = 'ENABLE_BROKER'
}

export interface IDashStatus {
  action: string;
  investmentAccountId: string;
  email: string;
  category: Categories;
  isCustomerBlocked?: boolean;
}

@Component
export default class DashStatus extends Vue {
  public isLoading = false;

  private async resendEmail(email: string) {
    this.isLoading = true;

    try {
      await customerAPI.checkAccount(email);

      this.isLoading = false;
      this.$notify({
        group: 'wrn-notifications',
        text: 'Pronto, enviamos um novo e-mail.',
        type: 'positive'
      });
    } catch (err) {
      this.isLoading = false;
      this.$notify({
        group: 'wrn-notifications',
        text: 'Ops! Tivemos um problema para enviar o e-mail. Tente novamente.',
        type: 'attention'
      });
    }
  }

  private goToTransactions(path: string) {
    this.$router.push({ path: `/v3/new-transactions/${path}` });
  }

  private goToNewWalletDepositSelection() {
    this.$router.push({ name: 'portfoliosToInvest' });
  }

  private goToInvestmentPage(portfolioId: string) {
    this.$router.push({
      name: 'investmentTypeSelection',
      params: { id: portfolioId }
    });
  }

  private goToRegulatoryUpdate() {
    this.$router.push({
      name: 'regulatory-update',
      query: { cameFrom: WarrenModules.Trade }
    });
  }

  public goToAction(dashStatus: IDashStatus) {
    const depositURL = '?choice=deposits&subcontext=investment-cart';
    const depositTradeURL = `${dashStatus.investmentAccountId}?choice=deposits&subcontext=trade&module=trade`;
    const trackEvent = GtmTracker.trackerWithDefaultProps({
      category: dashStatus.category
    });

    switch (dashStatus.action) {
      case ActionTypes.StartRegister:
        window.location.assign('/app/#/register');
        break;
      case ActionTypes.FirstDeposit:
        trackEvent({ action: 'Dashboard_Account_State', label: 'Depositar' });
        this.$router.push({ name: 'deposit-pix' });
        break;
      case ActionTypes.FirstTradeDeposit:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.$router.push({
          name: 'investment-transaction',
          params: { portfolio: dashStatus.investmentAccountId },
          query: { module: 'trade' }
        });
        break;
      case ActionTypes.FixRegister:
        this.$router.push({ name: 'personal' });
        break;
      case ActionTypes.UpdateToRegulation:
        this.$router.push({ name: 'regulatory-update' });
        break;
      case ActionTypes.SetPassword:
        this.resendEmail(dashStatus.email);
        break;
      case ActionTypes.StartTradeDeposit:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.goToTransactions(depositTradeURL);
        break;
      case ActionTypes.StartGenericDeposit:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        dashStatus.investmentAccountId
          ? this.goToInvestmentPage(dashStatus.investmentAccountId)
          : this.goToNewWalletDepositSelection();
        break;
      case ActionTypes.StartWealthDeposit:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.goToTransactions(depositURL);
        break;
      case ActionTypes.StartTradeTransaction:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.goToTransactions(
          `${dashStatus.investmentAccountId}?subcontext=trade&module=trade`
        );
        break;
      case ActionTypes.StartCashDeposit:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.goToTransactions(
          `${dashStatus.investmentAccountId}?choice=deposits&module=cash&backTo=home`
        );
        break;
      case ActionTypes.StartSuitability:
        window.location.assign('/app/#/suitability');
        break;
      case ActionTypes.StartBuilder:
        trackEvent({ action: 'Criar carteira', label: 'Dashboard' });
        this.$router.push({ name: 'catalog' });
        break;
      case DashStatusButtonActions.Transaction:
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.goToTransactions(
          `${dashStatus.investmentAccountId}?subcontext=trade&module=trade&showDepositButton=true`
        );
        break;
      case DashStatusButtonActions.Withdrawal:
        trackTradeWithdrawClick();
        this.goToTransactions(
          `${dashStatus.investmentAccountId}?subcontext=trade&module=trade&choice=transfer&showDepositButton=true`
        );
        break;
      case DashStatusButtonActions.DepositConversation:
        trackTradeTransactionClick();
        this.goToTransactions(
          `${dashStatus.investmentAccountId}?choice=deposits&subcontext=trade&module=trade&showDepositButton=true`
        );
        break;
      case DashStatusButtonActions.Deposit:
        trackTradeDepositClick();
        if (dashStatus.isCustomerBlocked) {
          this.goToRegulatoryUpdate();
          break;
        }
        this.$router.push({
          name: 'investment-transaction',
          params: { portfolio: dashStatus.investmentAccountId },
          query: { module: WarrenModules.Trade }
        });
        break;
      case DashStatusButtonActions.Search:
        trackSearchButtonClick();
        this.$router.push({ name: 'tradeSearch' });
        break;
      case DashStatusButtonActions.Earnings:
        this.$router.push({ name: 'earnings' });
        break;
      case DashStatusButtonActions.CollateralMargin:
        this.$router.push({ name: 'collateral-margin' });
        break;
      case DashStatusButtonActions.BrokerageNotes:
        this.$router.push({ name: 'accountStatements' });
        break;
      default:
        break;
    }
  }
}
