
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  NavBar,
  SkeletonLoader,
  ActionableHelperView,
  HelperView,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Notification } from '@/modules/common/components/Notification';
import { NotesList } from './components/NotesList';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  trackDailyNoteDownload,
  trackTradeNotesBackClick,
  trackTradeNotesViewLoad
} from './trackers';
import {
  getAvailableBrokerageNoteList,
  ITradeBrokerageNotesByYear,
  getDailyBrokerageNote,
  ITradeBrokerageNote
} from '@/modules/investments/services/trade';

@Component({
  components: {
    NavBar,
    BackButton,
    NotesList,
    SkeletonLoader,
    ActionableHelperView,
    HelperView,
    ContextColorProvider,
    LoaderIndicator,
    Notification
  }
})
export default class TradeNotesView extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly showNavbar!: boolean;

  notes: ITradeBrokerageNotesByYear[] = [];
  isLoading = false;
  isPdfLoading = false;
  isError = false;
  readonly NebraskaColors = NebraskaColors;

  created() {
    this.loadNotes();
    trackTradeNotesViewLoad();
  }

  get hasNotes() {
    return this.notes && this.notes.length !== 0;
  }

  private async loadNotes() {
    this.isLoading = true;
    try {
      const response = await getAvailableBrokerageNoteList();
      this.notes = response.availableYears;
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  onBackClick() {
    this.$router.back();
    trackTradeNotesBackClick();
  }

  notifyUser() {
    this.$notify({
      group: 'wrn-trade-notes',
      text: `Não foi possível abrir sua nota de negociação`,
      type: NotificationTypes.error
    });
  }

  async requestDailyNote(note: ITradeBrokerageNote) {
    this.isPdfLoading = true;
    try {
      trackDailyNoteDownload(note.title);
      const { generatedURL: url } = await getDailyBrokerageNote(note.id);

      window.open(url, '_blank');
    } catch {
      this.notifyUser();
    } finally {
      this.isPdfLoading = false;
    }
  }
}
