import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IAuthenticationProfile,
  IAuthenticationProfileResponse,
  ICustomerIdentification,
  EStatus,
  EFaceMatchStatus
} from './types';

const mapStatusToFaceMatchStatus = {
  [EStatus.unknown]: undefined,
  [EStatus.ocr_facematch_invalid]: EFaceMatchStatus.Invalid,
  [EStatus.ocr_facematch_valid]: EFaceMatchStatus.Valid,
  [EStatus.ocr_facematch_processing]: EFaceMatchStatus.Processing,
  [EStatus.ocr_facematch_processing_manually]: EFaceMatchStatus.Processing
};

export function castStatusToFaceMatchStatus({
  status,
  ...customerIdentification
}: ICustomerIdentification): IAuthenticationProfileResponse {
  return {
    faceMatchStatus: mapStatusToFaceMatchStatus[status],
    ...customerIdentification
  };
}

const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_ACCOUNT_API
});

export async function getCustomerIdentificationStatus(customerId: string) {
  const customerIdentification: ICustomerIdentification = await http.get(
    `/v1/identification/getCustomerIdentificationStatus?customerId=${customerId}`
  );

  return castStatusToFaceMatchStatus(customerIdentification);
}

export async function saveOcrFacematch({
  customer,
  faceMatchToken
}: IAuthenticationProfile) {
  const customerIdentification: ICustomerIdentification = await http.post(
    '/v1/identification/saveOCRFaceMatch',
    { customerId: customer, faceMatchToken }
  );

  return castStatusToFaceMatchStatus(customerIdentification);
}
