
import { Component, Vue } from 'vue-property-decorator';

import { NavBar, Tab, Tabs, TabList } from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';

import TradeAccountStatementView from './TradeAccountStatementView.vue';
import TradeNotesView from '../Notes/TradeNotesView.vue';
import { trackAccountStatemenViewLoad } from './trackers';

@Component({
  components: {
    BackButton,
    NavBar,
    Tab,
    Tabs,
    TabList,
    TradeAccountStatementView,
    TradeNotesView
  }
})
export default class TradeStatementWrapperView extends Vue {
  private currentTabIndex = 0;

  private changeTab(tab: number) {
    this.currentTabIndex = tab;
    return this.currentTabIndex;
  }

  get tabsList() {
    return [{ title: 'Notas de negociação' }];
  }

  created() {
    trackAccountStatemenViewLoad();
  }
}
