
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { IConversationQuestionSelectValue } from '@/modules/conversation/services/conversation';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
@Component({})
export default class AutoCompleteSelector extends Vue {
  @Prop({ type: Boolean, required: true })
  private displaySelect!: boolean;

  @Prop({ type: String, required: false })
  private searchFor?: string;

  @Prop({ type: String, required: false })
  private searchEmptyDefaultValue?: string;

  @Prop({ type: Array, required: true })
  private defaultArray!: string[];

  @Prop({ type: String, required: false })
  private defaultSearchEmptyReturn?: string;

  @Prop(Object)
  public emptySearchObject?: IConversationQuestionSelectValue;

  private NebraskaColors = NebraskaColors;
  private selectedElement?: string;
  private searchArray: string[] = [];
  private mounted() {
    if (this.displaySelect) {
      document.body.addEventListener('click', this.close);
    }
  }

  private selectContent(value: string) {
    this.selectedElement = value;
    this.selected();
  }

  @Watch('searchFor')
  private searchContent(value: string) {
    this.searchArray = [];
    this.selectedElement = undefined;
    this.defaultArray.forEach(element => {
      if (element.toLowerCase().includes(value.toLowerCase())) {
        this.searchArray.push(element);
      }
    });
    if (this.searchArray.length === 0 && this.defaultSearchEmptyReturn) {
      this.searchArray.push(this.defaultSearchEmptyReturn);
    }
  }

  @Watch('displaySelect')
  private openSelect() {
    if (this.displaySelect) {
      this.searchContent(this.searchFor!);
      document.body.addEventListener('click', this.close);
    }
  }

  private get selectSize() {
    const size =
      this.fetchCurrentArray.length > 4 ? 4 : this.fetchCurrentArray.length;
    return size;
  }

  private get isArrayEmpty() {
    return this.fetchCurrentArray.length === 0;
  }

  private get fetchCurrentArray() {
    let selectedArray = !this.searchFor ? this.defaultArray : this.searchArray;
    if (selectedArray.length === 0 && this.searchEmptyDefaultValue) {
      selectedArray = [this.searchEmptyDefaultValue];
    }
    return selectedArray;
  }

  @Emit()
  private selected(): string {
    return this.selectedElement!;
  }

  @Emit()
  private close() {
    document.body.removeEventListener('click', this.close);
  }
}
