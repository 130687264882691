export enum PageContext {
  DEFAULT = 'default',
  ONBOARDING = 'onboarding'
}

export type AnnouncementContent = {
  title: string;
  description: string;
  icon: string;
  buttonLabel: string;
};

const pageContentByContext = {
  default: {
    title: 'Conta mais segura',
    description:
      'Para ter maior segurança em sua conta, envie uma selfie e a foto de um documento (RG ou CNH). É rápido!',
    icon: 'ic_person',
    buttonLabel: 'Começar'
  },
  onboarding: {
    title: 'Para deixar sua conta ainda mais segura',
    description:
      'Para ter maior segurança nas suas transações, precisaremos que você nos envie uma foto do seu documento e uma selfie.',
    icon: 'ic_camera',
    buttonLabel: 'Continuar'
  }
};

export default pageContentByContext;
