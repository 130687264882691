import {
  customizeFavicon,
  customizeTitle
} from '@/modules/common/helpers/whitelabel';
import { HttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';

import {
  ICheckResetTokenRequest,
  ICheckSetupTokenRequest,
  ICommitResetTokenRequest,
  ICommitSetupTokenRequest,
  ILoginRequest,
  IUnblockTokenRequest,
  IVerifyEmailTokenRequest,
  IChatToken,
  ResetPasswordRequest,
  InstantLoginRequest,
  MembershipTermsResponse,
  GetThemeByParamType,
  ICheckSetupTokenResponse,
  ICommitSetupTokenResponse,
  InstantLoginResponse,
  WootricResponse,
  ILoginResponse,
  RegulatoryUpdateStatusResponse,
  GetCustomerAccountStatusResponse
} from './types';

import {
  ICustomTheme,
  IThemeByReferralResponse,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { httpClient, httpClientWithParams } from '../http-client';

export function getTheme(tokenStorageKey?: StorageKeys) {
  return httpClientWithParams(tokenStorageKey).get<any>(
    '/api/v2/account/b2b/custom-theme'
  );
}

export async function getThemeByParam(
  type: GetThemeByParamType,
  param: string,
  tokenStorageKey?: StorageKeys
): Promise<ICustomTheme> {
  const apiInstance = httpClientWithParams(tokenStorageKey);
  const response = await apiInstance.get<ICustomTheme>(
    `/api/v2/account/theme`,
    {
      params: {
        [type]: param
      }
    }
  );
  customizeTitle(response);
  customizeFavicon(response);
  return response;
}

export async function getThemeByReferralId(
  referralId: string
): Promise<IThemeByReferralResponse> {
  const response = await httpClient.get<IThemeByReferralResponse>(
    `/api/v2/account/fast-regiter-theme/${referralId}`
  );
  customizeTitle(response?.theme);
  customizeFavicon(response?.theme);
  return response;
}

export function getCustomer() {
  return httpClient.get<any>('/api/v2/account/me');
}

export function impersonate(customerId: string) {
  return httpClient.get<{ accessToken: string }>(
    `/api/v2/account/b2b/impersonate/${customerId}`
  );
}

export function login(body: ILoginRequest) {
  return httpClient.post<ILoginResponse>('/api/v2/account/login', body);
}

export function logout() {
  return httpClientWithParams(undefined, true).post<{}>(
    '/api/v2/account/logout'
  );
}

export function unblockHash(body: IUnblockTokenRequest): Promise<any> {
  return httpClient.post<any>('/api/v2/account/hash-unblock', body);
}

export function verifyEmail(body: IVerifyEmailTokenRequest): Promise<any> {
  return httpClient.post<any>('/api/v2/account/verify-email/commit', body);
}

export function checkResetToken(body: ICheckResetTokenRequest): Promise<any> {
  return httpClient.post<any>('/api/v2/account/reset-password/check', body);
}

export function commitResetToken(body: ICommitResetTokenRequest): Promise<any> {
  return httpClient.post<any>('/api/v2/account/reset-password/commit', body);
}

export function resetPassword(body: ResetPasswordRequest): Promise<{}> {
  return httpClient.post<{}>('/api/v2/account/reset-password/request', body);
}

export function checkSetupToken(
  body: ICheckSetupTokenRequest
): Promise<ICheckSetupTokenResponse> {
  return httpClient.post<ICheckSetupTokenResponse>(
    '/api/v2/account/setup/check',
    body
  );
}

export function commitSetupToken(
  body: ICommitSetupTokenRequest
): Promise<ICommitSetupTokenResponse> {
  return httpClient.post<ICommitSetupTokenResponse>(
    '/api/v2/account/setup/commit',
    body
  );
}

export function signUp(body: any): Promise<any> {
  return httpClient.post<any>('/api/v3/account/b2b/signup', body);
}

export function deleteAccount() {
  return httpClient.delete<void>(`/api/v2/account/delete`);
}

export function instantLogin(body: InstantLoginRequest) {
  return HttpClient.post<InstantLoginResponse>(
    '/api/v2/account/instant-login/commit',
    body
  );
}

export function instantLoginIntranet(body: InstantLoginRequest) {
  return HttpClient.post<InstantLoginResponse>(
    '/api/v2/account/instant-login/accept',
    body
  );
}

export function instantLoginUnblockHash(body: IUnblockTokenRequest) {
  return HttpClient.put<{}>('/api/v2/account/hash-unblock', body);
}

export function getChatToken() {
  const hasToken = !!localStorage.getItem('warren.accounts.accessToken');
  if (hasToken) {
    return HttpClient.get<IChatToken>('/api/v2/account/chat-token');
  }

  throw new Error('User not logged in');
}

export function getCustomerInvestmentProfile(customerId: string) {
  const httpAccountBff = httpClient.withOptions({
    baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
  });
  return httpAccountBff.get(
    `/v1/customer/getCustomerInvestmentProfile?customerId=${customerId}`
  );
}

export function getMembershipTerms() {
  return HttpClient.get<MembershipTermsResponse>('/api/v2/account/terms');
}

export function getWootricData() {
  return HttpClient.get<WootricResponse>('/api/v2/account/me/wootric');
}

export function getRegulatoryUpdateStatus(
  customerId: string
): Promise<RegulatoryUpdateStatusResponse> {
  const httpAccountBff = httpClient.withOptions({
    baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
  });
  return httpAccountBff.get(
    '/v1/customer/regulatoryUpdate/getRegulatoryStatus',
    {
      params: { customerId }
    }
  );
}

export function getCustomerAccountStatus(
  customerId: string
): Promise<GetCustomerAccountStatusResponse> {
  const httpAccountBff = httpClient.withOptions({
    baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
  });
  return httpAccountBff.get('/v1/customer/getAccountStatus', {
    params: { customerId }
  });
}
