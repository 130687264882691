export enum OrderModalSteps {
  Loading = 'Loading',
  Details = 'Details',
  Error = 'Error',
  Portability = 'Portability',
  Status = 'Status'
}

export enum OrderModalButtonSlots {
  ButtonPrimary = 'button-primary',
  ButtonSecondary = 'button-secondary'
}
