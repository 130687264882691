import Vue from 'vue';
import 'reflect-metadata';
import VueApollo from '@vue/apollo-option';
import Notifications from 'vue-notification';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';
import '@/styles/global/index.less';
import { apolloProvider } from '@/modules/common/services/graphql/apollo-provider';
import { AnalyticsPlugin } from '@/plugins/warren-analytics';
import NebraskaScheme from '@/plugins/nebraska-scheme';
import DatadogLogsPlugin from '@/plugins/datadog-logs';
import { DatadogRum } from '@/plugins/datadog-rum';
import {
  customizeTitle,
  customizeFavicon
} from '@/modules/common/helpers/whitelabel';
import { createPinia, PiniaVuePlugin } from 'pinia';

const { VUE_APP_ENABLE_LOGS, VUE_APP_ENABLE_RUM } = process.env;
const isLogsEnabled = VUE_APP_ENABLE_LOGS === 'true';
const isRumEnabled = VUE_APP_ENABLE_RUM === 'true';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;
Vue.use(AnalyticsPlugin);
Vue.use(VueApollo);
Vue.use(Notifications);
Vue.use(NebraskaScheme);

isLogsEnabled && Vue.use(DatadogLogsPlugin);
isRumEnabled && DatadogRum.init();

new Vue({
  router,
  store,
  pinia,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');

// TODO: Verificar se esses dois helpers ainda são necessários após remoção da plataforma de parceiros
customizeTitle();
customizeFavicon();
