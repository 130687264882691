var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('RegisterTemplate',{attrs:{"conversation-text":"Insira as informações sobre sua nacionalidade e ocupação.","partner-infos":_vm.user.partnerInfos}},[_c('form',{ref:"nationalityOccupationForm",staticClass:"wrn-form wrn-form-register",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('FormField',{class:{
        'form-error': _vm.getValidationError('personalProfile.nationality')
      },attrs:{"label-for":"register-nationality","label":_vm.getString('common_field_nationality_label'),"error":_vm.getValidationError('personalProfile.nationality'),"input-l-g":true}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.personalProfile.nationality),expression:"personalProfile.nationality"}],attrs:{"id":"register-nationality"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.personalProfile, "nationality", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.blurErrorCheck('personalProfile.nationality')}]}},_vm._l((_vm.getEnum('Nationality')),function(key,value){return _c('option',{key:key,domProps:{"value":value}},[_vm._v(" "+_vm._s(key)+" ")])}),0)]),_c('div',{staticClass:"wrn-form-row"},[_c('div',{staticClass:"wrn-c-sm-12 wrn-c-lg-6"},[_c('FormField',{class:{
            'form-error': _vm.getValidationError('personalProfile.birthCity')
          },attrs:{"label-for":"register-birth-city","label":_vm.getString('common_field_birthcity_label'),"error":_vm.getValidationError('personalProfile.birthCity'),"input-l-g":true}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.personalProfile.birthCity),expression:"personalProfile.birthCity",modifiers:{"trim":true}}],attrs:{"id":"register-birth-city","maxlength":"200"},domProps:{"value":(_vm.personalProfile.birthCity)},on:{"blur":[function($event){return _vm.blurErrorCheck('personalProfile.birthCity')},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.personalProfile, "birthCity", $event.target.value.trim())}}})])],1),_c('div',{staticClass:"wrn-c-sm-12 wrn-c-lg-6"},[_c('FormField',{class:{
            'form-error': _vm.getValidationError('personalProfile.birthState')
          },attrs:{"label-for":"register-birth-state","label":_vm.getString('common_field_birthstate_label'),"error":_vm.getValidationError('personalProfile.birthState'),"input-l-g":true}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.personalProfile.birthState),expression:"personalProfile.birthState"}],attrs:{"id":"register-birth-state"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.personalProfile, "birthState", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.blurErrorCheck('personalProfile.birthState')}]}},_vm._l((_vm.getEnum('State')),function(key,value){return _c('option',{key:key,domProps:{"value":value}},[_vm._v(" "+_vm._s(key)+" ")])}),0)])],1)]),(!_vm.hasEducationRegistered)?_c('FormField',{class:{
        'form-error': _vm.getValidationError('personalProfile.education')
      },attrs:{"label-for":"register-education","label":_vm.getString('common_field_education_label'),"error":_vm.getValidationError('personalProfile.education'),"input-l-g":true}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.personalProfile.education),expression:"personalProfile.education"}],attrs:{"id":"register-education"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.personalProfile, "education", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.blurErrorCheck('personalProfile.education')}]}},_vm._l((_vm.getEnum('Education')),function(key,value){return _c('option',{key:key,domProps:{"value":value}},[_vm._v(" "+_vm._s(key)+" ")])}),0)]):_vm._e(),_c('hr',{staticClass:"wrn-m-y-1"}),_c('ProfessionForm',{attrs:{"user-profession":_vm.personalProfile,"error":_vm.getValidationError('personalProfile.profession')},on:{"change":_vm.setUserProfession,"change-page-ready-to-submit":_vm.changePageReadyToSubmit,"handle-profession-form-tracking":_vm.handleTrackingEvent}}),_c('div',{staticClass:"wrn-inputbar wrn-inputbar-fixed wrn-inputbar__button-primary"},[_c('ButtonPrimary',{attrs:{"size":"large","type":"submit","label":_vm.getString('common_field_fast_register_save_button'),"loading":_vm.isLoading}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }