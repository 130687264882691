
import { Component, Prop, Vue } from 'vue-property-decorator';

enum SizeName {
  full = 'full',
  normal = 'normal',
  small = 'small',
  medium = 'medium'
}

@Component({})
export default class Container extends Vue {
  @Prop({ required: false, default: 'main', type: String })
  private as!: string;

  @Prop({ required: false, default: SizeName.normal, type: String })
  private size!: SizeName;
}
