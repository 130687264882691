export enum EStatusTypes {
  success = 'success',
  noResponse = 'noResponse',
  info = 'info',
  error = 'error'
}

const statusDataOnboarding = () => [
  {
    statusType: EStatusTypes.success,
    status: 'positive',
    title: 'Agora é só aguardar',
    subtitle:
      'Seu cadastro está sendo concluído, e logo mais você poderá começar a investir com a gente.'
  },
  {
    statusType: EStatusTypes.noResponse,
    status: 'positive',
    title: 'Agora é só aguardar',
    subtitle:
      'Seu cadastro está sendo concluído, e logo mais você poderá começar a investir com a gente.'
  },
  {
    statusType: EStatusTypes.info,
    status: 'information',
    title: 'Revisão dos seus dados',
    subtitle:
      'Para garantir que tudo esteja certo iremos analisar os seus dados. E assim que finalizarmos você receberá um e-mail e poderá começar a investir.'
  },
  {
    statusType: EStatusTypes.error,
    status: 'negative',
    title: 'Algo deu errado',
    subtitle:
      'Não foi possível realizar o cadastro da selfie e do documento. Tente novamente!'
  }
];

const statusDataAuthentication = (email: string) => [
  {
    statusType: EStatusTypes.success,
    status: 'positive',
    title: 'Cadastro concluído',
    subtitle:
      'Pronto! Sua conta está ainda mais segura. <br> O documento e a selfie foram cadastrados e validados.'
  },
  {
    statusType: EStatusTypes.noResponse,
    status: 'positive',
    title: 'Pronto! Agora é só aguardar',
    subtitle: `Analisaremos seus dados e, em breve, comunicaremos o cadastro dos dados  pelo e-mail <b>${email}</b>. <br><br>Você também pode acompanhar o andamento na categoria <b>Envio de Selfie e Documento</b>, no menu Minha Conta.`
  },
  {
    statusType: EStatusTypes.info,
    status: 'information',
    title: 'Dados em revisão',
    subtitle: `Para garantir maior segurança, revisaremos os seus dados e, em breve, comunicaremos a validação pelo e-mail <b>${email}</b>. <br><br> Você também pode acompanhar o andamento na categoria <b>Envio de Selfie e Documento</b>, no menu Minha Conta.`
  },
  {
    statusType: EStatusTypes.error,
    status: 'negative',
    title: 'Algo deu errado',
    subtitle:
      'Não foi possível realizar o cadastro da selfie e do documento. Tente novamente!'
  }
];

export const faceMatchStatus = (
  status: EStatusTypes,
  email: string,
  isOnboarding = false
) => {
  const statusData = isOnboarding
    ? statusDataOnboarding()
    : statusDataAuthentication(email);

  const currentStatus = statusData.filter(data => {
    return data.statusType === status;
  });

  return currentStatus[0];
};
