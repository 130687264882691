
import { Component, Vue, Prop } from 'vue-property-decorator';

import { OrderDetailsContent } from './components/OrderDetailsContent';
import { OrderDetailsHeader } from './components/OrderDetailsHeader';

import { OrderDetailsResponse } from '@/modules/trade/services/orders';
import { OrderTypes } from '@/modules/trade/types';
import * as trackOrderEvents from '@/modules/trade/components/OrderModal/trackers';

@Component({
  components: {
    OrderDetailsContent,
    OrderDetailsHeader
  }
})
export default class OrderDetails extends Vue {
  @Prop({ type: Object, required: true })
  readonly order!: OrderDetailsResponse;

  get instrumentType() {
    return this.order.instrument.type?.value;
  }

  get isPortabilityOrder() {
    return this.order.type?.value === OrderTypes.Portability;
  }

  get buttonLabel() {
    return this.isPortabilityOrder ? 'Editar preço médio' : undefined;
  }

  handleOnButtonClick() {
    trackOrderEvents.trackOrderDetailEditAveragePrice();
    this.$emit('on-button-click');
  }
}
