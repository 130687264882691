
import { Vue, Component, Emit } from 'vue-property-decorator';
import { Announcement } from '@/modules/onboarding/components/Announcement';

@Component({
  components: { Announcement }
})
export default class RegisterDocumentAnnouncement extends Vue {
  public get pageContent() {
    return {
      title: 'Tenha em mãos seu documento',
      description:
        'Para agilizarmos ainda mais o seu cadastro, tenha em mãos seu documento, pois precisaremos dele.',
      icon: 'ic_file',
      buttonLabel: 'Começar'
    };
  }

  @Emit()
  public goToNextStep() {}
}
