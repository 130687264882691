
import { Vue, Component } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { FaceMatchComponent } from './step-components';
import { EFaceMatchSteps, ERegistrationUpdateStatus } from './types';
import {
  faceMatchStatus,
  EStatusTypes
} from './components/Processing/helpers/processingHelpers';
import {
  getAuthenticationProfileByCustomerId,
  getCustomerIdentificationStatus
} from '@/modules/authentication/services/face-match';
import { EFaceMatchStatus } from '@/modules/authentication/services/face-match/types';
import { ICustomer } from '@/types/models';
import { namespace } from 'vuex-class';
import { maskEmail } from '@/modules/authentication/views/FaceMatch/helpers/mask-email';
import * as trackEvents from './components/FaceMatchTemplate/track-events';
import { PageContext } from './components/Announcement/page-content-by-context';
import { LoaderIndicator } from '@warrenbrasil/nebraska-web';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: { LoaderIndicator }
})
export default class FaceMatchView extends Vue {
  @CustomerModule.Getter('getCustomerId')
  readonly customerId!: string;

  @CustomerModule.Getter('getCustomer')
  readonly customer!: ICustomer;

  readonly NebraskaColors = NebraskaColors;

  registrationUpdateStatus? = ERegistrationUpdateStatus.ANNOUNCEMENT;
  registrationDate = '';
  private status: EStatusTypes = EStatusTypes.success;
  private currentStep = EFaceMatchSteps.ANNOUNCEMENT;
  private isLoading = false;

  get step() {
    return {
      component: FaceMatchComponent[this.currentStep]
    };
  }

  get getStatus() {
    return faceMatchStatus(this.status, this.maskedEmail, this.isOnboarding);
  }

  get maskedEmail() {
    const email = this.customer.email;
    return maskEmail(email);
  }

  get context(): PageContext {
    return this.$route.meta?.context === 'onboarding'
      ? PageContext.ONBOARDING
      : PageContext.DEFAULT;
  }

  private get isOnboarding() {
    return this.context === PageContext.ONBOARDING;
  }

  async created() {
    await this.getAuthenticationProfile();
  }

  private async getAuthenticationProfile() {
    this.isLoading = true;
    try {
      const response = this.isOnboarding
        ? await getCustomerIdentificationStatus(this.customerId)
        : await getAuthenticationProfileByCustomerId(this.customerId);
      this.setRegistrationDate(response.createdAt);
      this.checkCustomerFaceMatchStatus(response.faceMatchStatus);
    } catch {
      this.setCurrentStep(
        EFaceMatchSteps.ANNOUNCEMENT,
        ERegistrationUpdateStatus.ANNOUNCEMENT
      );
    } finally {
      this.isLoading = false;
    }
  }

  checkCustomerFaceMatchStatus(faceMatchStatus?: EFaceMatchStatus | boolean) {
    switch (faceMatchStatus) {
      case EFaceMatchStatus.Valid:
        this.setCurrentStep(
          EFaceMatchSteps.REGISTER_STATUS,
          ERegistrationUpdateStatus.CONCLUDED
        );
        break;
      case EFaceMatchStatus.InvalidDoc:
      case EFaceMatchStatus.InvalidFaceMatch:
      case EFaceMatchStatus.InvalidDocFaceMatch:
      case EFaceMatchStatus.Processing:
        this.setCurrentStep(
          EFaceMatchSteps.REGISTER_STATUS,
          ERegistrationUpdateStatus.UNDER_ANALYSIS
        );
        break;
      default:
        this.setCurrentStep(
          EFaceMatchSteps.ANNOUNCEMENT,
          ERegistrationUpdateStatus.ANNOUNCEMENT
        );
    }
  }

  private setCurrentStep(
    step: EFaceMatchSteps,
    registrationStatus?: ERegistrationUpdateStatus
  ) {
    this.currentStep = step;
    this.registrationUpdateStatus = registrationStatus;
  }

  private setRegistrationDate(date: Date | undefined) {
    this.registrationDate = String(date);
  }

  handleNextStep(status: EStatusTypes) {
    this.status = status;
    if (this.currentStep === EFaceMatchSteps.STATUS) {
      this.currentStep = EFaceMatchSteps.ANNOUNCEMENT;
    } else {
      this.currentStep++;
    }
  }

  goBackEvent() {
    const registrationStatus = this.registrationUpdateStatus;
    const mapperFaceMatchPage: {
      [key in ERegistrationUpdateStatus]: () => void;
    } = {
      [ERegistrationUpdateStatus.CONCLUDED]: () =>
        trackEvents.trackHeaderBackClickFromRegisteredData(this.isOnboarding),
      [ERegistrationUpdateStatus.UNDER_ANALYSIS]: () =>
        trackEvents.trackHeaderBackClickFromUnderAnalysis(this.isOnboarding),
      [ERegistrationUpdateStatus.ANNOUNCEMENT]: () =>
        trackEvents.trackHeaderBackClickFromAnnouncement(this.isOnboarding)
    };

    if (registrationStatus) {
      mapperFaceMatchPage[registrationStatus]();
    }

    this.goBack();
  }

  private goBack() {
    this.$router.back();
  }
}
