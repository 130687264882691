
import { Component, Vue, Emit } from 'vue-property-decorator';

import { FilterSelect } from '@warrenbrasil/nebraska-web';

import { InstrumentFilter } from '@/modules/trade/components/InstrumentFilter';
import { InstrumentFilter as IInstrumentFilter } from '@/modules/trade/services/orders';
import { SelectOption } from '@/modules/trade/views/Earnings/components/types';
import { InstrumentType } from '@/modules/trade/types';

@Component({
  components: {
    InstrumentFilter,
    FilterSelect
  }
})
export default class EarningsIncomingsFilters extends Vue {
  allActivesOptions: IInstrumentFilter[] = [
    {
      type: InstrumentType.Stock,
      description: 'Petrobrás',
      symbol: 'PETR4',
      image: {
        large: 'https://stg.warren.com.br/assets/trade/logos/PETR-3x.png',
        medium: 'https://stg.warren.com.br/assets/trade/logos/PETR-2x.png',
        small: 'https://stg.warren.com.br/assets/trade/logos/PETR-1x.png'
      }
    },
    {
      type: InstrumentType.Stock,
      description: 'Ambev',
      symbol: 'ABEV3',
      image: {
        large: 'https://stg.warren.com.br/assets/trade/logos/ABEV-3x.png',
        medium: 'https://stg.warren.com.br/assets/trade/logos/ABEV-2x.png',
        small: 'https://stg.warren.com.br/assets/trade/logos/ABEV-1x.png'
      }
    }
  ];

  selectedActiveInstrument = 'Todos os ativos';
  allEventsOptions: SelectOption[] = [
    { label: 'Desde o início', value: 1 },
    { label: 'Últimos 12 meses', value: 2 }
  ];

  selectedEventOption = 'Desde o início';

  allExportOptions: SelectOption[] = [
    { label: 'Arquivo CSV', value: 1 },
    { label: 'Arquivo PDF', value: 2 }
  ];

  @Emit('on-select-filter')
  handleSelectFilter(option: SelectOption) {
    return option;
  }

  handleSelectExportOption(option: SelectOption) {
    // TO DO Remove this line when the service is ready
    console.log('SELECTED EXPORT OPTION', option);
  }

  clearFilter() {
    console.log('CLEAR FILTER');
    // TO DO Remove this line when the service is ready
  }
}
