
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { FaceMatchTemplate } from '../FaceMatchTemplate';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HelperView } from '@warrenbrasil/nebraska-web';
import { IRegisterStatus } from './types';
import { ERegistrationUpdateStatus } from '../../types';
import { namespace } from 'vuex-class';
import * as registeredDataTrackEvents from './tracking/registered-data-track-events';
import * as underAnalysisTrackEvents from './tracking/under-analysis-track-events';
import { PageContext } from '../Announcement/page-content-by-context';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: { FaceMatchTemplate, HelperView }
})
export default class RegisterStatus extends Vue {
  @Prop({ type: String, required: true })
  public readonly registrationUpdate!: ERegistrationUpdateStatus;

  @Prop({ type: String, required: false })
  public readonly registrationDate!: string;

  @Prop({ type: String, required: false })
  public readonly customerEmail!: string;

  @Prop({ type: String, default: PageContext.DEFAULT })
  readonly context!: PageContext;

  @Emit('go-back-event')
  goBackEvent() {}

  @CustomerModule.Getter('getCustomerId')
  readonly customerId?: string;

  NebraskaColors = NebraskaColors;
  private isUnderAnalysis = false;

  public registerStatus: IRegisterStatus = {
    icon: '',
    contentTitle: '',
    description: '',
    type: '',
    submitButtonName: ''
  };

  public get isOnboarding(): boolean {
    return this.context === PageContext.ONBOARDING;
  }

  async created() {
    this.checkRegistrationUpdateStatus();
  }

  private checkRegistrationUpdateStatus() {
    if (this.registrationUpdate === ERegistrationUpdateStatus.UNDER_ANALYSIS) {
      this.isUnderAnalysis = true;
      this.setUnderAnalysis();
      underAnalysisTrackEvents.trackLoadUnderAnalysis(this.isOnboarding);
    } else {
      registeredDataTrackEvents.trackLoadRegisteredData(this.isOnboarding);
      this.setRegisteredData();
    }
  }

  public setUnderAnalysis() {
    this.registerStatus.contentTitle = 'Dados em análise';
    this.registerStatus.description = `Nossa equipe está fazendo análise do documento e selfie. Assim que concluirmos, enviaremos um e-mail para ${this.customerEmail}`;
    this.registerStatus.submitButtonName = 'Ok, entendi';
    this.registerStatus.icon = 'ic_progress_clock';
    this.registerStatus.type = 'info';
  }

  public setRegisteredData() {
    this.registerStatus.contentTitle = 'Dados cadastrados';
    this.registerStatus.description = `O documento de identificação e a selfie foram cadastrados em ${this.registrationDate}.`;
    this.registerStatus.submitButtonName = 'Voltar';
    this.registerStatus.icon = 'ic_check_simple';
    this.registerStatus.type = 'positive';
  }

  public submitAction() {
    this.isUnderAnalysis
      ? underAnalysisTrackEvents.trackBodyBackClick(this.isOnboarding)
      : registeredDataTrackEvents.trackBodyBackClick(this.isOnboarding);
    this.$router.back();
  }

  public goToHelpCenter() {
    this.isUnderAnalysis
      ? underAnalysisTrackEvents.trackHelpClick(this.isOnboarding)
      : registeredDataTrackEvents.trackHelpClick(this.isOnboarding);
    this.$router.push({ name: 'help-center' });
  }
}
