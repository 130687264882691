
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  HelperView,
  Modal,
  HeadingDisplay,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary,
  StatusMessage,
  LoaderIndicator,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { WithdrawSteps } from './components/WithdrawSteps';
import { InvestmentSteps } from './components/InvestmentSteps';
import { IOrderReceipt } from '@/modules/wealth/services/wealth-orders/types';
import {
  addBodyScrollWithScrollTo,
  removeBodyScroll
} from '@/modules/common/helpers/browser';
import {
  cancelOrder,
  getOrderReceiptDetails
} from '@/modules/wealth/services/wealth-orders/wealth-orders';
import { ModalSteps } from '@/modules/wealth/views/WealthPortfolioV2/components/Orders/components/OrderModal/types';

export enum States {
  INVESTMENT_BY_PRODUCT,
  WITHDRAW_BY_PRODUCT
}

@Component({
  components: {
    LoaderIndicator,
    HelperView,
    InvestmentSteps,
    WithdrawSteps,
    Modal,
    HeadingDisplay,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    StatusMessage,
    InitialAvatarIcon
  }
})
export default class OrderModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: String, default: null })
  readonly orderId!: string;

  @Watch('isOpen', { immediate: true })
  public toggleBodyScroll(isOpen: boolean) {
    this.modalStep = ModalSteps.Initial;
    if (isOpen) {
      removeBodyScroll();
      this.requestOrderReceipt();
    } else {
      addBodyScrollWithScrollTo();
    }
  }

  readonly NebraskaColors = NebraskaColors;

  orderData: IOrderReceipt | null = null;
  isLoadingOrderData = true;
  isLoadingCancel = false;
  isError = false;
  modalStep = ModalSteps.Initial;

  get state() {
    return this.orderData?.direction === 'Investment'
      ? States.INVESTMENT_BY_PRODUCT
      : States.WITHDRAW_BY_PRODUCT;
  }

  get ModalSteps() {
    return ModalSteps;
  }

  get isInvestment() {
    return this.state === States.INVESTMENT_BY_PRODUCT;
  }

  get headTitle() {
    return this.isInvestment
      ? 'Investimento em carteira'
      : 'Resgate em carteira';
  }

  get title() {
    return this.orderData?.portfolioName || '';
  }

  get subtitle() {
    const label = this.isInvestment ? 'Origem' : 'Destino';
    return `${label}: ${this.orderData?.toPortfolio}`;
  }

  get cancelButtonLabel() {
    return this.isInvestment ? 'Cancelar investimento' : 'Cancelar resgate';
  }

  get cancelHeading() {
    return this.isInvestment ? 'Cancelar investimento?' : 'Cancelar resgate?';
  }

  get progressBar() {
    if (!this.orderData?.historicStatus) return [];
    return this.orderData.historicStatus.map(step => {
      return {
        progress: step.progress,
        description: step.date,
        title: step.value
      };
    });
  }

  async handleCancelOrderClick() {
    if (this.modalStep === ModalSteps.Initial) {
      this.modalStep = ModalSteps.Cancel;
    } else {
      try {
        this.isLoadingCancel = true;
        await cancelOrder(this.orderId);
        this.$emit('reload');
        this.$emit('close');
        this.modalStep = ModalSteps.Initial;
      } catch (e) {
        console.log(e);
        this.modalStep = ModalSteps.CancelError;
      }
    }
    this.isLoadingCancel = false;
  }

  async requestOrderReceipt() {
    this.isLoadingOrderData = true;
    try {
      this.isError = false;
      this.orderData = await getOrderReceiptDetails(this.orderId);
    } catch (e) {
      this.isError = true;
    }
    this.isLoadingOrderData = false;
  }

  mounted() {
    this.modalStep = ModalSteps.Initial;
  }
}
