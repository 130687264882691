import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  page_screen: 'error_screen',
  experience_section: 'home'
});

export function trackLoadGenericError() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackTryAgainClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'try_again',
    page_screen_section: 'header'
  });
}
