
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { VMoney } from 'v-money';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';

@Component({
  components: { FormField },
  directives: { money: VMoney }
})
export default class MoneyInput extends Vue {
  public localAmount: number | null = null;

  @Prop({ type: String, required: false, default: 'money-input' })
  private id!: string;

  @Prop({ type: Number, required: false, default: null })
  private value!: number | null;

  @Prop({ type: Number, required: false, default: 16 })
  private maxNumberLength!: number;

  @Prop({ type: String, required: false })
  private label!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private required!: boolean;

  @Prop({ type: String, default: '' })
  private customErrorMessage!: string;

  @Prop({ type: String, default: '' })
  private error!: string;

  private currentError = '';

  public created() {
    if (this.value !== null) {
      this.localAmount = this.value * 100; // Needed for initial value library conversion
    }
    if (this.value === null && !this.disabled && this.required) {
      this.currentError = this.customErrorMessage;
    }
  }

  public get maxLengthWithMoneyFormat() {
    return this.maxNumberLength + 8;
  }

  public get computedErrorMessage() {
    return this.error || this.currentError;
  }

  @Emit('input')
  public emitInputEvent(event: Event & { inputType: string }, value: string) {
    if (
      event.inputType === 'deleteContentBackward' &&
      this.value === 0 &&
      this.required
    ) {
      this.localAmount = null;
      this.currentError = this.customErrorMessage;

      return null;
    } else {
      this.currentError =
        (this.required && this.disabled) || this.value !== 0
          ? ''
          : this.customErrorMessage;

      return parseFloat(
        value.replace('R$ ', '').replace(/\./g, '').replace(',', '.')
      );
    }
  }

  @Emit('blur')
  public emitBlurEvent() {}

  get formatterOptions() {
    return {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    };
  }
}
