
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  AccordionWrapper,
  Accordion
} from '@/modules/common/components/Accordion';
import { HelperView, SkeletonLoader } from '@warrenbrasil/nebraska-web';
import { EarningsReceivedsTable } from './components/EarningsReceivedsTable';
import { EarningsIncomingsTable } from './components/EarningsIncomingsTable';
import {
  getReceivedEarnings,
  Earning
} from '@/modules/trade/services/earnings';
import { EarningsTableStatus } from '@/modules/trade/views/Earnings/components/EarningsTable/types';
interface AccordionHeader {
  title: string;
  subtitle: string;
  id: number;
}
@Component({
  components: {
    EarningsReceivedsTable,
    EarningsIncomingsTable,
    AccordionWrapper,
    SkeletonLoader,
    HelperView,
    Accordion
  }
})
export default class EarningsAccordions extends Vue {
  @Prop({ type: Array, required: true })
  readonly accordionHeaders!: AccordionHeader[];

  @Prop({ type: Boolean, default: false })
  readonly isEarningsIncoming!: boolean;

  readonly EarningsTableStatus = EarningsTableStatus;
  earnings: Earning[] = [];
  status = EarningsTableStatus.Loading;
  totalReceived = '';
  created() {
    this.fetchReceivedEarnings();
  }

  get isLoading() {
    return this.status === EarningsTableStatus.Loading;
  }

  get isErrorState() {
    return this.status === EarningsTableStatus.Error;
  }

  async fetchReceivedEarnings() {
    this.status = EarningsTableStatus.Loading;
    try {
      const data = await getReceivedEarnings();
      this.earnings = data.earnings;
      this.totalReceived = data.totalReceived.formatted;
      this.status = EarningsTableStatus.Default;
    } catch {
      this.status = EarningsTableStatus.Error;
    }
  }

  handlePageChanged() {
    this.fetchReceivedEarnings();
  }
}
