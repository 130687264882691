import { httpClient } from '@/modules/common/services/http/http-client';
import { TradeInstrumentPositionPrice } from '@/modules/trade/types/instrument';
import {
  PositionsParams,
  GetPositionsResponse,
  GetWatchlistParams,
  GetWatchlistResponse,
  IInstrumentView,
  CreateOrderFeedback,
  CreateOrderBody,
  PositionsView,
  Instrument,
  InstrumentHistorical,
  PositionsSummaryResponse,
  GetStockLendingListParams,
  GetStockLendingListResponse,
  GetOptionsListParams,
  GetOptionsListResponse,
  GetForwardListResponse,
  GetForwardListParams,
  GetCustomOperationsListResponse,
  GetCustomOperationsListParams,
  GetHistoricalOptionsListParams,
  GetHistoricalOptionsListResponse,
  GetInstrumentPositionHistoricalResponse,
  GetInstrumentChartResponse,
  InstrumentPrice,
  InstrumentStatus,
  AcceptOutsideRiskProfileTerm
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function addToWatchlist(symbol: string): Promise<{}> {
  return httpClientWithDefaultOptions.post('/updateWatchlist', {
    symbol
  });
}

export function deleteFromWatchlist(symbol: string): Promise<{}> {
  return httpClientWithDefaultOptions.delete(`/watchlist/${symbol}`);
}

export function getPositions(
  params: PositionsParams,
  abortSignal?: AbortSignal
): Promise<GetPositionsResponse> {
  return httpClientWithDefaultOptions.get(`/positions`, {
    params,
    abortSignal
  });
}

export function getInstrumentPosition(
  symbol: string,
  abortSignal?: AbortSignal
): Promise<TradeInstrumentPositionPrice> {
  return httpClientWithDefaultOptions.get('/instrumentPosition', {
    params: {
      symbol
    },
    abortSignal
  });
}

export function getPositionsView(
  params: PositionsParams,
  abortSignal?: AbortSignal
): Promise<PositionsView<Instrument>> {
  return httpClientWithDefaultOptions.get(`/myAssetsView`, {
    params,
    abortSignal
  });
}

export function getPositionsHistoricalView(
  params: PositionsParams,
  abortSignal?: AbortSignal
): Promise<PositionsView<InstrumentHistorical>> {
  return httpClientWithDefaultOptions.get(`/myAssetsHistoricalView`, {
    params,
    abortSignal
  });
}

export function getWatchlist(
  params: GetWatchlistParams
): Promise<GetWatchlistResponse> {
  return httpClientWithDefaultOptions.get('/getWatchlist', {
    params
  });
}

export function getInstrumentDetailView(
  ticker: string
): Promise<IInstrumentView> {
  return httpClientWithDefaultOptions.get('/instrumentDetailView', {
    params: {
      ticker
    }
  });
}

export function createOrder(
  body: CreateOrderBody
): Promise<CreateOrderFeedback> {
  return httpClientWithDefaultOptions.post('/createOrder', body);
}

export function getPositionsSummary(): Promise<PositionsSummaryResponse> {
  return httpClientWithDefaultOptions.get('/homePosition');
}

export function getStockLendingList(
  params: GetStockLendingListParams,
  abortSignal?: AbortSignal
): Promise<GetStockLendingListResponse> {
  return httpClientWithDefaultOptions.get('/positions/btcList', {
    params,
    abortSignal
  });
}

export function getOptionsList(
  params: GetOptionsListParams,
  abortSignal?: AbortSignal
): Promise<GetOptionsListResponse> {
  return httpClientWithDefaultOptions.get('/positions/options', {
    params,
    abortSignal
  });
}

export function getHistoricalOptionsList(
  params: GetHistoricalOptionsListParams,
  abortSignal?: AbortSignal
): Promise<GetHistoricalOptionsListResponse> {
  return httpClientWithDefaultOptions.get('/positions/historicalOptions', {
    params,
    abortSignal
  });
}

export function getForwardList(
  params: GetForwardListParams,
  abortSignal?: AbortSignal
): Promise<GetForwardListResponse> {
  return httpClientWithDefaultOptions.get('/positions/forwardList', {
    params,
    abortSignal
  });
}

export function getCustomOperationsList(
  params: GetCustomOperationsListParams,
  abortSignal?: AbortSignal
): Promise<GetCustomOperationsListResponse> {
  return httpClientWithDefaultOptions.get('/positions/customOperations', {
    params,
    abortSignal
  });
}

export function getInstrumentPositionHistorical(
  symbol: string,
  abortSignal?: AbortSignal
): Promise<GetInstrumentPositionHistoricalResponse> {
  return httpClientWithDefaultOptions.get('/instrumentPositionHistorical', {
    params: {
      symbol
    },
    abortSignal
  });
}

export function getInstrumentChart(
  symbol: string,
  filter?: string | null,
  abortSignal?: AbortSignal
): Promise<GetInstrumentChartResponse> {
  return httpClientWithDefaultOptions.get('/instrumentChart', {
    params: {
      symbol,
      filter
    },
    abortSignal
  });
}

export function getInstrumentPrice(symbol: string): Promise<InstrumentPrice> {
  return httpClientWithDefaultOptions.get('/instrumentPrice', {
    params: {
      symbol
    }
  });
}

export function getInstrumentStatus(
  ticker: string
): Promise<{ instrument: InstrumentStatus }> {
  return httpClientWithDefaultOptions.get('/instrumentStatus', {
    params: {
      ticker
    }
  });
}

export function acceptOutsideRiskProfileTerm(
  symbol: string
): Promise<AcceptOutsideRiskProfileTerm> {
  return httpClientWithDefaultOptions.post('/acceptOutsideRiskProfileTerm', {
    symbol
  });
}
