
import { Component, Vue } from 'vue-property-decorator';
import { SkeletonLoader, HelperView } from '@warrenbrasil/nebraska-web';
import {
  BarChart,
  Series
} from '@/modules/common/components/charts/v2/BarChart';
import { EarningsInformation } from '@/modules/trade/views/Earnings/components/EarningsInformation';
import { getEarningsChart, AxisY } from '@/modules/trade/services/earnings';
import { EarningsHeader } from './components/EarningsHeader';

@Component({
  components: {
    BarChart,
    EarningsInformation,
    SkeletonLoader,
    HelperView,
    EarningsHeader
  }
})
export default class EarningsChart extends Vue {
  totalReceived = '';
  futureEarnings = '';
  status = 'loading';
  series: Series[] | null = null;
  labels: string[] | null = null;

  created() {
    this.fetchEarningsChart();
  }

  getSeries(axisY: AxisY[]) {
    const series: Series[] = [];
    axisY.forEach(e => {
      const formattedValues = e.values.map(e => e.value);
      series.push({
        data: formattedValues,
        name: e.description
      });
    });
    return series;
  }

  async fetchEarningsChart() {
    try {
      this.status = 'loading';
      const data = await getEarningsChart();
      this.totalReceived = data.totalReceived.formatted;
      this.futureEarnings = data.futureEarnings.formatted;
      this.series = this.getSeries(data.chart.axisY);
      this.labels = data.chart.axisX[0].values;
      this.status = 'default';
    } catch {
      this.status = 'error';
    }
  }
}
