enum ECustomerMaritialStatus {
  Single = 'solteiro',
  Married = 'casado-brasileiro-nato',
  MarriedNaturalized = 'casado-brasileiro-naturalizado',
  MarriedForeign = 'casado-estrangeiro',
  Divorced = 'divorciado',
  StableUnion = 'uniao-estavel',
  Unmarried = 'desquitado',
  Widower = 'viuvo'
}

export default ECustomerMaritialStatus;
