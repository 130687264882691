
import { Component, Mixins } from 'vue-property-decorator';
import { RouteMixin } from '@/modules/common/mixins/route';

import { StorageKeys } from '@/modules/common/services/local-storage';
import { getThemeByReferralId } from '@/modules/common/services/http/account';
import { GtmTracker } from '@/modules/common/services/analytics';
import { WarrenSession } from '@/modules/common/services/session';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  SigninTemplate,
  RoundedIcon,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { LoginHero } from '@/modules/authentication/views/SignIn/components/LoginHero';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { setNebraskaScheme } from '@/modules/common/services/theme';

import { MobileBannerToApp } from '../../components/MobileBannerToApp';
import { EmailStep } from '../../components/EmailStep';
import { SignUpStep } from '../../components/SignUpStep';
import { trackPageView, trackSendEmail } from '../../helpers';

export enum EFastRegister {
  emailStep = 'EmailStep',
  signUpStep = 'SignUpStep'
}

export enum TrackActions {
  Banner = 'Banner',
  Finished = 'Finished',
  Lead = 'Lead',
  Started = 'Started'
}

interface URLParams {
  email?: string;
  referralId?: string;
  brand?: string;
  brandName?: string;
  isAffiliate?: string;
}

@Component({
  components: {
    LoaderIndicator,
    ContextColorProvider,
    SigninTemplate,
    LoginHero,
    MobileBannerToApp,
    RoundedIcon,
    EmailStep,
    SignUpStep,
    FadeTransition
  }
})
export default class FastRegisterView extends Mixins(RouteMixin) {
  private currentStep = EFastRegister.emailStep;
  private NebraskaColors = NebraskaColors;
  private leadCustomerId = '';

  private URLParams: URLParams = {};

  private isWhitelabel = !!localStorage.getItem(StorageKeys.WhitelabelTheme);
  private isLoadingTheme = true;

  private brandName?: string;
  private brandLogo?: string;
  private brandDomain = '';
  private email = '';

  private async mounted() {
    this.URLParams = this.$route.query;
    const isUserLogged = !!localStorage.getItem('warren.accounts.accessToken');
    if (isUserLogged && !this.URLParams.referralId) {
      this.$router.push({ path: 'suitability' });
    } else {
      this.clearStorage();
    }
    trackPageView();
    this.track(TrackActions.Started);
    this.setTemplateTheme();
  }

  private async setTemplateTheme() {
    this.isWhitelabel = false;
    localStorage.removeItem(StorageKeys.WhitelabelTheme);
    localStorage.removeItem(StorageKeys.Theme);

    if (this.URLParams.referralId) {
      if (this.URLParams.brandName) {
        this.isWhitelabel = true;
        this.brandDomain = this.URLParams.brand ?? '';

        await getThemeByReferralId(this.URLParams.referralId).then(res => {
          const partner = res;

          localStorage.setItem(
            StorageKeys.Theme,
            JSON.stringify(partner.theme)
          );
          localStorage.setItem(StorageKeys.WhitelabelTheme, 'whitelabel');

          if (partner) {
            setNebraskaScheme(partner.theme);
            this.brandName = partner.theme.brandName!;
            this.brandLogo = partner.theme.logo;
          }
        });
      }
    }

    this.isLoadingTheme = false;
  }

  private onCompleteStep(customerId: string, email?: string) {
    switch (this.currentStep) {
      case EFastRegister.emailStep:
        this.leadCustomerId = customerId;
        this.email = email ?? '';
        this.currentStep = EFastRegister.signUpStep;
        this.track(TrackActions.Lead);
        trackSendEmail();
        break;
      case EFastRegister.signUpStep:
        this.track(TrackActions.Finished);
        this.$router.push({ path: 'suitability' });
    }
  }

  private gtmTrack(action: TrackActions) {
    GtmTracker.trackEvent({
      action,
      category: 'Activation',
      customerId: this.leadCustomerId,
      label: 'Fast Register'
    });
  }

  private track(action: TrackActions) {
    this.gtmTrack(action);
  }

  private returnToHome() {
    if (this.currentStep === EFastRegister.emailStep) {
      this.goToPath('/');
    } else {
      this.currentStep = EFastRegister.emailStep;
    }
  }

  private clearStorage() {
    WarrenSession.clean([
      StorageKeys.AccessToken,
      StorageKeys.ImpersonateToken,
      StorageKeys.CustomReferralId
    ]);
  }

  private get headerIcon(): string {
    return this.isSignUpStep ? 'ic_arrow_left' : 'ic_cancel';
  }

  private get isSignUpStep() {
    return this.currentStep === EFastRegister.signUpStep;
  }

  private get removeTemplateHeader() {
    return !this.isWhitelabel;
  }
}
