import { ICustomer } from '@/types/models';

export interface INotificationsConfig {
  id: string;
  email: boolean;
  push: boolean;
}

export interface IBrand {
  _id: string;
  name: string;
  owner: string;
  __v: number;
  createdAt: Date;
  domain: string;
  features: string[];
  excludeFeatures: string[];
  defaultTax: string;
  hasFeePayments: boolean;
  isBrandManaged: boolean;
  theme: string;
}

export interface IUserB2B extends ICustomer {
  _id: string;
  builderType: string;
  totalBalance: number;
  managementTax: string;
  portfolioFirstFinishedAt: Date;
  registerLastVerifiedAt: Date;
  registerLastStartedAt: Date;
  registerLastModifiedAt: Date;
  registerLastFinishedAt: Date;
  crmCommunicationType: string;
  notificationsConfig: INotificationsConfig[];
  depositAcceptedCount: number;
  depositFirstAcceptedAt: Date;
  depositLastAcceptedAt: Date;
  promotionalCode?: any;
  hasExternalInfo: boolean;
  brand: IBrand;
  suitabilityFinishedCount: number;
}

export interface IPrimary {
  fontColor: string;
  color: string;
  colorLight: string;
}

export interface IComponent {
  color: string;
  colorLight: string;
  fontColor: string;
  type: string;
}

export interface IThemeByReferralResponse {
  _id: string;
  email: string;
  name: string;
  type: string;
  phone: string;
  tradeMark: string;
  theme: ICustomTheme;
}

export enum EThemeType {
  BrandManaged = 'BrandManaged',
  WarrenManaged = 'WarrenManaged'
}

export interface ICustomTheme {
  _id: string;
  name: string;
  type: string;
  brandDomain?: string;
  brandName?: string;
  brand?: any;
  primary: IPrimary;
  logo?: any;
  avatar?: any;
  manifest?: any;
  fontHighlightingFamily?: any;
  fontBodyFamily?: any;
  components: IComponent[];
  colors: IColors;
  referralId: string;
}

export enum EColorOptionsKeys {
  tradingOverPrimary = 'tradingOverPrimary',
  tradingPrimary = 'tradingPrimary',
  wealthOverPrimary = 'wealthOverPrimary',
  wealthPrimary = 'wealthPrimary',
  cashOverPrimary = 'cashOverPrimary',
  cashPrimary = 'cashPrimary',
  accountOverPrimary = 'accountOverPrimary',
  accountPrimary = 'accountPrimary'
}

export interface IColorOptions {
  tradingOverPrimary: string;
  tradingPrimary: string;
  wealthOverPrimary: string;
  wealthPrimary: string;
  cashOverPrimary: string;
  cashPrimary: string;
  accountOverPrimary: string;
  accountPrimary: string;
}

export interface IColors {
  light: IColorOptions;
  dark: IColorOptions;
}

export enum StorageKeys {
  AccessToken = 'warren.accounts.accessToken',
  Brand = 'warren.brand',
  CustomerId = 'warren.customerId',
  FastRegisterSuitability = 'warren.fastRegister.suitability',
  Features = 'warren.features',
  PartnerFeatures = 'warren.partner.features',
  ForceWrnUserUpdate = 'warren.forceWrnUserUpdate',
  HasDownloadedFeeStatement = 'warren.partner.has_downloaded_fee_statement',
  HasUsedPositionDetailsButton = 'warren.has-used-position-details-button',
  HasShowedInvestmentViewTutorial = 'warren.has-showed-investment-view-tutorial',
  ImpersonateToken = 'warren.accounts.impersonateAccessToken',
  IsBrandOwner = 'warren.brandOwner',
  LastPartnerDashRoute = 'warren.partner.lastDashRoute',
  PartnerDashNewsInformation = 'warren.partner.dashNewsInformation',
  PartnerProfileNewsInformation = 'warren.partner.profileNewsInformation',
  PartnerStatementNewsInformation = 'warren.partner.statementNewsInformation',
  PartnerPortfolioNewsInformation = 'warren.partner.portfolioNewsInformation',
  NewPartnerFeaturesViewed = 'warren.partnerNewFeaturesViewed',
  Partner = 'warren.accounts.userB2b',
  IsPartnerAccountSession = 'warren.accounts.partnerAccountSession',
  isImpersonateByIntranet = 'warren.accounts.impersonateByIntranet',
  PartnerShowMoney = 'warre.partner.showMoney',
  ShowedPortfoliosTemplateTutorial = 'warren.partner.showed_portfolios_template_tutorial',
  TestABFastRegisterPasswordTip = 'fast-register-password-tip',
  Theme = 'warren.theme',
  Trademark = 'warren.fastRegister.tradeMark',
  WhitelabelTheme = 'warren.themes_cache.whitelabel.name',
  DarkMode = 'warren.darkMode',
  SessionWelcome = 'session.welcome',
  FeatureSurvey = 'warren.feature-survey',
  PreferredChartType = 'warren.preferred-chart-type',
  TypeTransactions = 'warren.type-transactions',
  InvestmentResultSummary = 'warren.investment-result-summary',
  LastInvestmentPortfolio = 'warren.last-investment-portfolio',
  LastRedeemInformation = 'warren.last-redeem-portfolio-info',
  LastRedeemWithdrawInformation = 'warren.last-redeem-withdraw-portfolio-info',
  CustomReferralId = 'warren.accounts.customerReferralId',
  RegulatoryUpdateRoutes = 'warren.regulatory-update-routes',
  ScheduleInvestmentMessage = 'warren.schedule-investment-message',
  HasOpenedWatchlistModal = 'warren.has-opened-watchlist-modal',
  HasOpenedProfitModal = 'warren.has-opened-profit-modal',
  HasOpenedCerberusModal = 'warren.has-opened-cerberus-modal',
  SelectedPerformanceFilters = 'warren.selected-consolidated-performance-filters'
}
