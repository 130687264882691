
import { Component, Prop, Vue } from 'vue-property-decorator';
import scrollToElement from 'scroll-to-element';

import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';

import { getBrandInitial } from '@/modules/common/services/partner';
import { getString, getEnum } from '@/modules/common/helpers/resources';
import { ICustomer } from '@/types/models/Customer';
import { IPersonalProfile } from '@/types/models';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { NotificationTypes } from '@/modules/common/services/http/notification';

import { RegisterTemplate } from '../../components/RegisterTemplate';
import { IAddress } from '../../types';
import { ProfessionForm } from './components/ProfessionForm';
import { nationalityValidation } from './helpers';
import {
  trackPageView,
  createEventData,
  trackFinishAdditionalDataEvent
} from '../../helpers/track-additional-events';
import { register } from '../../services/register/api';

@Component({
  components: {
    ButtonPrimary,
    FormField,
    ProfessionForm,
    RegisterTemplate
  }
})
export default class NationalityOccupation extends Vue {
  @Prop({ type: Function, required: false })
  private onRegisterFinished?: (data: any) => void;

  @Prop({ type: Object, default: () => ({ personalProfile: {} }) })
  private user!: ICustomer;

  private personalProfile: IPersonalProfile = {
    nationality: 'brasileiro-nato',
    birthCity: '',
    birthState: '',
    education: '',
    profession: '',
    workOrganization: ''
  };

  private errors: YupValidationErrors = {};
  private singleErrors: YupValidationErrors = {};
  private isLoading = false;
  private showSummary = false;
  private getString = getString;
  private getEnum = getEnum;
  private isPageReadyToSubmit = true;
  private hasEducationRegistered!: boolean;

  private mounted() {
    this.populatePersonalProfile();
    trackPageView();
  }

  private created() {
    this.hasEducationRegistered = !!this.user.personalProfile?.education;
  }

  private populatePersonalProfile() {
    const userAddress = this.user.address as IAddress;
    const { personalProfile: userPersonalProfile } = this.user;

    this.personalProfile = {
      nationality: userPersonalProfile?.nationality ?? 'brasileiro-nato',
      birthCity: userPersonalProfile?.birthCity || userAddress?.city,
      birthState: userPersonalProfile?.birthState || userAddress?.state,
      education: userPersonalProfile?.education,
      hasProfession: userPersonalProfile?.hasProfession,
      profession: userPersonalProfile?.profession,
      professionId: userPersonalProfile?.professionId,
      workOrganization: userPersonalProfile?.workOrganization,
      workOrganizationCnpj: userPersonalProfile?.workOrganizationCnpj
    };
  }

  private conversationFinished() {
    this.showSummary = true;
  }

  private setUserProfession(professionChanges: IPersonalProfile) {
    this.personalProfile = {
      ...this.personalProfile,
      profession: professionChanges.profession,
      professionId: professionChanges.professionId,
      workOrganization: professionChanges.workOrganization,
      hasProfession: professionChanges.hasProfession,
      workOrganizationCnpj: professionChanges.workOrganizationCnpj
    };

    this.blurErrorCheck('personalProfile.profession');
  }

  private openZendeskChat() {
    window.openChat();
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) {
      return err[0];
    }

    const singleErr = this.singleErrors[path];
    if (singleErr) {
      return singleErr[0];
    }

    return '';
  }

  private getPOSTData(): { user: Partial<ICustomer> } {
    const userData: { user: Partial<ICustomer> } = {
      user: {
        personalProfile: this.personalProfile
      }
    };

    return userData;
  }

  private focusOnErrorElement() {
    const form = this.$refs.nationalityOccupationForm as HTMLElement;
    const errorElement = form.querySelector('.wrn-color-error');
    const errorField = errorElement?.parentElement?.parentElement;

    if (errorField) {
      scrollToElement(errorField, {
        offset: 0,
        duration: 300
      });
    }
  }

  private handleTrackingEvent(field: string) {
    const [, fieldName] = field.split('.');
    createEventData(fieldName, this.personalProfile);
  }

  private async blurErrorCheck(key: string) {
    try {
      if (key !== 'personalProfile.profession') {
        this.handleTrackingEvent(key);
      }

      await nationalityValidation.validate(this.getPOSTData());

      this.errors[key] = [];
      this.singleErrors[key] = [];
      this.$forceUpdate();
    } catch (errors) {
      this.errors[key] = errors[key];
      this.singleErrors[key] = errors[key];
      this.$forceUpdate();
    }
  }

  private get getPartnerBrandName() {
    return getBrandInitial(this.user.partnerInfos);
  }

  private changePageReadyToSubmit(isPageReadyToSubmit: boolean) {
    this.isPageReadyToSubmit = isPageReadyToSubmit;
  }

  private async onSubmit() {
    if (!this.isPageReadyToSubmit) {
      return;
    }
    trackFinishAdditionalDataEvent();
    if (this.isLoading) return null;

    const data = this.getPOSTData();

    try {
      await nationalityValidation.validate(data.user);

      this.errors = {};
    } catch (errors) {
      this.errors = errors;

      if (!this.personalProfile.professionId) {
        const hasSelectedAProfessionFromList =
          !!this.personalProfile.professionId;
        if (hasSelectedAProfessionFromList) {
          this.errors = {
            ...this.errors,
            'personalProfile.professionId': ['Escolha uma profissão da lista']
          };
        }

        this.$nextTick(() => {
          this.focusOnErrorElement();
        });
      }

      return;
    }

    try {
      this.isLoading = true;

      const registerUpdateResponse = await register(data);
      this.isLoading = false;

      this.$emit('nextStep', registerUpdateResponse);

      if (this.onRegisterFinished)
        this.onRegisterFinished(registerUpdateResponse);
    } catch (err) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          err.description ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });

      this.isLoading = false;
    }
  }
}
