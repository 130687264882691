
import { Component, Vue } from 'vue-property-decorator';
import { EarningsChart } from './components/EarningsChart';
import { EarningsReceivedsFilters } from './components/EarningsReceivedsFilters';
import { EarningsAccordions } from '../EarningsAccordions';
import { SelectOption } from '@/modules/trade/views/Earnings/components/types';

@Component({
  components: {
    EarningsChart,
    EarningsAccordions,
    EarningsReceivedsFilters
  }
})
export default class EarningsReceiveds extends Vue {
  accordionHeaders = [
    {
      title: 'Junho de 2022',
      id: 1,
      subtitle: 'R$ 40,77'
    },
    {
      title: 'Maio de 2022',
      id: 2,
      subtitle: 'R$ 40,77'
    },
    {
      title: 'Abril de 2022',
      id: 3,
      subtitle: 'R$ 40,77'
    },
    {
      title: 'Março de 2022',
      id: 4,
      subtitle: 'R$ 40,77'
    },
    {
      title: 'Fevereiro de 2022',
      id: 5,
      subtitle: 'R$ 40,77'
    }
  ];

  onSelectFilter(filter: SelectOption) {
    console.log('FILTER SELECTED', filter);
    // TO DO Remove this line when the service is ready
  }
}
