
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MarginInformation } from './components/MarginInformation';
import { ButtonSecondary } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    MarginInformation,
    ButtonSecondary
  }
})
export default class MarginHeader extends Vue {
  @Prop({ type: String, required: true })
  readonly totalAllocatedMargin!: string;

  @Prop({ type: String, required: true })
  readonly totalMarginInUse!: string;

  @Prop({ type: String, required: true })
  readonly totalAvailableMargin!: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  deallocateMargin() {
    console.log('deallocateMargin');
  }
}
