
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Options, SeriesOptionsType } from 'highcharts';
import { Chart } from 'highcharts-vue';
import { configureBarChartOptions } from './helper';

@Component({
  components: {
    Highcharts: Chart
  }
})
export default class BarChartV2 extends Vue {
  @Prop({ type: Array, required: false })
  readonly labels!: string[];

  @Prop({ type: String, required: false, default: '450' })
  readonly height!: string;

  @Prop({ type: Array, required: true })
  readonly series!: SeriesOptionsType[];

  @Ref() readonly highchartsRef!: any;

  chartOptions: Options = {};

  created() {
    // Previne o gráfico ser iniciado com a altura padrão do highcharts e causar um "flick"
    // quando a altura correta é setada no mounted
    this.chartOptions = {
      chart: {
        height: this.height
      }
    };
  }

  mounted() {
    this.setChartOptions();
  }

  private setChartOptions() {
    this.chartOptions = configureBarChartOptions(this.series);
  }
}
