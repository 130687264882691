
import { Component, Vue } from 'vue-property-decorator';
import { getString } from '@/modules/common/helpers/resources';
import {
  checkResetToken,
  commitResetToken,
  unblockHash
} from '@/modules/common/services/http/account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  BaseIcon,
  BaseText,
  ButtonPrimary,
  Heading,
  InputPassword,
  Modal,
  ModalButton,
  NavBar,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { Notification } from '@/modules/common/components/Notification';
import { verifyPassword } from '@/modules/authentication/helpers';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { debounce } from 'lodash-es';
@Component({
  components: {
    BaseIcon,
    LoaderIndicator,
    BaseText,
    ButtonPrimary,
    Heading,
    InputPassword,
    Modal,
    ModalButton,
    NavBar,
    Notification,
    BrandImage
  }
})
export default class PasswordResetView extends Vue {
  private isLoading = true;
  private comittingSetup = false;
  private getString = getString;
  private setupToken = '';
  private password = '';
  private passwordConfirm = '';
  private passwordScore = 0;
  private isPasswordValid = false;
  private passwordMessage = '';
  private unblockModalOpen = false;
  private progressColor = NebraskaColors.statusInfo;
  private NebraskaColors = NebraskaColors;
  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;
  private async mounted() {
    this.setupToken = this.$route.params.token;
    await checkResetToken({ hash: this.setupToken }).then(
      () => {
        this.isLoading = false;
      },
      error => {
        if (error.message === 'error_account_blocked') {
          this.unblockModalOpen = true;
        } else {
          this.$notify({
            group: 'wrn-resetpassword-notification',
            text: 'OPS! ESTA CHAVE DE ACESSO TEMPORÁRIA É INVÁLIDA',
            type: NotificationTypes.error
          });
          setTimeout(() => {
            this.$router.push({ name: 'v3' });
          }, 2000);
        }
      }
    );
  }

  private async commitSetup() {
    this.comittingSetup = true;
    await commitResetToken({
      hash: this.setupToken,
      password: this.password
    }).then(
      () => {
        this.$notify({
          group: 'wrn-resetpassword-notification',
          text: 'Senha alterada com sucesso.',
          type: NotificationTypes.success
        });
        this.$router.push({ name: 'v3' });
      },
      () => {
        this.comittingSetup = false;
        this.$notify({
          group: 'wrn-resetpassword-notification',
          text: 'Não foi possivel registrar a nova senha.',
          type: NotificationTypes.error
        });
      }
    );
  }

  private closeUnblockModal() {
    this.$router.push({ name: 'v3' });
  }

  private async unblockAccount() {
    await unblockHash({ hash: this.setupToken, type: 'reset-password' }).then(
      () => {
        this.$notify({
          group: 'wrn-resetpassword-notification',
          text: this.getString('dialog_unblock_success'),
          type: NotificationTypes.success
        });
      },
      () => {
        this.comittingSetup = false;
        this.$notify({
          group: 'wrn-resetpassword-notification',
          text: 'Não foi possivel desbloquear.',
          type: NotificationTypes.error
        });
      }
    );
  }

  private get enableButton() {
    return this.isPasswordValid && this.password === this.passwordConfirm;
  }

  private debounceVerifyPassword = debounce(this.verifyPassword, 300);
  onInputChange() {
    this.isPasswordValid = false;
    this.debounceVerifyPassword();
  }

  private async verifyPassword() {
    const response = await verifyPassword(this.password);
    if (response) {
      this.passwordScore = response.passwordScore;
      this.passwordMessage = response.passwordMessage;
      this.isPasswordValid = response.isPasswordValid;
      this.progressColor = response.progressColor;
    }
  }

  private get passwordStrengthColor() {
    if (this.passwordScore <= 0.1) {
      return NebraskaColors.statusNegative;
    } else {
      return this.progressColor;
    }
  }
}
