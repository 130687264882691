
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonPrimary, Heading, BaseText } from '@warrenbrasil/nebraska-web';

import { getString } from '@/modules/common/helpers/resources';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { ICustomer } from '@/types/models/Customer';
import { FileUpload } from './components/FileUpload';
import { NotificationTypes } from '@/modules/common/services/http/notification';

import { RegisterTemplate } from '../../components/RegisterTemplate';
import { IDocument } from '../../types';
import {
  trackPageView,
  createEventData,
  trackFinishUploadDataEvent
} from '../../helpers/track-upload-pii-events';
import { documentUpload } from '../../services/register/api';

enum KeyType {
  FrontDocument = 'frontDocument',
  BackDocument = 'backDocument',
  FrontAddress = 'frontAddress'
}

enum NamesType {
  FrontDocument = 'document_front',
  BackDocument = 'document_back',
  FrontAddress = 'address_front'
}

interface DocumentFile {
  value: string;
  documentIcon: string;
  colorName: any;
  name: NamesType;
  id: KeyType;
  label: string;
  error: string;
  hasError: boolean;
  required: boolean;
}

@Component({
  components: {
    BaseText,
    ButtonPrimary,
    FileUpload,
    Heading,
    RegisterTemplate
  }
})
export default class DocumentsUpload extends Vue {
  @Prop({ type: Object, default: () => ({ document: {}, address: {} }) })
  private user!: ICustomer;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private documentState: Record<KeyType, DocumentFile> = {
    frontDocument: {
      value: '',
      documentIcon: 'ic_documents_rg_front',
      colorName: NebraskaColors.themePrimary,
      name: NamesType.FrontDocument,
      id: KeyType.FrontDocument,
      label: 'Imagem da frente (com ou sem verso)',
      error: getString('common_field_document_imagefront_error_required'),
      hasError: false,
      required: true
    },
    backDocument: {
      value: '',
      documentIcon: 'ic_documents_rg_back',
      colorName: NebraskaColors.themePrimary,
      name: NamesType.BackDocument,
      id: KeyType.BackDocument,
      label: 'Imagem do verso (se não coube na primeira foto)',
      error: '',
      hasError: false,
      required: false
    },
    frontAddress: {
      value: '',
      documentIcon: 'ic_documents_address',
      colorName: NebraskaColors.themePrimary,
      name: NamesType.FrontAddress,
      id: KeyType.FrontAddress,
      label: 'Imagem da frente do comprovante de endereço',
      error: getString('common_field_document_addressimage_error_required'),
      hasError: false,
      required: true
    }
  };

  private isLoading = false;
  private get getString() {
    return getString;
  }

  private async mounted() {
    /**
     * Aqui foi necessário forçar uma nova requisição para as FFs,
     * pois existe um teste AB via FF sendo aplicado pela Api,
     * remover a linha abaixo assim que o teste AB for desativado.
     **/
    trackPageView();
    await FeatureFlagsModule.fetchFeatureFlags();
  }

  private lineBreak(text: string) {
    return text.replace(/\\n/g, '<br>');
  }

  private getError(key: KeyType) {
    if (!this.documentState[key].hasError) return '';
    return this.documentState[key].error;
  }

  private notifyInfo(text: string) {
    this.$notify({ group: 'register', text, type: NotificationTypes.alert });
  }

  private validate(key: KeyType, value?: string) {
    createEventData('upload_pii_document', key);
    const hasError = !value;
    this.documentState[key].hasError = hasError;
  }

  private setError(key: KeyType, text: string) {
    this.$notify({ group: 'register', text, type: NotificationTypes.error });
    this.documentState[key].hasError = true;
  }

  private get isValid() {
    return Object.values(this.documentState).every(({ hasError }) => !hasError);
  }

  private async onSubmit() {
    trackFinishUploadDataEvent();
    this.validate(
      KeyType.FrontDocument,
      this.documentState.frontDocument.value
    );
    if (this.isLoading || !this.isValid) return null;
    this.$emit('nextStep');
  }

  private getProps(key: KeyType) {
    const { required, hasError, ...props } = this.documentState[key];
    return {
      ...props,
      fileUploadApi: this.fileUploadApi(props.name),
      class: [{ 'form-error': hasError, required }],
      error: this.getError(key)
    };
  }

  private get documentTitle() {
    const { type } = this.user.document as IDocument;
    if (type === 'rg') return 'Envie uma foto do seu RG';
    if (type === 'cnh') return 'Envie uma foto da sua CNH';
    return 'Envie uma foto do seu documento';
  }

  private fileUploadApi(name: NamesType) {
    return async (data: FormData) => {
      data.append('type', name);
      return documentUpload(this.user._id, data).then(({ path }) => path);
    };
  }
}
