import { isImpersonate } from '@/modules/common/services/partner';

export enum RedirectList {
  Suitability = '/v2/register/#/suitability',
  CustomerIdentification = '/v2/register/#/customer-identification'
}

export default function redirectTo(destination: RedirectList) {
  let url = window.location.origin + destination;
  if (
    window.location.origin.includes('amplify') ||
    window.location.origin.includes('localhost')
  ) {
    url = 'https://stg.warren.com.br' + destination;
  }
  window.location.assign(
    `${url}${isImpersonate() ? '?isImpersonate=true' : ''}`
  );
}
