/*
 ** Manter esse arquivo organizado por ordem alfabética
 */
export enum FeatureFlags {
  CS_CHAT = 'CS_CHAT',
  ENHANCE_TRANSACTIONS_BBB = 'ENHANCE_TRANSACTIONS_BBB',
  MFA_ENABLE_PROCESS = 'MFA-ENABLE-PROCESS',
  ONETRUST = 'br.com.warren.b2b-gama.onetrust-website',
  PARTNER_CREATE_INVESTOR_ACCOUNT = 'PARTNER_CREATE_INVESTOR_ACCOUNT',
  TRADE_3_0 = 'TRADE_3_0',
  WARREN_HOME_BROKER = 'WARREN_HOME_BROKER',
  WARREN_VERSION_3 = 'WARREN_VERSION_3',
  WEALTH_3_0 = 'WEALTH_3_0',
  PARTNER_TYPEFORM = 'PARTNER_TYPEFORM',
  DISABLE_WARREN_BUILDER = 'DISABLE_WARREN_BUILDER',
  STATEMENT_DOWNLOAD_B2C = 'br.com.warren.banking-cash.statement-download-b2c',
  PLATFORM_MAINTENANCE = 'br.com.warren.foundation.platform-maintenance',
  TUTORIAL_DEPOSIT_TRANSACTION = 'br.com.warren.b2b-omega.tutorial-deposit-transaction',
  FEATURE_SURVEY = 'br.com.warren.ip-tribe-pex.csat',
  TRADE_REQUEST_TRADEMAP = 'br.com.warren.trade.request-trademap',
  TRADE_ALLOCATION_SUBSCRIBES = 'br.com.warren.trade.subscription-listing',
  ENABLE_FACE_MATCH = 'br.com.warren.banking-security.enable-ocr-facematch',
  ENABLE_MFA_BY_PUSH = 'br.com.warren.banking-authentication.enable-mfa-by-push',
  TRADE_EVENTS_PROVISIONED = 'br.com.warren.trade.enable-provisioned-events',
  WALLET_WITHDRAW_BY_PRODUCT = 'br.com.warren.pro.redeem-by-products',
  TRADE_EARNINGS_PAGE_V1 = 'br.com.warren.black-ops.trade-earnings-page-v1',
  TRADE_CUSTODY_BTC = 'br.com.warren.trade.btc-contract-details',
  TRADE_COLLATERAL_MARGIN_V1 = 'br.com.warren.trade.collateral-margin',
  TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW = 'br.com.warren.trade.enable-new-broker-client-flow',
  CASH_CR_RATE_COSTS = 'br.com.warren.banking.cr-rate-costs',
  NEW_WALLET_WITHDRAW_DETAILS = 'br.com.warren.banking-transactional.new-wallet-withdrawal-details',
  WITHDRAWAL_CONVERSATION = 'br.com.warren.banking.conversation-withdrawal',
  FIXED_INCOME_REFERENCE_VALUE = 'br.com.warren.ip-myi.fixed-reference-value',
  FUTURE_TRANSACTIONS = 'br.com.warren.banking-cash.accounting-future-transactions',
  ACCOUNT_PROCESSING_WARNING = 'br.com.warren.banking.enable-account-processing-warning',
  SUITABILITY_SDUI_B2B = 'br.com.warren.banking-onboarding.suitability-sdui-b2b',
  WITHDRAW_WITH_TEMPLATES = 'br.com.warren.banking-payments.withdraw-with-templates',
  ENABLE_BROKERAGE_FEE_ON_COSTS_PAGE = 'br.com.warren.broker.enable-brokerage-fee-on-costs-page',
  ENABLE_BROKERAGE_FEE = 'br.com.warren.broker.enable-brokerage-fee',
  REGULATORY_UPDATE = 'br.com.warren.client-onboarding.regulatory-update',
  ENABLE_CUSTOM_OPERATIONS_LIST = 'br.com.warren.product-picking.enable-custom-operations-list',
  ENABLE_HISTORICAL_OPTIONS_LIST = 'br.com.warren.product-picking.enable-historical-options-list',
  ENABLE_TRANSIT_AMOUNT = 'br.com.warren.wealth-portfolios.enable-transit-amount',
  ENABLE_PORTFOLIO_WITHDRAW = 'br.com.warren.wealth-portfolios.enable-web-portfolio-withdraw',
  ENABLE_STATEMENTS_AND_NOTES_ON_INDEX_PORTFOLIO = 'br.com.warren.product-picking.enable-notes-and-statement-page-on-index-portfolio',
  SHOW_RLP_PAGE = 'br.com.warren.product-picking.show-retail-liquidity-provider-page',
  ENABLE_NEW_FEES_CHARGE = 'br.com.warren.fees-service.enable-new-fees-charge',
  ENABLE_PROFIT_VIEW = 'br.com.warren.experience.enable-profit-view',
  ENABLE_MARGIN_ALLOCATION = 'br.com.warren.experience.enable-margin-allocation',
  ENABLE_CERBERUS_WELCOME = 'br.com.warren.experience.enable-cerberus-welcome'
}
