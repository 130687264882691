
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseText, SkeletonLoader } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
@Component({
  components: {
    BaseText,
    SkeletonLoader
  }
})
export default class EarningsInformation extends Vue {
  @Prop({ type: String, required: true })
  readonly text!: string;

  @Prop({ type: String, required: true })
  readonly value!: string;

  @Prop({ type: String, default: '' })
  readonly subtitle!: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  readonly NebraskaColors = NebraskaColors;
}
