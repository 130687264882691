
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop({ type: String, required: true })
  private customWidth!: string;

  @Prop({ type: String, required: true })
  private customHeight!: string;

  @Prop({ type: String, required: false })
  private backgroundColor?: string;

  @Prop({ type: Boolean, required: false })
  private radius?: boolean;

  @Prop({ type: Boolean, default: false })
  private step?: boolean;

  @Prop({ type: String, default: '' })
  private progressColor?: string;

  private get computedProgress() {
    // display circle step
    if (this.step && this.customWidth === '0') {
      return `${this.customHeight}px`;
    }

    return `${this.customWidth}%`;
  }
}
