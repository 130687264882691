import { httpClient } from '@/modules/common/services/http/http-client';
import {
  GetOrdersParams,
  GetOrdersResponse,
  GetOrdersSummaryResponse,
  GetOrdersSummaryParams,
  ChangePortabilityAveragePriceResponse,
  OrderDetailsResponse,
  GetCustomerOrdersInstrumentsResponse,
  GetCustomerOrdersInstrumentsParams
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getOrders(
  params: GetOrdersParams,
  abortSignal?: AbortSignal
): Promise<GetOrdersResponse> {
  return httpClientWithDefaultOptions.get('/orders', {
    params,
    abortSignal
  });
}

export function getOrdersSummary(
  params: GetOrdersSummaryParams
): Promise<GetOrdersSummaryResponse> {
  return httpClientWithDefaultOptions.get('/orders/summary', {
    params
  });
}

export function getOrderDetails(
  id: string,
  abortSignal?: AbortSignal
): Promise<OrderDetailsResponse> {
  return httpClientWithDefaultOptions.get(`/order/${id}`, {
    abortSignal
  });
}

export function changePortabilityAveragePrice(
  id: string,
  averagePrice: number,
  abortSignal?: AbortSignal
): Promise<ChangePortabilityAveragePriceResponse> {
  return httpClientWithDefaultOptions.put(
    `/update-quote-value`,
    {
      orderId: id,
      quoteValue: averagePrice
    },
    {
      abortSignal
    }
  );
}

export function getCustomerOrdersInstruments(
  params: GetCustomerOrdersInstrumentsParams,
  abortSignal?: AbortSignal
): Promise<GetCustomerOrdersInstrumentsResponse> {
  return httpClientWithDefaultOptions.get('/customer-instruments', {
    params,
    abortSignal
  });
}
