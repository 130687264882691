import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { Route } from 'vue-router';
import { trackSuitability } from '../suitability/views/Suitability/track-events';
import redirectTo, { RedirectList } from './redirect-to';

function isSuitabilitySduiB2B() {
  return FeatureFlagsModule.isEnabled(FeatureFlags.SUITABILITY_SDUI_B2B);
}

export default function sduiSuitabilityGuard(
  _to: Route,
  _from: Route,
  next: Function
) {
  if (isSuitabilitySduiB2B()) {
    trackSuitability('Suitability Started');
    redirectTo(RedirectList.Suitability);
    return;
  }

  next();
}
