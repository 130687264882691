import { GtmTracker } from '@/modules/common/services/analytics';

const trackSignUpTermsEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup_terms',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackSignUpTermsEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackAccepted() {
  trackSignUpTermsEvent({
    event_type: 'click',
    event_name: 'agree'
  });

  trackSignUpTermsEvent({
    event_type: 'click',
    event_name: 'accepted_terms_of_service',
    is_conversion: true
  });
}
