
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EarningsTable } from '../EarningsTable';
import { HelperView } from '@warrenbrasil/nebraska-web';
import {
  getIncomingEarnings,
  Earning
} from '@/modules/trade/services/earnings';
import { EarningsTableStatus } from '../EarningsTable/types';
@Component({
  components: {
    EarningsTable,
    HelperView
  }
})
export default class EarningsIncomingsTable extends Vue {
  @Prop({ type: Number, required: true })
  readonly idItem!: number;

  readonly EarningsTableStatus = EarningsTableStatus;
  earnings: Earning[] = [];
  status = EarningsTableStatus.Loading;
  futureEarnings = '';
  created() {
    this.fetchIncomingEarnings();
  }

  async fetchIncomingEarnings() {
    this.status = EarningsTableStatus.Loading;
    try {
      const data = await getIncomingEarnings();
      this.earnings = data.earnings;
      this.futureEarnings = data.futureEarnings.formatted;
      this.status = EarningsTableStatus.Default;
    } catch {
      this.status = EarningsTableStatus.Error;
    }
  }
}
