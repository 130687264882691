
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { GenericError } from '../GenericError';
import { loadScript } from '@/modules/common/helpers/load-script';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  createAuthenticationProfile,
  saveOcrFacematch
} from '@/modules/authentication/services/face-match';
import { namespace } from 'vuex-class';
import { PageContext } from '../Announcement/page-content-by-context';
import { trackRegisterFinished } from './track-events';

const CustomerModule = namespace('CustomerModule');
const IDWALL_TOKEN = process.env.VUE_APP_IDWALL_TOKEN!;
const IDWAL_URL = process.env.VUE_APP_IDWALL_URL!;

@Component({
  components: { GenericError }
})
export default class IdWall extends Vue {
  @CustomerModule.Getter('getCustomerId')
  private customerId!: string;

  readonly idWallAttribute = 'data-idw-sdk-web';
  isError = false;
  unmounted = false;

  @Prop({ type: String, default: PageContext.DEFAULT })
  readonly context!: PageContext;

  @Emit('next-step')
  public goToNextStep() {}

  async created() {
    this.loadIdWallScript();
  }

  private async loadIdWallScript() {
    try {
      await loadScript(IDWAL_URL, 'idwall');
      this.setIdWall();
    } catch {
      this.setError(true);
    }
  }

  private setIdWall() {
    try {
      idwSDKWeb({
        token: IDWALL_TOKEN,
        onComplete: ({ token }) => {
          this.createAuthenticationProfile(token);
        }
      });
    } catch {
      this.setError(true);
    }
  }

  private async createAuthenticationProfile(token: string) {
    try {
      const profileData = {
        customer: this.customerId,
        faceMatchToken: token
      };

      if (this.context === PageContext.ONBOARDING) {
        await saveOcrFacematch(profileData);
        trackRegisterFinished();
      } else {
        await createAuthenticationProfile(profileData);
      }
      this.goToNextStep();
    } catch {
      this.setError(true);
      this.notifyError();
    }
  }

  private notifyError() {
    this.$notify({
      group: 'wrn-notifications',
      text: 'Erro ao atualizar cadastro',
      type: NotificationTypes.error
    });
  }

  private handleRetry() {
    this.setError(false);
    setTimeout(() => {
      this.setIdWall();
    }, 500);
  }

  private setError(isError: boolean) {
    if (this.context === PageContext.ONBOARDING) {
      this.$router.push({ name: 'uploads' });
    } else {
      this.isError = isError;
    }
  }

  beforeDestroy() {
    this.removeIdWallGlobalStyles();
  }

  private removeIdWallGlobalStyles() {
    const documentStyleSheets = Array.from(document.styleSheets);
    const isIdWallCssRule = (rule: CSSStyleRule) =>
      rule.selectorText === `[${this.idWallAttribute}]`;

    const idWallStyleSheets = documentStyleSheets.filter(rule => {
      const childrenCssRules = Array.from(rule.cssRules) as CSSStyleRule[];
      return childrenCssRules.some(isIdWallCssRule);
    });
    idWallStyleSheets.forEach(rule => rule.ownerNode?.remove());
  }
}
