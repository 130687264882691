import {
  ISuitabilityUserCreated,
  IConversationDataSuitability,
  ISuitabilityCreated
} from '../types';
import { httpClient } from '@/modules/common/services/http/http-client';

export function finishSuitabilityV2(
  data: IConversationDataSuitability
): Promise<ISuitabilityUserCreated> {
  return httpClient.post('api/v2/suitability/finish', data);
}

export function finishSuitabilityV3(
  data: IConversationDataSuitability,
  id: string
): Promise<ISuitabilityCreated> {
  return httpClient.post(`api/v3/suitability/customer/${id}`, data);
}
