import { GtmTracker } from '@/modules/common/services/analytics';

const trackAddressDocumentEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'address_document_upload',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackAddressDocumentEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSendAddressDocument() {
  trackAddressDocumentEvent({
    event_type: 'click',
    event_name: 'send'
  });
}

export function trackRedoAddressDocument() {
  trackAddressDocumentEvent({
    event_type: 'click',
    event_name: 'redo'
  });
}
