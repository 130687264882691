
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  BaseIcon,
  ButtonPrimary,
  IconButton,
  Footer,
  NavBar,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseIcon,
    ButtonPrimary,
    IconButton,
    Footer,
    NavBar,
    ButtonSecondary
  }
})
export default class FaceMatchTemplate extends Vue {
  @Prop({ required: false, type: String, default: 'Enviar documento e selfie' })
  readonly title!: string;

  @Prop({ required: false, type: String })
  readonly actionButtonIcon?: string;

  @Prop({ required: false, type: String })
  readonly closeButtonName?: string;

  @Prop({ required: false, type: String, default: 'Continuar' })
  readonly submitButtonName!: string;

  @Prop({ required: false, type: Boolean, default: true })
  readonly hasGoBackButtonIcon!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  readonly hasFooter!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly isTransparent!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ required: false, type: Function })
  public actionButton?: () => {};

  @Prop({ required: false, type: Function })
  public closeButtonAction?: () => {};

  @Prop({ required: false, type: Function })
  public submitButtonAction?: () => {};

  @Emit('go-back-event')
  goBackEvent() {}
}
