import { DocumentUploadResponse } from '../../types';
import { ICustomer } from '@/types/models';
import { httpClient } from '@/modules/common/services/http/http-client';

export function register(data = {}) {
  return httpClient.put<ICustomer>('/api/v3/account/update', data);
}

export function documentUpload(customerId: string, data = {}) {
  return httpClient.post<DocumentUploadResponse>(
    `/api/v3/account/upload/customer/${customerId}`,
    data
  );
}
