import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'extracts_and_trading_notes',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackAccountStatemenViewLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackStatementDownload(
  statementText: string,
  groupText: string
) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'extracts',
    event_name: 'select_extract',
    event_target: statementText,
    list_name: groupText
  });
}

export function trackPositionsStatementDownload(year: number) {
  trackStatementDownload(
    `Extrato em 31/12/${year}`,
    `Extratos complementares ${year}`
  );
  trackTradeEvent({
    action: `Clique Extrato 31/12`,
    label: 'Extrato'
  });
}

export function trackMonthlyPositionsStatementDownload(
  month: string,
  year: string
) {
  trackStatementDownload(month, year);
  trackTradeEvent({
    label: 'Extrato e Notas',
    action: 'Click - Extrato Mensal'
  });
}

export function trackConsolidatedPositionsDownload() {
  trackStatementDownload('Extrato desde o início', 'Consolidado');
  trackTradeEvent({
    label: 'Extrato e Notas',
    action: 'Click - Extrato Consolidado'
  });
}

export function trackSubscriptionsStatementDownload(year: number) {
  trackStatementDownload(
    'Extrato de subscrições',
    `Extratos complementares ${year}`
  );
  trackTradeEvent({
    action: `Clique Extrato Subscrições`,
    label: 'Extrato'
  });
}

export function trackPublicOfferingsStatementDownload(year: number) {
  trackStatementDownload(
    'Extrato de ofertas públicas',
    `Extratos complementares ${year}`
  );
  trackTradeEvent({
    action: `Clique Extrato Ofertas Públicas`,
    label: 'Extrato'
  });
}
