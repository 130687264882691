import { RouteConfig } from 'vue-router';

import Catalog from '@/modules/catalog/views/Catalog/Catalog.vue';
import VariableIncomeAcquisition from '@/modules/catalog/views/VariableIncomeAcquisition/VariableIncomeAcquisition.vue';
import FundsAcquisition from '@/modules/catalog/views/FundsAcquisition/FundsAcquisition.vue';

export const catalogRoutes: RouteConfig[] = [
  {
    name: 'catalog',
    path: '/catalog',
    component: Catalog,
    meta: {
      context: 'catalog'
    }
  },
  {
    name: 'variableIncomeAcquisition',
    path: '/catalog/variable-income/:id',
    component: VariableIncomeAcquisition,
    meta: {
      context: 'catalog'
    }
  },
  {
    name: 'fundsAcquisition',
    path: '/catalog/funds-acquisition/:type',
    component: FundsAcquisition,
    meta: {
      context: 'catalog'
    }
  }
];
