
import { Component, Vue } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ThemeProvider } from '@warrenbrasil/nebraska-web';

import { isWarrenManaged } from '@/modules/common/services/partner';

@Component({ components: { ThemeProvider } })
export default class RegisterThemeProvider extends Vue {
  private get primary() {
    return !isWarrenManaged()
      ? NebraskaColors.elementPrimary
      : NebraskaColors.brandPrimary;
  }

  private get overPrimary() {
    return !isWarrenManaged()
      ? NebraskaColors.textNegative
      : NebraskaColors.brandOverPrimary;
  }
}
