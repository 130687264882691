
import { Component, Vue } from 'vue-property-decorator';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  ContextColorProvider,
  NavBar,
  Tabs,
  TabList,
  Tab
} from '@warrenbrasil/nebraska-web';
import { EarningsReceiveds } from './components/EarningsReceiveds';
import { EarningsIncomings } from './components/EarningsIncomings';

@Component({
  components: {
    BackButton,
    ContextColorProvider,
    EarningsReceiveds,
    EarningsIncomings,
    NavBar,
    Tabs,
    TabList,
    Tab
  }
})
export default class EarningsView extends Vue {
  currentTabIndex = 0;

  onBackClick() {
    this.$router.back();
  }

  changeTab(tab: number) {
    this.currentTabIndex = tab;
  }
}
