import {
  httpClient,
  httpClientWithParams
} from '@/modules/common/services/http/http-client';
import { WarrenSession } from '@/modules/common/services/session';
import {
  IPortfolio,
  IProductBox,
  IPortfolioList
} from '@/types/models/Portfolio';
import { ICreatePortfolioBody } from '@/store/modules/WalletBuilder/ICreatePortfolioBody';

import {
  IBackgroundImages,
  IBackgroundImagesUrls,
  IGetInstrumentsFilters,
  IPerformanceHistoryOverview,
  IQueryBackgroundImages,
  Portfolio,
  PortfolioChartParams,
  PortfolioChart,
  PortfolioSectorAllocationResponse,
  PortfolioOrdersParams,
  PortfolioOrdersResponse,
  PortfolioAboutResponse,
  PortfolioMonthlyStatementsListParams,
  PortfolioMonthlyStatementsList,
  PortfolioBrokerageNotesListParams,
  PortfolioBrokerageNotesList,
  PortfolioPerformanceParams,
  PortfolioPerformanceResponse,
  UpdatePaidEventsDestinationBody,
  UpdatePaidEventsDestinationResponse,
  GetMonthlyStatementParams,
  MonthlyStatement,
  WealthChartParams
} from './portfolio-types';
import { StorageKeys } from '@/modules/common/services/local-storage';

export const http = httpClient.withOptions();
export const investmentsHttpClient = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInstruments(
  params: IGetInstrumentsFilters
): Promise<{ instrumentBoxes: IProductBox[] }> {
  return http.get('api/v2/finances/instruments/filter', {
    params
  });
}

export function create(
  portfolio: IPortfolio | ICreatePortfolioBody,
  storageKey?: StorageKeys
): Promise<IPortfolio> {
  return httpClientWithParams(storageKey).post(
    'api/v2/portfolios/create',
    portfolio
  );
}

export function update(
  portfolio: Partial<IPortfolio>
): Promise<{ portfolio: IPortfolio }> {
  return http.put(`api/v2/portfolios/single/${portfolio._id}`, portfolio);
}

export function rename(
  portfolioId: string,
  name: string
): Promise<{ portfolio: IPortfolio }> {
  const customerId = WarrenSession.get('warren.customerId');
  return http.put(
    `api/v1/portfolios/${portfolioId}/customer/${customerId}/rename`,
    {
      name
    }
  );
}

export function remove(
  portfolioId: string
): Promise<{ portfolio: IPortfolio }> {
  return http.delete(`api/v2/portfolios/single/${portfolioId}`);
}

export async function getAllPortfolios(): Promise<{
  portfolios: IPortfolioList[];
}> {
  const { portfolios } = await investmentsHttpClient.get('/v2/home/portfolios');

  return portfolios;
}

export function getPerformanceHistoryOverview(
  id: string
): Promise<IPerformanceHistoryOverview> {
  return http.get(`api/v3/portfolios/${id}/performance`);
}

export function getBackgroundImages(
  query: IQueryBackgroundImages
): Promise<IBackgroundImages> {
  return http.post('api/v2/portfolios/images/search', query);
}

export function putBackgroundImage(
  portfolioId: string,
  urls: IBackgroundImagesUrls
) {
  return http.put(`api/v2/portfolios/single/${portfolioId}/background`, urls);
}

export function getPortfolioV3(portfolioId: string): Promise<Portfolio> {
  return investmentsHttpClient.get('/v3/getPortfolio', {
    params: {
      portfolioId
    }
  });
}

export function getPortfolioChart(
  params: PortfolioChartParams
): Promise<PortfolioChart> {
  return investmentsHttpClient.get('/v3/getPortfolioChart', {
    params
  });
}

export function getWealthChart(
  params: WealthChartParams
): Promise<PortfolioChart> {
  return investmentsHttpClient.get('/v3/getWealthChart', {
    params
  });
}

export function getPortfolioSectorAllocation(
  portfolioId: string
): Promise<PortfolioSectorAllocationResponse> {
  return investmentsHttpClient.get('/v2/getPortfolioSectorAllocation', {
    params: {
      portfolioId
    }
  });
}

export function getPortfolioOrders(
  params: PortfolioOrdersParams,
  abortSignal?: AbortSignal
): Promise<PortfolioOrdersResponse> {
  return investmentsHttpClient.get('/v2/getOrders', {
    params,
    abortSignal
  });
}

export function getPortfolioAbout(
  portfolioId: string
): Promise<PortfolioAboutResponse> {
  return investmentsHttpClient.get('/v1/getPortfolioAbout', {
    params: {
      portfolioId
    }
  });
}

export function getPortfolioMonthlyStatementsList(
  params: PortfolioMonthlyStatementsListParams,
  abortSignal?: AbortSignal
): Promise<PortfolioMonthlyStatementsList> {
  return investmentsHttpClient.get(
    '/v1/portfolio/getVariableIncomePortfolio/statement/list',
    {
      params,
      abortSignal
    }
  );
}

export function getPortfolioBrokerageNotesList(
  params: PortfolioBrokerageNotesListParams,
  abortSignal?: AbortSignal
): Promise<PortfolioBrokerageNotesList> {
  return investmentsHttpClient.get(
    '/v1/portfolio/getVariableIncomePortfolio/brokerageNotes',
    {
      params,
      abortSignal
    }
  );
}

export function getPortfolioPerformance(
  params: PortfolioPerformanceParams,
  abortSignal?: AbortSignal
): Promise<PortfolioPerformanceResponse> {
  return investmentsHttpClient.get('/v2/getPortfolioPerformance', {
    params,
    abortSignal
  });
}

export function updatePaidEventsDestination(
  body: UpdatePaidEventsDestinationBody
): Promise<UpdatePaidEventsDestinationResponse> {
  return investmentsHttpClient.put(
    '/v1/portfolio/paidEventConfiguration',
    body
  );
}

export function getMonthlyStatement(
  params: GetMonthlyStatementParams,
  abortSignal?: AbortSignal
): Promise<MonthlyStatement> {
  return investmentsHttpClient.get(
    `/v1/portfolio/getVariableIncomePortfolio/statement/download`,
    {
      params,
      abortSignal
    }
  );
}
