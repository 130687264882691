
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SpinnerLoader, BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { getString } from '@/modules/common/helpers/resources';

export type FileUploadApi = (data: FormData) => Promise<string>;
export const TEN_MEGABYTES = 1024 * 1024 * 10;

@Component({ components: { BaseIcon, SpinnerLoader, BaseText } })
export default class FileUpload extends Vue {
  @Prop({ type: Function, required: false })
  private fileUploadApi?: FileUploadApi;

  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, required: false })
  private name?: string;

  @Prop({ type: String, required: false })
  private id?: string;

  @Prop({ type: String, required: false })
  private error?: string;

  @Prop({ type: String, default: '' })
  private documentIcon!: string;

  @Prop({ type: String, default: '' })
  private value!: string;

  @Prop({ type: Number, default: TEN_MEGABYTES })
  private maxSize!: number;

  readonly NebraskaColors = NebraskaColors;
  private fileName = '';
  private isLoading = false;
  private isPdfFile = false;

  private onSelectFile() {
    const inputFiles = this.inputFiles();
    const file = inputFiles[0];

    if (!file) return this.$emit('info', 'Não foi possível carregar a imagem');
    if (!/pdf|jpeg|jpg|png/i.test(file.type))
      return this.$emit('error', getString('error_upload_invalid_type'));
    if (file.size > this.maxSize)
      return this.$emit('error', getString('error_upload_file_too_big'));

    this.isPdfFile = file.type === 'application/pdf';
    this.fileName = file.name;

    const data = new FormData();
    data.append('file', file);
    this.sendFile(data);
  }

  private inputFiles() {
    const input = this.$refs.fileInput as HTMLInputElement;
    return input.files || [];
  }

  private get iconName() {
    return this.isPdfFile ? 'ic_pdf' : this.documentIcon;
  }

  private async sendFile(data: FormData) {
    this.$emit('upload', data);
    if (!this.fileUploadApi) return;
    this.isLoading = true;
    try {
      const src = await this.fileUploadApi(data);
      this.$emit('input', src);
    } catch (error) {
      this.$emit('error', error);
    } finally {
      this.isLoading = false;
    }
  }

  private get isImageVisible() {
    return this.value && !this.isPdfFile && !this.isLoading;
  }
}
