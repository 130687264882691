import { boolean, object, string } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { isString } from '@/modules/common/helpers/string';

const schema = object().shape({
  document: object().shape({
    number: string()
      .nullable()
      .when('type', {
        is: (val: string) => val === 'rg',
        then: string()
          .test({
            test: isString,
            message: 'Informe um número de RG válido.'
          })
          .required('Por favor, informe o número do seu RG válido.'),
        otherwise: string()
      })
      .when('type', {
        is: (val: string) => val === 'cnh',
        then: string()
          .test({
            test: isString,
            message: 'Informe um número de CNH válido.'
          })
          .required('Por favor, informe o número da sua CNH válida.'),
        otherwise: string()
      }),

    issueDate: string()
      .test({
        test: isString,
        message:
          'A data de emissão do seu documento deve contar o dia, mês e ano.'
      })
      .required('Insira a data de emissão do seu documento válida.'),

    issuedBy: string()
      .nullable()
      .when('type', {
        is: (val: string) => val === 'rg',
        then: string()
          .test({
            test: isString,
            message: 'Selecione o orgão emissor.'
          })
          .required('Selecione o orgão emissor.'),
        otherwise: string()
      }),

    state: string()
      .test({
        test: isString,
        message: 'Selecione o estado emissor do seu documento.'
      })
      .required('Selecione o estado emissor do seu documento.')
  }),
  acceptedTerms: boolean().oneOf(
    [true],
    'Para prosseguir, você deve concordar com os termos de uso.'
  )
});

export default new YupValidation(schema);
