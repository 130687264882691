import { GtmTracker } from '@/modules/common/services/analytics';
import { IInvestmentProfile, IPersonalProfile } from '@/types/models';

const trackRegisterPersonalDataEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_personal_data',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

function trackInsensitiveEvent(
  event_name: string,
  event_target: string,
  isClickEvent = false
) {
  trackRegisterPersonalDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    event_target,
    page_screen_section: 'form'
  });
}

function trackSensitiveEvent(event_name: string, isClickEvent = false) {
  trackRegisterPersonalDataEvent({
    event_type: isClickEvent ? 'click' : 'blur',
    event_name,
    page_screen_section: 'form'
  });
}

export function trackFinishPersonalDataEvent() {
  trackRegisterPersonalDataEvent({
    event_type: 'click',
    event_name: 'finish_personal_data'
  });
}

export function trackPageView() {
  trackRegisterPersonalDataEvent({
    event_type: 'load',
    event_name: 'view'
  });

  trackRegisterPersonalDataEvent({
    event_type: 'load',
    event_name: 'start_register_data',
    is_conversion: true
  });
}

type trackEventType = {
  fullName: Function;
  cpf: Function;
  birthDate: Function;
  mobile: Function;
  sex: Function;
  motherName: Function;
  totalInvested: Function;
  deposit: Function;
  maritalStatus: Function;
};

const trackEventName: trackEventType = {
  fullName: () => trackSensitiveEvent('fill_fullname'),
  cpf: () => trackSensitiveEvent('fill_cpf'),
  birthDate: (eventValue: string) =>
    trackInsensitiveEvent('fill_birth_date', eventValue),
  mobile: () => trackSensitiveEvent('fill_mobile'),
  sex: (eventValue: string) =>
    trackInsensitiveEvent('select_gender', eventValue),
  motherName: () => trackSensitiveEvent('fill_mother_name'),
  totalInvested: (eventValue: string) =>
    trackInsensitiveEvent('fill_total_invested', eventValue),
  deposit: (eventValue: string) =>
    trackInsensitiveEvent('fill_patrimony', eventValue),
  maritalStatus: (eventValue: string) =>
    trackInsensitiveEvent('select_marital_status', eventValue, true)
};

export const createEventData = (
  eventKey: string,
  personalProfile?: IPersonalProfile,
  investmentProfile?: IInvestmentProfile
) => {
  const eventFunction = trackEventName[eventKey as keyof trackEventType];
  if (eventKey === 'totalInvested' || eventKey === 'deposit') {
    return eventFunction(investmentProfile ? investmentProfile[eventKey] : '');
  }

  return eventFunction(
    personalProfile ? personalProfile[eventKey as keyof IPersonalProfile] : ''
  );
};
