import { warrenAnalyticsObject } from '@/plugins/warren-analytics';
import { trackerContextProps } from '@/modules/common/services/analytics/tracker-context';
import { Foo } from '../../types';

type Suitability =
  | 'Suitability Started'
  | 'Suitability Question Answered'
  | 'Suitability Finished';
type GetIncome = {
  incomeValue: number;
  totalInvested: number;
};

export function trackSuitability(event: Suitability) {
  warrenAnalyticsObject.track({
    ...trackerContextProps(),
    event,
    universalId: getUniversalId()
  });
}

type SuitabilityFinished = {
  market?: Foo;
  interest?: Foo;
  read?: Foo;
  isNewCustomer: boolean;
  earnings: number;
  education?: string;
};

export function trackSuitabilityFinished(event: SuitabilityFinished) {
  warrenAnalyticsObject.track({
    ...trackerContextProps(),
    event: 'Suitability Finished',
    market: event.market,
    interest: event.interest,
    read: event.read,
    isNewCustomer: event.isNewCustomer,
    category: 'suitability_regulated',
    earnings: event.earnings,
    education: event.education
  });
}

export function trackGetIncome({ incomeValue, totalInvested }: GetIncome) {
  warrenAnalyticsObject.track({
    ...trackerContextProps(),
    event: 'getIncome',
    incomeValue,
    totalInvested
  });
}

function getUniversalId(): string {
  const nameEQ = 'userUniversalId=';
  const ca = document.cookie.split(';');
  let finalId = '';
  ca.forEach(c => {
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0)
      return (finalId = c.substring(nameEQ.length, c.length));
  });

  return finalId;
}
