
import { Component, Vue, Watch } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { NavBar, BaseIcon, LoaderIndicator } from '@warrenbrasil/nebraska-web';

import { getCustomer } from '@/modules/common/services/http/account';
import { ProgressBar } from '@/modules/common/components/__deprecated__/ProgressBar';
import { Notification } from '@/modules/common/components/Notification';
import RegisterDocumentAnnouncement from '../../components/RegisterDocumentAnnouncement/RegisterDocumentAnnouncement.vue';

import { ICustomer } from '@/types/models';

import { trackRegister } from './track-events';
import redirectTo, {
  RedirectList
} from '@/modules/onboarding/helpers/redirect-to';

enum Steps {
  Personal = 'personal',
  Address = 'address',
  Nationality = 'nationality',
  Documents = 'documents',
  Uploads = 'uploads'
}

@Component({
  components: {
    BaseIcon,
    LoaderIndicator,
    NavBar,
    Notification,
    ProgressBar,
    RegisterDocumentAnnouncement
  }
})
export default class RegisterView extends Vue {
  private isLoading = true;

  private currentStep = 1;

  private totalSteps = this.steps.length;

  private customer?: ICustomer;

  private showAnnouncement = true;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private async mounted() {
    await this.getUser();

    if (this.customer?.isRegisterVerified) {
      this.$router.push({ name: 'user-account' });
    }
  }

  @Watch('$route', { deep: true, immediate: true })
  private setInitialStep() {
    const index = this.steps.findIndex(
      stepRoute => stepRoute === this.$route.name
    );
    if (index > -1) this.currentStep = index + 1;
  }

  private async getUser() {
    this.isLoading = true;
    return getCustomer()
      .then(data => (this.customer = data.customer))
      .finally(() => (this.isLoading = false));
  }

  private redirectToCustomerIdentification() {
    redirectTo(RedirectList.CustomerIdentification);
  }

  private async isNextStepCustomerIdentification(): Promise<boolean> {
    const isUploadStep = this.currentStep === this.uploadStep;
    const customerId = this.customer?._id;

    return !!isUploadStep && !!customerId;
  }

  private finishedRegister() {
    trackRegister();
    this.$router.push({ name: 'v3' });
  }

  private async nextStep(user?: ICustomer) {
    if (user) this.customer = user;
    this.currentStep++;

    if (await this.isNextStepCustomerIdentification()) {
      return this.redirectToCustomerIdentification();
    }

    const isFinished = this.currentStep > this.steps.length;
    if (isFinished) {
      return this.finishedRegister();
    }

    return this.goToStep(this.currentStep);
  }

  private onBackClick() {
    if (this.currentStep === 1) {
      this.$router.push({ name: 'v3' });
    } else {
      this.currentStep--;
      this.goToStep(this.currentStep);
    }
  }

  private goToStep(step: number) {
    this.$router.push({ name: this.steps[step - 1] });
  }

  private get uploadStep() {
    return this.steps.findIndex(step => step === Steps.Uploads) + 1;
  }

  private get steps() {
    return Object.values(Steps);
  }

  private get progressBarStepSize() {
    return 100 / this.totalSteps;
  }

  private get progressPercentage() {
    return (this.currentStep * this.progressBarStepSize).toString();
  }

  private get navbarIconName() {
    return this.currentStep === 1 ? 'ic_cancel' : 'ic_arrow_left';
  }

  private get navbarIconTestId() {
    return this.currentStep === 1 ? 'cancel' : 'previous-step';
  }

  public get showRegisterDocumentsAnnouncement() {
    return this.showAnnouncement && this.currentStep === 1;
  }

  public hideAnnouncement() {
    this.showAnnouncement = false;
  }
}
