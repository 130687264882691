import { IAddress, IDocument } from '@/modules/onboarding/register/types';
import { ISuitability } from '@/modules/onboarding/suitability/types';
import {
  IPersonalProfile,
  IProfessionalProfile,
  IAdvisorProfile,
  IInvestmentProfile,
  IBankAccount,
  IConfiguration
} from '@/types/models';

export interface CustomerResponse {
  customer: ICustomer;
}

export interface ICustomer {
  _id: string;
  name: string;
  email: string;
  password: string;
  facebook?: string;
  dash?: string;
  foreFeature?: string[];
  status?: string;
  canCreateInvoice?: boolean;
  segmentation: ClientSegmentation;
  clientTheme?: ClientTheme;
  clientType: ClientType;
  b2bType?: PartnerType;
  features: string[];
  // Waitlist Unlock
  goWaitList?: boolean;
  waitlistUnlockedAt?: Date;
  waitlistUnlockedVia?: string;
  // Deletion
  isDeleted?: boolean;
  deletedAt?: Date;
  // E-mail Verification
  isEmailVerified?: boolean;
  // Password Setting
  isPasswordSet?: boolean;
  // Login Lockout
  // Account block
  isBlocked?: boolean;
  // Suitability
  isSuitabilityStarted?: boolean;
  isSuitabilityFinished?: boolean;
  // Portfolio Builder
  isPortfolioStarted?: boolean;
  isPortfolioFinished?: boolean;
  hasPortfolio?: boolean;
  // Register Process
  isRegisterStarted?: boolean;
  isPortfolioVisited?: boolean;
  isRegisterFinished?: boolean;
  isPartnerRegisterVerified?: boolean;
  isRegisterValidated?: boolean;
  registerNotValidatedReason?: string;
  verifiedByExternalInfo?: boolean;
  isRegisterVerified?: boolean;
  mustUpdateAccount?: boolean;
  isRegisterRejected?: boolean;
  registerRejectedReason?: string;
  isRegisterModified?: boolean;
  // Invest Process
  isInvestStarted?: boolean;
  isInvestFinished?: boolean;
  // Pre-registration
  isPreregistered?: boolean;
  // Terms
  acceptedTerms?: boolean;
  lastAcceptedTermsAt?: Date;
  firstAcceptedTermsAt?: Date;
  acceptedTermsCount?: number;

  address?: IAddress | {};
  document?: IDocument | {};
  personalProfile?: IPersonalProfile;
  professionalProfile?: IProfessionalProfile | {};
  advisorProfile?: IAdvisorProfile;
  investmentProfile?: IInvestmentProfile;
  suitability?: ISuitability | {};
  bankAccounts?: IBankAccount[] | [];
  configs?: IConfiguration;
  referralId?: string;
  referredBy?: string;
  createdAt: Date;
  updatedAt?: Date;
  partnerInfos?: IPartnerInfo;
  shouldUpdateAccount?: boolean;
  isImpersonate?: boolean;
  depositFirstAcceptedAt: Date;
  depositAcceptedCount: number;
  hasVisitedWelcomeScreen?: boolean;
  investStartedCount?: number;
  totalBalance?: number;
}

export interface IPartnerInfo {
  _id: string;
  customer: string;
  email: string;
  name: string;
  status: string;
  domain: string;
  type: PartnerType;
  phone: string;
  isValid: boolean;
  isVerified: boolean;
  companyName: string;
  tradeMark: string;
  certifications: any[];
}

export enum RiskProfileName {
  CONSERVADOR = 'Conservador',
  CONVERSADORMODERADO = 'Conservador-Moderado',
  MODERADO = 'Moderado',
  MODERADOAGRESSIVO = 'Moderado-Agressivo',
  AGRESSIVO = 'Agressivo'
}

export enum ClientSegmentation {
  White = 'white',
  Pink = 'pink',
  HighPink = 'highPink',
  Black = 'black',
  UltraBlack = 'ultraBlack'
}

export enum ClientTheme {
  Pink = 'pink',
  Black = 'black',
  Whitelabel = 'whitelabel'
}

export interface ClientType {
  pink?: boolean;
  black?: boolean;
  partner?: boolean;
  betaTester?: boolean;
  b2bPartner?: boolean;
  whitelabel?: boolean;
}

export enum PartnerType {
  CFP = 'cfp',
  Manager = 'manager',
  Viewer = 'viewer',
  Planner = 'planner',
  AAI = 'aai'
}

export interface ICustomerSummary {
  name?: string;
  bruteBalance?: number;
  ccBalance?: number;
  bruteYeld?: number;
  email?: string;
  cpf?: string;
  suita?: ISuitability;
  portfolios?: IPortfolioSummary[] | [];
  transactions?: ITransactionSummary[] | [];
  positions?: IInvestmentPosition[] | [];
}

interface IInvestmentPosition {
  fund: string;
  earningsValue: number;
  initialQuote: number;
  todayQuote: number;
  grossValue: number;
  netValue: number;
  date: Date;
}

interface ITransactionSummary {
  id: string;
  type: string;
  status: string;
  date: Date;
  value: number;
  fromPortfolioName: string;
  toPortfolioName: string;
}

interface IPortfolioSummary {
  id: string;
  name: string;
  totalEarningsPercentage: number;
  balance: number;
  date: Date;
}
