import { AddressDocumentResponse } from '../../types';
import { httpClient } from '@/modules/common/services/http/http-client';

const httpAccountBff = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
});

export function addressDocumentUpload(data: FormData | undefined) {
  return httpAccountBff.post(`v1/customer/uploadAddressDocument`, data);
}

export function isAddressDocumentNeeded(
  customerId: string
): Promise<AddressDocumentResponse> {
  return httpAccountBff.get(
    `v1/customer/shouldUploadAddressDocument?customerId=${customerId}`
  );
}
