
import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Heading, BaseText, ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { DashStatusMixin } from '@/modules/common/mixins/dash-status';
import { DashStatus } from '@/modules/common/services/graphql/server/server.sdk';
import { ICustomer } from '@/types/models';
import { Categories } from '@/types/models/Analytics';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    Heading,
    BaseText,
    ButtonPrimary
  }
})
export default class HomeDashStatus extends mixins(DashStatusMixin) {
  @Prop({ type: Object })
  readonly dashStatus!: DashStatus;

  @Prop({ type: String, default: '' })
  readonly investmentAccountId!: string;

  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  public readonly NebraskaColors = NebraskaColors;

  get buttonLabel() {
    return this.dashStatus.card?.button || '';
  }

  get title() {
    return this.dashStatus.card?.title || '';
  }

  get message() {
    return this.dashStatus.card?.message || '';
  }

  @Emit('dash-status-click')
  private trackDashStatusButtonEvent(dashStatus: DashStatus) {
    return dashStatus.card;
  }

  onClick() {
    this.trackDashStatusButtonEvent(this.dashStatus);
    this.goToAction({
      action: this.dashStatus.card?.action || '',
      category: Categories.Home,
      investmentAccountId: this.investmentAccountId,
      email: this.customer.email
    });
  }
}
