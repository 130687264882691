
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Component, Vue } from 'vue-property-decorator';
import { NavBar } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BackButton,
    NavBar
  }
})
export default class PageHeader extends Vue {
  onBackClick() {
    this.$router.back();
  }
}
