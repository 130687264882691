
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';
import scrollToElement from 'scroll-to-element';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';

import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { getString, getEnum } from '@/modules/common/helpers/resources';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { Radio } from '@/modules/common/components/__deprecated__/Radio';
import { ICustomer } from '@/types/models';

import { IDocument } from '../../types';
import { RegisterTemplate } from '../../components/RegisterTemplate';
import { UserTerms } from './components/UserTerms';
import { documentsValidation } from './helpers';
import {
  trackPageView,
  createEventData,
  trackFinishPIIDataEvent
} from '../../helpers/track-pii-events';
import { register } from '../../services/register/api';

const dateISOToBR = (date: string) =>
  date.replace(/(\d{4}).(\d{2}).(\d{2}).*/, '$3/$2/$1');
const dateBRToISO = (date: string) =>
  date.replace(/(\d{2}).(\d{2}).(\d{4}).*/, '$3-$2-$1T00:00:00.000Z');

@Component({
  components: {
    ButtonPrimary,
    FormField,
    Radio,
    RegisterTemplate,
    TheMask,
    UserTerms
  }
})
export default class Documents extends Vue {
  public getEnum = getEnum;

  @Prop({ type: Function, default: () => {} })
  private onRegisterFinished!: (data: any) => void;

  @Prop({ type: Function, default: () => {} })
  private onBackClick!: () => {};

  @Prop({ type: Object, default: () => ({}) })
  private user?: ICustomer;

  private document = {
    acceptedTerms: false,
    type: 'rg',
    number: '',
    issueDate: '',
    issuedBy: '',
    state: ''
  };

  private errors: YupValidationErrors = {};
  private singleErrors: YupValidationErrors = {};
  private isLoading = false;

  private get getString() {
    return getString;
  }

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private created() {
    this.setDocument();
  }

  private mounted() {
    trackPageView();
  }

  private setDocument() {
    if (!this.user) return;
    this.document.acceptedTerms = Boolean(this.user.acceptedTerms);
    if (!this.user.document) return;
    const userDocument = this.user.document as IDocument;
    if (userDocument.type) this.document.type = userDocument.type;
    if (userDocument.number) this.document.number = userDocument.number;
    if (userDocument.issueDate)
      this.document.issueDate = dateISOToBR(String(userDocument.issueDate));
    if (userDocument.issuedBy) this.document.issuedBy = userDocument.issuedBy;
    if (userDocument.state) this.document.state = userDocument.state;
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) return err[0];

    const singleErr = this.singleErrors[path];
    if (singleErr) return singleErr[0];

    return '';
  }

  private getPOSTData() {
    const issuedBy =
      this.document.type === 'cnh' ? 'detran' : this.document.issuedBy;
    const issueDate = dateBRToISO(String(this.document.issueDate));
    const { acceptedTerms, ...document } = {
      ...this.document,
      issueDate,
      issuedBy
    };
    return { document, acceptedTerms };
  }

  private focusOnErrorElement() {
    const form = this.$refs.documentsForm as HTMLFormElement;
    const errorElement = form.querySelector('.wrn-color-error');
    const errorField = errorElement?.parentElement?.parentElement;

    if (errorField) scrollToElement(errorField, { offset: 0, duration: 300 });
  }

  private handleTrackingEvent(field: string) {
    const customerDocument = this.document as Partial<IDocument>;
    const [, fieldName] = field.split('.');
    createEventData(fieldName, customerDocument);
  }

  private async blurErrorCheck(key: string) {
    try {
      this.handleTrackingEvent(key);
      await documentsValidation.validate(this.getPOSTData());
      this.errors[key] = [];
      this.singleErrors[key] = [];
    } catch (errors) {
      this.errors[key] = errors[key];
      this.singleErrors[key] = errors[key];
      this.$forceUpdate();
    }
  }

  private async onSubmit() {
    if (this.isLoading) return;
    trackFinishPIIDataEvent();
    const user = this.getPOSTData();
    try {
      await documentsValidation.validate(user);
      this.errors = {};
    } catch (errors) {
      this.errors = errors;
      this.$nextTick(this.focusOnErrorElement);
      return;
    }

    this.isLoading = true;
    try {
      const response = await register({ user });
      this.onRegisterFinished(response);
      this.$emit('nextStep', response);
    } catch (error) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          error.description ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });
    } finally {
      this.isLoading = false;
    }
  }
}
