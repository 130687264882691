
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  HeadingDisplay,
  InputSelect,
  InputText,
  Checkbox,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { getMaritalStatusList } from '@/modules/account/services/resources';
import {
  MaritalStatus,
  Profession
} from '@/modules/account/services/resources/types';
import { ProfessionInput } from '@/modules/common/components/ProfessionInput';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';

import {
  RegulatoryUpdatePersonalData as IRegulatoryUpdatePersonalData,
  InputStatusType
} from '../../types';
import { getPersonalData, savePersonalData } from '../../services';
import { isMarried } from '../../helpers/is-married';
import { parseApiErrorToScreenError } from '../../helpers/parse-api-error-to-screen-error';
import yupValidation from './validate';
import {
  trackEvent,
  trackContinue,
  trackError,
  trackView
} from './track-events';
import { EventKnownProperties } from '@warrenbrasil/web-analytics';

@Component({
  components: {
    HeadingDisplay,
    InputSelect,
    InputText,
    ProfessionInput,
    Checkbox,
    ButtonContainer,
    ButtonPrimary
  }
})
export default class RegulatoryUpdatePersonalData extends Vue {
  @Prop({ type: Boolean, required: true })
  public showContent!: boolean;

  @Prop({ type: String, default: 'regulatoryUpdatePersonalData' })
  public cameFrom!: string;

  @Emit()
  private setIsLoading(_isLoading: boolean) {}

  @Emit()
  private setHasError(_hasError: boolean) {}

  @Emit()
  private goToNextPage() {}

  @Emit()
  private notifyError(_message?: string) {}

  @Emit()
  private changeTracker(
    _newTracker: (remainingProps: EventKnownProperties) => void
  ) {}

  public personalData: IRegulatoryUpdatePersonalData | null = null;

  public maritalStatusList: MaritalStatus[] = [];

  public isButtonLoading = false;

  public errors: YupValidationErrors = {};

  mounted() {
    this.fetchPersonalData();
    this.changeTracker(trackEvent);
  }

  private get enablePartnerCpf() {
    switch (this.personalData!.maritalStatus) {
      case 'casado-brasileiro-nato':
      case 'casado-brasileiro-naturalizado':
      case 'uniao-estavel':
        return true;
      default:
        return false;
    }
  }

  private get enablePartnerRne() {
    return this.personalData!.maritalStatus === 'casado-estrangeiro';
  }

  private async fetchPersonalData() {
    this.setIsLoading(true);
    this.setHasError(false);

    try {
      const [personalData, maritalStatusList] = await Promise.all([
        getPersonalData(),
        getMaritalStatusList()
      ]);

      trackView();

      this.personalData = personalData;
      this.maritalStatusList = maritalStatusList;
      this.blurMaritalStatus();
      this.blurHasProfession();
    } catch (_e) {
      this.setHasError(true);
    }

    this.setIsLoading(false);
  }

  public get showPartnerNameField() {
    return isMarried(this.personalData!.maritalStatus);
  }

  public setProfession({ profession, cbo, sinacor }: Profession) {
    this.personalData!.profession = { profession, cbo, sinacor };
    this.removeError('profession');
  }

  public getInputStatus(key: string) {
    return this.errors[key] ? InputStatusType.Error : InputStatusType.Default;
  }

  public getErrorMessage(key: string) {
    return this.errors[key] ? this.errors[key][0] : '';
  }

  public removeError(key: string) {
    delete this.errors[key];
  }

  public blurMaritalStatus() {
    if (!isMarried(this.personalData!.maritalStatus)) {
      this.personalData!.partnerName = '';
      this.personalData!.partnerCpf = '';
      this.personalData!.partnerForeignRne = '';
    }

    if (this.enablePartnerCpf) {
      this.personalData!.partnerForeignRne = '';
    }

    if (this.enablePartnerRne) {
      this.personalData!.partnerCpf = '';
    }
  }

  public blurHasProfession() {
    if (!this.personalData!.hasProfession) {
      this.personalData!.workOrganization = '';
      this.personalData!.workOrganizationCnpj = '';
    }
  }

  public async handleButtonClick() {
    await this.validateErrors();

    if (yupValidation.isValid()) {
      this.isButtonLoading = true;
      try {
        await savePersonalData(this.personalData!);
        trackContinue();
        this.goToNextPage();
      } catch (e: any) {
        if (e?.error?.message?.value === 'InvalidInput') {
          this.errors = parseApiErrorToScreenError(e.error.locations);
        } else if (e?.error?.message?.value === 'ExpiredPassword') {
          this.$router.push({
            name: 'regulatoryUpdateVerifyPassword',
            query: { cameFrom: this.cameFrom }
          });
        } else {
          trackError(e?.error?.message?.formatted);
          this.notifyError(e?.error?.message?.formatted);
        }
      }
      this.isButtonLoading = false;
    }

    Object.values(this.errors).forEach((v: string[]) => trackError(v[0]));
  }

  private async validateErrors() {
    try {
      await yupValidation.validate(this.personalData);
    } catch (_e) {}

    this.errors = yupValidation.getAllErrors();
  }
}
