
import { Component, Vue } from 'vue-property-decorator';

import { PageHeader } from './components/PageHeader';
import { InstrumentName } from '@/modules/trade/components/InstrumentName';
import {
  getCollateralMarginElegibleAssets,
  ElegibleAsset
} from '@/modules/trade/services/collateral-margin';
import {
  ContextColorProvider,
  SkeletonLoader,
  HelperView,
  BaseText,
  Heading,
  Row
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    ContextColorProvider,
    InstrumentName,
    SkeletonLoader,
    PageHeader,
    HelperView,
    BaseText,
    Heading,
    Row
  }
})
export default class ElegibleAssets extends Vue {
  readonly NebraskaColors = NebraskaColors;
  elegibleAssets: ElegibleAsset[] = [];
  loading = false;
  error = false;

  created() {
    this.fetchElegibleAssets();
  }

  async fetchElegibleAssets() {
    this.loading = true;
    try {
      this.elegibleAssets = await getCollateralMarginElegibleAssets();
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
