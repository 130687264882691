export const loadScript = (scriptUrl: string, id: string) =>
  new Promise((resolve, reject) => {
    const hasScript = document.getElementById(id);

    if (hasScript) {
      resolve(true);
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('id', id);
    script.setAttribute('src', scriptUrl);

    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(true);
    };

    document.body.appendChild(script);
  });
