import {
  ReceivedEarningsResponse,
  IncomingEarningsResponse,
  EarningsChartResponse
} from './types';

import { mockEarningsData } from '@/modules/trade/views/Earnings/__mocks__/earnings';

export function getEarningsChart(): Promise<EarningsChartResponse> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(mockEarningsData.mockEarningsChart);
    }, 1500);
  });
}

export function getReceivedEarnings(): Promise<ReceivedEarningsResponse> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        earnings: mockEarningsData.mockEarnings,
        totalReceived: {
          value: 10,
          formatted: 'R$ 10,00'
        }
      });
    }, 1500);
  });
}

export function getIncomingEarnings(): Promise<IncomingEarningsResponse> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        earnings: mockEarningsData.mockEarnings,
        futureEarnings: {
          value: 10,
          formatted: 'R$ 10,00'
        }
      });
    }, 1500);
  });
}
