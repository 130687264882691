
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  getMembershipTerms,
  MembershipTermsResponse
} from '@/modules/common/services/http/account';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import {
  BaseIcon,
  BaseText,
  ButtonPrimary,
  ContextColorProvider,
  Heading,
  IconButton,
  Footer,
  NavBar,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { Container } from '../Container';
import { trackPageView, trackAccepted } from './track-events';

export interface ConfirmResponse {
  acceptedTerms: boolean;
  close: Function;
}

@Component({
  components: {
    BaseIcon,
    LoaderIndicator,
    BaseText,
    ButtonPrimary,
    Container,
    ContextColorProvider,
    Heading,
    IconButton,
    Footer,
    NavBar
  }
})
export default class MembershipTermsModal extends Vue {
  @Prop({ required: true, type: Function }) private close!: () => void;
  @Prop({ required: true, type: Function }) private confirm!: (
    args: ConfirmResponse
  ) => ConfirmResponse;

  private NebraskaColors = NebraskaColors;
  private isSubmitting = false;
  private loading = false;
  private data: MembershipTermsResponse | null = null;

  private created() {
    this.setData();
  }

  public mounted() {
    trackPageView();
  }

  private handleClose() {
    this.confirm({ acceptedTerms: false, close: this.close });
    this.close();
  }

  private async setData() {
    this.loading = true;
    this.data = await getMembershipTerms();
    this.loading = false;
  }

  private async submit() {
    this.isSubmitting = true;
    trackAccepted();
    this.confirm({ acceptedTerms: true, close: this.close });
  }
}
