import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { ICustomer } from '@/types/models/Customer';
import { customerAPI } from '@/modules/common/services/http/customer';
import { StorageKeys } from '@/modules/common/services/local-storage';
import {
  getRegulatoryUpdateStatus,
  RegulatoryUpdateStatus
} from '../../services/http/account';
import { isEmpty } from 'lodash-es';

@Module({
  namespaced: true,
  name: 'CustomerModule'
})
export class CustomerStoreModule extends VuexModule {
  private customer?: ICustomer = undefined;
  public isImpersonated = false;
  public regulatoryUpdateStatus?: RegulatoryUpdateStatus = undefined;

  @Mutation
  public setCustomer(value: ICustomer) {
    localStorage.setItem(StorageKeys.CustomerId, value._id);
    this.customer = value;
  }

  @Mutation
  public setIsImpersonated(value: boolean) {
    this.isImpersonated = value;
  }

  @Mutation
  public resetCustomer() {
    localStorage.removeItem(StorageKeys.CustomerId);
    this.customer = undefined;
  }

  @Mutation
  public setRegulatoryUpdateStatus(value: RegulatoryUpdateStatus) {
    this.regulatoryUpdateStatus = value;
  }

  public get getCustomer(): ICustomer | undefined {
    return this.customer;
  }

  public get hasCustomer(): boolean {
    return !isEmpty(this.customer);
  }

  public get getCustomerId(): string {
    if (this.customer) return this.customer._id;
    return '';
  }

  public get getCustomerInitial(): string {
    if (this.customer) return this.customer.name.charAt(0);
    return '';
  }

  public get isCustomerPJ() {
    return !!this.customer?.professionalProfile;
  }

  public get isRegulatoryStatusBlocked() {
    return this.regulatoryUpdateStatus === RegulatoryUpdateStatus.BLOCKED;
  }

  public get isRegulatoryStatusAboutToBeBlocked() {
    return (
      this.regulatoryUpdateStatus === RegulatoryUpdateStatus.ABOUT_TO_BE_BLOCKED
    );
  }

  @Action
  public async fetchRegulatoryUpdateStatus() {
    const { status } = await getRegulatoryUpdateStatus(this.getCustomerId);
    this.context.commit('setRegulatoryUpdateStatus', status);
  }

  @Action({ rawError: true })
  public async fetchCustomer() {
    const { customer } = await customerAPI.getCustomer();
    this.context.commit('setCustomer', customer);

    this.context.dispatch('fetchRegulatoryUpdateStatus');
  }
}
