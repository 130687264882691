import { httpClient } from '@/modules/common/services/http/http-client';
import { MarginsData, ElegibleAsset } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getCollateralMarginSummary(): Promise<MarginsData> {
  return httpClientWithDefaultOptions.get('/collateralMarginSummary');
}

export function getCollateralMarginElegibleAssets(): Promise<
  Array<ElegibleAsset>
> {
  return httpClientWithDefaultOptions.get('/getElegibleAssets');
}

export function checkCollateralMarginFirstAccess(): Promise<boolean> {
  return httpClientWithDefaultOptions.get('/checkCollateralMarginFirstAccess');
}
