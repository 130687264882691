
import { Component, Mixins } from 'vue-property-decorator';
import { Row, SigninTemplate } from '@warrenbrasil/nebraska-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import {
  getThemeByParam,
  login,
  GetThemeByParamType,
  ILoginResponse
} from '@/modules/common/services/http/account';
import {
  ICustomTheme,
  getCustomTheme,
  setCustomTheme,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  isThemeWhitelabel,
  setNebraskaScheme
} from '@/modules/common/services/theme';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { ICustomer } from '@/types/models/Customer';
import {
  IMFAInfo,
  IMFALoginResponse
} from '@/modules/authentication/services/mfa';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { isPartnerRunningStandalone } from '@/modules/common/helpers/partner';
import {
  SignInForm,
  ISignInFormData,
  SignInStep
} from './components/SignInForm';
import { LoginHero } from './components/LoginHero';
import { MFALogin } from '@/modules/authentication/views/SecurityView/components/MFALogin';
import { WarrenSession } from '@/modules/common/services/session';
import {
  trackLogin,
  trackPageView,
  trackSignupLink,
  trackEmailEvent,
  trackPasswordEvent,
  trackForgotPasswordEvent,
  trackSendEmailClick,
  trackSendPasswordClick
} from '../../helpers/track-signin-events';
import { useSigninStore } from '@/modules/authentication/store';

const loadingThemeColors = {
  primary: NebraskaColors.backgroundSecondary,
  overPrimary: NebraskaColors.textPrimary
};

const PARTNER_DASH_URL = '/v/#/b2b/dash';
const PARTNER_STANDALONE_DASH_URL = '/partner/#/b2b/dash';
const PARTNER_STANDALONE_DASH_URL_LOCAL =
  'https://dev.warren.com.br/partner/#/b2b/dash';

interface urlParams {
  [key: string]: string;
}

@Component({
  components: {
    LoginHero,
    SignInForm,
    SigninTemplate,
    Row,
    MFALogin
  }
})
export default class SignInView extends Mixins(RouteMixin) {
  brand = { id: '', name: '' };
  isFormLoading = false;
  isLoadingTheme = false;
  isWhitelabel!: boolean;
  logo = '';
  overPrimary: string = loadingThemeColors.overPrimary;
  primary: string = loadingThemeColors.primary;
  step: SignInStep = 'email';
  submitError: any = null;
  isWarrenManaged = true;
  referralId = '';
  loginData = {
    email: '',
    password: ''
  };

  readonly signinModule = useSigninStore();

  private isUserWhitelabel = this.isWhitelabel;

  userHasMFAActive = false;
  infoMFA: IMFAInfo = {
    customerId: '',
    hasAuthorizedDevice: false,
    mfaSessionToken: ''
  };

  private mounted() {
    trackPageView();
  }

  private handleSignInTracking(event: Event) {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent.id === 'email') {
      return trackEmailEvent();
    } else if (inputEvent.id === 'password') {
      return trackPasswordEvent();
    }
  }

  private created() {
    this.isWhitelabel =
      location.href.includes('/v/') || this.brandDomain === 'whitelabel';
    CustomerModule.resetCustomer();
    this.clearStorage();
    this.getTheme({ brand: this.brandDomain });
  }

  private clearStorage() {
    WarrenSession.clean([
      StorageKeys.LastPartnerDashRoute,
      StorageKeys.PartnerShowMoney,
      StorageKeys.PartnerDashNewsInformation,
      StorageKeys.PartnerProfileNewsInformation,
      StorageKeys.PartnerStatementNewsInformation,
      StorageKeys.PartnerPortfolioNewsInformation,
      StorageKeys.NewPartnerFeaturesViewed,
      StorageKeys.ShowedPortfoliosTemplateTutorial,
      StorageKeys.HasDownloadedFeeStatement,
      StorageKeys.HasShowedInvestmentViewTutorial,
      StorageKeys.DarkMode,
      StorageKeys.HasUsedPositionDetailsButton,
      StorageKeys.IsPartnerAccountSession
    ]);
  }

  goToReset() {
    trackForgotPasswordEvent();
    this.$router.push({ name: 'reset' });
  }

  private getTheme(params?: Record<string, string>) {
    if (params?.brand && params.brand !== 'whitelabel') {
      const theme = this.getValidatedThemeStored(params.brand);

      if (theme) {
        this.themeLoadedHandler('domain', theme);
      } else {
        this.getThemeFromApi('domain', params.brand);
      }
    } else {
      this.setFallbackTheme(this.isWhitelabel);
    }
  }

  checkBackFlow() {
    if (this.step === 'password') {
      this.step = 'email';
    } else if (!this.isWhitelabel) {
      this.redirectToUrl();
    }
  }

  private getValidatedThemeStored(brand: string): ICustomTheme | undefined {
    const theme = getCustomTheme();
    if (theme && theme.brandDomain === brand) {
      return theme;
    }
  }

  private setLoadingTheme() {
    this.isLoadingTheme = true;
    this.setThemeColors(
      loadingThemeColors.primary,
      loadingThemeColors.overPrimary
    );
  }

  private getThemeFromApi(param: GetThemeByParamType, value: string) {
    this.setLoadingTheme();

    return getThemeByParam(param, value)
      .then(theme => this.themeLoadedHandler(param, theme))
      .catch(error => this.themeErrorHandler(error))
      .finally(() => {
        this.isLoadingTheme = false;
        this.isFormLoading = false;
      });
  }

  private themeLoadedHandler(param: GetThemeByParamType, theme: ICustomTheme) {
    this.submitError = null;
    this.setThemeData(theme);
    this.isWarrenManaged = !theme.type || theme.type === 'WarrenManaged';
    this.signinModule.setLogo(theme.logo!);
    this.signinModule.setBrandName(theme.brandName!);
    this.signinModule.setIsWarrenManaged(this.isWarrenManaged);
    this.referralId = theme.referralId;
    if (!theme.brand) this.isUserWhitelabel = false;

    setNebraskaScheme(theme);

    if (param === 'email') {
      setCustomTheme(theme);
      this.step = 'password';
    }
  }

  private themeErrorHandler(error: any) {
    this.submitError = error;
    this.setFallbackTheme(this.isWhitelabel);
  }

  private setThemeData(theme: ICustomTheme) {
    if (isThemeWhitelabel(theme)) this.brand.id = theme.brand || '';
    if (isThemeWhitelabel(theme) && theme.type !== 'WarrenManaged') {
      this.brand.name = theme.brandName!;
      this.logo = theme.logo;
      this.setResponseTheme(theme);
    } else {
      this.brand.name = '';
      this.logo = '';
      this.setFallbackTheme(false);
    }
  }

  private setFallbackTheme(isWhitelabel?: boolean) {
    if (isWhitelabel) {
      // We HAVE to set fixed color for business platform here, for users not authenticated cause the theme is not customized yet
      this.setThemeColors(
        NebraskaColors.businessPrimary,
        NebraskaColors.businessOverPrimary
      );
    } else {
      this.setThemeColors(
        NebraskaColors.brandPrimary,
        NebraskaColors.brandOverPrimary
      );
    }
  }

  private setResponseTheme(theme?: ICustomTheme) {
    if (!theme) {
      this.setFallbackTheme(this.isWhitelabel);
    } else if (theme.colors) {
      this.setThemeColors(
        theme.colors.light.accountPrimary,
        theme.colors.light.accountOverPrimary
      );
    } else {
      this.setFallbackTheme(isThemeWhitelabel(theme));
    }
  }

  private setThemeColors(primary: string, overPrimary: string) {
    this.primary = primary;
    this.overPrimary = overPrimary;
  }

  async onSubmit(formData: ISignInFormData) {
    this.loginData.email = formData.email;
    this.loginData.password = formData.password;
    this.isFormLoading = true;
    if (this.step === 'email') {
      trackSendEmailClick();
      await this.getThemeFromApi('email', formData.email);
      this.isFormLoading = false;
      document.getElementById('password')?.focus();
    } else {
      trackSendPasswordClick();
      await this.login({ ...formData, profileType: this.profileType });
    }
  }

  private login(formData: ISignInFormData) {
    return login(formData)
      .then(res => {
        this.loginHandler(res);
      })
      .catch(err => {
        this.isFormLoading = false;

        if (err.message === 'error_login_auth_token_verify') {
          this.infoMFA = err.custom;
          this.userHasMFAActive = true;
        } else {
          this.submitError = err;
        }
      });
  }

  async loginHandler(data: ILoginResponse | IMFALoginResponse) {
    localStorage.setItem(StorageKeys.AccessToken, data.accessToken);

    await FeatureFlagsModule.fetchFeatureFlags();

    this.submitError = null;
    trackLogin();
    this.setLocalStorageData(data);

    localStorage.setItem('warren.home.experience', 'true');

    if (data.user.clientType.b2bPartner) {
      this.partnerSignin(data.user);
    } else {
      if (!data.user.isSuitabilityFinished) {
        localStorage.setItem(StorageKeys.FastRegisterSuitability, 'true');
      }
      if (data.user.clientType.whitelabel) {
        if (data.partnerInfos?.tradeMark) {
          localStorage.setItem(
            'warren.fastRegister.tradeMark',
            data.partnerInfos.tradeMark
          );
        }
      }
      await CustomerModule.fetchCustomer();
      this.clientSignin();
    }
  }

  private setLocalStorageData(data: ILoginResponse | IMFALoginResponse) {
    const localStorageData = {
      [StorageKeys.Brand]: JSON.stringify(data?.brand || null),
      [StorageKeys.CustomerId]: data.user._id!,
      [StorageKeys.ForceWrnUserUpdate]: 'true',
      [StorageKeys.IsBrandOwner]: String(data.isBrandOwner)
    };

    Object.entries(localStorageData).forEach(([key, value]) =>
      localStorage.setItem(key, value)
    );
    if (data.user.clientType.whitelabel)
      localStorage.setItem(StorageKeys.WhitelabelTheme, 'whitelabel');
  }

  private createUrlParams(partnerId: string): urlParams {
    const attrs: urlParams = {
      category: 'account',
      action: 'Login',
      label: window.location.href
    };

    if (partnerId) attrs.partnerId = partnerId;
    if (this.brand.id) attrs.brandId = this.brand.id;

    return attrs;
  }

  private clientSignin() {
    const redirect = this.$route.query.redirect as string;
    localStorage.setItem(StorageKeys.IsPartnerAccountSession, 'false');
    this.$router.replace(redirect || '/home');
  }

  private partnerSignin(user: ICustomer) {
    const localStorageFeatures = localStorage.getItem(StorageKeys.Features);
    if (localStorageFeatures) {
      const featuresArray = JSON.parse(localStorageFeatures);
      localStorage.setItem(
        StorageKeys.PartnerFeatures,
        JSON.stringify(featuresArray)
      );
    }

    localStorage.setItem(StorageKeys.Partner, JSON.stringify(user));
    localStorage.setItem(StorageKeys.IsPartnerAccountSession, 'true');
    if (this.isWhitelabel && !isPartnerRunningStandalone()) {
      // Partner login from the partner url
      this.$router.push({ name: 'b2b-dash' });
    } else {
      this.redirectToPartnerArea(user._id);
    }
  }

  private redirectToPartnerArea(userId: string) {
    let url = PARTNER_DASH_URL;
    const isLocalEnv = process.env.VUE_APP_ENVIRONMENT === 'localhost';
    const urlParams = this.createUrlParams(userId);
    if (isPartnerRunningStandalone()) {
      url = isLocalEnv
        ? PARTNER_STANDALONE_DASH_URL_LOCAL
        : PARTNER_STANDALONE_DASH_URL;
    }
    this.redirectToUrl(
      url,
      {
        trackLogin: 'true',
        userId,
        ...urlParams
      },
      true
    );
  }

  private handleSignup() {
    trackSignupLink();
  }

  get brandDomain() {
    const params = this.getURLParams();
    return params.brand || '';
  }

  get getSuitaLink() {
    if (!this.isWarrenManaged && this.referralId) {
      return `/app/#/signup?context=b2b&brand=${this.brandDomain}&brandName=${this.brand.name}&referralId=${this.referralId}`;
    }
    return '/app/#/signup';
  }

  get isB2CAndNotStepPassword() {
    return !this.isWhitelabel && !this.isStepPassword;
  }

  get showDivider() {
    return this.isStepPassword || this.isB2CAndNotStepPassword;
  }

  get showBackButton() {
    return (
      !this.isWhitelabel ||
      (this.isStepPassword && !this.isLoadingTheme && !this.isFormLoading)
    );
  }

  get isStepPassword() {
    return this.step === 'password';
  }

  private get profileType() {
    return this.isUserWhitelabel ? 'b2b' : '';
  }
}
