import { Route } from 'vue-router';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

export const beforeEnterFaceMatch = (
  to: Route,
  from: Route,
  next: Function
) => {
  if (!FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_FACE_MATCH)) {
    next({ name: 'home' });
    return;
  }

  next();
};
